<template>
  <div class="q-pa-md" style="background-color: #f1f2f7;">
    <q-list>
      <q-item clickable v-ripple ripple @click="getTemplate()">
         <q-item-section avatar>
          <q-icon name="fa fa-download" color="grey" />
        </q-item-section>

        <q-item-section>
          <q-item-label>MRF - Manpower Requisition Form</q-item-label>
          <q-item-label caption lines="2">Manpower Requisition Form</q-item-label>
        </q-item-section>
      </q-item>


      <q-separator spaced inset />
    </q-list>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'LeaveApplication',
  data() {
    return {

    }
  },
  methods: {

     getTemplate: function() {
      axios.create({
         baseURL: this.apiUrl,
         headers: {
          'Authorization': `Bearer ${this.token}`
         }
      })
      .get('/form/download')
      .then((res) => {
         this.downloadTemplate(res.data.data.mrf_form)
      })
     },

     downloadTemplate: function(url) {
      axios({
         url: this.apiUrl + '/' + url,
         method: 'GET',
         responseType: 'arraybuffer',
      }).then((response) => {
         var fileURL = window.URL.createObjectURL(new Blob([response.data]));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', 'MRF_Form.pdf');
         document.body.appendChild(fileLink);

         fileLink.click();
      });
     },

  }
}
</script>
<style scoped>
</style>
