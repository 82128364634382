<template>
	<div class="q-pa-md">
		<div class="q-pa-md">
			<div class="row">
				<div class="row justify q-banner--top-padding">
					<h4 class="q-ma-none">MWE-Daily Settings</h4>
				</div>
				<div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
					<q-select v-model="region" option-value="id" option-label="region_no" :options="regionOptions" label="REGIONS" emit-value map-options @input="getCoverage" style="width: 200px"/>
					<q-select v-model="coverage" option-value="id" option-label="coverage" :options="coverageOptions" label="COVERAGE" emit-value map-options style="width: 200px" @input="getSector"/>
					<q-select v-model="sector" option-value="id" option-label="sector" :options="sectorOptions" label="SECTOR" emit-value map-options style="width: 200px" @input="displayData"/>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12 q-mb-md q-pr-xl">
				<div class="q-pa-md">
					<q-table
					title="MWE-Monthly List"
					:data="data"
					:columns="columns"
					row-key="id"
					:pagination.sync="pagination">
					<template v-slot:body="props">
						<q-tr slot="body"  :props="props"  style="cursor: pointer;">
							<q-td key="id" :props="props">{{ props.row.id }}</q-td>
							<q-td key="region" :props="props">{{ props.row.region }}</q-td>
							<q-td key="coverage" :props="props">{{ props.row.coverage }}</q-td>
							<q-td key="sector" :props="props">{{ props.row.sector }}</q-td>
							<q-td key="basic" :props="props">{{ props.row.basic }}</q-td>
							<q-td key="allowance" :props="props">{{ props.row.allowance }}</q-td>
							<q-td key="deminimis" :props="props">{{ props.row.deminimis }}</q-td>
							<q-td key="total" :props="props">{{ props.row.total }}</q-td>
							<q-td key="wonumber" :props="props">{{ props.row.wonumber }}</q-td>
							<q-td key="action" :props="props">
								<div >
									<q-btn round outline  color="orange" @click="editDialog(props)"> 
										<i class="fas fa-edit"></i>
										<q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" content-class="bg-orange">
											<strong>Edit</strong>
										</q-tooltip>
									</q-btn>
									<q-btn round outline  color="red" @click="removeRow(props)"> 
										<i class="fas fa-trash"></i>
										<q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" content-class="bg-red">
											<strong>Remove</strong>
										</q-tooltip>
									</q-btn>
								</div>
							</q-td>
						</q-tr>
					</template>
					<template v-slot:no-data="">
						<div class="full-width row flex-center text-red q-gutter-sm">
							<i class="fas fa-exclamation-triangle"></i>
							<span>
								No Result Found!
							</span>
						</div>
					</template>
				</q-table>
			</div>
		</div>
	</div>
	<q-page-sticky position="bottom-right" :offset="[18, 18]">
		<q-btn fab icon="fas fa-plus" @click="addDialog" color="primary" />
	</q-page-sticky>
	<q-dialog v-model="spinner" persistent >
		<q-card class="my-card transparent no-shadow">
			<q-card-section >
				<q-spinner-gears color="cyan"  size="5.5em"/>
			</q-card-section>
		</q-card>
	</q-dialog>

	<q-dialog v-model="deleted" persistent>
		<q-card>
			<q-card-section class="row items-center">
				<span class="q-ml-sm">This file will permanently delete.</span>
			</q-card-section>
			<q-card-actions align="right">
				<q-btn flat label="Cancel" color="primary" v-close-popup />
				<q-btn flat label="Continue" color="primary" v-close-popup @click="deletionConfirmation"/>
			</q-card-actions>
		</q-card>
	</q-dialog>
	<AddMWEDailyDialog></AddMWEDailyDialog>
</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';
import AddMWEDailyDialog from './AddMWEDailyDialog';

export default {
	components: {
		"AddMWEDailyDialog": AddMWEDailyDialog,
	},
	data () {
		return {
			tempID:0,
			spinner:false,
			deleted:false,
			region:null,
			coverage:null,
			sector:null,
			regionOptions:[],
			coverageOptions:[],
			sectorOptions:[],
			data:[],
			type:'daily',
			columns: [
			{ name: 'region', align: 'center', label: 'Region', field: 'region', sortable: true },
			{ name: 'coverage', align: 'center', label: 'Coverage', field: 'coverage', sortable: true },
			{ name: 'sector', align: 'center', label: 'Sector', field: 'sector' , sortable: true},
			{ name: 'basic', align: 'center', label: 'Basic', field: 'basic' , sortable: true},
			{ name: 'allowance', align: 'center', label: 'Allowance', field: 'allowance' , sortable: true},
			{ name: 'deminimis', align: 'center', label: 'Deminimis', field: 'deminimis' , sortable: true},
			{ name: 'total', align: 'center', label: 'Total', field: 'total' , sortable: true},
			{ name: 'wonumber', align: 'center', label: 'W.O Number', field: 'wonumber' , sortable: true},
			{ name: 'action', align: 'center', label: 'Action', field: '', sortable: true },
			],
			pagination: {
				rowsPerPage: 20
			},
		}
	},

	created(){
		this.eventHub.$on('updateList', this.displayData);
		this.displayRegions()
	},

	methods: {

		addDialog(emp) {
			this.eventHub.$emit('AddMWEMonthly',{type:'add',status:true,emp:''});
		},

		editDialog(emp) {
			this.eventHub.$emit('AddMWEMonthly',{type:'edit',status:true,emp:emp});
		},

		displayRegions: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/admin/regions`)
			.then((res) => {

				this.regionOptions = res.data.data;
				setTimeout(() => {
					this.spinner = false
				},500)
			})
		},

		getCoverage:function(){
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/admin/region/sector/${this.region}`)
			.then((res) => {
				this.coverage = ''
				this.sector = ''
				this.coverageOptions = res.data.data;
				setTimeout(() => {
					this.spinner = false
				},500)
			})
		},

		getSector:function(){
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/admin/sectors/${this.coverage}`)
			.then((res) => {
				this.sector = ''
				this.sectorOptions = res.data.data;
				setTimeout(() => {
					this.spinner = false
				},500)
			})
		},



		displayData: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/admin/mwe/monthly/${this.region}/${this.coverage}/${this.sector}/${this.type}`)
			.then((res) => {
				this.data = res.data.mwemonthly;
				setTimeout(() => {
					this.spinner = false
				},500)
			})

		},

		removeRow:function(obj){
			console.log(obj.row.od)
			this.deleted = true
			this.tempID = obj.row.id

		},

		deletionConfirmation:function(){
			this.spinner = true;
			let formData = new FormData();
			formData.append("tempID", this.tempID);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/admin/mwe/delete',formData)
			.then((res) => {
				this.tempID = 0
				this.spinner = false
				this.displayData()
			});
		}

	}
}
</script>
<style>
.caps {
	text-transform: uppercase
}
</style>