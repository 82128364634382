<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="col-12 q-mb-md right">
        <h4 class="q-ma-none">List of Employees </h4><br />
        <div class="row">
          <div class="row justify-end q-carousel__navigation-inner">
            <q-select v-model="location" :options="locationOptions" label="LOCATION" @input="filterByLocation" style="width: 200px" tabindex="0" />
            <q-select v-model="department" :options="departments" option-value="id" option-label="name"  label="DEPARTMENT" @input="filterByDepartment" style="width: 200px" />
          </div>
        </div>
      </div>
      <div class="col-12 q-mb-md">
        <div class="col-12 q-mb-md">
          <q-table title="Employees" dense :data="searchEmployee" :columns="columns" row-key="id" :pagination.sync="pagination">
            <template v-slot:body="props">
              <q-tr slot="body" :props="props">
                <q-td key="photo" :props="props">
                  <q-avatar>
                    <img src="../../assets/boy-avatar.png">
                  </q-avatar>
                </q-td>
                <q-td key="companyname" :props="props">{{ props.row.companyname }}</q-td>
                <q-td key="location" :props="props">{{ props.row.location }}</q-td>
                <q-td key="employee_id" :props="props">{{ props.row.employee_id }}</q-td>
                <q-td key="firstname" :props="props">{{ props.row.firstname }}</q-td>
                <q-td key="middlename" :props="props">{{ props.row.middlename }}</q-td>
                <q-td key="lastname" :props="props">{{ props.row.lastname }}</q-td>
                <q-td key="position" :props="props">{{ props.row.position }}</q-td>
                <q-td key="account" :props="props">{{ props.row.account }}</q-td>
                <q-td key="is_active" :props="props">
                  <q-btn  size="xs" :color="props.row.is_active == 'active' ? 'green': props.row.is_active == 'hold' ? 'orange' : 'red' " rounded :label="props.row.is_active"/>
                </q-td>

                <q-td key="action" :props="props" >
                  <div>
                    <q-btn round outline color="blue" @click="selectedRow(props)" >
                      <i class="fas fa-user-edit"></i>
                      <q-tooltip anchor="top middle" content-class="bg-blue" self="bottom middle" :offset="[10, 10]" >
                        <strong>View Deatils</strong>
                      </q-tooltip>
                    </q-btn>
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:no-data="">
              <div class="full-width row flex-center text-red q-gutter-sm">
                <i class="fas fa-exclamation-triangle"></i>
                <span>
                  No Result Found!
                </span>
              </div>
            </template>
            <template v-slot:top-right="props">
              <q-input dense v-model="searchKey" placeholder="Search Employee">
                <i class="fas fa-search" slot="append"></i>
              </q-input>
            </template>
          </q-table>
          <q-dialog v-model="spinner" persistent>
            <q-card class="my-card transparent no-shadow">
              <q-card-section>
                <q-spinner-gears color="cyan" size="5.5em" />
              </q-card-section>
            </q-card>
          </q-dialog>
        </div>
        <ViewDetails></ViewDetails>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
import ViewDetails from './ViewDetails';
export default {
  name: 'ViewEmployee',
  components: {
    "ViewDetails": ViewDetails,
  },
  data() {
    return {
      type:'ACTIVE',
      canDownload:false,
      canImport:false,
      canExport:false,
      canEdit:false,
      canUpdateCareer:false,
      canMBF:false,

      canDownloadValue:1,
      canImportValue:2,
      canEmportValue:3,
      canEditValue:4,
      canUpdateCareerValue:5,
      canMBFValue:6,

      exportModaL:false,
      importfile: false,
      template: null,
      status: 'ALL',
      department: 'ALL',
      location:'ALL',
      spinner: false,
      searchKey: null,
      pagination: {
        rowsPerPage: 10
      },
      columns: [
      { name: 'photo', align: 'left', label: 'Photo', field: '', sortable: true },
      { name: 'companyname', align: 'center', label: 'Company', field: 'companyname', sortable: true },
      { name: 'location', align: 'center', label: 'Location', field: 'location', sortable: true },
      { name: 'employee_id', align: 'center', label: 'Employee ID', field: 'employee_id', sortable: true },
      { name: 'firstname', align: 'center', label: 'First Name', field: 'firstname', sortable: true },
      { name: 'middlename', align: 'center', label: 'Middle Name', field: 'middlename', sortable: true },
      { name: 'lastname', align: 'center', label: 'Last Name', field: 'lastname', sortable: true },
      { name: 'position', align: 'center', label: 'Position', field: 'position', sortable: true },
      { name: 'account', align: 'center', label: 'Account', field: 'account', sortable: true },
      { name: 'is_active', align: 'center', label: 'Status', field: 'is_active', sortable: true },
      { name: 'action', align: 'center', label: 'Action', field: '', sortable: true },
      ],
      locationOptions:[],
      employees: [],
      statuses: [],
      departments: [],
      intEmployee: [],
    }

  },

  created() {
    this.getDropdownProperties()
    this.getEmployees()
    this.autoHoldEmployee()
    this.autoCareerUpdate()

  },

  computed: {
    searchEmployee() {
      if (this.searchKey !== null) {
        return this.employees.filter(emp => {
          return emp.firstname.toLowerCase().match(this.searchKey) || emp.firstname.toUpperCase().match(this.searchKey) || emp.lastname.toLowerCase().match(this.searchKey) || emp.lastname.toUpperCase().match(this.searchKey) || emp.employee_id.match(this.searchKey) || emp.sss_number.match(this.searchKey) || emp.philhealth_number.match(this.searchKey) || emp.tin_number.match(this.searchKey) || emp.hdmf_number.match(this.searchKey) 
        });
      } else {
        return this.employees
      }
    }
  },

  methods: {
    getEmployees: function() {
      this.spinner = true
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      })
      .get(`/employee/list/${this.userData.role_id}/${this.type}`)
      .then((res) => {
        this.employees = res.data.data;
        this.intEmployee = res.data.data;

        setTimeout(() => {
          this.spinner = false
        }, 500)
      })
    },

    selectedRow(emp) {
      this.eventHub.$emit('EditEmployee', { stat: true, employee: emp });
    },

    filterByDepartment: function() {
      if(this.department.id == 0){
        this.employees = this.intEmployee;
      }else{
        this.employees = this.intEmployee.filter(emp => emp.department_id == this.department.id);
      }
      

    },

    filterByLocation:function(){
      if(this.location.toUpperCase() == 'ALL'){
       this.employees = this.intEmployee;
     }else{
      this.employees = this.intEmployee.filter(emp => emp.location.toUpperCase() == this.location.toUpperCase());
    }
  },

  getDropdownProperties: function() {
    axios.create({
      baseURL: this.apiUrl,
      headers: { 'Authorization': `Bearer ${this.token}` }
    }).get('/employee/dropdown/properties')
    .then((res) => {

      this.locationOptions = res.data.data.location
      this.locationOptions.unshift('ALL')
      this.departments = res.data.data.department
      this.departments.unshift({'id':0,'name':'ALL'})
    });
  },

  autoCareerUpdate:function(){
    axios.create({
      baseURL: this.apiUrl,
      headers: { 'Authorization': `Bearer ${this.token}` }
    }).get('/employee/check/careerupdate')
    .then((res) => {
      console.log(res.data.message)
    });
  },

  autoHoldEmployee: function() {
    axios.create({
      baseURL: this.apiUrl,
      headers: { 'Authorization': `Bearer ${this.token}` }
    }).get('/employee/hold/status')
    .then((res) => {
      console.log(res.data.message)
    });
  },

  autoActivateEmployee: function(){
    axios.create({
      baseURL: this.apiUrl,
      headers: { 'Authorization': `Bearer ${this.token}` }
    }).get('/employee/activate/status')
    .then((res) => {
      console.log(res.data.message)
    });
  },

}
}
</script>
<style lang="css" scoped>
</style>