<template>
	<q-dialog v-model="myaddmodal">
	    <q-card class="q-bottom-sheet--list">
	        <q-card-section>
	          <div class="text-h6">Add Announcement</div>
	        </q-card-section>

	        <q-card-section>
	          	<div class="col-12 q-mb-md">

	          		<!-- START:: 11-03-2020 NICELE 11:36AM -->

						<q-input label="Subject" ref="subj" v-model="subject" :rules="[val => !!val || 'Subject is required']"/>
						<q-input label="Body" ref="message" v-model="message" type="textarea" :rules="[val => !!val || 'Body is required']"/>

						<q-file v-model="file" id="file" label="Pick one file" filled ref="file" accept="image/*,.pdf" :rules="[val => !!val || 'Attachment is required']" />

					<!-- data return : file : null -->
			        <!-- END:: 11-03-2020 NICELE 11:36AM -->
				</div>
	        </q-card-section>

	        <q-card-actions align="right" class="text-primary">
	        	<q-btn flat label="Save" type="submit" @click="addAnnouncement"/>
	          	<q-btn flat label="Close" v-close-popup />
	        </q-card-actions>
	    </q-card>
	</q-dialog>
</template>

<script>
	import axios from 'axios';
	import mixins from '../../mixins';
export default {
  	data () {
	    return {
	      myaddmodal: false,
	      date: null,
	      subject: '',
	      message: '',
	      file: null,
	      text: '',
	      dense: false,
	      selectedFile: null
	    }
 	},

    created() {
    	this.eventHub.$on('openAddModal', this.openAddModal);
    },

    methods: {
    	openAddModal:function(props){
    		this.myaddmodal = props.stat;
		},

	    pickFile() {
	    	this.file = this.$refs.file.files[0];
	    },

	    onUpload(){
	    	let formData = new FormData();
	    	formData.append("file", this.file, this.file.name);
	    	axios.post('/announcement/file',formData)
	    	.then(res => {
	    		console.log("res", res);
	    	});
	    },
	    addAnnouncement:function(){
			this.spinner=true;
			let formData = new FormData();

			// START:: 11-03-2020 NICELE 11:36AM
			this.$refs.subj.validate()
		    this.$refs.message.validate()
		    this.$refs.file.validate()

		    if (this.$refs.subj.hasError || this.$refs.message.hasError || this.$refs.file.hasError) {
		        this.formHasError = true
		    }else {
		        formData.append("subject", this.subject);
				formData.append("message", this.message);
				formData.append("files", this.file);

				console.log(formData)
				axios.create({
					baseURL : this.apiUrl,
					headers : {'Authorization' : `Bearer ${this.token}`}
				}).post('/announcement/add',formData)
				.then((res) => {
					console.log(res)
					this.myaddmodal = false;
					this.eventHub.$emit('addAnnouncement');
	            	this.$data.subject = "";
	            	this.$data.message = "";
	            	this.$data.file = "";
	            	this.$data.date = null;

	            	this.$q.notify({
			          color: 'positive',
			          message: 'Submitted'
			        })

				});
		    }
			// END:: 11-03-2020 NICELE 11:36AM

		},

    }

}
</script>
