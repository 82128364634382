<template>
	<div class="q-pa-md">
		<div class="q-pa-md" style="margin-top:-20px;">
			<div class="row">
				<div class="col-12 col-lg-12 col-xs-12  q-mb-md q-pr-xl" style="margin-top: 20px;">
					<p style="color: #564254; font-size: 22px; font-weight: bold; float: left;">Timekeeping Dashboard</p>
					<div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
						<q-select v-model="month" :options="monthOptions" label="Month" emit-value map-options option-value="id" option-label="name" style="width: 200px" @input="fetchAllData"/>
						<q-select v-model="cutoff" :options="cutoffOptions" label="Cut-off" emit-value map-options option-value="id" option-label="name" style="width: 200px" @input="fetchAllData"/>
					</div>
				</div>
			</div>
		</div>
		<div style="margin-bottom: 20px; display: flex; justify-content: center; align-items: center; flex-wrap: wrap; gap: 10px;">
			<!-- Total Vacation Leave for this Cut-Off -->
			<div style="color: #382e2c; font-size: 16px; display: flex; flex-direction: column; background: white; justify-content: center; 
				align-items: center; width: 350px; border-radius: 15px; margin-right: 10px; padding: 5px;">
					<p>Vacation Leave</p>
					<p style="font-size: 25px; font-weight: bold">{{ totalVL }}</p>
					<i class="fa fa-plane-departure fa-2x"></i>
			</div>

			<!-- Total Sick Leave for this Cut-Off -->
			<div style="color: #382e2c; font-size: 16px; display: flex; flex-direction: column; background: white; justify-content: center; 
				align-items: center; width: 350px; border-radius: 15px; margin-right: 10px; padding: 5px;">
					<p>Sick Leave</p>
					<p style="font-size: 25px; font-weight: bold">{{ totalSL }}</p>
					<i class="fa fa-procedures fa-2x"></i>
			</div>

			<!-- Total Emergency Leave for this Cut-Off -->
			<div style="color: #382e2c; font-size: 16px; display: flex; flex-direction: column; background: white; justify-content: center; 
				align-items: center; width: 350px; border-radius: 15px; margin-right: 10px; padding: 5px;">
					<p>Emergency Leave</p>
					<p style="font-size: 25px; font-weight: bold">{{ totalEL }}</p>
					<i class="fa fa-running fa-2x"></i>
			</div>

			<!-- Total Official Business Trip for this Cut-Off -->
			<div style="color: #382e2c; font-size: 16px; display: flex; flex-direction: column; background: white; justify-content: center; 
				align-items: center; width: 350px; border-radius: 15px; margin-right: 10px; padding: 5px;">
					<p>Business Trip</p>
					<p style="font-size: 25px; font-weight: bold">{{ totalOBT }}</p>
					<i class="fa fa-user-tie fa-2x"></i>
			</div>

			<!-- Total Change of Work for this Cut-Off -->
			<div style="color: #382e2c; font-size: 16px; display: flex; flex-direction: column; background: white; justify-content: center; 
				align-items: center; width: 350px; border-radius: 15px; margin-right: 10px; padding: 5px;">
					<p>Change of Schedule</p>
					<p style="font-size: 25px; font-weight: bold">{{ totalCWS }}</p>
					<i class="fa fa-calendar-alt fa-2x"></i>
			</div>

			<!-- Total Overtime for this Cut-Off -->
			<div style="color: #382e2c; font-size: 16px; display: flex; flex-direction: column; background: white; justify-content: center; 
				align-items: center; width: 350px; border-radius: 15px; margin-right: 10px; padding: 5px; margin-top: 10px">
					<p>Overtime</p>
					<p style="font-size: 25px; font-weight: bold">{{ totalOT }}</p>
					<i class="fa fa-user-clock fa-2x"></i>
			</div>
		</div>
		<!-- <div class="row">
			<div class="col-8 col-lg-8 col-xs-12 q-mb-md q-pr-xl">
				 <q-card class="my-card">
					<q-card-section>
						<div class="text-h5 q-mt-sm q-mb-xs">Account Statictic</div>
						<apexchart width="100%" height="300px" type="bar" :options="colchartOptions" :series="colseries"></apexchart>
					</q-card-section>
				</q-card>
				<q-tooltip anchor="bottom middle" content-class="bg-blue-grey-9" self="bottom middle" :offset="[10, 10]" >
                    <strong>Approval Leaves Statistic</strong>
                </q-tooltip>
			</div>
			<div class="col-4 col-lg-4 col-xs-12 q-mb-md q-pr-xl">
				 <q-card class="my-card">
					<q-card-section>
						<div class="text-h5 q-mt-sm q-mb-xs">Submitted Schedule</div>
						<div class="row">
							<div class="col-4 col-lg-4 col-xs-12">
							    <q-list dense >
							      <q-item  v-for="(dept,index) in whoSubmit" :key="index" style="width:300px;">
							      	<q-item-section avatar :class="dept.iconColor" >
	                                    <i :class="dept.icon"></i>
	                                </q-item-section>
							        <q-item-section :class="dept.textColor" style="margin-left:-38px;">
							          {{ dept.name }}
							        </q-item-section>
							      </q-item>
							    </q-list>
								 
							</div>
							<div class="col-8 col-lg-8 col-xs-12">
								<div class="q-pa-md flex flex-center" style="height:315px">
							    <q-knob v-model="knobValue" show-value size="280px" :thickness="0.40" color="green" track-color="green-3" class="text-green q-ma-md" readonly>
							    	<small>{{ knobValue }}%</small>
							    </q-knob>
							  	</div>
						  	</div>
					  	</div>
					</q-card-section>
					<q-tooltip anchor="bottom middle" content-class="bg-blue-grey-9" self="bottom middle" :offset="[10, 10]" >
                       	<strong>Completion of Schedule Submission per Department</strong>
                    </q-tooltip>
				</q-card>
			</div>
		</div> -->
		<div class="row" style="margin-left: 40px;">
			<div class="col-12 col-lg-12 col-xs-12  q-mb-md q-pr-xl">
				 <q-card class="my-card">
					<q-card-section>
						<div style="font-size: 18px; color: #382e2c; font-weight: bold;">Employee Statistics</div>
						<apexchart width="100%" height="300px" type="bar" :options="barchartOptions" :series="barseries"></apexchart>
					 </q-card-section>
				</q-card>
				<q-tooltip anchor="bottom middle" content-class="bg-blue-grey-9" self="bottom middle" :offset="[10, 10]" >
                   <strong>Total Number of Employee Monthly</strong>
                </q-tooltip>
			</div>
		</div>
		<div class="row" style="margin-left: 40px;">
			<div class="col-12 col-lg-12 col-xs-12  q-mb-md q-pr-xl">
				 <q-card class="my-card">
					<q-table 
					:data="forApproval" 
					:columns="columns" 
					row-key="id"
					:pagination.sync="pagination">>
					<template v-slot:top>
						<div style="height: 40px; margin-bottom: 20px; padding-top: -0px;">
							<h2 style="font-size: 18px; color: #382e2c; font-weight: bold;">For Approval</h2>
						</div>
					</template>
					<template v-slot:header="props">
				        <q-tr :props="props" >
					        <q-th v-for="col in props.cols" :key="col.name" :props="props" style="font-weight: bolder;"> <!-- :colspan="col.span" -->
					            {{ col.label }}
					         </q-th>
				        </q-tr>
			      </template>
			       <template v-slot:body="props">
					<q-tr slot="body" :props="props" >
						<q-td class="text-center">
							{{ props.row.name }}
						</q-td>
						<q-td v-for="(total,j) in props.row.data" :key="j" class="text-center">
							{{ total }}
						</q-td>
					</q-tr>
					</template>  
					<template v-slot:no-data="">
		              <div class="full-width row flex-center text-red q-gutter-sm">
		                <i class="fas fa-exclamation-triangle"></i>
		                <span>No Filed for this Cut-Off</span>
		              </div>
		            </template>
      				</q-table>
					
				</q-card>
				<q-tooltip anchor="bottom middle" content-class="bg-blue-grey-9" self="bottom middle" :offset="[10, 10]" >
                   <strong>Total Number of Employee Monthly</strong>
                </q-tooltip>
			</div>
		</div>
		<q-dialog v-model="spinner" persistent>
            <q-card class="my-card transparent no-shadow">
              <q-card-section>
                <q-spinner-gears color="cyan" size="5.5em" />
              </q-card-section>
            </q-card>
         </q-dialog>
	</div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import axios from 'axios';

export default {
	components: {
		"apexchart" : VueApexCharts,
	},

data(){
	return {
		spinner:false,
		totalVL:0,
		totalSL:0,
		totalEL:0,
		totalOBT:0,
		totalCWS:0,
		totalOT:0,
		knobValue:0,
		whoSubmit:[],
		forApproval:[],
		columns:[],
		pagination: {
			rowsPerPage: 10
		},
		month:new Date().getMonth() + 1,
		cutoff: new Date().getDate() > 10 && new Date().getDate() < 26 ? 2 : 1,
		cutoffOptions:[{id:1,name:'First Cut-off'},{id:2,name:'Second Cut-off'}],
		monthOptions:[
		{id:1,name:'January'},
		{id:2,name:'February'},
		{id:3,name:'March'},
		{id:4,name:'April'},
		{id:5,name:'May'},	
		{id:6,name:'June'},	
		{id:7,name:'July'},
		{id:8,name:'August'},
		{id:9,name:'September'},
		{id:10,name:'October'},
		{id:11,name:'November'},	
		{id:12,name:'December'},],
		colseries:[],
		colchartOptions: {
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: 'smooth',
			},
			xaxis: {
				type: 'datetime',
				categories: [],
			},
			tooltip: {
				x: {
					format: 'MMMM'
				},
			}
		},

		//bar graph
        barseries:[],
		barchartOptions: {
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: 'smooth',
			},
			xaxis: {
				type: 'datetime',
				categories: [],
			},
			tooltip: {
				x: {
					format: 'MMMM'
				},
			}
		},
	}
},

created(){
	this.fetchAllData()
},

methods: {

	fetchAllData:function(){

		this.spinner = true
	  	axios.all([
	  		this.getTotalData(),
	  		this.getColumnData(),
	  		this.getKnobData(),
	  		this.getBarData(),
	  		this.getforApproval()
	  		]).then(axios.spread((total,col,knob,bar,fa) => {
	  			//for total data
				this.totalVL = total.data.data.totalVL
				this.totalSL = total.data.data.totalSL
				this.totalEL = total.data.data.totalEL
				this.totalOBT = total.data.data.totalOBT
				this.totalCWS = total.data.data.totalCWS
				this.totalOT = total.data.data.totalOT

				//for column
				this.colseries = col.data.data.barseries
				col.data.data.barcategories.forEach((res) => {
					this.colchartOptions.xaxis.categories.push(res)
				})

				//for knob data
				this.knobValue = knob.data.data.percentage
				this.whoSubmit = knob.data.data.whosubmit
		
				//for line data
				this.barseries = bar.data.data.lineSeries
				bar.data.data.lineCat.forEach((res) => {
					this.barchartOptions.xaxis.categories.push(res)
				})

				//for headers 
				this.columns = fa.data.header
				this.forApproval = fa.data.total
				 setTimeout(() => {
	                this.spinner = false
	            }, 1000);
		
		  }));
	},

	getTotalData:function()  {
  		return  axios.create({
		        baseURL: this.apiUrl,
		        headers: {
		          'Authorization': `Bearer ${this.token}`
		        }
		      }).get(`/dashboard/timekeeping/total/${this.month}/${this.cutoff}`);
	},

	getKnobData:function() {
  		return axios.create({
		        baseURL: this.apiUrl,
		        headers: {
		          'Authorization': `Bearer ${this.token}`
		        }
		      }).get(`/dashboard/timekeeping/knob/${this.month}/${this.cutoff}`);
	},

	getBarData:function() {
  		return axios.create({
		        baseURL: this.apiUrl,
		        headers: {
		          'Authorization': `Bearer ${this.token}`
		        }
		      }).get('/dashboard/employee/line');
	},

	getColumnData:function() {
  		return axios.create({
		        baseURL: this.apiUrl,
		        headers: {
		          'Authorization': `Bearer ${this.token}`
		        }
		      }).get('/dashboard/employee/bar');
	},

	getforApproval:function() {
  		return axios.create({
		        baseURL: this.apiUrl,
		        headers: {
		          'Authorization': `Bearer ${this.token}`
		        }
		      }).get(`/dashboard/timekeeping/filed/leaves/${this.month}/${this.cutoff}`);
	},

}
}
</script>
<style lang="css" scoped>

.hitler-height{
	height:20px;
}
.hitler-icon{
	margin-top:-40px;
}

</style>