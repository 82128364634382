<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="row q-mb-md full-width">
        <div class="full-width" style="height: 40px;">
          <h4 class="q-ma-none row inline">Undertime</h4>
          <q-banner dense rounded class="bg-orange text-white float-right">
            <template v-slot:avatar>
              <q-icon name="fa fa-info-circle" color="white" />
            </template>
            Undertime should not be more than half of shift schedule.
          </q-banner>
        </div>
      </div>
      <q-form class="full-width">
        <div class="full-width row justify-center q-ma-md">
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Inclusive Date</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div class="row ">
                <div class="col-12">
                  <q-input v-model="utDate" outlined color="red-7" readonly>
                    <template v-slot:append>
                      <q-icon name="fa fa-calendar-alt" class="cursor-pointer">
                        <q-popup-proxy ref="qUTDateProxy" transition-show="flip-down" transition-hide="flip-up">
                          <q-date v-model="form.selectedUTDate" @input="$refs.qUTDateProxy.hide()" today-btn color="red-7" minimal />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="full-width row justify-center q-ma-md">
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Inclusive Time</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div class="row">
                <div class="col-6 q-pr-sm">Shift Schedule Time-In</div>
                <div class="col-6 q-pl-sm">Shift Schedule Time-Out</div>
              </div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none">
              <div class="row ">
                <div class="col-6 q-pr-sm">
                  <q-input :value="originalTimeIn" outlined color="red-7" readonly>
                    <template v-slot:append>
                      <q-icon name="fa fa-clock" class="cursor-pointer">
                        <q-popup-proxy transition-show="flip-down" transition-hide="flip-up">
                          <q-time v-model="form.shiftTimeIn" :minute-options="minuteOptions" landscape color="red-7" minimal>
                            <div class="row items-center justify-end q-gutter-xs">
                              <q-btn label="Cancel" color="red-7" style="width: 80px" flat v-close-popup />
                              <q-btn label="OK" color="red-7" style="width: 80px" flat @click="saveTime('shiftTimeIn', form.shiftTimeIn)" v-close-popup />
                            </div>
                          </q-time>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
                <div class=" col-6 q-pl-sm">
                  <q-input v-model="originalTimeOut" :disable="shiftTimeWorking" standout="bg-red-7 text-white" color="red-7" readonly />
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-md">
              <div class="row">
                <div class="col-6 q-pr-sm">Undertime</div>
              </div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none">
              <div class="row ">
                <div class="col-12 q-pr-sm">
                  <q-input :value="undertimeOut" :disable="shiftTimeWorking" outlined color="red-7" readonly>
                    <template v-slot:append>
                      <q-icon name="fa fa-clock" class="cursor-pointer">
                        <q-popup-proxy transition-show="flip-down" transition-hide="flip-up">
                          <q-time v-model="form.utTimeOut" landscape color="red-7" minimal>
                            <div class="row items-center justify-end q-gutter-xs">
                              <q-btn label="Cancel" color="red-7" style="width: 80px" flat v-close-popup />
                              <q-btn label="OK" color="red-7" style="width: 80px" flat @click="saveTime('utTimeOut', form.utTimeOut)" v-close-popup />
                            </div>
                          </q-time>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="full-width row justify-center q-ma-md">
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Reason</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div style="width: 100%; max-height: 150px">
                <q-input type="textarea" v-model="form.utReason" outlined color="red-7" maxlength="250" clearable bottom-slots counter hint="Max 250 characters" />
              </div>
            </q-card-section>
          </q-card>
        </div>
        <!--<div class="full-width row justify-center q-ma-md">
            <strong>ISO NUMBER: HR-007 REV.00</strong>
         </div>-->
      </q-form>
      <div class="full-width row justify-center q-ma-md">
        <div class="row">
          <div class="col-12 q-mt-md">
            <q-btn class="col-4 q-pa-none q-ma-md" @click="resetForm" size="md" style="width: 200px" push color="red-7">RESET</q-btn>
            <q-btn class="col-4 q-pa-none q-ma-md" @click="submitApplication" :loading="loading" size="md" style="width: 200px" push color="red-7">SUBMIT
              <template v-slot:loading>
                <q-spinner-hourglass class="on-left" />
                Sending...
              </template>
            </q-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';

let today = Date.now();
const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

export default {
  name: 'Overtime',
  props: ['value'],
  data() {
    return {
      shiftHours: 0,
      halfShiftHours: 0,
      halfShiftHours2Mins: 0,
      loading: false,
      form: {
        selectedUTDate: '',
        shiftTimeIn: '',
        shiftTimeOut: '',
        utTimeOut: '',
        utReason: ''
      },
      shiftTimeWorking: true,
      originalTimeIn: 'Please select a time...',
      originalTimeOut: 'Please select shift time-in...',
      undertimeOut: 'Please select shift time-in...',
      undertimeOutTemp: 0,
      undertimeDiff: 0,
      minuteOptions: [0, 30]
    }
  },
  methods: {
    saveTime: function(type, timeSent) {
      this.shiftHours = this.userData.shift_hours;
      this.halfShiftHours = this.shiftHours / 2;

      var tempTime = timeSent.split(':');
      var newTime = date.buildDate({ hours: tempTime[0], minutes: tempTime[1] });

      switch (type) {
        case 'shiftTimeIn':
          var originalFormattedTimeIn = date.formatDate(newTime, 'hh:mm A');
          this.originalTimeIn = originalFormattedTimeIn;
          var originalTimeOutPlus = date.addToDate(newTime, { hours: this.shiftHours });
          var originalFormattedTimeOut = date.formatDate(originalTimeOutPlus, 'hh:mm A');
          var finalTimeOut = date.formatDate(originalTimeOutPlus, 'HH:mm');
          this.originalTimeOut = originalFormattedTimeOut;
          this.form.shiftTimeOut = finalTimeOut;
          this.shiftTimeWorking = false;
          this.undertimeOut = 'Please select undertime hours...';
          break;
        case 'utTimeOut':
          this.undertimeOutTemp = timeSent;
          var undertimeOut = newTime;
          var tempShiftTimeOut = this.form.shiftTimeOut.split(':');
          var newShiftTimeOut = date.buildDate({ hours: tempShiftTimeOut[0], minutes: tempShiftTimeOut[1] });
          this.undertimeDiff = date.getDateDiff(newShiftTimeOut, undertimeOut, 'minutes')
          var newFormattedUTStart = date.formatDate(newTime, 'hh:mm A');
          this.undertimeOut = newFormattedUTStart;
          this.halfShiftHours2Mins = this.halfShiftHours * 60;
          break;
      }
    },

    submitApplication: function() {
      this.form.selectedUTDate = date.formatDate(this.form.selectedUTDate, 'YYYY-MM-DD');

      if (this.form.selectedUTDate == undefined || this.form.shiftTimeIn == '' ||
        this.form.shiftTimeOut == '' || this.form.utTimeOut == '' || this.form.utReason == '') {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please fill-in all necessary details.'
        });
      } else if (this.undertimeOutTemp > this.form.shiftTimeOut) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Undertime is greater than shift time-out.'
        });
      } else if (this.undertimeDiff >= this.halfShiftHours2Mins) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Undertime is greater than half of the shift hours. File for Halfday instead.'
        });
      } else {
        this.loading = true;

        let formData = new FormData();
        formData.append('form_data', JSON.stringify(this.form));

        axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).post('/online-forms/applications/undertime', formData).then((res) => {
          setTimeout(() => {
            this.loading = false;
            this.$q.notify({
              color: 'green-7',
              textColor: 'white',
              icon: 'fa fa-shipping-fast',
              message: 'Undertime Application Submitted'
            });
            this.resetForm();
          }, 1000);
        });
      }
    },

    resetForm: function() {
      var self = this;
      Object.keys(this.form).forEach(function(key, index) {
        self.form[key] = '';
      });
      this.originalTimeIn = 'Please select a time...';
      this.originalTimeOut = 'Please select shift time-in...',
        this.undertimeOut = 'Please select shift time-in...',
        this.shiftTimeWorking = true;
      setTimeout(() => {
        scrollToTop();
      }, 200);
    }
  },
  computed: {
    utDate: function() {
      if (this.form.selectedUTDate == '') {
        return 'Please select a date...';
      } else {
        let d = new Date(this.form.selectedUTDate);
        return date.formatDate(d, 'dddd, MMMM DD, YYYY');
      }
    }
  }
}
</script>
<style scoped>
</style>
