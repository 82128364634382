<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="row q-mb-md full-width">
        <div class="full-width" style="height: 40px;">
          <h4 class="q-ma-none row inline">Overtime</h4>
          <q-banner dense rounded class="bg-orange text-white float-right">
            <template v-slot:avatar>
              <q-icon name="fa fa-info-circle" color="white" />
            </template>
            Overtime should be min. of one(1) hour.
          </q-banner>
        </div>
      </div>
      <q-form class="full-width">
        <div class="full-width row justify-center q-ma-md">
          <!-- <div class="row col-4 q-pr-sm"> -->
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Inclusive Date</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div class="row">
                <div class="col-6 q-pr-sm">Overtime Start Date</div>
                <div class="col-6 q-pl-sm">Overtime End Date</div>
              </div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div class="row ">
                <div class="col-6 q-pr-sm">
                  <q-input v-model="otStartDate" outlined color="red-7" readonly>
                    <template v-slot:append>
                      <q-icon name="fa fa-calendar-alt" class="cursor-pointer">
                        <q-popup-proxy ref="qOTSTDateProxy" transition-show="flip-down" transition-hide="flip-up">
                          <q-date v-model="form.selectedOTStartDate" @input="$refs.qOTSTDateProxy.hide()" today-btn color="red-7" minimal />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
                <div class="col-6 q-pr-sm">
                  <q-input v-model="otEndDate" outlined color="red-7" readonly>
                    <template v-slot:append>
                      <q-icon name="fa fa-calendar-alt" class="cursor-pointer">
                        <q-popup-proxy ref="qOTEDDateProxy" transition-show="flip-down" transition-hide="flip-up">
                          <q-date v-model="form.selectedOTEndDate" @input="$refs.qOTEDDateProxy.hide()" today-btn color="red-7" minimal />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
                <!-- <div class="col-12">
                    <q-input v-model="otDate" outlined color="red-7" readonly>
                      <template v-slot:append>
                        <q-icon name="fa fa-calendar-alt" class="cursor-pointer">
                          <q-popup-proxy ref="qOTDateProxy" transition-show="flip-down" transition-hide="flip-up">
                            <q-date v-model="form.selectedOTDate" @input="$refs.qOTDateProxy.hide()" today-btn color="red-7" minimal />
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div> -->
              </div>
            </q-card-section>
          </q-card>
          <!-- </div> -->
        </div>
        <div class="full-width row justify-center q-ma-md">
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Schedule Type</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div class="row ">
                <div class="col-6 q-pr-sm">
                  <q-radio class="full-width" v-model="form.scheduleType" val="Regular Duty" @input="setSchedType(form.scheduleType)" label="Regular Duty" color="red-7" />
                </div>
                <div class="col-6 q-pl-sm">
                  <q-radio class="full-width" v-model="form.scheduleType" val="Restday" @input="setSchedType(form.scheduleType)" label="Restday" color="red-7" />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="full-width row justify-center q-ma-md">
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Inclusive Time</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div class="row">
                <div class="col-6 q-pr-sm">Shift Schedule Time-In</div>
                <div class="col-6 q-pl-sm">Shift Schedule Time-Out</div>
              </div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none">
              <div class="row ">
                <div class="col-6 q-pr-sm">
                  <q-input :value="originalTimeIn" :disable="shiftTimeWorking" outlined color="red-7" readonly>
                    <template v-slot:append>
                      <q-icon name="fa fa-clock" class="cursor-pointer">
                        <q-popup-proxy transition-show="flip-down" transition-hide="flip-up">
                          <q-time v-model="form.shiftTimeIn" :minute-options="minuteOptions" landscape color="red-7" minimal>
                            <div class="row items-center justify-end q-gutter-xs">
                              <q-btn label="Cancel" color="red-7" style="width: 80px" flat v-close-popup />
                              <q-btn label="OK" color="red-7" style="width: 80px" flat @click="saveTime('shiftTimeIn', form.shiftTimeIn)" v-close-popup />
                            </div>
                          </q-time>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
                <div class="col-6 q-pl-sm">
                  <q-input v-model="originalTimeOut" :disable="shiftTimeWorking" standout="bg-red-7 text-white" color="red-7" readonly />
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-md">
              <div class="row">
                <div class="col-6 q-pr-sm">Overtime Start</div>
                <div class="col-6 q-pl-sm">Overtime End</div>
              </div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none">
              <div class="row ">
                <div class="col-6 q-pr-sm">
                  <div v-show="form.scheduleType == 'Restday'">
                    <q-input :value="overtimeStart" :disable="otWorking" outlined color="red-7" readonly>
                      <template v-slot:append>
                        <q-icon name="fa fa-clock" class="cursor-pointer">
                          <q-popup-proxy transition-show="flip-down" transition-hide="flip-up">
                            <q-time v-model="form.otStart" :minute-options="minuteOptions" landscape color="red-7" minimal>
                              <div class="row items-center justify-end q-gutter-xs">
                                <q-btn label="Cancel" color="red-7" style="width: 80px" flat v-close-popup />
                                <q-btn label="OK" color="red-7" style="width: 80px" flat @click="saveTime('otStart', form.otStart)" v-close-popup />
                              </div>
                            </q-time>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>
                  <div v-show="form.scheduleType != 'Restday'">
                    <!-- <q-input v-model="overtimeStart" :disable="shiftTimeWorking" standout="bg-red-7 text-white" color="red-7" readonly /> -->
                    <q-input :value="overtimeStart" :disable="otWorking" outlined color="red-7" readonly>
                      <template v-slot:append>
                        <q-icon name="fa fa-clock" class="cursor-pointer">
                          <q-popup-proxy transition-show="flip-down" transition-hide="flip-up">
                            <q-time v-model="form.otStart" :minute-options="minuteOptions" landscape color="red-7" minimal>
                              <div class="row items-center justify-end q-gutter-xs">
                                <q-btn label="Cancel" color="red-7" style="width: 80px" flat v-close-popup />
                                <q-btn label="OK" color="red-7" style="width: 80px" flat @click="saveTime('otStart', form.otStart)" v-close-popup />
                              </div>
                            </q-time>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>
                </div>
                <div class="col-6 q-pl-sm">
                  <q-input :value="overtimeEnd" :disable="otWorking" outlined color="red-7" readonly>
                    <template v-slot:append>
                      <q-icon name="fa fa-clock" class="cursor-pointer">
                        <q-popup-proxy transition-show="flip-down" transition-hide="flip-up">
                          <q-time v-model="form.otEnd" :minute-options="minuteOptions" landscape color="red-7" minimal>
                            <div class="row items-center justify-end q-gutter-xs">
                              <q-btn label="Cancel" color="red-7" style="width: 80px" flat v-close-popup />
                              <q-btn label="OK" color="red-7" style="width: 80px" flat @click="saveTime('otEnd', form.otEnd)" v-close-popup />
                            </div>
                          </q-time>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="full-width row justify-center q-ma-md">
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Reason</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div style="width: 100%; max-height: 150px">
                <q-input type="textarea" v-model="form.otReason" outlined color="red-7" maxlength="250" clearable bottom-slots counter hint="Max 250 characters" />
              </div>
            </q-card-section>
          </q-card>
        </div>
       <!-- <div class="full-width row justify-center q-ma-md">
            <strong>ISO NUMBER: HR-008 REV.00</strong>
         </div>-->
      </q-form>
      <div class="full-width row justify-center q-ma-md">
        <div class="row">
          <div class="col-12 q-mt-md">
            <q-btn class="col-4 q-pa-none q-ma-md" @click="resetForm" size="md" style="width: 200px" push color="red-7">RESET</q-btn>
            <q-btn class="col-4 q-pa-none q-ma-md" @click="submitApplication" :loading="loading" size="md" style="width: 200px" push color="red-7">SUBMIT
              <template v-slot:loading>
                <q-spinner-hourglass class="on-left" />
                Sending...
              </template>
            </q-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';

let today = Date.now();
const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

export default {
  name: 'Overtime',
  props: ['value'],
  data() {
    return {
      shiftHours: 0,
      loading: false,
      form: {
        selectedOTStartDate: '',
        selectedOTEndDate: '',
        scheduleType: '',
        shiftTimeIn: '',
        shiftTimeOut: '',
        otStart: '',
        otEnd: '',
        totalOTHours: '',
        otReason: ''
      },
      shiftTimeWorking: true,
      otWorking: true,
      originalTimeIn: 'Please select schedule type...',
      originalTimeOut: 'Please select schedule type...',
      overtimeStart: 'Please select schedule type...',
      overtimeEnd: 'Please select schedule type...',
      minuteOptions: [0, 30],
      otMinuteOption: [],
      msOTStart: 0,
      msOTEnd: 0
    }
  },
  methods: {
    setSchedType: function(schedType) {
      this.shiftHours = this.userData.shift_hours;
      if (schedType == 'Restday') {
        this.shiftTimeWorking = true;
        this.otWorking = false;
        this.originalTimeIn = 'Restday';
        this.originalTimeOut = 'Restday';
        this.form.shiftTimeIn = '00:00';
        this.form.shiftTimeOut = '00:00';
        this.overtimeStart = 'Please select a time...';
        this.overtimeEnd = 'Please select a time...';
        this.otMinuteOption = [0, 30];
      } else {
        this.shiftTimeWorking = false;
        this.otWorking = true;
        this.originalTimeIn = 'Please select a time...';
        this.originalTimeOut = 'Please select shift time-in...';
        this.overtimeStart = 'Please select shift time-in...';
        this.overtimeEnd = 'Please select shift time-in...';
      }
    },

    saveTime: function(type, timeSent) {
      var tempTime = timeSent.split(':');
      var newTime = date.buildDate({ hours: tempTime[0], minutes: tempTime[1] });

      switch (type) {
        case 'shiftTimeIn':
          var originalFormattedTimeIn = date.formatDate(newTime, 'hh:mm A');
          this.originalTimeIn = originalFormattedTimeIn;
          var originalTimeOutPlus = date.addToDate(newTime, { hours: this.shiftHours });
          var originalFormattedTimeOut = date.formatDate(originalTimeOutPlus, 'hh:mm A');
          var finalTimeOut = date.formatDate(originalTimeOutPlus, 'HH:mm');
          this.originalTimeOut = originalFormattedTimeOut;
          this.form.shiftTimeOut = finalTimeOut;
          this.otWorking = false;
          this.overtimeStart = originalFormattedTimeOut;
          this.form.otStart = finalTimeOut;
          var tempOTStart = finalTimeOut.split(':');
          var newOTStartTime = date.buildDate({ hours: tempOTStart[0], minutes: tempOTStart[1] });
          this.msOTStart = newOTStartTime;
          break;
        case 'otStart':
          var newFormattedOTStart = date.formatDate(newTime, 'hh:mm A');
          this.overtimeStart = newFormattedOTStart;
          // this.msOTStart = newTime;
          break;
        case 'otEnd':
          var newFormattedOTEnd = date.formatDate(newTime, 'hh:mm A');
          this.overtimeEnd = newFormattedOTEnd;
          // this.msOTEnd = newTime;
          break;
      }
    },

    submitApplication: function() {
      var otStartDateSelected = date.formatDate(this.form.selectedOTStartDate, 'YYYY-MM-DD');
      var otEndDateSelected = date.formatDate(this.form.selectedOTEndDate, 'YYYY-MM-DD');
      var concatOTStart = otStartDateSelected + ' ' + this.form.otStart;
      var concatOTEnd = otEndDateSelected + ' ' + this.form.otEnd;
      var otStartDateTime = new Date(concatOTStart);
      var otEndDateTime = new Date(concatOTEnd);
      var minDiff = date.getDateDiff(otEndDateTime, otStartDateTime, 'minutes');
      var otTotalHours = parseFloat(minDiff / 60);
      console.log(otTotalHours)
      this.form.selectedOTStartDate = otStartDateSelected;
      this.form.selectedOTEndDate = otEndDateSelected;
      this.form.totalOTHours = otTotalHours;

      if (this.form.selectedOTStartDate == undefined || this.form.selectedOTEndDate == undefined ||
        this.form.scheduleType == '' || this.form.shiftTimeIn == '' || this.form.shiftTimeOut == '' ||
        this.form.otStart == '' || this.form.otEnd == '' || this.form.otReason == '') {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please fill-in all necessary details.'
        });
      } else if (otTotalHours < 1) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'OT should be minimum of 1 hour.'
        });
      } else {
        this.loading = true;

        let formData = new FormData();
        formData.append('form_data', JSON.stringify(this.form));

        axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).post('/online-forms/applications/overtime', formData).then((res) => {
            if(res.data.status){
                setTimeout(() => {
                  this.loading = false;
                  this.$q.notify({
                    color: 'green-7',
                    textColor: 'white',
                    icon: 'fa fa-check',
                    message: res.data.message
                  });
                  this.resetForm();
                }, 1000);
            }else{
                setTimeout(() => {
                  this.loading = false;
                  this.$q.notify({
                    color: 'red-7',
                    textColor: 'white',
                    icon: 'fa fa-times',
                    message: res.data.message
                  });
                  this.resetForm();
                }, 1000);
            }

        });
      }
    },

    resetForm: function() {
      var self = this;
      Object.keys(this.form).forEach(function(key, index) {
        self.form[key] = '';
      });
      this.originalTimeIn = 'Please select schedule type...';
      this.originalTimeOut = 'Please select schedule type...';
      this.overtimeStart = 'Please select schedule type...';
      this.overtimeEnd = 'Please select schedule type...';
      this.shiftTimeWorking = true;
      this.otWorking = true;
      setTimeout(() => {
        scrollToTop();
      }, 200);
    }
  },
  computed: {
    otStartDate: function() {
      if (this.form.selectedOTStartDate == '') {
        return 'Please select a date...';
      } else {
        let d = new Date(this.form.selectedOTStartDate);
        return date.formatDate(d, 'dddd, MMMM DD, YYYY');
      }
    },

    otEndDate: function() {
      if (this.form.selectedOTEndDate == '') {
        return 'Please select a date...';
      } else {
        let d = new Date(this.form.selectedOTEndDate);
        return date.formatDate(d, 'dddd, MMMM DD, YYYY');
      }
    }
  }
}
</script>
<style scoped>
</style>
