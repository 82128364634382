<template>
  <q-dialog v-model="alert" persistent transition-show="jump-down" transition-hide="jump-up">
    <div class="bg-white" style="height: auto; width: 400px; max-width: 400px;">
      <q-card class="my-card">
        <q-form ref="vForm" @submit="onSubmit">
          <q-card-section class="q-ma-none q-pa-none bg-grey-8 text-white">
            <q-item class="q-pa-md">
              <q-item-section avatar class="q-ml-xs q-mr-md" style="width:40px; min-width:40px;">
                <q-avatar text-color="white" icon="fa fa-1p5x fa-key" />
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-h5">Change Password</q-item-label>
              </q-item-section>
            </q-item>
          </q-card-section>
          <q-card-section class="q-ma-none q-pt-lg q-pl-lg q-pr-lg">
            <div class="col-12 q-col-gutter-md">
              <q-input v-model="oldPassword" color="grey-8" label-color="light-blue-10" stack-label label="Old Password" :rules="newpass" type="password" />
              <q-input v-model="newPassword" color="grey-8" label-color="light-blue-10" stack-label label="New Password" :rules="newPassRules.concat(minimumCharacters)" type="password" />
              <q-input v-model="confirmNewPassword" color="grey-8" label-color="light-blue-10" stack-label label="Confirm New Password" :rules="confirmPasswordRules.concat(passwordConfirmationRule)" type="password" />
            </div>
          </q-card-section>
          <div class="text-center q-mb-sm" v-if="flag">
            <q-chip :color="color" text-color="white">
              {{ message }}
            </q-chip>
          </div>
          <q-card-section>
            <div class="row justify-center q-gutter-md q-mb-sm">
              <q-btn type="submit" label="SUBMIT" color="primary" size="md" style="width: 100px;" />
              <q-btn label="CLOSE" color="grey-8" size="md" style="width: 100px;" @click="closeModal" />
            </div>
          </q-card-section>
        </q-form>
      </q-card>
    </div>
  </q-dialog>
</template>
<script>
import axios from 'axios';
import vueCookies from 'vue-cookies';
export default {
  name: 'ChangePassword',
  data: () => ({
    color: null,
    message: null,
    flag: false,
    alert: false,
    oldPassword: null,
    newPassword: null,
    confirmNewPassword: null,

    newpass: [v => v && v.length > 0 || 'This field is required!'],
    confirmPasswordRules: [v => !!v || 'Password is required'],
    newPassRules: [v => !!v || 'Password is required'],
  }),
  computed: {
    passwordConfirmationRule() {
      return () => this.newPassword === this.confirmNewPassword || 'Password must match';
    },
    minimumCharacters() {
      return () => this.newPassword.length >= 8 || 'Minimum of 8 characters';
    }
  },
  created() {
    this.eventHub.$on('changePassword', this.changePassword);
  },
  methods: {
    changePassword: function(payload) {
      this.alert = payload.status
    },

    closeModal: function() {
      this.alert = false;
      this.oldPassword = null;
      this.newPassword = null;
      this.confirmNewPassword = null;
    },

    onSubmit: function() {
      let formData = new FormData();
      formData.append("password", this.oldPassword);
      formData.append("newpass", this.newPassword);
      formData.append("cnewpass", this.confirmNewPassword);
      axios.create({
          baseURL: this.apiUrl,
          headers: { 'Authorization': `Bearer ${this.token}` }
        }).post('/employee/changepassword', formData)
        .then((res) => {
          this.flag = true
          this.color = 'green'
          this.message = res.data.message
          if (res.data.status == true) {
            vueCookies.remove(this.cookieKey);
            setTimeout(() => {
              location.reload();
            }, 2000);
          }
        }).catch((error) => {
          this.flag = true
          this.color = 'red'
          this.message = error.response.data.message
        });
    }
  }
}
</script>
<style>
</style>