<template>
    <div class="q-gutter-sm q-pa-lg">
      <div class="row">
        <div class="col-12 q-mb-md right">
          <h4 class="q-ma-none">Regular Employee Report </h4><br />
        </div>
        <div class="col-12 q-mb-sm">
           <q-form @submit="filterReport">
             <div class="row items-start">
               <div class="row inline q-pa-md">
                   <q-input filled outlined v-model="fromDate" mask="date" :rules="['date']" label="Date From">
                       <template v-slot:append>
                           <i class="far fa-calendar-alt cursor-pointer" >
                               <q-popup-proxy ref="fromDateProxy" transition-show="scale" transition-hide="scale">
                                   <q-date v-model="fromDate" @input="() => $refs.fromDateProxy.hide()" />
                               </q-popup-proxy>
                           </i>
                       </template>
                   </q-input>
               </div>
               <div class="row inline q-pa-md">
                   <q-input filled outlined v-model="toDate" mask="date" :rules="['date']" label="Date To">
                       <template v-slot:append>
                           <i class="far fa-calendar-alt cursor-pointer" >
                               <q-popup-proxy ref="dhDateProxy" transition-show="scale" transition-hide="scale">
                                   <q-date v-model="toDate" @input="() => $refs.dhDateProxy.hide()" />
                               </q-popup-proxy>
                           </i>
                       </template>
                   </q-input>
               </div>
               <div class="row inline q-pa-md">
                 <q-select filled v-model="store" :options="storeOptions" label="Store Name" emit-value map-options option-value="id" option-label="name"  style="width: 200px" class="q-mr-sm col" :rules="[formRules.required]"/>
               </div>
               <div class="row inline q-pa-md">
                  <q-btn style="height: 54px;background: #FF0080; color: white" label="Generate Report" type="submit" />
               </div>
             </div>
           </q-form>
        </div>
          <div class="col-12 q-mb-md">
            <q-table flat bordered :data="reportEmployee"  :columns="columns" color="primary" row-key="name" :loading="loading">
               <template v-slot:body="props">
                <q-tr slot="body" :props="props">

                  <q-td key="transaction_number" :props="props">{{ props.row.transaction_number }}</q-td>
                  <q-td key="employee_name" :props="props">{{ props.row.employee_name }}</q-td>
                  <q-td key="storename" :props="props">{{ props.row.storename }}</q-td>
                  <q-td key="department" :props="props">{{ props.row.department }}</q-td>
                  <q-td key="total_price" :props="props">{{ props.row.total_price }}</q-td>

                  <q-td key="action" :props="props" >
                    <div>
                       <q-btn outline color="primary" icon="fas fa-calendar" label="View" @click="viewDetail(props.row)"/>
                    </div>
                  </q-td>
                </q-tr>
              </template>
              <template v-slot:loading>
                  <q-inner-loading showing>
                      <q-spinner-hourglass  size="50px" color="cyan" />
                  </q-inner-loading>
              </template>
              <template v-slot:no-data>
                <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                  <span>No Result Found!</span><q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                </div>
              </template>
             <template v-slot:top-right>
               <q-btn v-show="reportEmployee.length > 0"
                 color="pink"
                 label="Download Computed CSV"
                 no-caps
                 @click="exportTable"
               />
             </template>
           </q-table>
          </div>

          <q-dialog v-model="detailsDialog" persistent>
            <q-card>
              <q-card-section>
                <div class="text-h6">Order Details</div>
              </q-card-section>

              <q-card-section class="q-pt-none" style="min-width: 50vh">
                 <div class="text-subtitle1 text-weight-bold">{{ details.transaction_number }}</div>
                 <div class="text-caption">{{ details.employee_name }}</div>
                 <div class="text-caption">{{ details.department }}</div>
                 <div class="text-caption">{{ details.date_created_formatted }}</div>
                 <q-markup-table>
                  <thead>
                    <tr>
                      <th class="text-left">Item</th>
                      <th class="text-right">Qty</th>
                      <th class="text-right">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in details.products" :key="item.id">
                      <td class="text-left">{{ item.product_name }}</td>
                      <td class="text-right">{{ item.quantity }}</td>
                      <td class="text-right">₱ {{ item.price }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th class="text-left">Total Price</th>
                      <th class="text-right"></th>
                      <th class="text-right">₱ {{ details.total_price }}</th>
                    </tr>
                  </tfoot>
                </q-markup-table>
              </q-card-section>

              <q-card-actions align="right" class="text-primary">
                <q-btn flat label="Close" v-close-popup />
              </q-card-actions>
            </q-card>
          </q-dialog>
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios';
  export default {
    name: 'Pantry',
    data() {
      return {
         loading:false,
         detailsDialog: false,
         fromDate: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
         toDate: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
         store:'',
         storeOptions: [],

        pagination: {
          rowsPerPage: 10
        },

        columns: [
        { name: 'transaction_number', align: 'left', label: 'Transaction Number', field: 'transaction_number', sortable: true },
        { name: 'employee_name', align: 'center', label: 'Employee Name', field: 'employee_name', sortable: true },
        { name: 'storename', align: 'center', label: 'Store Name', field: 'storename', sortable: true },
        { name: 'department', align: 'center', label: 'Department', field: 'department', sortable: true },
        { name: 'total_price', align: 'center', label: 'Total Price', field: 'total_price', sortable: true },
        { name: 'action', align: 'center', label: 'Action', field: '', sortable: true },
        ],
        reportEmployee:[],
        details:{}
      }

    },

    created() {
        this.storeList()
    },

    methods: {
      filterReport(){
         this.loading = true
            var form = new FormData()
            form.append('fromDate',this.fromDate);
            form.append('toDate',this.toDate);
            form.append('store',this.store);
            axios.post(
              this.apiUrl+'/mobile/pantry/regular/report',form,
              { headers : {'Authorization' : `Bearer ${this.token}`}}
            ).then((res)=>{
               this.reportEmployee = res.data.data;
               this.loading = false  
            })
        },

      exportTable () {
         var formData = new FormData()
         formData.append('fromDate',this.fromDate);
         formData.append('toDate',this.toDate);
         formData.append('store',this.store);

         axios.create({
          baseURL : this.apiUrl,
          responseType: 'arraybuffer',
          headers : {'Authorization' : `Bearer ${this.token}`}
        }).post(`/mobile/pantry/regular/export`,formData)
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'Regular_Employee_Report.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();
        });
      },

      storeList : function(){
        axios.create({
            baseURL : this.apiUrl,
            headers : {'Authorization' : `Bearer ${this.token}`}
        }).get(`/mobile/pantry/store/list`)
        .then((res) => {
            this.storeOptions = res.data
        });
      },

      viewDetail: function(obj){
         this.details = obj
         this.detailsDialog = true
      }

    }
  }
  </script>
