<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row ">
      <div class="col-12 q-mb-md">
        <h4 class="q-ma-none">Set Role Access</h4>
      </div>
      <div class="col-12 q-mb-md">
     
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';


export default {
  data() {
    return {
      loadingModel: false,
    }
  },
  methods: {
    onSubmitSearch: function() {
    
    },
  }
}
</script>
<style lang="css" scoped>
.qitemLabel {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
</style>