<template>
  <div class="q-pa-md">
    <div class="q-pa-md">
      <div class="row">
		<div class="row justify q-banner--top-padding">
            <q-btn style="margin-right: 10px;" color="blue" label="Add Loan Type" @click="openDialog"/>
			<q-btn style="margin-right: 10px;" color="green" label="Loan Template" @click="getTemplate"/>
            <q-btn color="orange" style="margin-right: 10px;" label="Add Manual Loan" @click="addManual" />
			<q-btn color="red" label="Upload Loan" @click="uploadLoan"/>
		</div>
        <div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">

            <q-input filled v-model="fromDay" mask="date" :rules="['date']">
                FROM:
              <template v-slot:append>
                <i class="far fa-calendar-alt cursor-pointer" >
                  <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="fromDay">
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Close" color="primary" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </i>
              </template>
            </q-input>

            <q-input filled v-model="toDay" mask="date" :rules="['date']">
                TO:
              <template v-slot:append>
                <i class="far fa-calendar-alt cursor-pointer" >
                  <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="toDay">
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Close" color="primary" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </i>
              </template>
            </q-input>
            <q-btn color="primary" label="Filter Data" @click="filterData"/>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
      <q-table  :dense="$q.screen.lt.md" :grid="$q.screen.xs" title="Detailed TK" :data="generatedData" :columns="columns" row-key="id" separator="cell" :pagination.sync="pagination">
        <template v-slot:body="props">
            <q-tr slot="body" :props="props">
                <q-td key="empID" :props="props">{{ props.row.empID }}</q-td>
                <q-td key="name" :props="props">{{ props.row.name }}</q-td>
                <q-td key="loanType" :props="props">{{ props.row.loanType }}</q-td>
                <q-td key="loanAmount" :props="props">{{ props.row.loanAmount }}</q-td>
                <q-td key="actualAmount" :props="props">{{ props.row.actualAmount }}</q-td>
                <q-td key="loadDate" :props="props">{{ props.row.loadDate }}</q-td>
                <q-td key="startDate" :props="props">{{ props.row.startDate }}</q-td>
                <q-td key="endDate" :props="props">{{ props.row.endDate }}</q-td>
                <q-td key="monthlyDeduction" :props="props">{{ props.row.monthlyDeduction }}</q-td>
                <q-td key="loanBalance" :props="props">{{ props.row.loanBalance }}</q-td>
                <q-td key="status" :props="props">
                    <q-badge outline :color="(props.row.status== 'ACTIVE') ? 'blue' : (props.row.status == 'HOLD') ? 'red':'green' " :label="props.row.status" />
                </q-td>
                <q-td  key="holdOrActive" :props="props">
                    <q-toggle v-model="props.row.holdOrActive" :disable="(props.row.status == 'PAID') ? true : false" @input="setState(props.row.status,props.row.id)" size="xl" checked-icon="fas fa-check" color="green" unchecked-icon="fas fa-times" />
                </q-td>
                <q-td>
                    <div class="q-pa-md q-gutter-sm">
                        <q-btn outline round color="primary" icon="fas fa-calendar-alt" @click="showHistory(props.row.id)">
                            <q-tooltip content-class="bg-primary" anchor="top middle" self="bottom middle" :offset="[10, 10]">
                              <strong>Deduction History</strong>
                            </q-tooltip>
                        </q-btn>
                        <q-btn outline round color="orange" icon="fas fa-edit" :disable="(props.row.status == 'PAID') ? true : false" @click="editLoan(props.row)">
                            <q-tooltip content-class="bg-orange" anchor="top middle" self="bottom middle" :offset="[10, 10]">
                              <strong>Edit Loan</strong>
                            </q-tooltip>
                        </q-btn>
                    </div>
                </q-td>
            </q-tr>
        </template>
        <template v-slot:no-data="">
          <div class="full-width row flex-center text-red q-gutter-sm">
            <i class="fas fa-exclamation-triangle"></i>
            <span>
              No Result Found!
            </span>
          </div>
        </template>
        <template v-slot:top>
          <q-input dense v-model="searchKey" placeholder="Search Employee">
            <i class="fas fa-search" slot="append"></i>
          </q-input>
          <q-space />
          <q-btn color="primary" label="Download Excel" style="margin-right: 10px;" no-caps @click="exportTable('xlsx')"/>
          <q-btn color="primary" label="Download CSV" no-caps @click="exportTable('csv')"/>
        </template>
      </q-table>
    </div>
    <q-dialog v-model="spinner" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section>
          <q-spinner-gears color="cyan" size="5.5em" />
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- add manual loan -->
    <q-dialog v-model="manual" persistent>
      	<q-card style="min-width: 350px">
        	<q-card-section>
          		<div class="text-h6">{{ loanTitle }}</div>
        	</q-card-section>
        	<q-card-section class="q-pt-none">
				<q-form @submit="onSubmit" class="q-gutter-md">
	 			<q-input filled v-model="employee_id" label="Employee ID" lazy-rules :rules="[ val => val && val.length > 0 || 'This field is required']"/>
				<q-select filled v-model="loan_type" :options="loanTypeOptions" label="Loan Type" />
                <q-input filled v-model="loan_amt" label="Loan Amount" lazy-rules :rules="[ val => val && val.length > 0 || 'This field is required']"/>
				<q-input filled v-model="actual_amt" label="Actual Amount" lazy-rules :rules="[ val => val && val.length > 0 || 'This field is required']"/>

                <q-input filled v-model="loan_date" mask="date">
                    Date Loan:
                  <template v-slot:append>
                    <i class="far fa-calendar-alt cursor-pointer" >
                      <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="loan_date">
                          <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Close" color="primary" flat />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </i>
                  </template>
                </q-input>

                <q-input filled v-model="start_date" mask="date">
                    Start Date:
                  <template v-slot:append>
                    <i class="far fa-calendar-alt cursor-pointer" >
                      <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="start_date">
                          <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Close" color="primary" flat />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </i>
                  </template>
                </q-input>

                <q-input filled v-model="end_date" mask="date">
                    End Date:
                  <template v-slot:append>
                    <i class="far fa-calendar-alt cursor-pointer" >
                      <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="end_date">
                          <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Close" color="primary" flat />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </i>
                  </template>
                </q-input>

                <q-input filled v-model="monthly_deduct" label="Monthly Deduction" lazy-rules :rules="[ val => val && val.length > 0 || 'This field is required']"/>
                <div align="right">
				   <q-btn label="Close" v-close-popup color="orange"/>
				   <q-btn label="Submit" type="submit" color="primary"   class="q-ml-sm" />
				</div>
   				</q-form>
        	</q-card-section>

      	</q-card>
    </q-dialog>

    <!-- manual code -->
	<q-dialog v-model="loantypeDialog" persistent>
      	<q-card class="q-bottom-sheet--list"  style="max-width:1100px;width:1100px;height:850px">
        	<q-card-section>
          		<div class="text-h6">Add Loan Type</div>
        	</q-card-section>
        	<q-card-section class="q-pt-none">
				<div class="row">
					<div class="col-3 q-mb-md" >
						<q-form @submit="loanTypeSubmit" class="q-gutter-md">
			 			<q-input filled v-model="loanType" label="Loan Type" lazy-rules :rules="[ val => val && val.length > 0 || 'This field is required']"/>
						<div align="right">
						   <q-btn label="Close" v-close-popup color="orange"/>
						   <q-btn label="Submit" type="submit" color="primary"   class="q-ml-sm" />
						</div>
		   				</q-form>
					</div>
					<div class="col-9 q-mb-md" >
						<div class="q-pa-md">
							<q-table :data="loanTypeData" :columns="loanColumns" row-key="id" :pagination.sync="pagination" >
								<template v-slot:body="props">
							  		<q-tr slot="body" :props="props" @click="selectedRow(props)">
										<q-td key="types" :props="props">{{ props.row.types }}</q-td>
							  		</q-tr>
								</template>
								<template v-slot:no-data="">
								  	<div class="full-width row flex-center text-red q-gutter-sm">
									<i class="fas fa-exclamation-triangle"></i>
									<span>
									  No Result Found!
									</span>
								  	</div>
							</template>
				  			</q-table>
						</div>
					</div>
				</div>
        	</q-card-section>
      	</q-card>
    </q-dialog>


    <!-- import adjustment -->
	<q-dialog v-model="importfile" persistent transition-show="scale" transition-hide="scale">
		<q-card class="q-bottom-sheet--list">
			<q-form ref="form" autocomplete="off" @submit="uploadFile">
				<q-card-section>
					<div class="text-h6">Upload Loan</div>
				</q-card-section>
				<q-card-section>
					<small>(.csv Only)</small><br/>
					<q-file color="teal" filled v-model="files" label="Select your file"  accept=".csv" :rules="[formRules.required]">
						<template v-slot:prepend>
						  <i class="fa fa-upload"> </i>
						</template>
					 </q-file>
				</q-card-section>
				<q-card-actions align="right">
					<div>
						<q-btn flat label="Submit" type="submit" color="primary"/>
						<q-btn flat label="Close" color="primary" v-close-popup/>
					</div>
				</q-card-actions>
			</q-form>
		</q-card>
	</q-dialog>

    <!-- deduction history -->
	<q-dialog v-model="deductHistory"  transition-show="scale" transition-hide="scale">
		<q-card class="q-bottom-sheet--list">
            <q-list>
                <q-item-label header class="text-h6 text-center">Total Deduction: <span class="text-weight-bold text-green">{{ totaldeductionHistory }}</span></q-item-label>
                <q-item v-show="deductionHistory.length > 0" v-for="(deduct,index) in deductionHistory"  :key="index">
                    <q-item-section>
                        <q-item-label>{{ deduct.noOfDeduction}} Deduction</q-item-label>
                        <q-item-label caption lines="2" class="text-black">Paydate:
                            <span class="text-weight-bold text-green">{{deduct.paydate}}</span>
                        </q-item-label>
                        <q-item-label caption lines="2" class="text-black">Cutt Off:
                            <span class="text-weight-bold text-green">{{deduct.cuttOff}}</span>
                        </q-item-label>
                        <q-item-label caption lines="2" class="text-black">Amount:
                            <span class="text-weight-bold text-green">{{deduct.amountDeduct}}</span>
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item v-show="deductionHistory.length <= 0">
                    <q-item-section class="text-h6 text-center">No Deduction Yet!</q-item-section>
                </q-item>
            </q-list>
		</q-card>
	</q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
import { exportFile } from 'quasar'

function wrapCsvValue(val, formatFn) {
  let formatted = formatFn !== void 0 ? formatFn(val) : val

  formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

  formatted = formatted.split('"').join('""')
  return `"${formatted}"`
}
export default {
  data() {
    return {
      separator: 'cell',
      fromDay: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
      toDay: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
      importfile: false,
      deductHistory:false,
      files:[],
      spinner: false,

      pagination: {
        rowsPerPage: 30
      },

      loantypeDialog:false,
      loanTitle:'',
      manual:false,
      employee_id:'',
  	  loan_type:'',
      loanTypeOptions:[],
  	  loan_amt: '',
      actual_amt: '',
  	  loan_date:new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
  	  start_date:new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
  	  end_date:new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
  	  monthly_deduct: '',


      generatedData: [],

      searchKey: '',
      data: [],
      columns: [
        { name: 'empID', align: 'center', label: 'Employee ID', field: 'empID', sortable: true},
        { name: 'name', align: 'center', label: 'Name', field: 'name', sortable: true},
		{ name: 'loanType', align: 'center', label: 'Loan Type', field: 'loanType', sortable: true},
		{ name: 'loanAmount', align: 'center', label: 'Loan Amount', field: 'loanAmount', sortable: true},
		{ name: 'actualAmount', align: 'center', label: 'Actual Amount', field: 'actualAmount', sortable: true},
        { name: 'loadDate', align: 'center', label: 'Loan Date', field: 'loadDate', sortable: true},
		{ name: 'startDate', align: 'center', label: 'Start Date', field: 'startDate', sortable: true},
		{ name: 'endDate', align: 'center', label: 'End Date', field: 'endDate', sortable: true},
		{ name: 'monthlyDeduction', align: 'center', label: 'Monthly Deduction', field: 'monthlyDeduction', sortable: true},
		{ name: 'loanBalance', align: 'center', label: 'Deduction/Balance', field: 'loanBalance', sortable: true},
        { name: 'status', align: 'center', label: 'Loan Status', field: 'status', sortable: true},
        { name: 'holdOrActive', align: 'center', label: 'Hold/Active', field: 'holdOrActive', sortable: true},
        { align: 'center', label: 'Action',sortable: true},
      ],

      loanColumns: [
  		{ name: 'types', align: 'center', label: 'Loan Type', field: 'types', sortable: true },
	  ],
      loanTypeData:[],
      loanType:'',
      loanTypeTempID:0,
      deductionHistory:[],
      totaldeductionHistory:0,
    }
  },

  created() {
    this.getLoanList()
    this.getLoanTypeList()
  },

  methods: {

    openDialog:function(){
        this.loantypeDialog  = true
    },

    addManual:function(){
        this.loanTitle = 'ADD LOAN'
        this.manual  = true
        this.clearFields();
    },

    editLoan:function(obj){
        this.loanTitle = 'EDIT LOAN'
        this.manual  = true
        this.tempID = obj.id
        this.employee_id = obj.empID
        this.loan_type = obj.loanType
        this.loan_amt = obj.loanAmount
        this.actual_amt = obj.actualAmount
        this.loan_date = obj.loadDate
        this.start_date = obj.startDate
        this.end_date = obj.endDate
        this.monthly_deduct = obj.monthlyDeduction
    },

    uploadLoan:function(){
  		this.importfile = true
  	},

    filterData: function() {
        this.getLoanList()
    },

    selectedRow:function(obj){
		this.loanTypeTempID = obj.row.id
		this.loanType = obj.row.types
	},

    getLoanList: function() {
      this.spinner = true;
      let formData = new FormData();
      formData.append("fromDay", this.fromDay);
      formData.append("toDay", this.toDay);
      axios.create({
          baseURL: this.apiUrl,
          headers: { 'Authorization': `Bearer ${this.token}` }
        }).post(`/payroll/deduction/list`, formData)
        .then((res) => {
          this.generatedData = res.data.data
          this.spinner = false
          this.dataFlag = true
        });
    },

    getLoanTypeList: function() {
		this.spinner = true
		axios.create({
			baseURL: this.apiUrl,
			headers: {
				'Authorization': `Bearer ${this.token}`
			}})
		.get(`/payroll/loan/type/list`)
		.then((res) => {
            this.loanTypeOptions = res.data.types
		    this.loanTypeData = res.data.data
            this.loanType = ''
			setTimeout(() => {
				this.spinner = false
			},500)
		})
    },

    onSubmit:function(){
        this.spinner = true;
		let formData = new FormData();
        formData.append("tempID", this.tempID);
		formData.append("empID", this.employee_id);
		formData.append("loanType", this.loan_type);
		formData.append("loanAmount", this.loan_amt);
        formData.append("actualAmount", this.actual_amt);
        formData.append("loanDate", this.loan_date);
        formData.append("startDate", this.start_date);
		formData.append("endDate", this.end_date);
        formData.append("monthlyDeduct", this.monthly_deduct);

		axios.create({
            baseURL: this.apiUrl,
            headers: { 'Authorization': `Bearer ${this.token}` }
        }).post(`/payroll/deduction`, formData)
          .then((res) => {
			console.log(res.data)
            this.spinner = false
			this.getLoanList()
			this.clearFields();
            this.manual = false
          });
	},

    setState:function(status,id){
        let formData = new FormData();
        formData.append("id", id);
		formData.append("status", status);
		axios.create({
            baseURL: this.apiUrl,
            headers: { 'Authorization': `Bearer ${this.token}` }
        }).post(`/payroll/change-loan-status`, formData)
          .then((res) => {
			console.log(res.data)
            this.getLoanList()
        });
    },

    showHistory:function(id){
        this.deductHistory = true
        let formData = new FormData();
        formData.append("id", id);
		axios.create({
            baseURL: this.apiUrl,
            headers: { 'Authorization': `Bearer ${this.token}` }
        }).post(`/payroll/loan/history`, formData)
          .then((res) => {
            this.totaldeductionHistory = res.data.total
			this.deductionHistory = res.data.data

        });
    },


    loanTypeSubmit:function(){
		let formData = new FormData();
		formData.append("loanType", this.loanType);
        formData.append("loanTypeTempID", this.loanTypeTempID);
		axios.create({
            baseURL: this.apiUrl,
            headers: { 'Authorization': `Bearer ${this.token}` }
        }).post(`/payroll/loan/type`, formData)
          .then((res) => {
			console.log(res.data)
            this.getLoanTypeList()
          });
	},

    clearFields:function(){
        this.employee_id = '';
		this.loan_type ='';
		this.loan_amt='';
        this.actual_amt='';
        this.loan_date='';
        this.start_date='';
		this.end_date='';
        this.monthly_deduct='';
    },

    uploadFile:function(){
		this.spinner = true
		let formData  = new FormData();
		formData.append("files", this.files);
		axios({
			onUploadProgress: uploadEvent => {
				console.log("Progress: " + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
			},
			headers : {'Authorization' : `Bearer ${this.token}`},
			url:this.apiUrl+'/payroll/deduction/upload',
			data: formData,
			method: 'POST'
			}).then(response => {
				setTimeout(() => {
					this.spinner = false
					this.importfile = false
					this.files = []
					this.$q.notify({
						message: 'Loan Deduction Uploaded Successfully!',
						color: 'green',
						position:'center',
						actions: [{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }]
					})
				},500)
			}).catch((error) => {
				console.log(error)
		})
	},

    getTemplate: function() {
		axios.create({
			baseURL: this.apiUrl,
			headers: {
				'Authorization': `Bearer ${this.token}`
			}})
		.get('/employee/template/download')
		.then((res) => {
			//console.log(res.data.data.adjustment_template)
			this.downloadTemplate(res.data.data.loan_template)
		})
	},

	downloadTemplate:function(url){
		axios({
			url: this.apiUrl+'/'+url,
			method: 'GET',
			responseType: 'arraybuffer',
		}).then((response) => {
			var fileURL = window.URL.createObjectURL(new Blob([response.data]));
			var fileLink = document.createElement('a');
			fileLink.href = fileURL;
			fileLink.setAttribute('download', 'Loan_Template.csv');
			document.body.appendChild(fileLink);

			fileLink.click();
		});
	},

    exportTable: function(fileExtension) {

      const content = [this.columns.map(col => wrapCsvValue(col.label))].concat(
        this.generatedData.map(row => this.columns.map(col => wrapCsvValue(
          typeof col.field === 'function' ?
          col.field(row) :
          row[col.field === void 0 ? col.name : col.field],
          col.format
        )).join(','))
      ).join('\r\n')

      const status =  exportFile(
        `Loan.${fileExtension}`,
        content,
        `text/${fileExtension}`
      );

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        })
      }
    },

  }
}
</script>
<style>
tr:hover {
  cursor: pointer;
  background-color: #f8f5f8;
}
</style>
