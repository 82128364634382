<template>
	<div class="q-pa-lg">
		<div class="row">
			<div class="col-12 col-lg-12 col-xs-12 col-md-12 q-mb-md q-pr-xl">
				<q-stepper
				v-model="step"
				vertical
				color="primary"
				animated
				>
				<q-step :name="1" title="Step 1 (Set Monthly Schedule)" icon="settings" :done="step > 1" >
					<strong>Make sure you already uploaded the monthly schedule!</strong>
					<q-stepper-navigation>
						<q-btn @click="step = 2" color="primary" label="Continue"/>
					</q-stepper-navigation>
				</q-step>

				<q-step :name="2" title="Step 2 (Set Hourly Schedule)" icon="fas fa-exclamation-triangle" :done="step > 1" >
					<strong>Make sure you already uploaded the hourly schedule!</strong>
					<q-stepper-navigation>
						<q-btn flat @click="step = 1" color="primary" label="Back" class="q-ml-sm" />
						<q-btn @click="step = 3" color="primary" label="Continue"/>
					</q-stepper-navigation>
				</q-step>

				<q-step :name="3" title="Step 3 (Set Timelogs)"  icon="fas fa-exclamation-triangle" :done="step > 2">
					<strong>Make sure you already uploaded the time logs of all employees!</strong>
					<q-stepper-navigation>
						<q-btn flat @click="step = 2" color="primary" label="Back" class="q-ml-sm" />
						<q-btn @click="step = 4" color="primary" label="Continue" />
					</q-stepper-navigation>
				</q-step>

				<q-step :name="4" title="Step 4 (Approved all filed leaves)" icon="fas fa-exclamation-triangle" :done="step > 3">
					<strong>Make sure you already approved all filed leaves of all employees!</strong>
					<q-stepper-navigation>
						<q-btn flat @click="step = 3" color="primary" label="Back" class="q-ml-sm"/>
						<q-btn @click="step = 5" color="primary" label="Continue" />
					</q-stepper-navigation>
				</q-step>

				<q-step :name="5" title="Final Step (Generate)" icon="fas fa-exclamation-triangle">
					<q-form ref="vForm">
						If you comply with the requirements above, you can now generate DTR!
						<br/><br/><br/>

						<div class="q-gutter-md row q-carousel__navigation-inner">
							<q-input v-model="fromDay" mask="date" :rules="['date']"  label="From">
								<template v-slot:append>
									<i class="far fa-calendar-alt cursor-pointer" >
										<q-popup-proxy ref="fDateProxy" transition-show="scale" transition-hide="scale">
											<q-date v-model="fromDay" @input="() => $refs.fDateProxy.hide()" />
											</q-popup-proxy>
										</i>
									</template>
								</q-input>
								<q-input v-model="toDay" mask="date" :rules="['date']"  label="To">
									<template v-slot:append>
										<i class="far fa-calendar-alt cursor-pointer" >
											<q-popup-proxy ref="tDateProxy" transition-show="scale" transition-hide="scale">
												<q-date v-model="toDay" @input="() => $refs.tDateProxy.hide()" />
												</q-popup-proxy>
											</i>
										</template>
									</q-input>
									<q-btn color="primary"  @click="generateDTR('mdetailed')" label="MONTHLY DETAILED DTR" type="submit"/>
									<q-btn color="green"  @click="generateDTR('msummary')" label="MONTHLY SUMMARY DTR" type="submit"/>
									<q-btn color="primary"  @click="generateDTR('hdetailed')" label="HOURLY DETAILED DTR" type="submit"/>
									<q-btn color="green"  @click="generateDTR('hsummary')" label="HOURLY SUMMARY DTR" type="submit"/>
									<q-btn color="pink" @click="generateDTR('summary')" label="GENERATE DTR SUMMARY" type="submit"/>
								</div>

								<q-stepper-navigation>
									<q-btn flat @click="step = 4" color="primary" label="Back" class="q-ml-sm" />
								</q-stepper-navigation>
							</q-form>
						</q-step>
					</q-stepper>
				</div>
				<div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl" v-if="dataFlag">
					<q-table
					:dense="$q.screen.lt.md"
					:grid="$q.screen.xs"
					title="Generated Monthly Detailed DTR"
					:data="generatedData"
					:columns="columns"
					row-key="id"
					:pagination.sync="pagination">
					<template v-slot:no-data="">
						<div class="full-width row flex-center text-red q-gutter-sm">
							<i class="fas fa-exclamation-triangle"></i>
							<span>
								No Result Found!
							</span>
						</div>
					</template>
					<template v-slot:top-right="props">
						<q-btn outline color="green" @click="download" >Download</q-btn> 
					</template>
				</q-table>

			</div>
		</div>
		<q-dialog v-model="spinner" persistent >
			<q-card class="my-card transparent no-shadow">
				<q-card-section >
					<q-spinner-gears color="cyan"  size="5.5em"/>
				</q-card-section>
			</q-card>
		</q-dialog>
	</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';


export default {
	data () {
		return {
			test:null,
			dataFlag:false,
			type:null,
			filename:'Report',
			fromDay: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
			toDay: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
			step: 1,
			spinner:false,
			pagination: {
				rowsPerPage: 20
			},
			generatedData:[],
			columns: [
			{ name: 'empID', align: 'center', label: 'Employee ID', field: 'empID', sortable: true },
			{ name: 'name', align: 'center', label: 'Name', field: 'name' , sortable: true},
			{ name: 'dept', align: 'center', label: 'Dept', field: 'dept', sortable: true },
			{ name: 'position', align: 'center', label: 'Position', field: 'position', sortable: true },
			{ name: 'date', align: 'center', label: 'Date', field: 'date', sortable: true },

			{ name: 'reg_hour', align: 'center', label: 'Regular Hour', field: 'reg_hour', sortable: true },
			{ name: 'under_time', align: 'center', label: 'Under Time', field: 'under_time', sortable: true },
			{ name: 'absent', align: 'center', label: 'Absent', field: 'absent', sortable: true },
			{ name: 'tardy', align: 'center', label: 'Tardy', field: 'tardy', sortable: true },
			{ name: 'OT', align: 'center', label: 'OT', field: 'OT', sortable: true },

			{ name: 'RD', align: 'center', label: 'RD', field: 'RD', sortable: true },
			{ name: 'RD_OT', align: 'center', label: 'RD-OT', field: 'RD_OT', sortable: true },

			{ name: 'SH', align: 'center', label: 'SH', field: 'SH', sortable: true },
			{ name: 'SH_OT', align: 'center', label: 'SH-OT', field: 'SH_OT', sortable: true },
			{ name: 'SH_RD', align: 'center', label: 'SH-RD', field: 'SH_RD', sortable: true },
			{ name: 'SH_RD_OT', align: 'center', label: 'SH-RD-OT', field: 'SH_RD_OT', sortable: true },

			{ name: 'LH', align: 'center', label: 'LH', field: 'LH', sortable: true },
			{ name: 'LH_OT', align: 'center', label: 'LH-OT', field: 'LH_OT', sortable: true },
			{ name: 'LH_RD', align: 'center', label: 'LH-RD', field: 'LH_RD', sortable: true },
			{ name: 'LH_RD_OT', align: 'center', label: 'LH-RD-OT', field: 'LH_RD_OT', sortable: true },

			{ name: 'ND', align: 'center', label: 'ND', field: 'ND', sortable: true },
			{ name: 'ND_OT', align: 'center', label: 'ND-OT', field: 'ND_OT', sortable: true },
			{ name: 'ND_RD', align: 'center', label: 'ND-RD-OT', field: 'ND_RD', sortable: true },
			{ name: 'ND_RD_OT', align: 'center', label: 'ND-RD-OT', field: 'ND_RD_OT', sortable: true },
			{ name: 'ND_SH', align: 'center', label: 'ND-SH', field: 'ND_SH', sortable: true },
			{ name: 'ND_SH_OT', align: 'center', label: 'ND-SH-OT', field: 'ND_SH_OT', sortable: true },
			{ name: 'ND_SH_RD', align: 'center', label: 'ND-SH-RD', field: 'ND_SH_RD', sortable: true },
			{ name: 'ND_SH_RD_OT', align: 'center', label: 'ND-SH-RD-OT', field: 'ND_SH_RD_OT', sortable: true },
			{ name: 'ND_LH', align: 'center', label: 'ND-LH', field: 'ND_LH', sortable: true },
			{ name: 'ND_LH_OT', align: 'center', label: 'ND-LH-OT', field: 'ND_LH_OT', sortable: true },
			{ name: 'ND_LH_RD', align: 'center', label: 'ND-LH-RD', field: 'ND_LH_RD', sortable: true },
			{ name: 'ND_LH_RD_OT', align: 'center', label: 'ND-LH-RD-OT', field: 'ND_LH_RD_OT', sortable: true },
			],	
		}
	},

	methods: {
		generateDTR : function(type){
			this.spinner = true;
			this.type = type
			let formData = new FormData();
			formData.append("from", this.fromDay.replace("/", "-").replace("/", "-"));
			formData.append("to", this.toDay.replace("/", "-").replace("/", "-"));

			formData.append("type", type);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post(`/timekeeping/generate`,formData)
			.then((res) => {
				this.generatedData = res.data.dtr
				this.spinner = false
				this.dataFlag = true
			});
		},

		download: function() {
			let formData = new FormData();
			formData.append("from", this.fromDay.replace("/", "-").replace("/", "-"));
			formData.append("to", this.toDay.replace("/", "-").replace("/", "-"));
			formData.append("type", this.type);

			if(this.type == 'mdetailed'){
				this.filename = 'Monthly Detailed';
			}else if(this.type == 'msummary'){
				this.filename = 'Monthly Summary';
			}else if(this.type == 'hdetailed'){
				this.filename = 'Hourly Detailed';
			}else if(this.type == 'hsummary'){ 
				this.filename= 'Hourly Summary';
			}else if(this.type == 'summary'){
				this.filename = 'Summary';
			}

			axios.create({
				baseURL : this.apiUrl,
				responseType: 'arraybuffer',
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post(`/download/file`,formData)
			.then((response) => {
				var fileURL = window.URL.createObjectURL(new Blob([response.data]));
				var fileLink = document.createElement('a');
				fileLink.href = fileURL;
				fileLink.setAttribute('download', ''+this.filename+' from '+this.fromDay+' to '+this.toDay+'.xls');
				document.body.appendChild(fileLink);
				fileLink.click();
			});

			// axios.create({
			// 	baseURL: this.apiUrl,
			// 	responseType: 'arraybuffer',
			// 	headers: {
			// 		'Authorization': `Bearer ${this.token}`
			// 	}})
			// .get('/download/file')
			// .then((response) => {
			// 	//console.log(response.data)
			// 	var fileURL = window.URL.createObjectURL(new Blob([response.data]));
			// 	var fileLink = document.createElement('a');
			// 	fileLink.href = fileURL;
			// 	fileLink.setAttribute('download', 'Report.xls');
			// 	document.body.appendChild(fileLink);
			// 	fileLink.click();
			// })
		},

	}
}
</script>

