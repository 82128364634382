<template>
    <div class="q-gutter-sm q-pa-lg">
        <div class="row">
            <div class="row q-mb-md full-width">
                <div class="full-width text-h4" style="height: 40px;">
                    Re-Hire Employee
                    <q-chip square class="q-ml-sm text-bold" size="md" color="light-blue" text-color="white">
                        <q-avatar font-size="14px" color="blue-grey-8" text-color="white" style="width:1.7em">{{ applicationCounts }}</q-avatar>
                        Applications
                    </q-chip>
                </div>
            </div>

            <div class="full-width row justify-center q-ma-md">
                <q-card bordered class="my-card col-10 q-pa-md">
                    <q-card-section class="q-pa-none q-ma-none q-mt-sm">
                        <div class="row">
                        <div class="col-12">
                        <!-- :visible-columns="visibleColumns" 01182023 -->
                            <q-table :data="applicationsData" :columns="ResultColumns" :pagination.sync="Pagination" :filter="filter" row-key="id" dense
                            virtual-scroll :virtual-scroll-item-size="48" style="max-height: 600px">
                            <template v-slot:top-right>
                                <q-input borderless dense debounce="300" v-model="filter" placeholder="Search" outlined @change="searchData">
                                <template v-slot:append>
                                    <q-icon name="fas fa-search" />
                                </template>
                                </q-input>
                            </template>
                            <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" >

                                <q-td key="action" style="text-align:center;">
                                    <q-btn color="negative" rounded size="sm" text-color="white" @click="openModal(props.row)" label="Complete" />
                                </q-td>
                                <q-td key="id" :props="props">{{ props.row.id }}</q-td>
                                <q-td key="employee_id" :props="props" >{{ props.row.employee_id }}</q-td>
                                <q-td key="firstname" :props="props" >{{ props.row.firstname }}</q-td>
                                <q-td key="middlename" :props="props" >{{ props.row.middlename }}</q-td>
                                <q-td key="lastname" :props="props" >{{ props.row.lastname }}</q-td>
                                <q-td key="date_created" :props="props">{{ props.row.date_created }}</q-td>

                            </q-tr>
                            <template v-slot:no-data>
                                <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                                <span>
                                    No Applications Yet
                                </span>
                                <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                                </div>
                            </template>
                            </q-table>
                        </div>
                        </div>
                    </q-card-section>
                </q-card>
            </div>


            <!-- ======================================================================================= -->
            <!-- new employee rehire dialog -->
            <q-dialog
                v-model="newEmployeeDialog"
                persistent
                :maximized="maximizedToggle"
                transition-show="slide-up"
                transition-hide="slide-down"
                >
                <q-card>
                    <q-bar>
                    <q-space />

                    <q-btn dense flat  @click="maximizedToggle = false" :disable="!maximizedToggle">
                        <i class="fas fa-window-minimize"></i>
                        <q-tooltip v-if="maximizedToggle" content-class="bg-white text-primary" >Minimize</q-tooltip>
                    </q-btn>

                    <q-btn dense flat  @click="maximizedToggle = true" :disable="maximizedToggle">
                        <i class="far fa-window-maximize"></i>
                        <q-tooltip v-if="!maximizedToggle" content-class="bg-white text-primary">Maximize</q-tooltip>
                    </q-btn>

                    <q-btn dense flat v-close-popup>
                        <i class="fas fa-times"></i>
                        <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
                    </q-btn>
                    </q-bar>

                    <q-card-section class="q-mx-xl">
                    <q-form ref="vFormEdit" @submit="doneApplication()">
                        <div class="row">

                        <div class="text-h4 col-9">New Employee Information</div>
                        <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md" style="text-align: end;">
                            <q-btn class="q-mx-sm" color="green" style="width: 100px" type="submit" >Finalize</q-btn>
                            <q-btn class="q-mx-sm" color="blue" v-close-popup style="width: 100px" type="button" @click="closeEmployeeModal">Close</q-btn>
                        </div>
                        </div>
                        <br/>
                        <div class="row">
                        <div class="col-4 col-lg-4 col-xs-12 col-md-6 q-mb-md q-pr-xl q-gutter-md">
                            <div style="display: flow-root">
                            <div class="text-h6" style="float: left">Personal Information</div>
                            <q-btn class="q-mx-sm" style="float:right" color="orange" type="button" v-show="generateIdButton" @click="generateEmployeeID">Generate Employee ID</q-btn>
                            </div>
                            <q-separator/>
                            <q-input outlined required v-model="generatedEmployeeIdValue" label="EMPLOYEE NO." :label-color="validateInput(generatedEmployeeIdValue)" class="caps"/>
                            <q-input outlined v-model="personalInfo.lastname" label="LASTNAME" :label-color="validateInput(personalInfo.lastname)" class="caps"/>
                            <q-input outlined v-model="personalInfo.firstname" label="FIRSTNAME"  :label-color="validateInput(personalInfo.firstname)" class="caps"/>
                            <q-input outlined v-model="personalInfo.middlename" label="MIDDLENAME"  :label-color="validateInput(personalInfo.middlename)" class="caps"/>
                            <q-input outlined v-model="personalInfo.birthdate" mask="date"  label="DATE OF BIRTH"  :label-color="validateInput(personalInfo.birthdate)" >
                            <template v-slot:append>
                                <i class="far fa-calendar-alt cursor-pointer" >
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="personalInfo.birthdate" @input="() => $refs.qDateProxy.hide()" />
                                    </q-popup-proxy>
                                </i>
                                </template>
                            </q-input>
                            <q-select outlined v-model="personalInfo.gender" :options="gender" label="GENDER"  :label-color="validateInput(personalInfo.gender)" />
                            <q-select outlined v-model="personalInfo.civilStat" :options="civilStatus" label="CIVIL STATUS" :label-color="validateInput(personalInfo.civilStat)" />
                            <q-input outlined v-model="personalInfo.contactNo" label="CONTACT NO."  :label-color="validateInput(personalInfo.contactNo)" class="caps"/>
                            <q-input outlined v-model="personalInfo.landline" label="LANDLINE"  :label-color="validateInput(personalInfo.landline)" class="caps"/>
                            <q-input outlined v-model="personalInfo.permanentAdd" label="PERMANENT ADDRESS" :label-color="validateInput(personalInfo.permanentAdd)" class="caps"/>
                            <q-input outlined v-model="personalInfo.presentAdd" label="PRESENT ADDRESS" :label-color="validateInput(personalInfo.presentAdd)" class="caps"/>
                            <br/>
                            <div class="text-h6">Person to Notify in case of Emergency</div>
                            <q-separator/>
                            <q-input outlined v-model="notifyPerson.personToNotif" label="PERSON TO NOTIFY" :label-color="validateInput(notifyPerson.personToNotif)" class="caps"/>
                            <q-input outlined v-model="notifyPerson.personToNotifContact" label="PERSON TO NOTIFY CONTACT" :label-color="validateInput(notifyPerson.personToNotifContact)" class="caps"/>
                            <q-select outlined v-model="notifyPerson.personToNotifRelationship" :options="relationship" label="RELATIONSHIP(Person to Notify)" :label-color="validateInput(notifyPerson.personToNotifRelationship)"/>
                            <q-input outlined v-model="notifyPerson.personToNotifEmailAdd" label="EMAIL ADDRESS(Person to Notify)" :label-color="validateInput(notifyPerson.personToNotifEmailAdd)"/>
                        </div>
                        <div class="col-4 col-lg-4 col-xs-12 col-md-6 q-mb-md q-pr-xl q-gutter-md">
                            <div class="text-h6">Company Information</div>
                            <q-separator/>
                            <q-select outlined v-model="companyInfo.company" option-value="id" option-label="name" :options="companyOptions" label="COMPANY" emit-value map-options :label-color="validateInput(companyInfo.company)"/>
                            <q-select outlined v-model="companyInfo.is_active"  :options="statuses" label="STATUS" :label-color="companyInfo.is_active == 'ACTIVE' ? 'green' : companyInfo.is_active == 'HOLD' ? 'orange' : 'red'" @input="changeStatus"/>
                            <q-input outlined v-model="companyInfo.holdEffectivity" mask="date"  label="HOLD EFFECTIVITY" v-show="companyInfo.isHold" :label-color="validateInput(companyInfo.holdEffectivity)">
                                <template v-slot:append>
                                <i class="far fa-calendar-alt cursor-pointer" >
                                    <q-popup-proxy ref="heDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="companyInfo.holdEffectivity"  @input="calendarChange"/>
                                    </q-popup-proxy>
                                </i>
                                </template>
                            </q-input>
                            <q-input outlined v-model="companyInfo.hold_period"  label="HOLD PERIOD" v-show="companyInfo.isHold"  :label-color="validateInput(companyInfo.hold_period)"/>
                            <q-select outlined v-model="companyInfo.holdReason"  :options="reasons" label="HOLD/SEPARATION REASON" v-show="companyInfo.isHold" :label-color="validateInput(companyInfo.holdReason)"/>
                            <q-input outlined v-model="companyInfo.holdDuration" label="HOLD DURATION" class="caps" v-show="companyInfo.isHold" :label-color="validateInput(companyInfo.holdDuration)"/>

                            <q-input outlined v-model="companyInfo.holdDate" mask="date"  label="HOLD DATE" v-show="companyInfo.isHold"  :label-color="validateInput(companyInfo.holdDate)"/>

                            <q-select outlined v-model="companyInfo.position"  :options="positions" label="POSITION" :label-color="validateInput(companyInfo.position)"/>
                            <q-select outlined v-model="companyInfo.department_id" option-value="id" option-label="name" :options="departmentslist" label="DEPARTMENT" emit-value map-options :label-color="validateInput(companyInfo.department_id)"/>
                            <q-input outlined v-model="personalInfo.email" label="EMAIL ADDRESS" :label-color="validateInput(personalInfo.email)"/>

                            <q-input  outlined v-model="companyInfo.dateHired" mask="date" label="DATE HIRED" :label-color="validateInput(companyInfo.dateHired)">
                                <template v-slot:append>
                                <i class="far fa-calendar-alt cursor-pointer" >
                                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="companyInfo.dateHired" @input="() => $refs.qDateProxy.hide()" />
                                    </q-popup-proxy>
                                    </i>
                                </template>
                                </q-input>

                                <q-select outlined v-model="companyInfo.account"  :options="accounts" label="ACCOUNT" :label-color="validateInput(companyInfo.account)"/>
                                <q-select outlined v-model="companyInfo.costcenter"  :options="costCenter" label="COST CENTER" :label-color="validateInput(companyInfo.costcenter)"/>
                                <q-select outlined v-model="companyInfo.location"  :options="location" label="LOCATION" :label-color="validateInput(companyInfo.location)"/>
                                <q-select outlined v-model="companyInfo.rank"  :options="rank"  label="RANK" :label-color="validateInput(companyInfo.rank)" @input="employmentStatusOption()"/>
                                <q-select outlined v-model="companyInfo.employmentStatus"  :options="empStatus"  label="EMPLOYMENT STATUS" :label-color="validateInput(companyInfo.employmentStatus)"/>

                                <q-select outlined v-model="companyInfo.immediateHead"  :options="immediateHeadOptions" label="IMMEDIATE HEAD" :label-color="validateInput(companyInfo.immediateHead)"/>
                                <q-select outlined v-model="companyInfo.divisionHead"  :options="divisionHeadOptions" label="DIVISION HEAD" :label-color="validateInput(companyInfo.divisionHead)"/>
                        </div>
                        <div class="col-4 col-lg-4 col-xs-12 col-md-6 q-mb-md q-pr-xl q-gutter-md">
                            <div class="text-h6">Salary Information</div>
                            <q-select outlined v-model="governmentInfo.payClass" :options="payclass" label="PAY CLASS" :label-color="validateInput(governmentInfo.payClass)" @input="checkPayclass" v-show="ifConsultant"/>
                            <q-select outlined v-model="governmentInfo.region" option-value="id" option-label="region_no" :options="regionOptions" label="REGION" emit-value map-options v-show="isMWE" @input="getSector"/>
                            <q-select outlined v-model="governmentInfo.sector" option-value="id" option-label="sector" :options="sectorOptions" label="SECTOR" emit-value map-options v-show="isMWE" @input="getMWEInfo"/>

                                <q-input outlined v-model="salaryInfo.basicSalary"  label="BASIC" :label-color="validateInput(salaryInfo.basicSalary)"   v-show="ifConsultant" @input="calculateTotalSalary()"/>
                                <q-input outlined v-model="salaryInfo.allowance"    label="ALLOWANCE"  :label-color="validateInput(salaryInfo.allowance)"   v-show="ifConsultant" @input="calculateTotalSalary()"/>
                                <q-input outlined v-model="salaryInfo.deminimis"    label="DEMINIMIS"  :label-color="validateInput(salaryInfo.deminimis)"  v-show="ifConsultant" @input="calculateTotalSalary()"/>
                                <q-input outlined v-model="salaryInfo.totalSalary"  label="TOTAL SALARY"  :label-color="validateInput(salaryInfo.totalSalary)"  v-show="ifConsultant"/>

                                <q-select outlined v-model="salaryInfo.paymentMode"  :options="paymentmode" label="MODE OF PAYMENT" :label-color="validateInput(salaryInfo.paymentMode)" v-show="ifConsultant"/>
                                <q-select outlined v-model="salaryInfo.paymentBank"  :options="payingbank" label="PAYING BANK" :label-color="validateInput(salaryInfo.paymentBank)" v-show="ifConsultant"/>
                                <q-input outlined v-model="salaryInfo.accountNumber" label="ACCOUNT/PAYCARD NO." :label-color="validateInput(salaryInfo.accountNumber)" class="caps" v-show="ifConsultant"/>
                                <q-input outlined v-model="salaryInfo.fee" label="Fee" v-show="!ifConsultant" />

                                <br/>
                                <div class="text-h6">Educational Background</div>
                                <q-separator/>
                                <q-select outlined v-model="educationBackground.educationAttainment"  :options="educationalOptions" label="EDUCATIONAL ATTAINMENT" @input="checkEduc" :label-color="validateInput(educationBackground.educationAttainment)"/>
                                <q-select outlined v-model="educationBackground.course" :options="coursesOptions" label="COURSE" v-show="isCGrad" :label-color="validateInput(educationBackground.course)"/>
                                <q-input outlined v-model="educationBackground.school" label="SCHOOL GRADUATED"  v-show="isCGrad" :label-color="validateInput(educationBackground.school)" class="caps"/>
                                <q-input outlined v-model="educationBackground.highschool" label="HIGH SCHOOL" v-show="isHSGrad" :label-color="validateInput(educationBackground.highschool)" class="caps"/>
                                    <q-separator/>
                        </div>
                        <!-- =====================ruben::13/07/2023========================== -->
                        <div class="col-4 col-lg-4 col-xs-12 col-md-6 q-mb-md q-pr-sm q-gutter-md">
                            <div class="text-h6">Government Info</div>
                            <q-input outlined v-model="governmentInfo.sssNo" label="SSS #" />
                            <q-input outlined v-model="governmentInfo.hdmfNo" label="HDMF #" />
                            <q-input outlined v-model="governmentInfo.phicNo" label="PHIC #" />
                            <q-input outlined v-model="governmentInfo.tinNo" label="TIN #" />
                        </div>
                        <!-- ================================================================ -->
                        </div>
                    </q-form>
                    </q-card-section>
                </q-card>
                </q-dialog>
            <!-- ======================================================================================= -->

        </div>
    </div>
</template>

<script>

import axios from 'axios'
import moment from "moment";

export default{
    name: "ReHireList",
    data(){
        return{
            filter: '',
            loadingModel: true,
            loadingText: '',
            loadingAction: false,
            viewDetailsDialog: false,
            actionDialog: false,
            ForNotHiredDialog: false,
            applications: '',
            applicationsData: [],
            Pagination: {
                sortBy: 'date_of_application',
                descending: true,
                // page: 1,
                rowsPerPage: 0
            },
            searchData:'',
            ResultColumns: [
                {
                    name: 'action',
                    field: 'action',
                    label: 'Action',
                    style: 'width: 200px',
                    align: 'center',
                    headerStyle: 'width: 200px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'id',
                    field: 'id',
                    label: 'ID',
                    align: 'center',
                    headerStyle: 'width: 200px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'employee_id',
                    field: 'employee_id',
                    label: 'Employee ID',
                    sortable: true,
                    style: 'width: 240px',
                    align: 'center',
                    headerStyle: 'width: 240px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'firstname',
                    field: 'firstname',
                    label: 'First Name',
                    sortable: true,
                    style: 'width: 240px',
                    align: 'center',
                    headerStyle: 'width: 240px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'middlename',
                    field: 'middlename',
                    label: 'Middle Name',
                    sortable: true,
                    style: 'width: 240px',
                    align: 'center',
                    headerStyle: 'width: 240px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'lastname',
                    field: 'lastname',
                    label: 'Last Name',
                    sortable: true,
                    style: 'width: 240px',
                    align: 'center',
                    headerStyle: 'width: 240px',
                    headerClasses: 'bg-indigo-1'
                },
                {
                    name: 'date_created',
                    field: 'date_created',
                    label: 'Date Created',
                    sortable: true,
                    style: 'width: 240px',
                    align: 'center',
                    headerStyle: 'width: 240px',
                    headerClasses: 'bg-indigo-1'
                }
            ],
            applicationCounts: 0,
            // ForEndorseDialog: false,
            // endorse: {
            //     rh_id:"",
            //     account: "",
            //     position: "",
            //     pb_date: "",
            //     basic_salary: "",
            //     status: "",
            //     contact_name: "",
            //     contact_relation: "",
            //     contact_number: "",
            //     location: "",
            //     deminimis:""
            // },
            // positionEndorse: [],
            // accountEndorse: [],
            // sites:["ORTIGAS","MARILAO","DAPITAN"],
            rh_id:'',
            emp_details:{},

            //for new rehire data
            newEmployeeDialog: false,
            maximizedToggle: true,
            // generateIdButton: true,
            // generatedEmployeeIdValue: null,
            personalInfo:{
                id:null,
                employee_no:null,
                firstname:null,
                middlename:null,
                lastname:null,
                birthdate:null,
                gender:null,
                civilStat:null,
                contactNo:null,
                landline:null,
                permanentAdd:null,
                presentAdd:null,
                email:null,
            },
            notifyPerson:{
                personToNotif:null,
                personToNotifContact:null,
                personToNotifRelationship:null,
                personToNotifEmailAdd:null
            },
            educationBackground:{
                educationAttainment:null,
                course:null,
                school:null,
                highschool:null,
            },
            companyInfo:{
                is_active:null,
                isHold:false,
                company:null,
                department:null,
                department_id:null,
                dateHired:null,
                position:null,
                account:null,
                costcenter:null,
                location:null,
                rank:null,
                employmentStatus:null,
                hold_period:null,
                holdReason:null,
                holdDate:new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
                holdEffectivity:null,
                holdDuration:null,
                immediateHead:null,
                divisionHead:null,
                    shift_hours:null,
                    period_rate:null,
            },
            previousCompany:{
                companyName:null,
                workDuration:null
            },
            salaryInfo:{
                basicSalary:null,
                allowance:null,
                deminimis:null,
                totalSalary:null,
                paymentMode:null,
                paymentBank:null,
                accountNumber:null,
                fee:null
            },
            healthInfo:{
                healthCardName:null,
                healthCardNo:null,
                insuranceName:null,
                insuranceNo:null
            },
            governmentInfo:{
                sssNo:null,
                hdmfNo:null,
                phicNo:null,
                tinNo:null,
                taxStatus:null,
                payClass:null,
                region:null,
                sector:null,
                accountability:[],
            },
            holdPeriodList: [],
            statuses: [],
            reasons: [],
            coursesOptions:[],
            paymentmode: [],
            departmentslist: [],
            payingbank: [],
            payclass: [],
            gender: [],
            civilStatus:[],
            relationship:[],
            accounts:[],
            costCenter:[],
            taxStatus:[],
            location:[],
            rank: [],
                periodRates:[],
            empStatus:[],
            regionOptions:[],
            sectorOptions:[],
            accountabilities:[],
            positions:[],
            immediateHeadOptions:[],
            divisionHeadOptions:[],
            educationalOptions:[],
            companyOptions:[],
            isHSGrad:false,
            isCGrad:false,
                    disabled:false,
                    isMWE:false,
                    ifConsultant:true,
                    spinner:false,
            newCreatedEmployeeId: "",
            generateIdButton: true,
            generatedEmployeeIdValue: null,
            confirmModal: false,
            confirm: {
                title: "",
                message: "",
                code: ""
            },
            viewData: [],

        }
    },
    methods: {
        endorseApplication(){
            this.ForEndorseDialog = false
            // var formData = new FormData()
            // formData.append('form_data',Object.assign(this.emp_details,this.endorse))
            let newHire = Object.assign(this.emp_details,this.endorse)
            axios.get(this.apiUrl+'/employee/rehired-endorse',{
                headers: {'Authorization': `Bearer ${this.token}`},
                params:newHire
            }).then((res) => {
                console.log('endorse success:',res)
            })
        },
        fetchData(){
            axios.get(this.apiUrl+'/employee/rehired-list',{
                headers: { 'Authorization': `Bearer ${this.token}`},
                params:{status:'endorse'}
            }).then((res) => {
                // console.log('rehired list:',res)
                this.applicationsData = res.data
                this.applicationCounts = res.data.length
            })
        },
        closeEmployeeModal(){
          this.generatedEmployeeIdValue = "";
          this.generateIdButton = true;
        },
        generateEmployeeID(){
          axios.create({
            baseURL: this.apiUrl,
            headers: {
              'Authorization': `Bearer ${this.token}`
            }})
          .post(`/employee/generate-employee-id`)
          .then((res) => {
              console.log(res.data);
              this.generatedEmployeeIdValue = res.data;
              this.generateIdButton = false;
          })
        },
        validateInput:function(value){
          if (value == null || value == '' || value == 'NULL' || value == 'null' || value == "0000-00-00" || value == 0){
            return 'red';
          } else {
            return 'green';
          }
        },
        changeStatus:function(){
          if(this.companyInfo.is_active == 'HOLD'){
            this.companyInfo.isHold = true
          }else{
            this.companyInfo.isHold = false
          }
        },
        checkEduc:function(){
          var educ = this.educationBackground.educationAttainment
          if(educ == 'HIGH SCHOOL'){
            this.isHSGrad = true;
            this.isCGrad = false;
          }else{
            this.isHSGrad = false;
            this.isCGrad = true;
          }
        },

        getDropdownProperties:function(){
          axios.create({
            baseURL : this.apiUrl,
            headers : {'Authorization' : `Bearer ${this.token}`}
          }).get('/employee/dropdown/properties')
          .then((res) => {
            console.log('get dropdown',res)
            this.gender = res.data.data.gender
            this.paymentmode = res.data.data.paymentMode
            this.payingbank = res.data.data.bank
            this.payclass = res.data.data.payClass
            this.holdPeriodList = res.data.data.holdPeriodList
            this.departmentslist = res.data.data.department
            this.statuses = res.data.data.status
            this.rank = res.data.data.rank
            this.positions = res.data.data.positions
            this.accounts = res.data.data.account
            this.costCenter = res.data.data.costCenter
            this.location = res.data.data.location
            this.civilStatus  = res.data.data.civilStatus
            this.relationship = res.data.data.relationship
            this.taxStatus = res.data.data.taxStatus
            this.empStatus = res.data.data.empStatus
            this.companyOptions = res.data.data.companies
            this.immediateHeadOptions = res.data.data.immediatehead
            this.divisionHeadOptions = res.data.data.divisionhead
            this.coursesOptions = res.data.data.courses

            this.accountabilities = res.data.data.accountability
            this.educationalOptions = res.data.data.educAttaintment

            this.reasons = res.data.data.reasons
            this.periodRates = res.data.data.periodRate
          });
        },

        checkPayclass:function(){
          var type = this.governmentInfo.payClass

          if(type == 'MONTHLY - MWE' || type == 'DAILY - MWE'){
            this.isMWE = true
            this.displayRegions()
            this.disabled = true
          }else{
            this.disabled = false
            this.isMWE = false
            this.salaryInfo.basicSalary = 0
            this.salaryInfo.allowance = 0
            this.salaryInfo.deminimis = 0
            this.salaryInfo.totalSalary = 0
          }
        },

        getSector:function(){
          axios.create({
            baseURL: this.apiUrl,
            headers: {
              'Authorization': `Bearer ${this.token}`
            }})
          .get(`/admin/region/sectors/${this.governmentInfo.region}`)
          .then((res) => {
            this.governmentInfo.sector = ''
            this.salaryInfo.basicSalary = ''
            this.salaryInfo.allowance = ''
            this.salaryInfo.deminimis = ''
            this.salaryInfo.totalSalary = ''
            this.sectorOptions = res.data.data;
            setTimeout(() => {
              this.spinner = false
            },500)
          })
        },

        getMWEInfo:function(){
          axios.create({
            baseURL: this.apiUrl,
            headers: {
              'Authorization': `Bearer ${this.token}`
            }})
          .get(`/admin/check/mwe/${this.governmentInfo.region}/${this.governmentInfo.sector}/${this.governmentInfo.payClass}`)
          .then((res) => {
            if(res.data.data != null){
              this.salaryInfo.basicSalary = res.data.data.basic
              this.salaryInfo.allowance = res.data.data.allowance
              this.salaryInfo.deminimis = res.data.data.deminimis
              this.salaryInfo.totalSalary = res.data.data.total
            }else{
              this.salaryInfo.basicSalary = 0
              this.salaryInfo.allowance = 0
              this.salaryInfo.deminimis = 0
              this.salaryInfo.totalSalary = 0
            }

          })
        },

        calendarChange:function(){
          this.$refs.heDateProxy.hide() //close calendar
          var date = this.companyInfo.holdEffectivity.substring(8, 10)
          if(date > 10 && date < 26){
            this.companyInfo.hold_period = '11 to 25'
          }else{
            this.companyInfo.hold_period = '26 to 10'
          }
        },

        //this is for data fill
        openModal(details){
            console.log('new employee info:',details)
            this.rh_id = details.id
            var data = JSON.parse(details.emp_data)
            // this.viewData = data.emp_data
            var edattn = '';
            if(this.viewData.ad_edattain == "ed0"){
                edattn = "High School Graduate";
            } else if(this.viewData.ad_edattain == "ed1"){
                edattn = "College Level";
            } else if(this.viewData.ad_edattain == "ed2"){
                edattn = "Degree Holder";
            } else if(this.viewData.ad_edattain == "ed3"){
                edattn = "Vocational Graduate";
            }else {
                edattn = this.viewData.ad_edattain;
            }

            // PERSONAL INFORMATION
            this.personalInfo.id = this.viewData.emp_keyid;
            this.personalInfo.employee_no = this.viewData.employee_id;
            this.personalInfo.firstname = data.firstname;
            this.personalInfo.middlename = data.middlename;
            this.personalInfo.lastname = data.lastname;
            this.personalInfo.gender = (data.gender == 'F') ? "FEMALE" : "MALE";
            this.personalInfo.landline = data.telephone;
            this.personalInfo.birthdate = data.date_of_birth;
            this.personalInfo.civilStat = data.civil_status;
            this.personalInfo.contactNo = data.mobile_number;
            this.personalInfo.permanentAdd = data.address;
            this.personalInfo.presentAdd = data.address;
            this.personalInfo.email = data.email;

            this.notifyPerson.personToNotif = data.contact_name;
            this.notifyPerson.personToNotifContact = data.contact_number;
            this.notifyPerson.personToNotifRelationship = data.contact_relation;
            this.notifyPerson.personToNotifEmailAdd = data.email_of_person_to_notif;

            this.educationBackground.educationAttainment = edattn;
            this.educationBackground.course = data.college_course;
            this.educationBackground.school = data.college_school;
            this.educationBackground.highschool = data.high_school;

            // available companyInfo
            this.companyInfo.company = "STERLING GLOBAL CALL CENTER INC.";
            this.companyInfo.account = data.account;
            this.companyInfo.is_active = "ACTIVE";
            this.companyInfo.dateHired = data.pb_date;
            this.companyInfo.rank = "RANK AND FILE";
            this.companyInfo.employmentStatus = data.employment_status;
            this.companyInfo.position = data.position;

            //ruben::13-06-2023
            this.companyInfo.location = data.location
            this.salaryInfo.deminimis = data.deminimis

            if(this.companyInfo.rank === 'TRAINEE'){
                this.isTrainee = false;
            }else{
                this.isTrainee = true;
            }

            this.governmentInfo.payClass = "MONTHLY";
            this.salaryInfo.basicSalary = data.basic_salary;
            this.salaryInfo.allowance = data.allowance;

            // ruben::13/07/2023
            this.governmentInfo.sssNo = data.sss
            this.governmentInfo.tinNo = data.tin
            this.governmentInfo.hdmfNo = data.hdmf
            this.governmentInfo.phicNo = data.phic

            this.newEmployeeDialog = true;
        },


        updateEndorseApplicants(){
            axios.get(this.apiUrl+'/employee/rehired-endorse/complete',{
                headers: {'Authorization': `Bearer ${this.token}`},
                params:{rh_id:this.rh_id}
            }).then((res) => {
                console.log('endorse success:',res)
                this.fetchData()
            })
        },


        doneApplication(){

          let formData = new FormData();

          let bday = moment(this.personalInfo.birthdate).format('YYYY-MM-DD');
          let date_hired = moment(this.companyInfo.dateHired).format('YYYY-MM-DD');

          formData.append("id", this.applications[0]);
          formData.append("employee_id", this.generatedEmployeeIdValue);
          formData.append("firstname", this.personalInfo.firstname);
          formData.append("middlename", this.personalInfo.middlename);
          formData.append("lastname", this.personalInfo.lastname);
          formData.append("email", this.personalInfo.email);
          formData.append("date_of_birth", bday);
          formData.append("gender", (this.personalInfo.gender == 'male') ? 'M' : 'F' );
          formData.append("civil_status", this.personalInfo.civilStat);
          formData.append("mobile_number", this.personalInfo.contactNo);
          formData.append("landline_number", this.personalInfo.landline);
          formData.append("address", this.personalInfo.permanentAdd);
          formData.append("city_address", this.personalInfo.presentAdd);

          formData.append("person_to_notif", this.notifyPerson.personToNotif);
          formData.append("person_to_notif_contact", this.notifyPerson.personToNotifContact);
          formData.append("relation_to_person_to_notif", this.notifyPerson.personToNotifRelationship);
          formData.append("email_of_person_to_notif", this.notifyPerson.personToNotifEmailAdd);

          formData.append("educational_attainment", this.educationBackground.educationAttainment);
          formData.append("college_course", this.educationBackground.course);
          formData.append("college_school", this.educationBackground.school);
          formData.append("high_school", this.educationBackground.highschool);

          formData.append("date_hired", date_hired);
          formData.append("company", this.companyInfo.company);
          formData.append("department", this.companyInfo.department_id);
          formData.append("position", this.companyInfo.position);
          formData.append("account", this.companyInfo.account);
          formData.append("cost_center", this.companyInfo.costcenter);
          formData.append("location", this.companyInfo.location);
          formData.append("rank", this.companyInfo.rank);
          formData.append("employment_status", this.companyInfo.employmentStatus);

          formData.append("is_active", this.companyInfo.is_active);
          formData.append("hold_effectivity", this.companyInfo.holdEffectivity);
          formData.append("hold_period", this.companyInfo.hold_period);
          formData.append("hold_reason", this.companyInfo.holdReason);
          formData.append("hold_duration", this.companyInfo.holdDuration);
          formData.append("hold_date", this.companyInfo.holdDate);


          formData.append("immediate_head", this.companyInfo.immediateHead);
          formData.append("division_head", this.companyInfo.divisionHead);

          formData.append("previous_company", this.previousCompany.companyName);
          formData.append("previous_company_length_of_stay", this.previousCompany.workDuration);

          formData.append("basic_salary", this.salaryInfo.basicSalary);
          formData.append("allowance", this.salaryInfo.allowance);
          formData.append("deminimis", this.salaryInfo.deminimis);
          formData.append("total_salary", this.salaryInfo.totalSalary);

          formData.append("payment_mode", this.salaryInfo.paymentMode);
          formData.append("paying_bank", this.salaryInfo.paymentBank);
          formData.append("account_number", this.salaryInfo.accountNumber);

          formData.append("healthcard_name", this.healthInfo.healthCardName);
          formData.append("healthcard_number", this.healthInfo.healthCardNo);
          formData.append("insurance_name", this.healthInfo.insuranceName);
          formData.append("insurance_number", this.healthInfo.insuranceNo);

          //formData.append("sss_number", this.governmentInfo.sssNo);
          //formData.append("hdmf_number", this.governmentInfo.hdmfNo);
          //formData.append("philhealth_number", this.governmentInfo.phicNo);
          //formData.append("tin_number", this.governmentInfo.tinNo);
          formData.append("bir_number", this.governmentInfo.tinNo);
          formData.append("tax_status", this.governmentInfo.taxStatus);
          formData.append("pay_class", this.governmentInfo.payClass);
          formData.append("regionID", this.governmentInfo.region);
          formData.append("sectorID", this.governmentInfo.sector);
          formData.append("accountability", this.governmentInfo.accountability);
          formData.append("shift_hours", this.companyInfo.shift_hours);
          formData.append("period_rate", this.companyInfo.period_rate);

          //   ruben::13/07/2023
          formData.append("sss_number", this.governmentInfo.sssNo);
          formData.append("hdmf_number", this.governmentInfo.hdmfNo);
          formData.append("philhealth_number", this.governmentInfo.phicNo);
          formData.append("tin_number", this.governmentInfo.tinNo);
          
          axios.create({
              baseURL: this.apiUrl,
              headers: {
              'Authorization': `Bearer ${this.token}`
              }
          }).post('/employee/endorse-create-application', formData).then((res) => {

              this.$q.loading.show()
              setTimeout(() => {
              console.log("FINALIZEEE CLICKED; ", res);
              var data = res.data.data;
              if(res.data.status === true){
                this.personalInfo.id = data.sub_emp_id;

                this.confirm.title = "Employee";
                this.confirm.message = res.data.message;
                this.confirm.code = 201;
                // alert(res.data.statusCode);
                this.confirmModal = true;

                this.newEmployeeDialog = false;
                // console.log('ruben test',data);
                this.updateEndorseApplicants();
              }else{
                // this.newEmployeeDialog = false;
                // console.log(res.data)

                this.confirm.title = "Error";
                this.confirm.message = res.data.message;
                this.confirm.code = 1062;
                // alert(res.data.statusCode);
                this.confirmModal = true;
                this.generatedEmployeeIdValue = "";
                this.generateIdButton = true;
                // this.loadApplications();
              }
              //mar_08_2023
              //add by ruben
              this.$q.loading.hide()
              }, 1000);
          });
        },

    },
    mounted(){
        this.fetchData()
        this.getDropdownProperties()
    }
}
</script>
