<template>
   <div class="q-pa-md q-gutter-md">
      <div class="column" style="height: 150px">
         <div class="col-12 col-sm-12">
            <q-list bordered separator class="rounded-borders">
               <q-item>
                 <q-item-section>
                   <q-item-label>List of Payslip</q-item-label>
                 </q-item-section>

                 <q-item-section side middle>
                   <q-item-label caption><q-btn size="sm" flat style="color: #FF0080" label="I forgot my payslip password?" @click="forgotPassword"/></q-item-label>
                 </q-item-section>
               </q-item>

               <q-item clickable v-ripple v-for="payslip in payslipList" :key="payslip.id" @click="selectedRow(payslip)">
                  <q-item-section avatar top>
                    <q-avatar icon="fas fa-file-invoice" color="teal" text-color="white" />
                  </q-item-section>

                 <q-item-section>
                   <q-item-label lines="1"><strong>Payroll Date: {{ payslip.paydate }}</strong></q-item-label>
                   <q-item-label caption lines="2">
                     <span class="text-weight-bold">Cut-off Period: </span>
                     <strong>{{ payslip.payroll_from }} - {{ payslip.payroll_to }}</strong>
                   </q-item-label>
                 </q-item-section>

              <q-item-section side middle>
                <q-badge v-show="payslip.is_new" color="teal" label="New!" />
              </q-item-section>
            </q-item>
            <q-separator inset="item" />
          </q-list>
       </div>
       <div class="col-12 col-sm-12"/>
       <div class="col-12 col-sm-12">
          <q-list bordered separator class="rounded-borders">
             <q-item>
               <q-item-section>
                 <q-item-label>List of 13th Month Pay</q-item-label>
               </q-item-section>
             </q-item>

             <q-item clickable v-ripple v-for="thirteenth in thirteenthMonthPay" :key="thirteenth.id" @click="selectedThirteenth(thirteenth)">
                <q-item-section avatar top>
                  <q-avatar icon="fas fa-file-invoice" color="teal" text-color="white" />
                </q-item-section>

               <q-item-section>
                 <q-item-label lines="1"><strong>Payroll Date: Dec {{ thirteenth.year }}</strong></q-item-label>
                 <q-item-label caption lines="2">
                   <span class="text-weight-bold">Cut-off Period: </span>
                   <strong>Jan {{ thirteenth.year }} - Dec {{ thirteenth.year }}</strong>
                 </q-item-label>
               </q-item-section>
          </q-item>
          <q-separator inset="item" />
        </q-list>
       </div>
    </div>
    <q-dialog v-model="spinner" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section>
          <q-spinner-gears color="cyan" size="5.5em" />
        </q-card-section>
      </q-card>
    </q-dialog>
    <showDialog></showDialog>
    <ForgotPassword></ForgotPassword>
    <ValidatePassword></ValidatePassword>
    <ThirteenthMonth></ThirteenthMonth>
   </div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';
import showDialog from './Details';
import ForgotPassword from './ForgotPassword';
import ValidatePassword from './ValidatePassword';
import ThirteenthMonth from './ThirteenthMonth';
export default {
   components: {
     "showDialog": showDialog,
     "ForgotPassword": ForgotPassword,
     "ValidatePassword": ValidatePassword,
     "ThirteenthMonth": ThirteenthMonth,
   },
  data () {
    return {
      spinner:false,
      payslipList:[],
      mobileNumber:'',
      thirteenthMonthPay:[],
    }
  },

  mounted(){
    this.getPayslipList();
    this.getMobileNumber();

    this.get13thMonthPayList();
  },


  methods:{

   getPayslipList: function() {
     this.loadingText = 'Retrieving Data';
     this.spinner = true;
     axios.create({
         baseURL: this.apiUrl,
         headers: {
           'Authorization': `Bearer ${this.token}`
         }
      })
      .get(`/mobile/payslip/list/${this.userData.employee_id}`)
      .then((res) => {
         this.payslipList = res.data
         this.spinner = false;
      })
      .finally(() => {
         this.spinner = false;
         this.loadingText = '';
      })
   },

   get13thMonthPayList: function() {
     this.loadingText = 'Retrieving Data';
     this.spinner = true;
     axios.create({
         baseURL: this.apiUrl,
         headers: {
           'Authorization': `Bearer ${this.token}`
         }
      })
      .get(`/mobile/thirteenth/month/list/${this.userData.employee_id}`)
      .then((res) => {
         this.thirteenthMonthPay = res.data
         this.spinner = false;
      })
      .finally(() => {
         this.spinner = false;
         this.loadingText = '';
      })
   },

   getMobileNumber:function(){
      axios.create({
            baseURL: this.apiUrl,
            headers: {
             'Authorization': `Bearer ${this.token}`
            }
         })
         .get(`/mobile/number`)
         .then((res) => {
           this.mobileNumber = res.data.data
         })
   },

   selectedRow(payslip) {
     this.eventHub.$emit('validatePassword', { stat: true,type:'payslip', payslip: payslip });
   },

   selectedThirteenth(thirteenthMonth) {
     this.eventHub.$emit('validatePassword', { stat: true,type:'thirteenthMonth', thirteenthMonth: thirteenthMonth });
   },

   forgotPassword() {
     this.eventHub.$emit('forgotPassword', { stat: true, mobile: this.mobileNumber});
   },


   }
}
</script>
