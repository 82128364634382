<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="row q-mb-md full-width">
        <div class="full-width" style="height: 40px;">
          <h4 class="q-ma-none row inline">Change Work Schedule</h4>
          <q-banner dense rounded class="bg-orange text-white float-right">
            <template v-slot:avatar>
              <q-icon name="fa fa-info-circle" color="white" />
            </template>
            File your Change of Work Schedule one day before the actual date.
          </q-banner>
        </div>
      </div>
      <q-form class="full-width">
        <div class="full-width row justify-center q-ma-md">
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Inclusive Dates</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div class="row">
                <div class="col-6 q-pr-sm">From</div>
                <div class="col-6 q-pl-sm">To</div>
              </div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none">
              <div class="row ">
                <div class="col-6 q-pr-sm">
                  <q-input v-model="dateFromInput" outlined color="red-7" readonly>
                    <template v-slot:append>
                      <q-icon name="fa fa-calendar-alt" class="cursor-pointer">
                        <q-popup-proxy ref="qDateFromProxy" transition-show="flip-down" transition-hide="flip-up">
                          <!--<q-date v-model="form.selectedDateFrom" :options="startDate" @input="$refs.qDateFromProxy.hide()" today-btn color="red-7" minimal />-->
                          <q-date v-model="form.selectedDateFrom"  @input="$refs.qDateFromProxy.hide()" today-btn color="red-7" minimal />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
                <div class="col-6 q-pl-sm">
                  <q-input v-model="dateToInput" outlined color="red-7" readonly>
                    <template v-slot:append>
                      <q-icon name="fa fa-calendar-alt" class="cursor-pointer">
                        <q-popup-proxy ref="qDateToProxy" transition-show="flip-down" transition-hide="flip-up">
                          <!--<q-date v-model="form.selectedDateTo" :options="endDate" @input="$refs.qDateToProxy.hide()" today-btn color="red-7" minimal />-->
                          <q-date v-model="form.selectedDateTo"  @input="$refs.qDateToProxy.hide()" today-btn color="red-7" minimal />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="full-width row justify-center q-ma-md">
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Schedule Type</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div class="row">
                <div class="col-6 q-pr-sm">From</div>
                <div class="col-6 q-pl-sm">To</div>
              </div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none">
              <div class="row ">
                <div class="col-6 q-pr-sm">
                  <q-select v-model="form.selectedWorkFrom" :options="workTypeOptions" @input="setOrigTimes(form.selectedWorkFrom)" options-cover outlined color="red-7" />
                </div>
                <div class="col-6 q-pl-sm">
                  <q-select v-model="form.selectedWorkTo" :options="workTypeOptions" @input="setNewTimes(form.selectedWorkTo)" options-cover outlined color="red-7" />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="full-width row justify-center q-ma-md">
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Inclusive Time</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div class="row">
                <div class="col-6 q-pr-sm">Original Time-In</div>
                <div class="col-6 q-pl-sm">Original Time-Out</div>
              </div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none">
              <div class="row ">
                <div class="col-6 q-pr-sm">
                  <q-input :value="originalTimeIn" :disable="oTimeWorking" outlined color="red-7" readonly>
                    <template v-slot:append>
                      <q-icon name="fa fa-clock" class="cursor-pointer">
                        <q-popup-proxy transition-show="flip-down" transition-hide="flip-up">
                          <q-time v-model="form.selectedOriginalTimeIn" :minute-options="minuteOptions" landscape color="red-7" minimal>
                            <div class="row items-center justify-end q-gutter-xs">
                              <q-btn label="Cancel" color="red-7" style="width: 80px" flat v-close-popup />
                              <q-btn label="OK" color="red-7" style="width: 80px" flat @click="saveTime('originalTimeIn',form.selectedOriginalTimeIn)" v-close-popup />
                            </div>
                          </q-time>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
                <div class="col-6 q-pl-sm">
                  <q-input v-model="originalTimeOut" :disable="oTimeWorking" standout="bg-red-7 text-white" color="red-7" readonly />
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-md">
              <div class="row">
                <div class="col-6 q-pr-sm">New Time-In</div>
                <div class="col-6 q-pl-sm">New Time-Out</div>
              </div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none">
              <div class="row ">
                <div class="col-6 q-pr-sm">
                  <q-input :value="newTimeIn" :disable="nTimeWorking" outlined color="red-7" readonly>
                    <template v-slot:append>
                      <q-icon name="fa fa-clock" class="cursor-pointer">
                        <q-popup-proxy transition-show="flip-down" transition-hide="flip-up">
                          <q-time v-model="form.selectedNewTimeIn" :minute-options="minuteOptions" landscape color="red-7" minimal>
                            <div class="row items-center justify-end q-gutter-xs">
                              <q-btn label="Cancel" color="red-7" style="width: 80px" flat v-close-popup />
                              <q-btn label="OK" color="red-7" style="width: 80px" flat @click="saveTime('newTimeIn',form.selectedNewTimeIn)" v-close-popup />
                            </div>
                          </q-time>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
                <div class=" col-6 q-pl-sm">
                  <q-input v-model="newTimeOut" :disable="nTimeWorking" standout="bg-red-7 text-white" color="red-7" readonly />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="full-width row justify-center q-ma-md">
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Reason</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div style="width: 100%; max-height: 150px">
                <q-input type="textarea" v-model="form.cwsReason" outlined color="red-7" maxlength="250" clearable bottom-slots counter hint="Max 250 characters" />
              </div>
            </q-card-section>
          </q-card>
        </div>
        <!--<div class="full-width row justify-center q-ma-md">
            <strong>ISO NUMBER: HR-009 REV.00</strong>
         </div>-->
      </q-form>
      <div class="full-width row justify-center q-ma-md">
        <div class="row">
          <div class="col-12 q-mt-md">
            <q-btn class="col-4 q-pa-none q-ma-md" @click="resetForm" size="md" style="width: 200px" push color="red-7">RESET</q-btn>
            <q-btn class="col-4 q-pa-none q-ma-md" @click="submitApplication" :loading="loading" size="md" style="width: 200px" push color="red-7">SUBMIT
              <template v-slot:loading>
                <q-spinner-hourglass class="on-left" />
                Sending...
              </template>
            </q-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';

let today = Date.now();
let dateToday = date.formatDate(today, 'YYYY/MM/DD');
const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

export default {
  name: 'ChangeSchedule',
  props: ['value'],
  data() {
    return {
      shiftHours: 0,
      loading: false,
      date: '',
      dateToday,
      form: {
        selectedDateFrom: '',
        selectedDateTo: '',
        selectedOriginalTimeIn: '',
        selectedOriginalTimeOut: '',
        selectedNewTimeIn: '',
        selectedNewTimeOut: '',
        selectedWorkFrom: '',
        selectedWorkTo: '',
        cwsReason: ''
      },
      oTimeWorking: false,
      nTimeWorking: false,
      originalTimeIn: 'Please select a time...',
      originalTimeOut: 'Please select original time-in...',
      newTimeIn: 'Please select a time...',
      newTimeOut: 'Please select new time-in...',
      minuteOptions: [0, 30],
      workTypeOptions: ['Restday', 'Duty', 'Vacation Leave','No Schedule']
    }
  },
  methods: {
    startDate: function(date) {
      return date > this.dateToday;
    },

    endDate: function(date) {
      if (this.form.selectedDateFrom == '') {
        return date > this.dateToday;
      } else {
        return date >= this.form.selectedDateFrom;
      }
    },

    setOrigTimes: function(workType) {
      this.shiftHours = this.userData.shift_hours;
      if (workType == 'Restday') {
        this.oTimeWorking = true;
        this.originalTimeIn = 'Restday';
        this.originalTimeOut = 'Restday';
        this.form.selectedOriginalTimeIn = '00:00';
        this.form.selectedOriginalTimeOut = '00:00';
      } else if (workType == 'Vacation Leave') {
        this.oTimeWorking = true;
        this.originalTimeIn = 'Vacation Leave';
        this.originalTimeOut = 'Vacation Leave';
        this.form.selectedOriginalTimeIn = '00:00';
        this.form.selectedOriginalTimeOut = '00:00';
      } else {
        this.oTimeWorking = false;
        this.originalTimeIn = 'Please select a time...';
        this.originalTimeOut = 'Please select original time-in...';

      }
    },

    setNewTimes: function(workType) {
      if (workType == 'Restday') {
        this.nTimeWorking = true;
        this.newTimeIn = 'Restday';
        this.newTimeOut = 'Restday';
        this.form.selectedNewTimeIn = '00:00';
        this.form.selectedNewTimeOut = '00:00';
      } else if (workType == 'Vacation Leave') {
        this.nTimeWorking = true;
        this.newTimeIn = 'Vacation Leave';
        this.newTimeOut = 'Vacation Leave';
        this.form.selectedNewTimeIn = '00:00';
        this.form.selectedNewTimeOut = '00:00';
      } else {
        this.nTimeWorking = false;
        this.newTimeIn = 'Please select a time...';
        this.newTimeOut = 'Please select new time-in...';
      }
    },

    saveTime: function(type, timeSent) {
      var tempTime = timeSent.split(':');
      var newTime = date.buildDate({ hours: tempTime[0], minutes: tempTime[1] });

      switch (type) {
        case 'originalTimeIn':
          var originalFormattedTimeIn = date.formatDate(newTime, 'hh:mm A');
          this.originalTimeIn = originalFormattedTimeIn;
          var originalTimeOutPlus = date.addToDate(newTime, { hours: this.shiftHours });
          var originalFormattedTimeOut = date.formatDate(originalTimeOutPlus, 'hh:mm A');
          this.originalTimeOut = originalFormattedTimeOut;
          this.form.selectedOriginalTimeOut = date.formatDate(originalTimeOutPlus, 'HH:mm');
          break;
        case 'newTimeIn':
          var newFormattedTimeIn = date.formatDate(newTime, 'hh:mm A');
          this.newTimeIn = newFormattedTimeIn;
          var newTimeOutPlus = date.addToDate(newTime, { hours: this.shiftHours });
          var newFormattedTimeOut = date.formatDate(newTimeOutPlus, 'hh:mm A');
          this.newTimeOut = newFormattedTimeOut;
          this.form.selectedNewTimeOut = date.formatDate(newTimeOutPlus, 'HH:mm');
          break;
      }
    },

    submitApplication: function() {
      if (this.form.selectedDateFrom == undefined || this.form.selectedDateTo == undefined ||
        this.form.selectedOriginalTimeIn == '' || this.form.selectedOriginalTimeOut == '' ||
        this.form.selectedNewTimeIn == '' || this.form.selectedNewTimeOut == '' ||
        this.form.cwsReason == '') {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please fill-in all necessary details.'
        });
      } else if (this.form.selectedDateFrom > this.form.selectedDateTo) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please check the date. "Date From" is greater than "Date To".'
        });
      } else {
        this.loading = true;
        this.form.selectedDateFrom = date.formatDate(this.form.selectedDateFrom, 'YYYY-MM-DD');
        this.form.selectedDateTo = date.formatDate(this.form.selectedDateTo, 'YYYY-MM-DD');

        let formData = new FormData();
        formData.append('form_data', JSON.stringify(this.form));

        axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).post('/online-forms/applications/cws', formData).then((res) => {
          setTimeout(() => {
            this.loading = false;
            this.$q.notify({
              color: 'green-7',
              textColor: 'white',
              icon: 'fa fa-shipping-fast',
              message: 'Change of Work Schedule Application Submitted'
            });
            this.resetForm();
          }, 1000);
        });
      }
    },

    resetForm: function() {
      var self = this;
      Object.keys(this.form).forEach(function(key, index) {
        self.form[key] = '';
      });
      this.originalTimeIn = 'Please select a time...';
      this.originalTimeOut = 'Please select original time-in...';
      this.newTimeIn = 'Please select a time...';
      this.newTimeOut = 'Please select new time-in...';
      this.oTimeWorking = false;
      this.nTimeWorking = false;
      setTimeout(() => {
        scrollToTop();
      }, 200);
    }
  },
  computed: {
    dateFromInput: function() {
      if (this.form.selectedDateFrom == '') {
        return 'Please select a date...';
      } else {
        let d = new Date(this.form.selectedDateFrom);
        return date.formatDate(d, 'dddd, MMMM DD, YYYY');
      }
    },

    dateToInput: function() {
      if (this.form.selectedDateTo == '') {
        return 'Please select a date...';
      } else {
        let d = new Date(this.form.selectedDateTo);
        return date.formatDate(d, 'dddd, MMMM DD, YYYY');
      }
    }
  }
}
</script>
<style scoped>
</style>
