<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="col-12 q-mb-md right">
        <h4 class="q-ma-none">List of Trainees </h4><br />
        <div class="row">
          <div class="row justify q-banner--top-padding">
            <q-btn style="margin-right: 10px;" color="green" label="Import" @click="importTrainees"></q-btn>
            <q-btn color="primary" label="Template" @click="getTemplate"></q-btn>
          </div>
        </div>
      </div>
      <div class="col-12 q-mb-md">
        <div class="col-12 q-mb-md">
            <q-table  :dense="$q.screen.lt.md" :grid="$q.screen.xs" title="Trainee"
            :data="trainees"
            :columns="columns"
            row-key="id" separator="cell"
            no-data-label="I didn't find anything for you!"
            :pagination.sync="pagination"
            :loading="loading"
            :filter="filter"
            @request="onRequest"
            binary-state-sort>
            <template v-slot:body="props">
              <q-tr slot="body" :props="props">
               <q-td key="tid" :props="props">{{ props.row.tid }}</q-td>
                <q-td key="fname" :props="props">{{ props.row.fname }}</q-td>
                <q-td key="lname" :props="props">{{ props.row.lname }}</q-td>
                <q-td key="department" :props="props">{{ props.row.department }}</q-td>
                <q-td key="max_credit" :props="props">{{ props.row.max_credit }}</q-td>
                <q-td key="is_hold" :props="props">
                   <q-chip outline v-show="props.row.is_hold == 'N'" color="green" text-color="white" icon="fa fa-check">ACTIVE</q-chip>
                   <q-chip outline v-show="props.row.is_hold == 'Y'" color="amber" text-color="white" icon="fa fa-clock">HOLD</q-chip>
                </q-td>
                <q-td key="reason" :props="props">{{ props.row.reason }}</q-td>
                <q-td key="action" :props="props" >
                  <div class="q-gutter-sm">
                    <q-btn outline class="text-orange-14" size="sm" label="Edit" @click="editEmployee(props.row)"/>
                    <q-btn outline class="text-red-14" size="sm" label="Delete" @click="deleteEmployee(props.row)"/>
                    <q-btn outline :class="props.row.is_hold == 'N' ? 'text-teal-14' : 'text-amber' " size="sm" :label="props.row.is_hold == 'N' ? 'Hold' : 'Unhold'"  @click="holdEmployee(props.row)"/>
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:no-data="">
              <div class="full-width row flex-center text-red q-gutter-sm">
                <i class="fas fa-exclamation-triangle"></i>
                <span>The filter didn't uncover any results!</span>
              </div>
            </template>
            <template v-slot:loading>
                <q-inner-loading showing>
                    <q-spinner-hourglass  size="50px" color="cyan" />
                </q-inner-loading>
            </template>
            <template v-slot:top-right="props">
              <q-input dense v-model="filter" placeholder="Search Trainee">
                <i class="fas fa-search" slot="append"></i>
              </q-input>
            </template>
          </q-table>
        </div>
        <HoldDialog></HoldDialog>
        <DeleteDialog></DeleteDialog>
        <EditDialog></EditDialog>
        <ImportDialog></ImportDialog>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
import HoldDialog from './HoldDialog';
import DeleteDialog from './DeleteDialog';
import EditDialog from './EditDialog';
import ImportDialog from './ImportDialog';
export default {
  name: 'TraineeList',
  components: {
    "HoldDialog": HoldDialog,
    "DeleteDialog": DeleteDialog,
    "EditDialog": EditDialog,
    "ImportDialog": ImportDialog,
  },
  data() {
    return {
        filter: '',
        loading: false,
        pagination: {
            page: 1,
            rowsPerPage: 15,
            rowsNumber: 10
        },

      importfile: false,
      isHold:false,
      columns: [
      { name: 'tid', align: 'center', label: 'Trainee ID', field: 'tid', sortable: true },
      { name: 'fname', align: 'center', label: 'First Name', field: 'fname', sortable: true },
      { name: 'lname', align: 'center', label: 'Last Name', field: 'lname', sortable: true },
      { name: 'department', align: 'center', label: 'Department', field: 'department', sortable: true },
      { name: 'max_credit', align: 'center', label: 'Max Credit', field: 'max_credit', sortable: true },
      { name: 'is_hold', align: 'center', label: 'Status', field: 'is_hold', sortable: true },
      { name: 'reason', align: 'center', label: 'Hold Reason', field: 'reason', sortable: true },
      { name: 'action', align: 'center', label: 'Action', field: '', sortable: true },
      ],
      trainees: [],
    }

  },

  created() {
     this.eventHub.$on('refreshList', this.getEmployees);
    this.getEmployees()
  },


  methods: {

     getTemplate(){
       this.downloadTemplate('uploads/template/trainee_template.csv','TraineeTemplate')
     },

      //start if server side
      onRequest (props) {
        const { page, rowsPerPage } = props.pagination
        const filter = props.filter
        this.loading = true
        setTimeout(() => {
          this.getRowsNumberCount(filter).then(total => {
              this.pagination.rowsNumber = total
          })
          const fetchCount = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage
          const startRow = (page - 1) * rowsPerPage
          this.fetchFromServer(startRow, fetchCount, filter)
          this.pagination.page = page
          this.pagination.rowsPerPage = rowsPerPage
        }, 1500)
      },

      fetchFromServer (startRow, count, filter) {
          axios.get(this.apiUrl + '/mobile/pantry/trainee/list', {
              headers: { 'Authorization': `Bearer ${this.token}` },
              params: {
                start: startRow,
                countPerPage: count,
                filter: filter,
              }
          }).then((response) => {
              this.trainees = response.data
              this.loading = false
          }).catch((error) => {
              console.log(error);
          })
     },

      async getRowsNumberCount (filter) {
         let response = await axios.get(this.apiUrl + '/mobile/pantry/trainee/count', {
             headers: { 'Authorization': `Bearer ${this.token}` },
             params: {
                filter: filter,
             }
         })
         return response.data
      },

      ///end of server side

    getEmployees: function() {
         this.onRequest({pagination:this.pagination,filter:''})
    },

    onSubmit:function(){
      let formData  = new FormData();
      formData.append("files", this.file);
      axios({
         onUploadProgress: uploadEvent => {
           console.log("Progress: " + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
         },
         headers : {'Authorization' : `Bearer ${this.token}`},
         url:this.apiUrl+'/employee/import-account',
         data: formData,
         method: 'POST'
      }).then(response => {
         console.log(this.token)
         console.log('tes',response)

         setTimeout(() => {
           this.importfile = false
         },500)
         this.$q.notify({
             message: 'Successful',
             color: 'green',
             position:'center',
             actions: [
               { label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
             ]
         })
      }).catch((error) => {
         var message = error.response.data.exception[1].message
         var index = message.indexOf("1062");
         var final = message.substring(index + 4);
         this.$q.notify({
           message: final,
           color: 'negative',
           position:'center',
           actions: [
             { label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
           ]
         })
      })
    },

  downloadTemplate: function(url,filename) {
    axios({
      url: this.apiUrl + '/' + url,
      method: 'GET',
      responseType: 'arraybuffer',
    }).then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${filename}.csv`);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  },

  importTrainees: function() {
    this.eventHub.$emit('importFileDialog', { status: true });
  },

  editEmployee:function(obj){
     this.eventHub.$emit('editEmployee', { status: true,obj:obj});
  },

  deleteEmployee:function(obj){
     this.eventHub.$emit('deleteEmployee', { status: true,id:obj.id});
  },

   holdEmployee:function(obj){
      this.eventHub.$emit('holdEmployee', { status: true,id:obj.id,isHold:obj.is_hold });
   }
}
}
</script>
<style lang="css" scoped>
</style>
