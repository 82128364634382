<template>
    <div class="q-pa-md">
        <div class="q-pa-md">
			<div class="row">
				<div class="row justify q-banner--top-padding q-pa-md">
					<q-select filled v-model="searchKey" use-input input-debounce="0" label="Search Employee"
			        :options="employeeList" @filter="filterFn" style="width: 550px" behavior="dialog" class="caps"
			      	>
			        <template v-slot:no-option>
			          <q-item>
			            <q-item-section class="text-grey">
			              No results
			            </q-item-section>
			          </q-item>
			        </template>
			      </q-select>
				</div>
                <div class="row justify q-banner--top-padding q-pa-md">
					<q-select v-model="year" :options="yearOptions"  filled label="Select Year" class="caps" style="width:200px"/>
				</div>
                <div class="row justify q-banner--top-padding q-pa-md">
					 <q-btn color="primary" style="color: red;" label="Show Details" @click="showDetails" />
				</div>
			</div>
		</div>
        <div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
            <div class="q-pa-md">
                <q-table title="Pag-ibig Employee Contribution" :data="pagibigData" :columns="columns" row-key="id" :pagination.sync="pagination" >
                    <template v-slot:body="props">
                        <q-tr slot="body" :props="props" @click="selectedRow(props)">
                            <q-td key="months" :props="props">{{ props.row.months }}</q-td>
                            <q-td key="monthlyBasic" :props="props">{{ props.row.monthlyBasic }}</q-td>
                            <q-td key="fcPagiContri" :props="props">{{ props.row.fcPagiContri }}</q-td>
                            <q-td key="scPagContri" :props="props">{{ props.row.scPagContri }}</q-td>
                            <q-td key="totalPagContri" :props="props">{{ props.row.totalPagContri }}</q-td>
                            <q-td key="fcBasic" :props="props">{{ props.row.fcBasic }}</q-td>
                            <q-td key="scBasic" :props="props">{{ props.row.scBasic }}</q-td>
                            <q-td key="totalBasic" :props="props">{{ props.row.totalBasic }}</q-td>
                        </q-tr>
                    </template>
                    <template v-slot:no-data="">
                        <div class="full-width row flex-center text-red q-gutter-sm">
                        <i class="fas fa-exclamation-triangle"></i>
                        <span>
                          No Result Found!
                        </span>
                        </div>
                </template>
                </q-table>
              </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
export default {
	data () {
		return {
			spinner:false,
			empID:'',

			searchKey:'',
			employeeList:[],

            year:'',
            yearOptions:[],

            pagination: {
              rowsPerPage: 30
            },

            columns: [
                { name: 'months', align: 'center', label: 'MONTHS', field: 'months', sortable: true},
                { name: 'monthlyBasic', align: 'center', label: 'MONTHLY BASIC PAY', field: 'monthlyBasic', sortable: true},
      		    { name: 'fcPagiContri', align: 'center', label: '1ST CUTOFF PAG-IBIG CONTRI', field: 'fcPagiContri', sortable: true},
      		    { name: 'scPagContri', align: 'center', label: '2ND CUTOFF PAG-IBIG CONTRI', field: 'scPagContri', sortable: true},
                { name: 'totalPagContri', align: 'center', label: 'TOTAL PAG-IBIG CONTRI', field: 'totalPagContri', sortable: true},
      		    { name: 'fcBasic', align: 'center', label: '1ST CUTOFF BASIC PAY', field: 'fcBasic', sortable: true},
      		    { name: 'scBasic', align: 'center', label: '2ND CUTOFF BASIC PAY', field: 'scBasic', sortable: true},
      		    { name: 'totalBasic', align: 'center', label: 'TOTAL BASIC PAY', field: 'totalBasic', sortable: true},
            ],
            pagibigData:[],
		}
	},

	created(){
		this.getListOfEmployees()
        this.yearsList()
	},

	methods: {

		filterFn (val, update) {
	     	if (val === '') {
		        update(() => {
		          this.employeeList = this.employeeOptions
		        })
	        	return
	      	}
	      update(() => {
	        	const needle = val.toLowerCase()
	        	this.employeeList = this.employeeOptions.filter(emp => emp.toLowerCase().indexOf(needle) > -1)
	      })
	  	},

		getListOfEmployees: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/employee/list`)
			.then((res) => {
				this.employeeOptions = res.data.data;
			})

		},

        showDetails:function(){
            this.$q.loading.show({
                spinnerColor: 'yellow',
                spinnerSize: 140,
                backgroundColor: 'purple',
                message: 'Generating in process...',
                messageColor: 'black'
            })
            var employeeID = this.searchKey.substr(0, this.searchKey.indexOf('|'));
            let formData = new FormData();
            formData.append("empID", employeeID);
            formData.append("year", this.year);
            axios.create({
                baseURL: this.apiUrl,
                headers: { 'Authorization': `Bearer ${this.token}` }
              }).post(`/backend/pagibig/contribution`, formData)
              .then((res) => {
                    this.pagibigData = res.data.data
                    this.timer = setTimeout(() => {
                        this.$q.loading.hide()
                        this.timer = void 0
                    }, 1000)
              });
        },

        yearsList : function(){
            axios.create({
                baseURL : this.apiUrl,
                headers : {'Authorization' : `Bearer ${this.token}`}
            }).post(`/timekeeping/years/list`)
            .then((res) => {
                this.yearOptions = res.data.data
            });
        },

        beforeDestroy () {
            if (this.timer !== void 0) {
              clearTimeout(this.timer)
              this.$q.loading.hide()
            }
        }
	}
}
</script>
