<template>
	<div>
		<q-dialog v-model="dialog" persistent transition-show="scale" transition-hide="scale">
			<q-card class="q-bottom-sheet--list">
				<q-form ref="vForm" @submit="updateRole">
					<q-card-section>
						<div class="text-h6">{{ name }} Access</div>
					</q-card-section>
					<q-card-section>
						<div class="q-gutter-sm">
							<strong>User List Actions</strong>
							<q-option-group :options="options" label="Notifications" type="checkbox" v-model="accessGroup" />
						</div>
					</q-card-section>
					<q-card-actions align="right">
						<div>
							<q-btn flat label="Set Access" type="submit" color="primary"/>
							<q-btn flat label="Close" color="primary" @click="closeModal"/>
						</div>
					</q-card-actions>
				</q-form>
			</q-card>
		</q-dialog>
		<q-dialog v-model="spinner" persistent >
			<q-card class="my-card transparent no-shadow">
				<q-card-section >
					<q-spinner-gears color="cyan"  size="5.5em"/>
				</q-card-section>
			</q-card>
		</q-dialog>
	</div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';

export default {

	data() {
		return {
			name:null,
			userID:null,
			spinner: false,
			dialog: false,
			ranks:[],
			accessGroup:[],
			options: []
		}
	},
	created(){
		this.eventHub.$on('UserAccess', this.openModal);
		
	},

	methods: {
		updateRole:function(){

			let formData = new FormData();
			formData.append("userID", this.userID);
			formData.append("access", this.accessGroup);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/admin/role/access',formData)
			.then((res) => {
				this.$q.notify({
					message: 'Successful! Check it now!',
					color: 'green',
					position:'center',
					actions: [
				{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
				]
			})
				
			});
		},

		openModal:function(paylaod){
			this.dialog = paylaod.stat
			this.userID = paylaod.employee.row.id
			this.name = paylaod.employee.row.firstname +' ' + paylaod.employee.row.lastname
			this.displayModuleAccess()
		},

		closeModal:function(){
			this.dialog = false
		},

		displayModuleAccess: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/admin/role/access/${this.userID}`)
			.then((res) => {
				var access= res.data.userAccess.moduleAction_id.split (",").map(Number)
				this.accessGroup = access
				//alert(this.accessGroup)
				this.options = res.data.modules		
				
			})

		},


	}
}
</script>
<style lang="css" scoped>
</style>