<template>
	<div class="q-pa-md">
		<div class="row">
			<div class="col-12 q-mb-md">
				<h4 class="q-ma-none">Coverage Settings</h4>
			</div>
		</div>
		<q-form ref="vForm" @submit="onSubmit">
			<div class="row">
				<div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
					<q-select v-model="region" option-value="id" option-label="region_no" :rules="[formRules.required]" :options="regionOptions" label="REGIONS" emit-value map-options @input="getProvinces"/>
					<q-select v-model="coverage" use-chips multiple :options="coverageOptions" label="COVERAGE"  counter hint="Total"/>
					<q-select v-model="withGCA"  :options="gcaOptions" label="GCA" />
					<q-separator />
					<div class="q-pa-md q-gutter-sm">
						<q-btn  class="full-width" color="teal" type="submit" >Save</q-btn>
					</div>
				</div>
				<div class="col-9 col-lg-9 col-xs-12 col-md-6 q-mb-md q-pr-xl">
					<div class="q-pa-md">
						<q-table
						title="Location List"
						:data="data"
						:columns="columns"
						row-key="id"
						:pagination.sync="pagination">
						<template v-slot:body="props">
							<q-tr slot="body"  :props="props" @click="selectedRow(props)" style="cursor: pointer;">
								<q-td key="id" :props="props">{{ props.row.counter }}</q-td>
								<q-td key="region" :props="props">{{ props.row.region }}</q-td>
								<q-td key="coverage" :props="props">{{ props.row.coverage }}</q-td>
								<q-td key="action" :props="props">
									<div >
										<q-btn round outline  color="red" @click="removeRow(props)"> 
											<i class="fas fa-trash"></i>
											<q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" color="red">
												<strong>Remove</strong>
											</q-tooltip>
										</q-btn>
									</div>
								</q-td>
							</q-tr>
						</template>
						<template v-slot:no-data="">
							<div class="full-width row flex-center text-red q-gutter-sm">
								<i class="fas fa-exclamation-triangle"></i>
								<span>
									No Result Found!
								</span>
							</div>
						</template>
					</q-table>
				</div>
			</div>

		</div>
	</q-form>

	<q-dialog v-model="spinner" persistent >
		<q-card class="my-card transparent no-shadow">
			<q-card-section >
				<q-spinner-gears color="cyan"  size="5.5em"/>
			</q-card-section>
		</q-card>
	</q-dialog>

	<q-dialog v-model="deleted" persistent>
		<q-card>
			<q-card-section class="row items-center">
				<span class="q-ml-sm">This file will permanently delete.</span>
			</q-card-section>
			<q-card-actions align="right">
				<q-btn flat label="Cancel" color="primary" v-close-popup />
				<q-btn flat label="Continue" color="primary" v-close-popup @click="deletionConfirmation"/>
			</q-card-actions>
		</q-card>
	</q-dialog>
</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';


export default {

	data () {
		return {
			withGCA:'',
			tempID:0,
			spinner:false,
			deleted:false,
			region:null,
			regionOptions:[],
			coverage:[],
			coverageOptions:[],
			gcaOptions:[],
			data:[],
			columns: [
			{ name: 'id', align: 'center', label: 'ID', field: 'id', sortable: true },
			{ name: 'region', align: 'center', label: 'Region', field: 'region', sortable: true },
			{ name: 'coverage', align: 'center', label: 'Coverage', field: 'coverage', sortable: true},
			{ name: 'action', align: 'center', label: 'Action', field: '', sortable: true },
			],
			pagination: {
				rowsPerPage: 20
			},
		}
	},

	created(){
		this.displayRegions()
		this.displayData()
		this.displayGCA()
	},

	methods: {

		onSubmit : function(){
			this.spinner = true;
			let formData = new FormData();
			formData.append("regionID", this.region);
			formData.append("coverage", this.coverage);
			formData.append("withGCA", this.withGCA);
			formData.append("tempID", this.tempID);

			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/admin/coverage',formData)
			.then((res) => {
				console.log(res.data.data)
				this.displayData()
				this.region = ''
				this.coverage = []
				this.withGCA = ''
				this.tempID = 0
				this.spinner = false;
				this.$refs.vForm.reset()
			});
		},

		displayData: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/admin/coverage`)
			.then((res) => {
				this.data = res.data.data;
				setTimeout(() => {
					this.spinner = false
				},500)
			})

		},


		getProvinces: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/admin/coverage/province/${this.region}`)
			.then((res) => {
				this.coverage = []
				this.coverageOptions = res.data.data;
				setTimeout(() => {
					this.spinner = false
				},500)
			})

		},

		displayRegions: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/admin/regions`)
			.then((res) => {

				this.regionOptions = res.data.data;
				setTimeout(() => {
					this.spinner = false
				},500)
			})
		},

		displayGCA: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/employee/dropdown/properties`)
			.then((res) => {
				this.gcaOptions = res.data.data.gca
				setTimeout(() => {
					this.spinner = false
				},500)
			})
		},

		selectedRow:function(obj){
			console.log(obj.row)
			this.region = obj.row.regionID
			this.withGCA = obj.row.withGCA
			this.tempID = obj.row.id

			this.coverage = []
			var myStr = obj.row.wocoverage;
			var strArray = myStr.split(",");

			for(var i = 0; i < strArray.length; i++){
				this.coverage.push(strArray[i]);
			}
		},

		removeRow:function(obj){

			this.deleted = true
			this.tempID = obj.row.id
		},

		deletionConfirmation:function(){
			this.spinner = true;
			let formData = new FormData();
			formData.append("tempID", this.tempID);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/admin/coverage/delete',formData)
			.then((res) => {
				this.tempID = 0
				this.displayData()
			});
		}

	}
}
</script>
<style>
.caps {
	text-transform: uppercase
}
</style>
