<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="row q-mb-md full-width">
        <div class="full-width text-h4">
          Leave Application Form
          <q-chip square class="q-ml-sm text-bold" size="md" color="light-blue" text-color="white">
            <q-avatar font-size="11px" color="blue-grey-8" text-color="white" style="width:1.5em">{{ vl_remaining }}</q-avatar>
            VL Credits
          </q-chip>
          <q-chip square class="q-ml-sm text-bold" size="md" color="green" text-color="white">
            <q-avatar font-size="11px" color="blue-grey-8" text-color="white" style="width:1.5em">{{ sl_remaining }}</q-avatar>
            SL Credits
          </q-chip>
          <q-banner dense rounded class="row inline bg-orange text-white float-right">
            <template v-slot:avatar>
              <q-icon name="fa fa-info-circle" color="white" />
            </template>
            For Halfday covered leave, please file separate application.
          </q-banner>
        </div>
      </div>
      <q-form class="full-width">
        <div class="full-width row justify-center q-ma-md">
          <div class="row col-4 q-pr-sm">
            <q-card bordered class="my-card col-12 q-pa-md">
              <q-card-section class="q-pa-none q-ma-none">
                <div class="text-h6">Leave Type</div>
              </q-card-section>
              <q-card-section class="q-pa-none q-ma-none q-mt-sm">
                <div>
                  <q-select v-model="form.leaveType" :options="leaveOptions" @input="resetDates()" outlined color="amber-7" />
                </div>
              </q-card-section>
            </q-card>
          </div>
          <div class="row col-4 q-pl-sm">
            <q-card bordered class="my-card col-12 q-pa-md">
              <q-card-section class="q-pa-none q-ma-none">
                <div class="text-h6">Reliever</div>
              </q-card-section>
              <q-card-section class="q-pa-none q-ma-none q-mt-sm">
                <div class="row ">
                  <div class="col-12">
                    <q-input v-model="form.leaveReliever" placeholder="(Optional)" clearable outlined color="amber-7" />
                  </div>
                </div>
              </q-card-section>
            </q-card>
          </div>
        </div>
        <div class="full-width row justify-center q-ma-md">
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Inclusive Dates</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div class="row">
                <div class="col-6 q-pr-sm">From</div>
                <div class="col-6 q-pl-sm">To</div>
              </div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none">
              <div class="row ">
                <div class="col-6 q-pr-sm">
                  <q-input v-model="dateFromInput" outlined color="amber-7" readonly>
                    <template v-slot:append>
                      <q-icon name="fa fa-calendar-alt" class="cursor-pointer">
                        <q-popup-proxy ref="qDateFromProxy" transition-show="flip-down" transition-hide="flip-up">
                          <q-date v-model="form.selectedDateFrom" @input="$refs.qDateFromProxy.hide()" :options="startDate" today-btn color="amber-7" minimal />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
                <div class="col-6 q-pl-sm">
                  <q-input v-model="dateToInput" outlined color="amber-7" readonly>
                    <template v-slot:append>
                      <q-icon name="fa fa-calendar-alt" class="cursor-pointer">
                        <q-popup-proxy ref="qDateToProxy" transition-show="flip-down" transition-hide="flip-up">
                          <q-date v-model="form.selectedDateTo" @input="$refs.qDateToProxy.hide()" :options="endDate" today-btn color="amber-7" minimal />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="full-width row justify-center q-ma-md">
          <div class="row col-4 q-pr-sm">
            <q-card bordered class="my-card col-12 q-pa-md">
              <q-card-section class="q-pa-none q-ma-none">
                <div class="text-h6">Leave Covered</div>
              </q-card-section>
              <q-card-section class="q-pa-none q-ma-none q-mt-sm">
                <div class="row ">
                  <div class="col-6 q-pr-sm">
                    <q-radio class="full-width" v-model="form.leaveCovered" val="Whole Day" label="Whole Day" color="amber-7" />
                  </div>
                  <div class="col-6 q-pl-sm">
                    <q-radio class="full-width" v-model="form.leaveCovered" val="Half Day" label="Half Day" color="amber-7" />
                  </div>
                </div>
              </q-card-section>
            </q-card>
          </div>
          <div class="row col-4 q-pl-sm">
            <q-card bordered class="my-card col-12 q-pa-md">
              <q-card-section class="q-pa-none q-ma-none">
                <div class="text-h6">Date of Return</div>
              </q-card-section>
              <q-card-section class="q-pa-none q-ma-none q-mt-sm">
                <div class="row ">
                  <div class="col-12">
                    <q-input v-model="dateReturnInput" outlined color="amber-7" readonly>
                      <template v-slot:append>
                        <q-icon name="fa fa-calendar-alt" class="cursor-pointer">
                          <q-popup-proxy ref="qDateRetProxy" transition-show="flip-down" transition-hide="flip-up">
                            <q-date v-model="form.dateReturn" @input="$refs.qDateRetProxy.hide()" :options="returnDate" today-btn color="amber-7" minimal />
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>
                </div>
              </q-card-section>
            </q-card>
          </div>
        </div>
        <div class="full-width row justify-center q-ma-md">
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Reason</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div style="width: 100%; max-height: 150px">
                <q-input type="textarea" v-model="form.leaveReason" outlined color="amber-7" maxlength="250" clearable bottom-slots counter hint="Max 250 characters" />
              </div>
            </q-card-section>
          </q-card>
        </div>
        <!--<div class="full-width row justify-center q-ma-md">
            <strong>ISO NUMBER: HR-010 REV.00</strong>
         </div>-->
      </q-form>
      <div class="full-width row justify-center q-ma-md">
        <div class="row">
          <div class="col-12 q-mt-md">
            <q-btn class="col-4 q-pa-none q-ma-md" @click="resetForm" label="Reset" size="md" style="width: 200px" push color="amber-7" />
            <q-btn class="col-4 q-pa-none q-ma-md" @click="submitLeaveApplication" :loading="loading" label="Submit" size="md" style="width: 200px" push color="amber-7">
              <template v-slot:loading>
                <q-spinner-hourglass class="on-left" />
                Sending...
              </template>
            </q-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';
let newDate = new Date();
let dateToday = date.formatDate(newDate, 'YYYY/MM/DD');
let tempTwoDaysAfter = date.addToDate(newDate, { days: 2 });
let twoDaysAfter = date.formatDate(tempTwoDaysAfter, 'YYYY/MM/DD');
let tempThreeDaysAfter = date.addToDate(newDate, { days: 3 });
let threeDaysAfter = date.formatDate(tempTwoDaysAfter, 'YYYY/MM/DD');
let temp2MonthsBefore = date.subtractFromDate(newDate, { month: 2 });
let twoMonthsBefore = date.formatDate(temp2MonthsBefore, 'YYYY/MM/DD');
let temp2MonthsAfter = date.addToDate(newDate, { month: 2 });
let twoMonthsAfter = date.formatDate(temp2MonthsAfter, 'YYYY/MM/DD');
const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};
export default {
  name: 'LeaveApplication',
  data() {
    return {
      loading: false,
      date: null,
      dateToday,
      twoDaysAfter,
      threeDaysAfter,
      twoMonthsBefore,
      twoMonthsAfter,
      vl_remaining: null,
      sl_remaining: null,
      leaveOptions: [
        'Emergency Leave',
        'Vacation Leave',
        'Sick Leave',
        'Maternity Leave',
        'Paternity Leave'
      ],
      form: {
        leaveType: 'Please select type of Leave',
        leaveReliever: null,
        selectedDateFrom: null,
        selectedDateTo: null,
        leaveCovered: null,
        dateReturn: null,
        leaveReason: null
      },
    }
  },
  methods: {
    loadLeaves: function() {
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/leave/credits/retrieve', {
          params: {
            category: 'single',
            keyword: 'user'
          }
        })
        .then((res) => {
          let leaveCredits = res.data.data;
          this.vl_remaining = leaveCredits.vl_remaining;
          this.sl_remaining = leaveCredits.sl_remaining;
        });
    },
    startDate: function(date) {
      if (this.form.leaveType == 'Sick Leave') {
        return date <= this.dateToday;
      } else if (this.form.leaveType == 'Vacation Leave') {
        return date >= this.twoDaysAfter;
      } else {
        return date >= this.twoMonthsBefore && date <= this.twoMonthsAfter;
      }
    },
    endDate: function(date) {
      if (this.form.selectedDateFrom == null) {
        if (this.form.leaveType == 'Sick Leave') {
          return date <= this.dateToday;
        } else if (this.form.leaveType == 'Vacation Leave') {
          return date >= this.twoDaysAfter;
        } else {
          return date >= this.twoMonthsBefore && date <= this.twoMonthsAfter;
        }
      } else {
        if (this.form.leaveType == 'Sick Leave') {
          return date <= this.dateToday;
        } else if (this.form.leaveType == 'Vacation Leave') {
          return date >= this.twoDaysAfter;
        } else {
          return date >= this.twoMonthsBefore && date <= this.twoMonthsAfter;
        }
      }
    },
    returnDate: function(date) {
      if (this.form.selectedDateTo == null) {
        if (this.form.leaveType == 'Sick Leave') {
          return date <= this.dateToday;
        } else if (this.form.leaveType == 'Vacation Leave') {
          return date >= this.threeDaysAfter;
        } else {
          return date >= this.twoMonthsBefore && date <= this.twoMonthsAfter;
        }
      } else {
        if (this.form.leaveType == 'Sick Leave') {
          return date <= this.dateToday;
        } else {
          return date >= this.form.selectedDateTo + 1;
        }
      }
    },
    submitLeaveApplication: function() {
      const dateTots = date.getDateDiff(this.form.selectedDateTo, this.form.selectedDateFrom, 'days') + 1;
      if (this.form.selectedDateFrom == null || this.form.selectedDateTo == null ||
        this.form.dateReturn == null || this.form.leaveCovered == null ||
        this.form.leaveReason == null || this.form.leaveReason == '') {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please fill-in all necessary details.'
        });
      } else if (this.form.selectedDateFrom > this.form.selectedDateTo) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please check the date. "Date From" is greater than "Date To".'
        });
      } else if (this.form.selectedDateTo > this.form.dateReturn) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please check the date. "Date To" is greater than "Date of Return".'
        });
      } else if (this.form.leaveType == 'Emergency Leave' && this.form.leaveCovered == 'Whole Day' && dateTots > this.vl_remaining) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Insufficient Leave Credits.'
        });
      } else if (this.form.leaveType == 'Emergency Leave' && this.form.leaveCovered == 'Half Day' && (dateTots / 2) > this.vl_remaining) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Insufficient Leave Credits.'
        });
      } else if (this.form.leaveType == 'Emergency Leave' && this.vl_remaining == 0.00) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Insufficient Vacation Leave Credits.'
        });
      } else if (this.form.leaveType == 'Vacation Leave' && this.form.leaveCovered == 'Whole Day' && dateTots > this.vl_remaining) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Insufficient Vacation Leave Credits.'
        });
      } else if (this.form.leaveType == 'Vacation Leave' && this.form.leaveCovered == 'Half Day' && (dateTots / 2) > this.vl_remaining) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Insufficient Vacation Leave Credits.'
        });
      } else if (this.form.leaveType == 'Vacation Leave' && this.vl_remaining == 0.00) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Insufficient Vacation Leave Credits.'
        });
      } else if (this.form.leaveType == 'Sick Leave' && this.form.leaveCovered == 'Whole Day' && dateTots > this.sl_remaining) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Insufficient Sick Leave Credits.'
        });
      } else if (this.form.leaveType == 'Sick Leave' && this.form.leaveCovered == 'Half Day' && (dateTots / 2) > this.sl_remaining) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Insufficient Sick Leave Credits.'
        });
      } else if (this.form.leaveType == 'Sick Leave' && this.sl_remaining == 0.00) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Insufficient Sick Leave Credits.'
        });
        // } else if (this.form.leaveType == 'Solo Parent Leave' && this.leaveAppCredits.sp_availed >= 7) {
        //   this.$q.notify({
        //     color: 'red-6',
        //     textColor: 'white',
        //     icon: 'fa fa-exclamation-circle',
        //     message: 'Insufficient Solo Parent Leave Credits.'
        //   });
      } else {
        this.loading = true;
        this.form.selectedDateFrom = date.formatDate(this.form.selectedDateFrom, 'YYYY-MM-DD');
        this.form.selectedDateTo = date.formatDate(this.form.selectedDateTo, 'YYYY-MM-DD');
        this.form.dateReturn = date.formatDate(this.form.dateReturn, 'YYYY-MM-DD');
        let formData = new FormData();
        formData.append('form_data', JSON.stringify(this.form));
        axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).post('/leave/application', formData).then((res) => {
          setTimeout(() => {
            this.loading = false;
            this.$q.notify({
              color: 'green-7',
              textColor: 'white',
              icon: 'fa fa-shipping-fast',
              message: this.form.leaveType + ' Application Submitted'
            });
            this.resetForm();
            // this.leaveCoveredDisabled = false;
            this.loadLeaves();
          }, 1000);
        });
      }
    },
    resetDates: function() {
      this.form.selectedDateFrom = null;
      this.form.selectedDateTo = null;
      this.form.dateReturn = null;
    },
    resetForm: function() {
      var self = this;
      Object.keys(this.form).forEach(function(key, index) {
        self.form[key] = null;
      });
      this.form.leaveType = 'Please select type of Leave from the list';
      this.leaveTypeCovered = false;
      setTimeout(() => {
        scrollToTop();
      }, 200);
    }
  },
  computed: {
    dateFromInput: function() {
      if (this.form.selectedDateFrom == null) {
        return 'Please select a date...';
      } else {
        let d = new Date(this.form.selectedDateFrom);
        return date.formatDate(d, 'dddd, MMMM DD, YYYY');
      }
    },
    dateToInput: function() {
      if (this.form.selectedDateTo == null) {
        return 'Please select a date...';
      } else {
        let d = new Date(this.form.selectedDateTo);
        return date.formatDate(d, 'dddd, MMMM DD, YYYY');
      }
    },
    dateReturnInput: function() {
      if (this.form.dateReturn == null) {
        return 'Please select a date...';
      } else {
        let d = new Date(this.form.dateReturn);
        return date.formatDate(d, 'dddd, MMMM DD, YYYY');
      }
    }
  },
  mounted() {
    this.loadLeaves();
  }
}
</script>
<style scoped>
</style>
