<template>
	<div class="q-pa-md" style="background-color: #f1f2f7;">
		<div style="margin-bottom: 20px; display: flex; justify-content: space-around; align-items: center; flex-wrap: wrap; gap: 10px;">
			<p style="width: 100vw; color: #564254; font-size: 22px; font-weight: bold; float: left; margin-left: 10px;">Dashboard</p>
			<!-- Current Active Employees -->
			<div style="color: #382e2c; font-size: 16px; display: flex; flex-direction: column; background: white; justify-content: center; 
				align-items: center; width: 380px; border-radius: 15px; padding: 5px;">
					<p>Active Employees</p>
					<p style="font-size: 25px; font-weight: bold">{{ totalActive }}</p>
					<i class="fa fa-users fa-2x"></i>
			</div>

			<!-- Total of New Employees for this month -->
			<div style="color: #382e2c; font-size: 16px; display: flex; flex-direction: column; background: white; justify-content: center; 
				align-items: center; width: 380px; border-radius: 15px; padding: 5px;">
					<p>New Employees This Month</p>
					<p style="font-size: 25px; font-weight: bold">{{ totalNew }}</p>
					<i class="fa fa-user-plus fa-2x"></i>
			</div>

			<!-- Current Total of Hold Employees -->
			<div style="color: #382e2c; font-size: 16px; display: flex; flex-direction: column; background: white; justify-content: center; 
				align-items: center; width: 380px; border-radius: 15px; padding: 5px;">
					<p>Hold Employees</p>
					<p style="font-size: 25px; font-weight: bold">{{ totalHold }}</p>
					<i class="fa fa-user-tag fa-2x"></i>
			</div>

			<!-- Current Total of Active Accounts -->
			<div style="color: #382e2c; font-size: 16px; display: flex; flex-direction: column; background: white; justify-content: center; 
				align-items: center; width: 380px; border-radius: 15px; padding: 5px;">
					<p>Active Accounts</p>
					<p style="font-size: 25px; font-weight: bold">{{ totalAccount }}</p>
					<i class="fa fa-headset fa-2x"></i>
			</div>
		</div>

		<div style="margin-left: 40px;">
			<div class="row">
			<div class="col-8 col-lg-8 col-xs-12  q-mb-md q-pr-xl">
				 <q-card class="my-card">
					<q-card-section>
						<div style="font-size: 18px; color: #382e2c; font-weight: bold;">Employee Statistics</div>
						<apexchart width="100%" height="300px" type="line" :options="linechartOptions" :series="lineseries"></apexchart>
					 </q-card-section>
				</q-card>
				<q-tooltip anchor="bottom middle" content-class="bg-blue-9" self="bottom middle" :offset="[10, 10]" >
                    <strong>Total Number of Employee Monthly</strong>
                </q-tooltip>
			</div>
			<div class="col-4 col-lg-4 col-xs-12  q-mb-md q-pr-xl">
				 <q-card class="my-card">
					<q-card-section>
						<div style="font-size: 18px; color: #382e2c; font-weight: bold;">Department Statistics</div>
						<apexchart width="100%" height="313px" type="pie"  :options="pieDeptChartOptions" :series="pieDeptSeries" />
					 </q-card-section>
				</q-card>
				<q-tooltip anchor="bottom middle" content-class="bg-pink-9" self="bottom middle" :offset="[10, 10]" >
                    <strong>Total Number of Employee per Department</strong>
                </q-tooltip>
			</div>
		</div>
			<div class="row">
				<div class="col-8 col-lg-8 col-xs-12  q-mb-md q-pr-xl">
					<q-card class="my-card">
						<q-card-section>
							<div style="font-size: 18px; color: #382e2c; font-weight: bold;">Account Statistics</div>
							<apexchart width="100%" height="300px" type="area" :options="barchartOptions" :series="barseries"></apexchart>
						</q-card-section>
					</q-card>
					<q-tooltip anchor="bottom middle" content-class="bg-orange-9" self="bottom middle" :offset="[10, 10]" >
						<strong>Total Number of Employee per Account Monthly</strong>
					</q-tooltip>
				</div>
				<div class="col-4 col-lg-4 col-xs-12  q-mb-md q-pr-xl">
					<q-card class="my-card">
						<q-card-section>
							<div style="font-size: 18px; color: #382e2c; font-weight: bold;">Account Statistics</div>
							<apexchart width="100%" height="313px" type="pie"  :options="pieChartOptions" :series="pieSeries" />
						</q-card-section>
					</q-card>
					<q-tooltip anchor="bottom middle" content-class="bg-green-9" self="bottom middle" :offset="[10, 10]" >
						<strong>Total Number of Employee per Account</strong>
					</q-tooltip>
				</div>
			</div>
		</div>
		<q-dialog v-model="spinner" persistent>
            <q-card class="my-card transparent no-shadow">
              <q-card-section>
                <q-spinner-gears color="cyan" size="5.5em" />
              </q-card-section>
            </q-card>
         </q-dialog>
	</div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import axios from 'axios';

export default {
	components: {
		"apexchart" : VueApexCharts,
	},

	data() {
		return {
			spinner:false,
			totalActive:0,
			totalNew:0,
			totalHold:0,
			totalAccount:0,

			lineseries:[],
			linechartOptions:{
					legend: {
						show: false
					},
					dataLabels: {
						enabled: false,
					},
					stroke: {
						width: 5,
						curve: 'smooth'
					},
					markers: {
						size: 5,
					},
					xaxis:{
						type: 'datetime',
						categories:[],
					},
					tooltip: {
						x: {
							format: 'MMMM'
						},
					}
			},

		 	pieDeptSeries:[],
	        pieDeptChartOptions:{
	          	labels: [],
				legend: {
					show: false
				},
	          	responsive: [{
	            breakpoint: 480,
	            options: {
	              	chart: {
	               	 width: 200
	              	},
	            }
	          	}]
	        },

	        pieSeries:[],
	        pieChartOptions:{
	          	labels: [],
				legend: {
					show: false
				},
	          	responsive: [{
	            breakpoint: 480,
	            options: {
	              	chart: {
	               	 width: 200
	              	},
	            }
	          	}]
	        },

        	barseries:[],
			barchartOptions: {
				legend: {
					show: false
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: 'smooth',
				},
				xaxis: {
					type: 'datetime',
					categories: [],
				},
				tooltip: {
					x: {
						format: 'MMMM'
					},
				},
				plotOptions: {
					bar: {
					horizontal: false,    // Set to true if you want horizontal bars
					columnWidth: '200%',   // Adjusts the width of each bar, 100% is the full width
					borderRadius: 5,      // Optional: rounding the corners of bars
					borderWidth: 500      // This increases the border width of the bars
					}
				},
			},
	}
},

created(){
	this.fetchAllData()
	console.log(this.token)
},

methods: {

	fetchAllData:function(){

		this.spinner = true
	  	axios.all([
	  		this.getTotalData(),
	  		this.getPieData(),
	  		this.getLineData(),
	  		this.getBarData(),
	  		this.getDeptPieData()
	  		]).then(axios.spread((total,pie,line,bar,dept) => {
	  			//for total data
			    this.totalActive = total.data.data.totalActive
				this.totalNew = total.data.data.totalNew
				this.totalHold = total.data.data.totalHold
				this.totalAccount = total.data.data.totalAccount

				//for pie data
				this.pieSeries = pie.data.data.pieSeries
				pie.data.data.pieLabel.forEach((val) => {
					this.pieChartOptions.labels.push(val)
				})

				//for bar data
				this.barseries = bar.data.data.barseries
				bar.data.data.barcategories.forEach((res) => {
					this.barchartOptions.xaxis.categories.push(res)
				})

				//for line data
				this.lineseries = line.data.data.lineSeries
				line.data.data.lineCat.forEach((res) => {
					this.linechartOptions.xaxis.categories.push(res)
				})

				//for dept pie data
				this.pieDeptSeries = dept.data.data.pieDeptSeries
				dept.data.data.pieDeptLabel.forEach((dept) => {
					this.pieDeptChartOptions.labels.push(dept)
				})

				 setTimeout(() => {
	                this.spinner = false
	            }, 1000);
		
		  }));
	},

	getTotalData:function() {
  		return axios.create({
		        baseURL: this.apiUrl,
		        headers: {
		          'Authorization': `Bearer ${this.token}`
		        }
		      }).get('/dashboard/employee/total');
	},

	getDeptPieData:function() {
  		return axios.create({
		        baseURL: this.apiUrl,
		        headers: {
		          'Authorization': `Bearer ${this.token}`
		        }
		      }).get('/dashboard/employee/pie/dept');
	},

	getPieData:function() {
  		return axios.create({
		        baseURL: this.apiUrl,
		        headers: {
		          'Authorization': `Bearer ${this.token}`
		        }
		      }).get('/dashboard/employee/pie');
	},

	getLineData:function() {
  		return axios.create({
		        baseURL: this.apiUrl,
		        headers: {
		          'Authorization': `Bearer ${this.token}`
		        }
		      }).get('/dashboard/employee/line');
	},

	getBarData:function() {
  		return axios.create({
		        baseURL: this.apiUrl,
		        headers: {
		          'Authorization': `Bearer ${this.token}`
		        }
		      }).get('/dashboard/employee/bar');
	},

	}
}

</script>

<style lang="css" scoped>

.hitler-height{
	height:20px;
}
.hitler-icon{
	margin-top:-40px;
}

</style>