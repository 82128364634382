<template>
	<div>
		<q-dialog v-model="importfile" persistent transition-show="scale" transition-hide="scale">
			<q-card class="q-bottom-sheet--list">
				<q-form ref="form" autocomplete="off" @submit="onSubmit">
					<q-card-section>
						<div class="text-h6">Select Gov. File</div>
					</q-card-section>
					<q-card-section>
						<input type="file" id="file" ref="file" :rules="[ val => val && val.length > 0 || 'This field is required!']"  v-on:change="handleFileUpload()" required />

					</q-card-section>
					<q-card-actions align="right">
						<div>
							<q-btn flat label="Submit" type="submit" color="primary"/>
							<q-btn flat label="Close" color="primary" @click="closeModal"/>
						</div>
					</q-card-actions>
				</q-form>
			</q-card>
		</q-dialog>

		<q-dialog v-model="spinner" persistent >
			<q-card class="my-card transparent no-shadow">
				<q-card-section >
					<q-spinner-gears color="cyan"  size="5.5em"/>
				</q-card-section>
			</q-card>
		</q-dialog>
	</div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';

export default {

	data() {
		return {
			importfile:false,
			file:'',
			spinner:false,
		}
	},
	created(){
		this.eventHub.$on('importGovDialog', this.importFile);
	},

	methods: {

		importFile:function(payload){
			this.importfile = payload.status
		},

		onSubmit:function(){
			this.spinner = true
			let formData  = new FormData();
			formData.append("files", this.file);
			axios({
				onUploadProgress: uploadEvent => {
					console.log("Progress: " + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
				},
				headers : {'Authorization' : `Bearer ${this.token}`},
				url:this.apiUrl+'/employee/import/government',
				data: formData,
				method: 'POST'
			}).then(response => {
				setTimeout(() => {
					this.spinner = false
					this.importfile = false
				},500)
				this.eventHub.$emit('refreshEmployeeList');
				this.$q.notify({
					message: 'Successful',
					color: 'green',
					position:'center',
					actions: [
				{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
				]
			})
			}).catch((error) => {
				var message = error.response.data.exception[1].message
				var index = message.indexOf("1062");
				var final = message.substring(index + 4);
				this.$q.notify({
					message: final,
					color: 'negative',
					position:'center',
					actions: [
				{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
				]
			})
				this.spinner = false
			})
		},
		handleFileUpload(){
			this.file = this.$refs.file.files[0];
		},

		closeModal:function(){
			this.importfile = false
		}
	}
}
</script>
<style lang="css" scoped>
</style>
