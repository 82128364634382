<template>
  <div class="q-pa-md">

    <q-form @submit="generatePayslip">
      <div class="row items-start">
        <div class="row inline q-pa-md">
          <q-select clearable filled v-model="searchKey" use-input input-debounce="0" label="Search Employee" :options="employeeList" @filter="filterFn"  behavior="dialog" class="q-mr-sm col caps" :rules="[ val => val && val.length > 0 || 'Please select Employee']">
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  No results
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
        <div class="row inline q-pa-md">
        <q-select filled v-model="yearfilter" :options="yearOptions" label="Year"  style="width: 200px" class="q-mr-sm col"/>
        </div>
        <div class="row inline q-pa-md">
          <q-select filled v-model="month" :options="monthOptions" label="Month" emit-value map-options option-value="id" option-label="name" style="width: 200px" class="q-mr-sm col" />
        </div>
        <div class="row inline q-pa-md">
          <q-select filled v-model="cutoff" :options="cutoffOptions" label="Cut-off" emit-value map-options option-value="id" option-label="name" style="width: 200px"  class="q-mr-sm col"/>
        </div>
        <div class="row inline q-pa-md">
          <q-btn color="green" style="height: 54px; color: red;" label="Generate Payslip" type="submit" />
        </div>
      </div>
    </q-form>

    <div ref="employeePayslip">
      <div class="row full-width justify-center">
        <div style="width: 1000px; border: 1px solid black">
          <q-card class="q-pa-sm">
            <q-card-section class="text-center">
              <q-img :src="require('../../assets/sgcc-black.png')" basic width="300px" class="items-center" />
              <div class="text-h4 text-red-10 q-mt-sm">STATEMENT OF EARNINGS AND DEDUCTIONS</div>
            </q-card-section>
            <div class="q-pt-none q-pl-md q-pr-md q-mt-md q-mb-lg">
                <q-separator/><br/>
                <div class="row" >
                    <div class="col-md-6 col-sm-12 col-xs-12" align="left">
                        <table width="100%" >
                            <tr>
                                <th class="payslip-info-header">EMPLOYEE ID</th>
                                <th class="payslip-info-header">EMPLOYEE NAME</th>
                                <th class="payslip-info-header">POSITION</th>

                            </tr>
                            <tr>
                                <td class="payslip-info-data">{{ employeeID }}</td>
                                <td class="payslip-info-data">{{ fullname }}</td>
                                <td class="payslip-info-data">{{ position }}</td>

                            </tr>
                            <tr>
                                <th class="payslip-info-header">DEPARTMENT</th>
                                <th class="payslip-info-header">SSS NUMBER</th>
                                <th class="payslip-info-header">TIN NUMBER</th>
                            </tr>
                            <tr>
                                <td class="payslip-info-data">{{ department }}</td>
                                <td class="payslip-info-data">{{ sssNumber }}</td>
                                <td class="payslip-info-data">{{ tin }}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="col"></div>
                    <div class="col-md-4 col-sm-12 col-xs-12" align="right">
                        <table width="100%" >
                            <tr>
                                <th class="payslip-info-header">PAYROLL PERIOD</th>
                                <th class="payslip-info-header">PAYMENT DATE</th>
                            </tr>
                            <tr>
                                <td class="payslip-info-data">{{ paymentperiod }}</td>
                                <td class="payslip-info-data">{{ dateofpayment }}</td>
                            </tr>
                            <tr>
                                <th class="payslip-info-header">MONTHLY / DAILY / DAILY RATE</th>
                                <th class="payslip-info-header">WORKING DAYS / HOURS</th>
                            </tr>
                            <tr>
                                <td class="payslip-info-data">{{ monthlyrate  }}</td>
                                <td class="payslip-info-data">{{ daysofWork }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <br/><q-separator/>
            </div>
            <div class="row justify-between q-pt-none q-pl-md q-pr-md q-mt-md q-mb-lg">
              <table width="100%" >
                <tr>
                  <td class="payslip-earnings-header left-padder" style="width: 220px; text-align: left">EARNINGS</td>
                  <td class="payslip-earnings-header" style="width: 60px;">UNIT</td>
                  <td class="payslip-earnings-header" style="width: 85px;">AMOUNT</td>
                </tr>
                <tr>
                  <td class="payslip-earnings-data left-padder left-border" style="width: 185px; text-align: left">BASIC PAY</td>
                  <td class="payslip-earnings-data left-border right-border" style="width: 75px;">{{  }} </td>
                  <td class="payslip-earnings-amount right-border" style="width: 100px;">{{ earnings.basicPay }}</td>
                </tr>
                <tr v-show="parseInt(earnings.allowance) > 0">
                  <td class="payslip-earnings-data left-padder left-border" style="width: 185px; text-align: left">ALLOWANCE</td>
                  <td class="payslip-earnings-data left-border right-border" style="width: 75px;">{{  }} </td>
                  <td class="payslip-earnings-amount right-border" style="width: 100px;">{{ earnings.allowance }}</td>
                </tr>
                <tr v-show="parseInt(earnings.deminimis) > 0">
                  <td class="payslip-earnings-data left-padder left-border" style="width: 185px; text-align: left">DEMINIMIS</td>
                  <td class="payslip-earnings-data left-border right-border" style="width: 75px;">{{  }} </td>
                  <td class="payslip-earnings-amount right-border" style="width: 100px;">{{ earnings.deminimis }}</td>
                </tr>
                <tr v-show="parseInt(earnings.legalholiday) > 0">
                  <td class="payslip-earnings-data left-padder left-border" style="width: 185px; text-align: left">REGULAR HOLIDAY</td>
                  <td class="payslip-earnings-data left-border right-border" style="width: 75px;">{{  }} </td>
                  <td class="payslip-earnings-amount right-border" style="width: 100px;">{{ earnings.legalholiday }}</td>
                </tr>
                <tr v-show="parseInt(earnings.specialholiday) > 0">
                  <td class="payslip-earnings-data left-padder left-border" style="width: 185px; text-align: left">SPECIAL HOLIDAY</td>
                  <td class="payslip-earnings-data left-border right-border" style="width: 75px;">{{  }} </td>
                  <td class="payslip-earnings-amount right-border" style="width: 100px;">{{ earnings.specialholiday }}</td>
                </tr>
                <tr v-show="parseInt(earnings.nightdiff) > 0">
                  <td class="payslip-earnings-data left-padder left-border" style="width: 185px; text-align: left">NIGHT DIFF</td>
                  <td class="payslip-earnings-data left-border right-border" style="width: 75px;">{{  }} </td>
                  <td class="payslip-earnings-amount right-border" style="width: 100px;">{{ earnings.nightdiff }}</td>
                </tr>
                <tr v-show="parseInt(earnings.overtime) > 0">
                  <td class="payslip-earnings-data left-padder left-border" style="width: 185px; text-align: left">OVERTIME</td>
                  <td class="payslip-earnings-data left-border right-border" style="width: 75px;">{{  }} </td>
                  <td class="payslip-earnings-amount right-border" style="width: 100px;">{{ earnings.overtime }}</td>
                </tr>
                <tr v-for="(adjust,i) in adjustments" :key="i">
                  <td class="payslip-earnings-data left-padder left-border" style="width: 185px; text-align: left">{{ adjust.description }}</td>
                  <td class="payslip-earnings-data left-border right-border" style="width: 75px;">&nbsp;</td>
                  <td class="payslip-earnings-amount right-border" style="width: 100px;">{{ adjust.amount }}</td>
                </tr>
                <tr>
                  <td class="total-header left-border right-border bottom-border" colspan=2>GROSS PAY</td>
                  <td class="payslip-earnings-data right-border bottom-border" style="width: 100px; text-align: right;font-weight: 1900;"><span style="color:blue">&#8369; {{ grossPay }}</span></td>
                </tr>
              </table>
            </div>
            <div class="row justify-between q-pt-none q-pl-md q-pr-md q-mt-md q-mb-lg">
              <table width="100%">
                <tr>
                  <td class="payslip-earnings-header left-padder" style="width: 220px; text-align: left">DEDUCTIONS</td>
                   <td class="payslip-earnings-header" style="width: 60px;">BALANCE</td>
                  <td class="payslip-earnings-header" style="width: 60px;">UNIT</td>
                  <td class="payslip-earnings-header" style="width: 85px;">AMOUNT</td>
                </tr>
                <tr>
                  <td class="payslip-earnings-data left-padder left-border" style="width: 185px; text-align: left">SSS PREMIUM</td>
                  <td class="payslip-earnings-data left-border" style="width: 75px;">{{ }}</td>
                  <td class="payslip-earnings-data left-border right-border" style="width: 75px;">{{  }}</td>
                  <td class="payslip-earnings-amount right-border" style="width: 100px;">{{ mandatoryDeduct.sssPremium }}</td>
                </tr>
                <tr>
                  <td class="payslip-earnings-data left-padder left-border" style="width: 185px; text-align: left">PHILHEALTH PREMIUM</td>
                  <td class="payslip-earnings-data left-border" style="width: 75px;">{{ }}</td>
                  <td class="payslip-earnings-data left-border right-border" style="width: 75px;">{{ }}</td>
                  <td class="payslip-earnings-amount right-border" style="width: 100px;">{{ mandatoryDeduct.philPremium }}</td>
                </tr>
                <tr>
                  <td class="payslip-earnings-data left-padder left-border" style="width: 185px; text-align: left">PAG IBIG PREMIUM</td>
                  <td class="payslip-earnings-data left-border" style="width: 75px;">{{ }}</td>
                  <td class="payslip-earnings-data left-border right-border" style="width: 75px;">{{ }}</td>
                  <td class="payslip-earnings-amount right-border" style="width: 100px;">{{ mandatoryDeduct.pagPremium }}</td>
                </tr>
                <tr v-show="parseInt(mandatoryDeduct.tax) > 0">
                  <td class="payslip-earnings-data left-padder left-border" style="width: 185px; text-align: left">WITHHOLDING TAX</td>
                  <td class="payslip-earnings-data left-border" style="width: 75px;">{{ }}</td>
                  <td class="payslip-earnings-data left-border right-border" style="width: 75px;">{{ }}</td>
                  <td class="payslip-earnings-amount right-border" style="width: 100px;">{{ mandatoryDeduct.tax }}</td>
                </tr>
                <tr v-show="parseInt(mandatoryDeduct.absent) > 0">
                  <td class="payslip-earnings-data left-padder left-border" style="width: 185px; text-align: left">ABSENT &nbsp;</td>
                  <td class="payslip-earnings-data left-border" style="width: 75px;">{{ }}</td>
                  <td class="payslip-earnings-data left-border right-border" style="width: 75px;">{{ }}</td>
                  <td class="payslip-earnings-amount right-border" style="width: 100px;">&#8369; {{ mandatoryDeduct.absent }}</td>
                </tr>
                <tr v-show="parseInt(mandatoryDeduct.undertime) > 0">
                  <td class="payslip-earnings-data left-padder left-border" style="width: 185px; text-align: left">TARDINESS &nbsp;</td>
                  <td class="payslip-earnings-data left-border right-border" style="width: 75px;">{{ }}</td>
                  <td class="payslip-earnings-data left-border right-border" style="width: 75px;">{{ }}</td>
                  <td class="payslip-earnings-amount right-border" style="width: 100px;">&#8369; {{ mandatoryDeduct.undertime }}</td>
                </tr>
                <tr v-for="(deduct,i) in deductions" :key="i">
                  <td class="payslip-earnings-data left-padder left-border" style="width: 185px; text-align: left">{{ deduct.description }}</td>
                  <td class="payslip-earnings-data left-border" style="width: 75px; text-align: left"> <span style="color: blue;" v-show="deduct.type == 'LOAN'" @click="showHistory(deduct.id)" >BAL: {{ deduct.balance }} </span></td>
                  <td class="payslip-earnings-data left-border right-border" style="width: 75px;">&nbsp;</td>
                  <td class="payslip-earnings-amount right-border" style="width: 100px;">{{ deduct.amount }}</td>
                </tr>
                <tr>
                  <td class="total-header left-border right-border bottom-border" colspan=3 style="width: 185px; text-align: right">TOTAL DEDUCTIONS</td>
                  <td class="payslip-earnings-data right-border bottom-border" style="width: 100px;text-align: right"><span style="color:red">&#8369; {{ totalDeduction }}</span></td>
                </tr>
              </table>
            </div>
            <div class="row justify-between q-pt-none q-pl-md q-pr-md q-mt-md q-mb-lg">
              <table width="100%">
                <tr>
                  <td class="payslip-earnings-header left-padder" style="text-align: left;" colspan="3">EARNINGS SUMMARY</td>
                </tr>
                <tr >
                  <td class="payslip-earnings-data left-padder left-border" style="width: 185px; text-align: left" colspan="2">GROSS PAY</td>
                  <td class="payslip-earnings-data left-padder left-border right-border" style="width: 100px;text-align: right"><span style="color:blue">&#8369; {{ grossPay }}</span></td>
                </tr>
                <tr >
                  <td class="payslip-earnings-data left-padder left-border" style="width: 185px; text-align: left" colspan="2">TOTAL DEDUCTION</td>
                  <td class="payslip-earnings-data left-padder left-border right-border" style="width: 100px;text-align: right"><span style="color:red">&#8369; {{ totalDeduction }}</span></td>
                </tr>
                <tr >
                  <td class="payslip-earnings-data left-padder left-border bottom-border" style="width: 185px; text-align: left" colspan="2">NET PAY</td>
                  <td class="payslip-earnings-data left-padder left-border right-border bottom-border" style="width: 100px;text-align: right"><span style="color:green">&#8369; {{ netPay }}</span></td>
                </tr>

              </table>
            </div>
            <div class="row full-width q-pa-md items-center">
              <span class="payslip-footer text-grey-13">Copyright © {{ yearfilter }} JE310</span>
              <span class="payslip-footer text-red-13">This statement is system generated. This is CONFIDENTIAL! Do not share to anyone.</span>
            </div>
          </q-card>
        </div>
      </div>
    </div>

    <!-- running balance -->
	<q-dialog v-model="deductHistory"  transition-show="scale" transition-hide="scale">
		<q-card class="q-bottom-sheet--list">
            <q-list>
                <q-item-label header class="text-h6 text-center">
                    Running Balance<br/>
                    <!-- <span class="text-weight-bold text-green">test</span> -->
                </q-item-label>
                <q-item v-show="deductionHistory.length > 0" v-for="(deduct,index) in deductionHistory"  :key="index">
                    <q-item-section>
                        <q-item-label>{{ deduct.noOfDeduction}} Deduction</q-item-label>
                        <q-item-label caption lines="2" class="text-black">Paydate:
                            <span class="text-weight-bold text-green">{{ deduct.paydate }}</span>
                        </q-item-label>
                        <q-item-label caption lines="2" class="text-black">Cutt Off:
                            <span class="text-weight-bold text-green">{{ deduct.cuttOff }}</span>
                        </q-item-label>
                        <q-item-label caption lines="2" class="text-black">Balance:
                            <span class="text-weight-bold text-blue">{{ deduct.balance }}</span>
                        </q-item-label>
                        <q-item-label caption lines="2" class="text-black">Deduction:
                            <span class="text-weight-bold text-red">{{ deduct.amountDeduct }}</span>
                        </q-item-label>
                        <q-item-label caption lines="2" class="text-black">Remain:
                            <span class="text-weight-bold text-green">{{ deduct.remaining }}</span>
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item v-show="deductionHistory.length <= 0">
                    <q-item-section class="text-h6 text-center">No Deduction Yet!</q-item-section>
                </q-item>
            </q-list>
		</q-card>
	</q-dialog>

  </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
export default {
  data() {
    return {
        yearfilter:new Date().getFullYear(),
        yearOptions: [],
        deductHistory:false,
        totaldeductionHistory:0,
        deductionHistory:[],

        employeeID: '',
        fullname: '',
        position: '',
        department: '',
        bankAccountNumber: '',
        sssNumber: '',
        tin: '',
        taxstatus: '',
        paymentmode: '',
        paymentperiod: '',
        dateofpayment: '',
        monthlyrate: '',
        daysofWork: '',

        totalEarnedVLSL: 0.00,
        totalAvailedVL: 0.00,
        totalAvailedSL: 0.00,
        //searchKey: 'SG20148003 | CINDY ARCEGA GUTLAY',
      //searchKey: 'SG20106071 | SAGLIBA EDGAR SAGA',
      searchKey: '',
      employeeList: [],
      month: new Date().getMonth() + 1,
      monthOptions: [
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'August' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' },
      ],
      cutoff: 1,
      cutoffOptions: [{ id: 1, name: 'First Cut-off' }, { id: 2, name: 'Second Cut-off' }],
      earnings: [],
      mandatoryDeduct: [],

      deductions: [],
      adjustments: [],

      grossPay:0,
      totalDeduction:0,
      netPay:0

    }
  },
  created() {
    this.getListOfEmployees();
    this.yearsList();
    this.generatePayslip()
  },
  methods: {
      yearsList : function(){
          axios.create({
              baseURL : this.apiUrl,
              headers : {'Authorization' : `Bearer ${this.token}`}
          }).post(`/timekeeping/years/list`)
          .then((res) => {
              this.yearOptions = res.data.data
          });
      },

    filterFn(val, update) {
      if (val === '') {
        update(() => {
          this.employeeList = this.employeeOptions;
        })
        return
      }
      update(() => {
        const needle = val.toLowerCase();
        this.employeeList = this.employeeOptions.filter(emp => emp.toLowerCase().indexOf(needle) > -1);
      })
    },

    getListOfEmployees: function() {
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get(`/employee/list`)
        .then((res) => {
          this.employeeOptions = res.data.data;
        })
    },

    generatePayslip: function() {
      // this.$q.loading.show({
      //   spinnerColor: 'yellow',
      //   spinnerSize: 140,
      //   backgroundColor: 'purple',
      //   message: 'Generating in process...',
      //   messageColor: 'black'
      // })
      var employeeID = this.searchKey.substr(0, this.searchKey.indexOf('|'));
      let formData = new FormData();
      formData.append("empID", employeeID);
      formData.append("month", this.month);
      formData.append("year", this.yearfilter);
      formData.append("cutoff", this.cutoff);
      axios.create({
          baseURL: this.apiUrl,
          headers: { 'Authorization': `Bearer ${this.token}` }
        }).post(`/backend/generate/payslip`, formData)
        .then((res) => {
          this.employeeID = res.data.data.employeeID;
          this.fullname = res.data.data.fullname;
          this.position = res.data.data.position;
          this.department = res.data.data.department;

          this.bankAccountNumber = this.spaceBank(res.data.data.bankAccountNumber);
          this.sssNumber = this.spaceSSS(res.data.data.sssNumber);
          this.tin = this.spaceTIN(res.data.data.tin);
          this.taxstatus = res.data.data.taxstatus;
          this.paymentmode = res.data.data.paymentmode;
          this.monthlyrate = res.data.data.monthlyrate;
          this.daysofWork = res.data.data.daysofWork;
          this.paymentperiod = res.data.data.paymentperiod;
          this.dateofpayment = res.data.data.dateofpayment;

         // this.totalEarnedVLSL = res.data.data.totalEarnedVLSL;
          //this.totalAvailedVL = res.data.data.totalAvailedVL;
          //this.totalAvailedSL = res.data.data.totalAvailedSL;

          this.earnings = res.data.earnings;
          this.mandatoryDeduct = res.data.mandatoryDeduct
          this.deductions = res.data.deductbreakdown
          this.adjustments = res.data.adjustbreakdown
          this.grossPay = res.data.grossPay;
          this.totalDeduction = res.data.totalDeduction;
          this.netPay = res.data.netPay;





          //console.log(res.data.deduct)
          // this.timer = setTimeout(() => {
          //   this.$q.loading.hide();
          //   this.timer = void 0;
          // }, 2000)
        });
    },

    showHistory:function(id){
        this.totaldeductionHistory = 0
        this.deductionHistory = []
        this.deductHistory = true
        let formData = new FormData();
        formData.append("id", id);
		axios.create({
            baseURL: this.apiUrl,
            headers: { 'Authorization': `Bearer ${this.token}` }
        }).post(`/payroll/loan/history`, formData)
          .then((res) => {
            this.totaldeductionHistory = res.data.total
			this.deductionHistory = res.data.data
        });
    },

    spaceTIN: function(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    spaceSSS: function(x) {
      return x.toString().substr(0, 2) + " " + x.toString().substr(2, 7) + " " + x.toString().substr(9, x.length);
    },
    spaceBank: function(x) {
      return x.toString().replace(/\B(?=(\d{4})+(?!\d))/g, " ");
    },
    beforeDestroy() {
      if (this.timer !== void 0) {
        clearTimeout(this.timer);
        this.$q.loading.hide();
      }
    }
  }
}
</script>
<style scoped>
.text-h4 {
  font-size: 20px;
}
.payslip-table-info-header {
  width: auto;
  table-layout: fixed;
  border-collapse: collapse;
  display: table;
  border: none;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-user-select: none;
  user-select: none;
}
.payslip-table-info-data {
  width: auto;
  table-layout: fixed;
  border-collapse: collapse;
  display: table;
  border: none;
  margin-bottom: 5px;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-user-select: none;
  user-select: none;
}
.payslip-info-header {
  line-height: 10px;
  font-size: 10px;
  text-align: center;
  color: white;
  background-color: #B71C1C;
  margin: none;
  padding: 5px;
  border: 1px solid #A10909;
}
.payslip-info-data {
  line-height: 11px;
  font-size: 11px;
  font-family: "Consolas", "Courier", sans-serif;
  font-weight: bold;
  text-align: center;
  border: none;
  margin: none;
  padding: none;
}
.payslip-table-main {
  width: 366px;
  table-layout: fixed;
  border-collapse: collapse;
  display: table;
  empty-cells: show;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-user-select: none;
  user-select: none;
}
.payslip-earnings-header {
  line-height: 10px;
  font-size: 10px;
  text-align: center;
  color: white;
  background-color: #B71C1C;
  margin: none;
  padding: 5px;
  border: 1px solid #A10909;
}
.left-padder {
  padding-left: 15px;
}
.right-padder {
  padding-right: 15px;
}
.payslip-earnings-data {
  height: 30px;
  line-height: 11px;
  font-size: 11px;
  font-family: "Consolas", "Courier", sans-serif;
  font-weight: bold;
  text-align: center;
  margin: none;
  padding: none;
  border: 0;
}
.payslip-earnings-amount {
  height: 30px;
  line-height: 11px;
  font-size: 11px;
  font-family: "Consolas", "Courier", sans-serif;
  font-weight: bold;
  text-align: right;
  margin: none;
  padding: none;
  padding-right: 15px;
  border: 0;
}
.payslip-net-pay {
  height: 60px;
  line-height: 14px;
  font-size: 14px;
  font-family: "Consolas", "Courier", sans-serif;
  font-weight: bold;
  text-align: left;
  margin: none;
  padding: none;
  padding-left: 20px;
  border: 0;
  border-left: 1px solid #A10909;
}
.payslip-net-amount {
  height: 60px;
  line-height: 14px;
  width: 110px;
  font-size: 14px;
  font-family: "Consolas", "Courier", sans-serif;
  font-weight: bold;
  text-align: left;
  margin: none;
  padding: none;
  border: 0;
  border-right: 1px solid #A10909;
}
.left-border {
  border-left: 1px solid #A10909;
}
.right-border {
  border-right: 1px solid #A10909;
}
.top-border {
  border-top: 1px solid #A10909;
}
.bottom-border {
  border-bottom: 1px solid #A10909;
}
.total-header {
  line-height: 11px;
  font-size: 11px;
  font-weight: bold;
  text-align: right;
  background-color: #F5AFAF;
  margin: none;
  padding: none;
}
.payslip-earnings-cy {
  height: 30px;
  width: 110px;
  line-height: 11px;
  font-size: 11px;
  font-family: "Consolas", "Courier", sans-serif;
  font-weight: bold;
  text-align: left;
  margin: none;
  padding: none;
  border: 1px solid #A10909;
  padding-left: 20px;
}
.payslip-footer {
  width: 100%;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  display: block;
}
</style>
