<template>
    <div class="q-pa-md">
		<div class="q-pa-md">
			<div class="row">
				<div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
          <div class="q-gutter-md row justify-start q-carousel__navigation-inner row items-center">
            <p style="color: #564254; font-size: 22px; font-weight: bold;">Attendance</p>
          </div>
          
					<q-select v-model="yearfilter" :options="yearOptions" label="Year"  style="width: 200px"/>
					<q-select v-model="month" :options="monthOptions" label="Month" emit-value map-options option-value="id" option-label="name" style="width: 200px"/>
							<q-btn color="primary" label="Filter"/>
						</div>
					</div>
				</div>
				<div class="q-pa-md">
					<q-table :dense="$q.screen.lt.md" :grid="$q.screen.xs" :data="timelogs" :columns="columns"
					:pagination.sync="pagination" row-key="id" separator="cell"
					no-data-label="I didn't find anything for you!" :loading="loading" :filter="filter"
					@request="onRequest" binary-state-sort>

					<template v-slot:body="props">
						<q-tr slot="body" :props="props">
							<q-td key="employee_id" :props="props">{{ props.row.employee_id }}</q-td>
							<q-td key="name" :props="props" class="caps">{{ props.row.name }}</q-td>
							<q-td key="type" :props="props" class="caps">{{ props.row.type }}</q-td>
							<q-td key="time_logs" :props="props">{{ props.row.time_logs }}</q-td>
						</q-tr>
					</template>
					<template v-slot:no-data="">
			          <div class="full-width row flex-center text-red q-gutter-sm">
			            <i class="fas fa-exclamation-triangle"></i>
			            <span>The filter didn't uncover any results!</span>
			          </div>
			        </template>
					<template v-slot:loading>
			            <q-inner-loading showing>
			                <q-spinner-hourglass  size="50px" color="cyan" />
			            </q-inner-loading>
			        </template>
					<template v-slot:top>
            <div style="width: 100%; display: flex; justify-content: center; align-items: center; flex-wrap: wrap; gap: 10px; margin-bottom: 10px;">
                <q-btn :disabled="disableBtn" @click="uploadAttendance('In'); disableBtn = true" push color="primary" label="Check In" />
                <q-btn @click="uploadAttendance('Out')" push color="primary" label="Check Out" />
                <q-btn @click="uploadAttendance('Break In')" push color="primary" label="Break In" />
                <q-btn @click="uploadAttendance('Break Out')" push color="primary" label="Break Out" />
                <!-- <q-btn @click="uploadAttendance('Log Out')" push color="primary" label="Log Out" /> -->
            </div>
						<!-- <q-input dense v-model="filter" placeholder="Search Employee">
							<i class="fas fa-search" slot="append"></i>
						</q-input>
						<q-space />
						<q-btn color="primary" label="Download" no-caps @click="exportTable"/> -->
					</template>
				</q-table>
			</div>

			<q-dialog v-model="spinner" persistent >
				<q-card class="my-card transparent no-shadow">
					<q-card-section >
						<q-spinner-gears color="cyan"  size="5.5em"/>
					</q-card-section>
				</q-card>
			</q-dialog>
	</div>
</template>

<script>
	import axios from 'axios';
	import mixins from '../../mixins';
	import { exportFile } from 'quasar';
	function wrapCsvValue (val, formatFn) {
		let formatted = formatFn !== void 0 ? formatFn(val) : val

		formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

		formatted = formatted.split('"').join('""')
		return `"${formatted}"`
	}
	export default {
		data () {
			return {
        disableBtn: false,
				filter: '',
		        loading: false,
		        pagination: {
		            page: 1,
		            rowsPerPage: 15,
		            rowsNumber: 10
		        },

				yearfilter: new Date().getFullYear(),
				yearOptions: [],
				cutoff: 1,
				month: new Date().getMonth() + 1,

				importfile:false,
				spinner:false,
				file:'',

				columns: [
				{ name: 'employee_id', align: 'center', label: 'Employee ID', field: 'employee_id', sortable: true },
				{ name: 'type', align: 'center', label: 'Types', field: 'type', sortable: true },
				{ name: 'time_logs', align: 'center', label: 'Date', field: 'time_logs', sortable: true },
				],
				timelogs: [],
				cutoffOptions:[{id:1,name:'First Cut-off'},{id:2,name:'Second Cut-off'}],
				monthOptions:[
				{id:1,name:'January'},
				{id:2,name:'February'},
				{id:3,name:'March'},
				{id:4,name:'April'},
				{id:5,name:'May'},
				{id:6,name:'June'},
				{id:7,name:'July'},
				{id:8,name:'August'},
				{id:9,name:'September'},
				{id:10,name:'October'},
				{id:11,name:'November'},
				{id:12,name:'December'},],
				}
		},

		created(){
			this.yearsList()
		},

		mounted(){
	        this.onRequest({
	            pagination: this.pagination,
	            filter: ''
	        })
	    },

		methods: {

			//start if server side
			onRequest (props) {
		      const { page, rowsPerPage} = props.pagination
		      const filter = props.filter
		      this.loading = true
		      // emulate server
		      setTimeout(() => {
		        this.getRowsNumberCount(filter).then(total => {
		            this.pagination.rowsNumber = total
		        })
		        const fetchCount = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage
		        const startRow = (page - 1) * rowsPerPage
		        this.fetchFromServer(startRow, fetchCount, filter)
		        this.pagination.page = page
		        this.pagination.rowsPerPage = rowsPerPage
		      }, 1500)
		    },

		    fetchFromServer (startRow, count, filter) {
		        axios.get(this.apiUrl + '/timekeeping/attendance', {
		            headers: { 'Authorization': `Bearer ${this.token}` },
		            params: {
		              year: this.yearfilter,
		              month: this.month,
		              cutoff: this.cutoff,
		              start: startRow,
		              countPerPage: count,
		              filter: filter,
		            }
		        }).then((response) => {
		            this.timelogs = response.data.data
                	console.log('data:' + JSON.stringify(this.timelogs))
					this.loading = false
		        }).catch((error) => {
		            console.log(error);
		        })
		   },

		    async getRowsNumberCount (filter) {
		       let response = await axios.get(this.apiUrl + '/timekeeping/attendance/total', {
		           headers: { 'Authorization': `Bearer ${this.token}` },
		           params: {
		             year: this.yearfilter,
		             month: this.month,
		             cutoff: this.cutoff,
		             filter: filter,
		           }
		       })
		       return response.data = response.data == 0 ? null : null;
		    },

        yearsList : function(){
					axios.create({
						baseURL : this.apiUrl,
						headers : {'Authorization' : `Bearer ${this.token}`}
					}).post(`/timekeeping/years/list`)
					.then((res) => {
						this.yearOptions = res.data.data
					});
				},

        uploadAttendance(type){
            let formData = new FormData();
            formData.append("type", type);
            axios.create({
                baseURL: this.apiUrl,
                headers: { 'Authorization': `Bearer ${this.token}` }
            }).post('/timekeeping/attendance', formData)
            .then((res) => {
              this.onRequest({
                  pagination: this.pagination,
                  filter: ''
              })
                this.$q.notify({
                  progress: true,
                  message: `Check ${type} Successful.`,
                  color: 'green'
                }, 2000)
            }).catch((error) => {
              this.$q.notify({
                  progress: true,
                  message: `Something went wrong.`,
                  color: 'red'
              }, 2000)
                console.log(error.response.status)
            });
        }
			}
		}
</script>