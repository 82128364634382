<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row ">
      <div class="col-12 q-mb-md">
        <h4 class="q-ma-none">Set Employee Permissions</h4>
      </div>
      <div class="col-12 q-mb-md">
        <q-form ref="vForm" @submit="onSubmitSearch" class="q-gutter-sm">
          <q-input v-model="searchModel" dense outlined :rules="[formRules.required]" placeholder="Search employee here...">
            <template v-slot:append>
              <q-icon name="fa fa-search" />
            </template>
          </q-input>
        </q-form>
      </div>
      <div class="col-12 q-mb-md">
        <q-table title="Search Result" :data="searchResultData" :columns="searchResultColumns" row-key="employee_id" :hide-bottom="searchResultData.length > 0" :loading="loading">
          <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click="searchRowClick(props.row.name,props.row.id)">
            <q-td key="employee_id" :props="props">{{ props.row.employee_id }}</q-td>
            <q-td key="name" :props="props">{{ props.row.name }}</q-td>
            <q-td key="position" :props="props">{{ props.row.position }}</q-td>
            <q-td key="department" :props="props">{{ props.row.department }}</q-td>
          </q-tr>
          <template v-slot:no-data="{ message }">
            <div class="full-width row flex-center text-red-5 q-gutter-sm">
              <span>
                {{ message }}
              </span>
              <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
            </div>
          </template>
        </q-table>
      </div>
      <div class="col-12" v-if="modulesArea">
        <q-card bordered class="my-card q-pa-sm">
          <div class="col-12 q-mb-md">
            <q-banner bordered dense rounded class="bg-light-blue-9 text-white text-center">
              Module Permissions For: <b>{{empName}}</b>
            </q-banner>
          </div>
          <div class="row q-col-gutter-sm">
            <div class="col-3" v-for="mods in modules" :key="mods.id">
              <q-card bordered>
                <q-card-section>
                  <div>
                    <q-item tag="label">
                      <q-item-section side top>
                        <q-toggle v-model="mods.enabled" @input="setVal(mods)" checked-icon="fa fa-check" color="green" unchecked-icon="fa fa-times" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label class="qitemLabel">{{ humanize(mods.name) }}</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" transition-show="scale" transition-hide="scale" :content-class="mods.enabled ? 'bg-red' : 'bg-green'" content-style="font-size: 13px">
                      <div v-if="mods.enabled === true">
                        Click to disable module.
                      </div>
                      <div v-else>
                        Click to enable module.
                      </div>
                    </q-tooltip>
                  </div>
                  <div v-if="mods.sub_modules.length > 0">
                    <q-expansion-item caption="Sub-menus">
                      <q-card-section>
                        <div v-for="submods in mods.sub_modules" :key="submods.id">
                          <div>
                            <q-item tag="label">
                              <q-item-section side top>
                                <q-toggle v-model="submods.enabled" @input="setVal(submods)" checked-icon="fa fa-check" color="green" unchecked-icon="fa fa-times" />
                              </q-item-section>
                              <q-item-section>
                                <q-item-label class="qitemLabel">{{ humanize(submods.name) }}</q-item-label>
                              </q-item-section>
                            </q-item>
                            <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" transition-show="scale" transition-hide="scale" :content-class="submods.enabled ? 'bg-red' : 'bg-green'" content-style="font-size: 13px">
                              <div v-if="submods.enabled === true">
                                Click to disable module.
                              </div>
                              <div v-else>
                                Click to enable module.
                              </div>
                            </q-tooltip>
                          </div>
                        </div>
                      </q-card-section>
                    </q-expansion-item>
                  </div>
                  <div v-else>
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </div>
          <div class="col-12" v-if="confirmConfig">
            <div class="row q-col-gutter-sm q-mt-md justify-end">
              <div class="col-3">
                <q-btn :loading="modEffectLoading" @click="moduleStatus" size="lg" style="width: 100%" push color="primary"> CONFIRM
                  <template v-slot:loading>
                    <q-spinner-gears class="on-left" />
                    UPDATING...
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card>
      </div>
    </div>
    <q-dialog v-model="loadingModel" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-lg">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            Fetching Records
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';

const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

export default {
  name: 'ModulePermissions',
  data() {
    return {
      empName: '',
      loadingModel: false,
      loading: false,
      modEffectLoading: false,
      modulesArea: false,
      confirmConfig: false,
      searchModel: null,
      searchResultData: [],
      searchResultColumns: [{
          name: 'employee_id',
          field: 'employee_id',
          label: 'Employee ID',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px'
        },
        {
          name: 'name',
          field: 'name',
          label: 'Name',
          style: 'width: 300px; padding-left: 100px',
          align: 'left',
          headerStyle: 'width: 300px; text-align:center'
        },
        {
          name: 'position',
          field: 'position',
          label: 'Position',
          style: 'width: 300px; padding-left: 100px',
          align: 'left',
          headerStyle: 'width: 300px; text-align:center'
        },
        {
          name: 'department',
          field: 'department',
          label: 'Department',
          align: 'center',
        }
      ],
      modules: [],
      updatedMods: [],
      selectedIsAdmin: false,
      selectedEmployeeId: 0,
      borderRadius: 'border-radius'
    }
  },
  methods: {
    onSubmitSearch: function() {
      this.loading = true;
      this.modulesArea = false;
      this.$refs.vForm.validate();

      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/employees/search', {
          params: {
            keyword: this.searchModel
          }
        })
        .then((res) => {
          this.loading = false;
          this.searchResultData = [];
          if (res.data.data.length > 0) {
            for (let emp of res.data.data) {
              this.searchResultData.push({
                id: emp.id,
                name: this.toTitleCase(emp.name),
                employee_id: emp.employee_id,
                position: emp.position,
                department: emp.department
              });
            }
          } else {
            this.modulesArea = false;
            this.confirmConfig = false;
          }
        })
    },

    searchRowClick: function(rowName, rowID) {
      this.loadingModel = true;
      this.empName = rowName;
      this.selectedEmployeeId = rowID;

      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/employees/modules', {
          params: {
            id: rowID
          }
        })
        .then((res) => {
          this.loadingModel = false;
          this.modulesArea = true;
          this.modules = res.data.data;
          if (this.modules[0].name == "admin" && this.modules[0].enabled == true) {
            this.selectedIsAdmin = true;
            this.setAllEnabled();
          }
          this.confirmConfig = true;
        })
    },

    setVal: function(value) {
      var i, v, a;
      if (value.name == "admin") {
        for (i = 0; i < this.modules.length; i++) {
          if (value.enabled == false) {
            this.modules[i].enabled = false;
            if (this.modules[i].sub_modules.length > 0) {
              for (v = 0; v < this.modules[i].sub_modules.length; v++) {
                this.modules[i].sub_modules[v].enabled = false;
              }
            }
          } else {
            this.modules[i].enabled = true;
            if (this.modules[i].sub_modules.length > 0) {
              for (v = 0; v < this.modules[i].sub_modules.length; v++) {
                this.modules[i].sub_modules[v].enabled = true;
              }
            }
          }
        }
      } else if (value.name != "admin" && this.selectedIsAdmin == true) {
        this.modules[0].enabled = false;
        for (i = 1; i < this.modules.length; i++) {
          if (this.modules[i].enabled == false && this.modules[i].sub_modules.length > 0) {
            for (v = 0; v < this.modules[i].sub_modules.length; v++) {
              this.modules[i].sub_modules[v].enabled = false;
            }
          }
        }
      } else {
        for (i = 1; i < this.modules.length; i++) {
          if (this.modules[i].enabled == false && this.modules[i].sub_modules.length > 0) {
            for (v = 0; v < this.modules[i].sub_modules.length; v++) {
              this.modules[i].sub_modules[v].enabled = false;
            }
          }
        }
      }
    },

    moduleStatus: function() {
      this.modEffectLoading = true;
      this.updatedMods = [];
      for (let updateMods of this.modules) {
        for (let updateSubMods of updateMods.sub_modules) {
          this.updatedMods.push({
            id: updateSubMods.id,
            enabled: updateSubMods.enabled
          });
        }
        this.updatedMods.push({
          id: updateMods.id,
          enabled: updateMods.enabled
        });
      }

      let formData = new FormData();

      formData.append("employee_id", this.selectedEmployeeId);
      formData.append("modules", JSON.stringify(this.updatedMods));

      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/employees/module/modified', formData).then((res) => {
        console.log('Modules Set Success');
      })

      setTimeout(() => {
        this.modEffectLoading = false
        this.$q.notify({
          color: 'blue-9',
          textColor: 'white',
          icon: 'fa fa-cogs',
          message: 'Modules Set Success'
        });
        scrollToTop();
      }, 1500);
    },

    setAllEnabled: function() {
      for (let mod of this.modules) {
        mod.enabled = true;
        for (let sub of mod.sub_modules) {
          sub.enabled = true;
        }
      }
    }
  }
}
</script>
<style lang="css" scoped>
.qitemLabel {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.semi-round {
  border-radius: 10px;
}
</style>