<template>
  <q-dialog v-model="dialog" persistent>
    <q-card style="width: 490px; max-width: 80vw;">
      <q-bar>
        <q-space />
        <q-btn dense flat v-close-popup>
          <i class="fas fa-times"></i>
          <q-tooltip>Close</q-tooltip>
        </q-btn>
      </q-bar>
      <q-card-section style="max-height: 150vh" >
        <q-form ref="vForm" @submit="onSubmit">
              <div class="q-gutter-sm row justify-center" style="margin-bottom: 10px;">
                <q-checkbox v-model="status" label="IN-ACTIVE" color="red" val="INACTIVE" />
                <q-checkbox v-model="status" label="HOLD"  color="orange" val="HOLD" />
                <q-checkbox v-model="status" label="ACTIVE" color="teal" val="ACTIVE" />
              </div>
              <div class="q-gutter-md row justify-center">
                <q-btn color="primary" label="Download CSV" @click="fileExtension = 'csv'" type="submit"/>
                <q-btn color="primary" label="Download EXCEL" @click="fileExtension = 'excel'" type="submit"/>
              </div>
            </q-form>
          </q-card-section>
        </q-card>
      </q-dialog>
    </template>
    <script>
    import axios from 'axios';
    import mixins from '../../mixins';
    export default {
      data () {
        return {
          dialog: false,
          fromDay:'',
          toDay:'',
          location:'ALL',
          department:0,
          paymentmode:'ALL',
          bank:'ALL',
          payclass:'ALL',
          account:'ALL',
          costcenter:'ALL',
          taxtstatus:'ALL',
          status:['ACTIVE'],
          locationOptions:[],
          departmentOptions:[],
          paymentmodeOptions:[],
          bankOptions:[],
          payclassOptions:[],
          accountOptions:[],
          costCenterOptions:[],
          rankOptions:[],
          taxStatusOptions:[],
          fileExtension: 'xls'
        }
      },

      created(){
        this.eventHub.$on('FilterOptions', this.filterDialog);
        this.filterOptions()
      },

      methods: {
        onSubmit:function(){
          let formData = new FormData();
          formData.append("role_id", this.userData.role_id);
          formData.append("fromDay", this.fromDay);
          formData.append("toDay", this.toDay);
          formData.append("location", this.location);
          formData.append("department", this.department);
          formData.append("paymentmode", this.paymentmode);
          formData.append("bank", this.bank);
          formData.append("payclass", this.payclass);

          formData.append("account", this.account);
          formData.append("costcenter", this.costcenter);
          formData.append("taxtstatus", this.taxtstatus);
          formData.append("status", JSON.stringify(this.status));

          axios.create({
            baseURL : this.apiUrl,
            responseType: 'arraybuffer',
            headers : {'Authorization' : `Bearer ${this.token}`}
          }).post(`/employee/list/download`,formData)
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            if(this.fileExtension === 'csv'){
              fileLink.setAttribute('download', 'Employeelist.csv');
            }else{
              fileLink.setAttribute('download', 'Employeelist.xls');
            }
            document.body.appendChild(fileLink);
            fileLink.click();
          });
        },

        filterDialog:function(payload){
          this.dialog = payload.status

        },


        filterOptions:function(){

          axios.create({
            baseURL : this.apiUrl,
            headers : {'Authorization' : `Bearer ${this.token}`}
          }).get('/employee/dropdown/properties')
          .then((res) => {

           this.locationOptions = res.data.data.location
           this.departmentOptions = res.data.data.department,
           this.paymentmodeOptions = res.data.data.paymentMode
           this.bankOptions = res.data.data.bank
           this.payclassOptions = res.data.data.payClass
           this.accountOptions = res.data.data.account
           this.costCenterOptions = res.data.data.costCenter
           this.rankOptions = res.data.data.rank
           this.taxStatusOptions = res.data.data.taxStatus

           this.locationOptions.unshift('ALL')
           this.departmentOptions.unshift({'id':0,'name':'ALL'})
           this.paymentmodeOptions.unshift('ALL')
           this.bankOptions.unshift('ALL')
           this.payclassOptions.unshift('ALL')
           this.accountOptions.unshift('ALL')
           this.costCenterOptions.unshift('ALL')
           this.rankOptions.unshift('ALL')
           this.taxStatusOptions.unshift('ALL')
         });
        },
      }
    }
    </script>
