<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="row q-mb-md full-width">
        <div class="full-width text-h4" style="height: 40px;">
          New Application List
          <q-chip square class="q-ml-sm text-bold" size="md" color="blue" text-color="white">
            <q-avatar font-size="14px" color="blue-grey-8" text-color="white" style="width:1.7em">{{ applicationCounts }}</q-avatar>
            Applications
          </q-chip>
        </div>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-12 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <q-table :data="applicationsData" :columns="ResultColumns" :pagination.sync="Pagination" :filter="filter" row-key="id" dense
                virtual-scroll :virtual-scroll-item-size="48" style="max-height: 600px">
                  <template v-slot:top-right>
                    <q-input borderless dense debounce="300" v-model="filter" placeholder="Search" outlined>
                      <template v-slot:append>
                        <q-icon name="fas fa-search" />
                      </template>
                    </q-input>
                  </template>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" >
                    <q-td key="action" style="text-align:center;">
                      <q-btn color="red" rounded size="sm" text-color="white" label="Incomplete" @click="openConfirmation(props.row.id, 'incomplete')" v-if="props.row.application_status == 'Processing'"/>
                      <q-btn color="green" rounded size="sm" text-color="white" label="Complete" @click="openConfirmation(props.row.id, 'complete')"/> <!-- update 01092023 -->
                    </q-td>

                    <q-td key="application_status" >
                        <q-badge outline color="primary" label="Processing" v-if="props.row.application_status == 'Processing'"/>
                        <q-badge outline color="orange" label="Incomplete" v-if="props.row.application_status == 'Incomplete'"/>
                    </q-td>
                    <q-td key="applicant_id">{{ props.row.applicant_id }}</q-td>
                    <q-td key="firstname" >{{ props.row.firstname }}</q-td>
                    <q-td key="middlename" >{{ props.row.middlename }}</q-td>
                    <q-td key="lastname" >{{ props.row.lastname }}</q-td>
                    <q-td key="mobile" >{{ props.row.mobile }}</q-td>
                    <q-td key="email" >{{ props.row.email }}</q-td>
                    <q-td key="presaddress" >{{ props.row.presaddress }}</q-td>
                    <q-td key="prescity" >{{ props.row.prescity }}</q-td>
                    <q-td key="civil_status" >{{ props.row.civil_status }}</q-td>
                    <q-td key="gender" >{{ props.row.gender }}</q-td>
                    <q-td key="date_of_birth" >{{ props.row.date_of_birth }}</q-td>
                    <q-td key="edattain" >{{ props.row.edattain }}</q-td>
                    <q-td key="course" >{{ props.row.course }}</q-td>
                    <q-td key="estrainee" >{{ props.row.estrainee }}</q-td>
                    <q-td key="account" >{{ props.row.account }}</q-td>
                    <q-td key="batch" >{{ props.row.batch }}</q-td>
                    <q-td key="date_hired" >{{ props.row.date_hired }}</q-td>
                    <q-td key="expected_pbdate" >{{ props.row.expected_pbdate }}</q-td>
                    <q-td key="result" >{{ props.row.result }}</q-td>

                    <q-td key="date_of_application">{{ props.row.date_of_application }}</q-td>
                    <q-td key="position_applying_for">{{ props.row.position_applying_for }}</q-td>
                    <q-td key="with_call_center_experience">{{ props.row.with_call_center_experience }}</q-td>

                  </q-tr>
                  <template v-slot:no-data>
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
    <q-dialog v-model="loadingModel" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            {{loadingText}}
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="loadingAction" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            Processing Application
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="noData">
      <q-card>
        <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">NO DATA</span>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="row q-pa-md">
            <div class="text-h6">There is no data!</div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="confirmModal">
      <q-card style="width: 500px">
        <q-card-section>
          <div class="text-h6">{{ confirm.title }}</div>
        </q-card-section>
        <q-separator />
        <q-card-section class="q-py-sm" style="text-align: center">
          <q-icon name="fas fa-question-circle" style="font-size: 50px;color: blue;" />
          <div class="text-h5">{{ confirm.message }}</div>
        </q-card-section>
        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn flat label="Yes" @click="updateApplication(confirm.id, confirm.status)"/>
          <q-btn flat label="Cancel" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';
import { exportFile } from 'quasar';
import vueCookies from 'vue-cookies';
function wrapCsvValue (val, formatFn) {
  let formatted = formatFn !== void 0
    ? formatFn(val)
    : val

  formatted = formatted === void 0 || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

export default {
  name: 'NewApplication',
  data() {
    return {
      filter: '',
      loadingModel: false,
      loadingText: '',
      loadingAction: false,
      applicationCounts: 0,
      applications: [],
      applicationsData: [],
      forHiredSelected: [],
      noData: false,
      dateTo: null,
      dateFrom: null,
      searchDateToFrom: {
          dateTo: null,
          dateFrom: null,
          status: 'Endorse'
      },
      Pagination: {
        sortBy: 'date_of_application',
        descending: true,
        // page: 1,
        rowsPerPage: 0
      },
      ResultColumns: [
        {
          name: 'action',
          field: 'action',
          label: 'Action',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'application_status',
          field: 'application_status',
          label: 'Status',
          sortable: true,
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'applicant_id',
          field: 'applicant_id',
          label: 'Applicant ID',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'firstname',
          field: 'firstname',
          label: 'First Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'middlename',
          field: 'middlename',
          label: 'Middle Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'lastname',
          field: 'lastname',
          label: 'Last Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'mobile',
          field: 'mobile',
          label: 'Contact Number',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'email',
          field: 'email',
          label: 'Email Address',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'presaddress',
          field: 'presaddress',
          label: 'Home Address',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'prescity',
          field: 'prescity',
          label: 'City',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'civil_status',
          field: 'civil_status',
          label: 'Civil Status',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'gender',
          field: 'gender',
          label: 'Gender',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'date_of_birth',
          field: 'date_of_birth',
          label: 'Birthdate',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'edattain',
          field: 'edattain',
          label: 'Highest Educational Attainment',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'course',
          field: 'course',
          label: 'Course',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'estrainee',
          field: 'estrainee',
          label: 'E.S.',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'account',
          field: 'account',
          label: 'Account',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'batch',
          field: 'batch',
          label: 'Batch',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'date_hired',
          field: 'date_hired',
          label: 'Date Hired',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'expected_pbdate',
          field: 'expected_pbdate',
          label: 'Expected PB Date',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'result',
          field: 'result',
          label: 'Result',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'date_of_application',
          field: 'date_of_application',
          label: 'Date Applied',
          sortable: true,
          align: 'center',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'position_applying_for',
          field: 'position_applying_for',
          label: 'Position Applying For',
          align: 'center',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'with_call_center_experience',
          field: 'with_call_center_experience',
          label: 'With Call Center Expereience?',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
      ],
      confirmModal: false,
      confirm: {
        title: "",
        message: "",
        id: "",
        status: ""
      }
    }
  },
  methods: {
        searchData: function(){
            vueCookies.set(this.searchKey, {
            'search': this.filter
            });
            //this.filter = this.searchKeyword;
        },

        loadApplications: function() {
            this.loadingText = 'Retrieving Applications';
            this.loadingModel = true;
            this.applications = [];
            this.applicationsData = [];

        axios.create({
            baseURL: this.apiUrl,
            headers: {
                'Authorization': `Bearer ${this.token}`
            }})
            .get('/employee/application')
            .then((res) => {
                this.applications = res.data.data;
                this.applicationCounts = Object.keys(this.applications).length;
                console.log(JSON.stringify(this.applications[0].applicant_details))

                for (let eachApplication of this.applications) {
                    let name = eachApplication.applicant_details.lastname + ', ' + eachApplication.applicant_details.firstname + ' ' + eachApplication.applicant_details.middlename;
                    let tempDate = eachApplication.applicant_details.date_apply;
                    let date_applied = date.formatDate(tempDate, 'MMMM DD, YYYY');
                    let date_exam = date.formatDate(eachApplication.applicant_details.exam_date, 'MMMM DD, YYYY');

                    var status = "";
                    if(eachApplication.applicant_details.passfail == 'Pass'){
                        status = "For Final Interview";
                    }else if(eachApplication.applicant_details.passfail == 'Fail'){
                        status = "Delete";
                    }else{
                        status = "No results";
                    }

                    var edattn = '';
                    if(eachApplication.applicant_details.edattain == "ed0"){
                        edattn = "High School Graduate";
                    } else if(eachApplication.applicant_details.edattain == "ed1"){
                        edattn = "College Level";
                    } else if(eachApplication.applicant_details.edattain == "ed2"){
                        edattn = "Degree Holder";
                    } else if(eachApplication.applicant_details.edattain == "ed3"){
                        edattn = "Vocational Graduate";
                    }else {
                        edattn = eachApplication.applicant_details.edattain;
                    }

                    var appstatus = '';
                    if(eachApplication.status == 'process'){
                        appstatus = 'Processing';
                    }else if(eachApplication.status == 'incomplete'){
                        appstatus = 'Incomplete';
                    }

                    // console.log(eachApplication);

                    this.applicationsData.push({
                        id: eachApplication.id,
                        application_status: appstatus,
                        tracking_number: eachApplication.applicant_details.tracking_number,
                        trainee_id: eachApplication.applicant_details.trainee_id,
                        firstname: eachApplication.applicant_details.firstname,
                        middlename: eachApplication.applicant_details.middlename,
                        lastname: eachApplication.applicant_details.lastname,
                        mobile: eachApplication.applicant_details.mobile,
                        email: eachApplication.applicant_details.email,
                        presaddress: eachApplication.applicant_details.presaddress,
                        prescity: eachApplication.applicant_details.prescity,
                        civil_status: eachApplication.applicant_details.civil_status,
                        gender: eachApplication.applicant_details.gender,
                        edattain: edattn,
                        course: eachApplication.applicant_details.course,
                        estrainee: eachApplication.applicant_details.estrainee,
                        account: eachApplication.applicant_details.account,
                        batch: eachApplication.applicant_details.batch,
                        date_hired: eachApplication.applicant_details.date_hired,
                        expected_pbdate: eachApplication.applicant_details.expected_pbdate,
                        result: eachApplication.applicant_details.result,
                        date_of_application: date_applied,
                        position_applying_for: eachApplication.applicant_details.position_sought,
                        with_call_center_experience: eachApplication.applicant_details.ccexperience,
                        applicant_id: eachApplication.applicant_details.tracking_number,
                        exam_date: date_exam,
                        passfail: eachApplication.applicant_details.passfail_int,
                        // ave_grade: eachApplication.applicant_details.ave_grade,
                        inactive: eachApplication.applicant_details.inactive,
                        status: status
                    });
                    // console.log(this.applicationsData);
                }

            }).finally(() => {
                this.loadingModel = false;
                this.loadingText = '';
            });
        },

        openConfirmation(id, status){
          this.confirmModal = true;
          if(status == "incomplete"){
            this.confirm.title = "Incomplete";
            this.confirm.message = "Are you sure you want to proceed?"
          }else{
            this.confirm.title = "Complete";
            this.confirm.message = "Are you sure you want to proceed?"
          }
          this.confirm.id = id;
          this.confirm.status = status;
        },
        updateApplication(id, status){
          let formData = new FormData();
            var data = {
                id: id,
                status: status
            }
            formData.append('form_data', JSON.stringify(data));
            axios.create({
                baseURL: this.apiUrl,
                headers: {
                'Authorization': `Bearer ${this.token}`
                }
            }).post('/employee/update-application', formData).then((res) => {
                setTimeout(() => {
                // console.log(res);
                this.confirmModal = false;
                this.loadingText = 'Retrieving Applications';
                this.loadingModel = true;
                this.loadApplications();
                }, 1000);
            }).finally(() => {
                this.loadingModel = false;
                this.loadingText = '';
            });
        },
        // incompleteApplication(id){

        //     let formData = new FormData();
        //     var data = {
        //         id: id,
        //         status: 'incomplete'
        //     }
        //     formData.append('form_data', JSON.stringify(data));
        //     axios.create({
        //         baseURL: this.apiUrl,
        //         headers: {
        //         'Authorization': `Bearer ${this.token}`
        //         }
        //     }).post('/employee/update-application', formData).then((res) => {
        //         setTimeout(() => {
        //         // console.log(res);
        //         this.loadingText = 'Retrieving Applications';
        //         this.loadingModel = true;
        //         this.loadApplications();
        //         }, 1000);
        //     }).finally(() => {
        //         this.loadingModel = false;
        //         this.loadingText = '';
        //     });
        // },
        // completeApplication(id){
        //     let formData = new FormData();
        //     var data = {
        //         id: id,
        //         status: 'complete'
        //     }
        //     formData.append('form_data', JSON.stringify(data));
        //     axios.create({
        //         baseURL: this.apiUrl,
        //         headers: {
        //         'Authorization': `Bearer ${this.token}`
        //         }
        //     }).post('/employee/update-application', formData).then((res) => {
        //         setTimeout(() => {
        //         // console.log(res);
        //         this.loadApplications();
        //         }, 1000);
        //     }).finally(() => {
        //         this.loadingModel = false;
        //         this.loadingText = '';
        //     });
        // },
    },
    mounted() {
        this.loadApplications();
    }
}
</script>
<style scoped>
.sticky {
  height: 80px;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  top: 0;
  z-index: 10;
}

.sticky div {
  width: 100%;
}

.btn-height {
  height: 40px;
}

.text-head {
  font-size: 16px;
}
</style>
