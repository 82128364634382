<template>
	<div>
		<q-dialog v-model="career" persistent transition-show="scale" transition-hide="scale">
			<q-card class="q-bottom-sheet--list"  style="max-width:2000px;width:2000px;height:850px">
				<q-bar>
					<q-space />
					<q-btn dense flat v-close-popup>
						<i class="fas fa-times"></i>
						<q-tooltip>Close</q-tooltip>
					</q-btn>
				</q-bar>
				<!-- ========================================================== -->
				<!-- ruben::26/07/2023 -->
				<!-- ========================================================== -->
				<div class="row full-width">
					<div class="col-12 q-ma-md">
						<!-- <q-btn outline color="green" label="Download Template" @click="getTemplate" v-show="canDownload"/> -->
						<q-btn label="Template" color="positive" class="q-mb-sm q-mr-sm" @click="downloadTemplate('uploads/template/career_update.csv')"/>
						<q-btn label="Upload" color="negative" class="q-mb-sm q-mr-sm" @click="prompt = true"/>
					</div>
				</div>
				<!-- ========================================================== -->
				<div class="row">
					<div class="col-3 q-mb-md" >
						<q-form ref="vForm" @submit="onSubmit">
							<div class="q-pa-md">
							</div>
							<q-card-section>
								<div class="col-12 q-mb-md q-gutter-md">
									<q-select outlined v-model="actionTake" option-value="id" option-label="name" :options="actionTakeOptions" label="Action" emit-value map-options @input="checkInput" required class="caps"/>
									<q-input outlined v-model="others" label="Action Taken"  v-show="isOther"/>

									<q-input  outlined v-model="effectivity" mask="date" :rules="['date']"  label="Effectivity" v-show="isCommon">
										<template v-slot:append>
											<i class="far fa-calendar-alt cursor-pointer" >
												<q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
													<q-date v-model="effectivity" @input="() => $refs.qDateProxy.hide()" />
												</q-popup-proxy>
											</i>
										</template>
									</q-input>
									<q-select outlined v-model="cut_off" :options="cutoffoptions" label="Cut-Off" v-show="isCommon" class="caps"/>

									<q-input outlined v-model="from" label="From" v-show="isFromTo" class="caps"/>

									<q-select outlined v-model="location"  :options="locationOptions" label="TO" v-show="isTransfer" @input="selectedItem" class="caps"/>
									<q-select outlined v-model="rank"  :options="rankOptions"   label="TO" v-show="isPromotion" @input="selectedItem" class="caps"/>
									<q-select outlined v-model="employmentStatus"  :options="employmentStatusOption" label="TO" v-show="isChange" @input="selectedItem" class="caps"/>

									<q-input outlined v-model="basicsalary" label="Basic Salary"  type="number" v-show="isSsalary" @input="changeSalary" class="caps"/>
									<q-input outlined v-model="allowance" label="Allowance"  type="number" v-show="isSsalary" @input="changeAllowance" class="caps"/>
									<q-input outlined v-model="deminimis" label="Deminimis"  type="number"  v-show="isSsalary" class="caps"/>

									<q-input outlined v-model="remarks" label="Remarks" v-show="isCommon" class="caps"/>
									</div>
								</q-card-section>
								<q-card-actions align="center">
									<div>
										<q-btn flat label="Delete" color="red" @click="deleteCareer()" v-show="isSelected"/>
										<q-btn flat label="Submit" type="submit" color="primary"/>
										<q-btn flat label="Close" color="primary" @click="closeModal"/>

									</div>
								</q-card-actions>

							</q-form>
						</div>
						<div class="col-9 q-mb-md" >
							<div class="q-pa-md">
							<q-table :title="fullname" :data="careerupdates" :columns="columns" row-key="id" :pagination.sync="pagination" >
				            <template v-slot:body="props">
				              <q-tr slot="body" :props="props" @click="selectedRow(props)">
				                <q-td key="action_taken" :props="props">{{ props.row.action_taken }}</q-td>
				                <q-td key="effectivity" :props="props">{{ props.row.effectivity }}</q-td>
				                <q-td key="cut_off" :props="props">{{ props.row.cut_off }}</q-td>
				                <q-td key="from" :props="props">{{ props.row.from }}</q-td>
				                <q-td key="to" :props="props">{{ props.row.to }}</q-td>
				                <q-td key="basic_salary" :props="props">{{ props.row.basic_salary }}</q-td>
				                <q-td key="allowance" :props="props">{{ props.row.allowance }}</q-td>
				                <q-td key="deminimis" :props="props">{{ props.row.deminimis }}</q-td>
								<q-td key="total" :props="props">{{ props.row.total }}</q-td>
								<q-td key="remarks" :props="props">{{ props.row.remarks }}</q-td>
								<q-td key="update_by" :props="props">{{ props.row.update_by }}</q-td>
								<q-td key="date_created" :props="props">{{ props.row.date_created }}</q-td>
				              </q-tr>
				            </template>
			            <template v-slot:no-data="">
			              <div class="full-width row flex-center text-red q-gutter-sm">
			                <i class="fas fa-exclamation-triangle"></i>
			                <span>
			                  No Result Found!
			                </span>
			              </div>
			            </template>
			          </q-table>
							</div>
						</div>
					</div>
				</q-card>
			</q-dialog>
			<q-dialog v-model="spinner" persistent >
				<q-card class="my-card transparent no-shadow">
					<q-card-section >
						<q-spinner-gears color="cyan"  size="5.5em"/>
					</q-card-section>
				</q-card>
			</q-dialog>

			<!-- ========================================================== -->
			<!-- ruben::26/07/2023 -->
			<!-- ========================================================== -->
			<q-dialog v-model="prompt" persistent>
				<q-card style="min-width: 350px">
					<q-card-section>
						<div class="text-h6">Upload CSV File</div>
					</q-card-section>

					<q-card-section class="q-pt-none">
					<!-- <q-input dense v-model="address" autofocus @keyup.enter="prompt = false" /> -->
					<q-file outlined v-model="fileUpload" label="Select CSV File"  accept=".csv"/>
					</q-card-section>

					<q-card-actions align="right" class="text-primary">
					<q-btn flat label="Cancel" v-close-popup />
					<q-btn flat label="Upload" @click="onUpload"/>
					</q-card-actions>
				</q-card>
			</q-dialog>
			<!-- ========================================================== -->
		</div>
	</template>
	<script>
	import axios from 'axios';
	import mixins from '../../mixins';

	export default {

		data() {
			return {
				obj:{},
				tempID:0,
				isSelected:false,
				titleCareer:'Career Update',

				isCommon:false,
				isSsalary:false,
				isOther:false,
				isFromTo:false,

				pagination: {
		        	rowsPerPage: 10
		      	},
				cut_off:null,
				career:false,
				spinner:false,
				fullname:null,
				emp_id:null,
				actionTake:null,
				others:null,
				basicsalary:0,
				allowance:0,
				deminimis:0,
				total:0,
				effectivity:null,
				from:'',
				to:'',
				location:'',
				locationOptions:'',
				rank:'',
				rankOptions:'',
				employmentStatus:'',
				employmentStatusOption:'',
				isPromotion:false,
				isTransfer:false,
				isChange:false,
				isSsalaryAdjust:false,
				remarks:'',
				actionTaken:null,
				columns: [
			//	{ name: 'action', align: 'center', label: 'Action', field: '', sortable: true },
				{ name: 'action_taken', align: 'center', label: 'Action Taken', field: 'action_taken', sortable: true },
				{ name: 'effectivity', label: 'Effectivity', field: 'effectivity', sortable: true },
				{ name: 'cut_off', label: 'Cut-off', field: 'cut_off', sortable: true },
				{ name: 'from', label: 'From', field: 'from' , sortable: true},
				{ name: 'to', label: 'To', field: 'to' , sortable: true},
				{ name: 'basic_salary', label: 'Basic Salary', field: 'basic_salary' , sortable: true},
				{ name: 'allowance', label: 'Allowance', field: 'allowance' , sortable: true},
				{ name: 'deminimis', label: 'Deminimis', field: 'deminimis' , sortable: true},
				{ name: 'total', label: 'Total', field: 'total' , sortable: true},
				{ name: 'remarks', label: 'Remarks', field: 'remarks' , sortable: true},
				{ name: 'update_by', label: 'Update by', field: 'update_by' , sortable: true},
				{ name: 'date_created', label: 'Date Updated', field: 'date_created' , sortable: true},
				],
				cutoffoptions: ['26 - 10', '11 - 25'],
				careerupdates: [],
				actionTakeOptions:[],
				//ruben::26/07/2023
				prompt: false,
				fileUpload: '',
			}
		},
		created(){
			this.eventHub.$on('CareerUpdates', this.careerUpdate);
			this.getDropdownProperties()

		},

		methods: {
			// ===================================================================================
			// ruben::26/07/2023

			msgAlrt(title,caption,type){
				let color = (type == 'success') ? 'positive' : 'negative'
				let icon = (type == 'success') ? 'far fa-check-circle' : 'far fa-times-circle'
				this.$q.notify({
					icon:icon,
					message:title,
					caption:caption,
					color:color
				})
			},
			onUpload(){
				let formData  = new FormData();
				formData.append("files", this.fileUpload);
				axios({
					headers : {'Authorization' : `Bearer ${this.token}`},
					url:this.apiUrl+'/employee/career-update',
					data: formData,
					method: 'POST'
				}).then(response => {
					this.msgAlrt('Success','File Uploaded successfully','success')
					this.prompt = false
					this.fileUpload = ''
					// this.loadData()
					console.log('career update: upload',response.data)
				})
			},

			downloadTemplate: function(url) {
				axios({
					url: this.apiUrl + '/' + url,
					method: 'GET',
					responseType: 'arraybuffer',
				}).then((response) => {
					var fileURL = window.URL.createObjectURL(new Blob([response.data]));
					var fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute('download', 'CareerUpdateTemplate.csv');
					document.body.appendChild(fileLink);

					fileLink.click();
				});
			},
			// end function
			// ===================================================================================
			
			checkInput:function(){

				this.isSelected = false
				this.to = ''
				this.location =''
				this.rank =''
				this.employmentStatus=''

				var selected = this.actionTake;
				//console.log(selected)

				if(selected == 2){//Transfer
		          	this.isCommon = true
		          	this.isFromTo = true
		          	this.isSsalary = false
		          	this.isOther = false

					this.isTransfer = true
					this.isChange = false
					this.isPromotion = false
					this.from = this.obj.location
		        }else  if(selected == 3){ //Transfer with Salary Adjustment
		          	this.isCommon = true
		          	this.isFromTo = true
		          	this.isSsalary = true
		          	this.isOther = false

					this.isTransfer = true
					this.isChange = false
					this.isPromotion = false
					this.from = this.obj.location
		        }else  if(selected == 4){ //Salary Adjustment
		          	this.isCommon = true
		          	this.isFromTo = true
		          	this.isSsalary = true
		          	this.isOther = false

					this.isTransfer = false
					this.isChange = false
					this.isPromotion = false
					this.from = this.obj.basic_salary
				}else  if(selected == 5){ //Change Employment Status
					this.isCommon = true
		          	this.isFromTo = true
		          	this.isSsalary = false
		          	this.isOther = false

					this.isTransfer = false
					this.isChange = true
					this.isPromotion = false
					this.from = this.obj.employment_status

				}else  if(selected == 6){ //Change Employment Status with Salary Adjustment

					this.isCommon = true
		          	this.isFromTo = true
		          	this.isSsalary = true

					this.isTransfer = false
					this.isChange = true
					this.isPromotion = false
					this.from = this.obj.employment_status
				}else  if(selected == 9){ //Temporary allowance
					this.isCommon = true
					this.isFromTo = true
					this.isSsalary = true

					this.isTransfer = false
					this.isChange = false
					this.isPromotion = false
					this.from = this.obj.allowance
				}else  if(selected == 10){ //Promotion
					this.isCommon = true
		          	this.isFromTo = true
		          	this.isSsalary = true

					this.isTransfer = false
					this.isChange = false
					this.isPromotion = true
					this.from = this.obj.rank
		        }else{
		          	this.isCommon = false
		          	this.isSsalary = false
		          	this.isOther = false
		          	this.isFromTo = false

					this.isTransfer = false
					this.isChange = false
					this.isPromotion = false
		        }

      },
	  		changeSalary:function(val){
				var selected = this.actionTake;
				if(selected == 4){
					this.to = this.basicsalary
				}
			},
			changeAllowance:function(val){
				var selected = this.actionTake;
				if(selected == 9){
					this.to = this.allowance
				}
			},
			selectedItem:function(val){
				this.to = val
			},

			selectedRow:function(obj){
				var id = this.actionTakeOptions.filter(x => x.name.toLowerCase() === obj.row.action_taken.toLowerCase());
				this.actionTake = id[0].id
				this.tempID = obj.row.id
				this.from = obj.row.from

				this.checkInput()
				this.isSelected = true
			},

		//delete selected career updates
		deleteCareer:function(){
			axios({
				headers : {'Authorization' : `Bearer ${this.token}`},
				url:this.apiUrl+`/employee/delete/careerupdate/${this.tempID}`,
				method: 'POST'
			}).then(response => {
				this.getCareerUpdate(this.emp_id)
			}).catch((error) => {
				console.log(error.response)
			})
		},

		getDropdownProperties:function(){

			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).get('/employee/dropdown/properties')
			.then((res) => {
				this.locationOptions = res.data.data.location
				this.rankOptions = res.data.data.rank
				this.employmentStatusOption = res.data.data.empStatus
			});
		},


		//list of career update for selected employee
    careerUpdate:function(payload){
		this.actionTake = ''
		this.isCommon = false
		this.isSsalary = false
		this.isOther = false
		this.isFromTo = false
		this.isTransfer = false

		this.obj = payload.employee.row

      	this.from = payload.employee.row.employment_status
      	this.basicsalary = payload.employee.row.basic_salary
      	this.allowance = payload.employee.row.allowance
      	this.deminimis = payload.employee.row.deminimis
		this.location = payload.employee.row.location


         // console.log(payload.employee.row.employment_status)

          var total =  parseInt(this.basicSalary) + parseInt(this.allowance) + parseInt(this.deminimis);
          this.total = total;

          this.fullname = payload.employee.row.firstname + ' ' + payload.employee.row.lastname + '' + ' Career Update'
          this.emp_id = payload.employee.key
          this.career = payload.stat
          this.getCareerUpdate(payload.employee.key)
      },

      onSubmit:function(){
      	if(this.actionTake == 8){
      		this.actionTaken = this.others
      	}else{
      		this.actionTaken = this.actionTakeOptions[this.actionTake - 1].name
      	}

      	this.spinner = true
      	let formData  = new FormData();
      	formData.append("emp_id", this.emp_id);
      	formData.append("action_taken",this.actionTaken);
      	formData.append("effectivity", this.effectivity);
      	formData.append("cut_off", this.cut_off);
      	formData.append("from", this.from);
      	formData.append("to", this.to);
      	formData.append("basicsalary", this.basicsalary);
      	formData.append("allowance", this.allowance);
      	formData.append("deminimis", this.deminimis);
      	formData.append("remarks", this.remarks);
      	formData.append("updateby", this.usersFullname);
      	axios({
      		headers : {'Authorization' : `Bearer ${this.token}`},
      		url:this.apiUrl+'/employee/update/career',
      		data: formData,
      		method: 'POST'
      	})
      	.then(response => {


      		setTimeout(() => {
      			this.spinner = false
      		},500)
      		this.getCareerUpdate(this.emp_id)
      	}).catch((error) => {
      		console.log(error.response)
      	})
      },

      getCareerUpdate:function(id){
      	axios.create({
      		baseURL: this.apiUrl,
      		headers: {
      			'Authorization': `Bearer ${this.token}`
      		}})
      	.get(`/employee/career/list/${id}`)
      	.then((res) => {
      		this.careerupdates = res.data.data;
			console.log(this.careerupdates)
      		this.actionTakeOptions = res.data.actiontake;

      	})

      },

      closeModal:function(){
      	this.career = false
      },
  }
}
</script>
<style lang="css" scoped>
</style>
