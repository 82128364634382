<template>
  <q-dialog v-model="dialog" persistent transition-show="flip-down" transition-hide="flip-up">
    <div style="height: auto; width: 400px; max-width: 400px;">
      <q-card class="my-card">
        <q-card-section class="text-center text-bold q-mb-none q-pb-none q-pl-none q-pr-none">
          <div class="full-width col-12 q-mt-md q-mb-lg">
            <q-item-section v-show="confirm">
              <q-item-label>Do you want to logout HRIS?</q-item-label>
            </q-item-section>
            <q-item-section v-show="confirmedOut">
              <q-item-label>
                <q-spinner-hourglass class="on-left" />Logging out...
              </q-item-label>
            </q-item-section>
          </div>
          <div class="q-ma-none q-pa-none">
            <q-list class="row">
              <q-item clickable class="bg-red-5 text-white col" @click="onSubmit">
                <q-item-section>
                  <q-item-label>YES</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable class="bg-grey-8 text-white col" @click="closeDialog">
                <q-item-section>
                  <q-item-label>NO</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </q-dialog>
</template>
<script>
import vueCookies from 'vue-cookies';
export default {
  data() {
    return {
      dialog: false,
      confirm: true,
      confirmedOut: false
    }
  },
  created() {
    this.eventHub.$on('logOut', this.openDialog);
  },
  methods: {
    openDialog: function(payload) {
      this.dialog = payload.status
      this.routes = payload.routes
    },
    closeDialog: function() {
      this.dialog = false
    },
    onSubmit: function() {
      this.confirm = false;
      this.confirmedOut = true;
      vueCookies.remove(this.cookieKey);
      setTimeout(() => {
        location.reload();
        this.loading = false
      }, 1000);
    }
  }
}
</script>
<style lang="css" scoped>
</style>