<template>
    <div class="q-gutter-sm q-pa-lg">
        <div class="row">
            <div class="row q-mb-md full-width">
                <div class="full-width text-h4" style="height: 40px;">
                SMS Template
                </div>
            </div>

            <div class="row q-mb-md full-width">
                <div class="col-12">
                    <q-btn label="new template" color="negative" class="q-mb-sm q-mr-sm" @click="templateDialog = true" />
                    <!-- <q-input borderless dense debounce="300"  v-model="msgContent" placeholder="Message Content...." outlined  autogrow/> -->
                </div>
            </div>

            <div class="full-width row justify-center q-ma-md">
                <q-card bordered class="my-card col-12 q-pa-md">
                <q-card-section class="q-pa-none q-ma-none q-mt-sm">
                    <div class="row">
                    <div class="col-12">
                        <q-table
                            :data="tempList"
                            :columns="columns"
                            :pagination.sync="Pagination"
                            :filter="filter"
                            row-key="id"
                            dense
                        virtual-scroll :virtual-scroll-item-size="48" style="max-height: 600px">
                        <template v-slot:top-right>
                            <q-input borderless dense debounce="300" v-model="filter" placeholder="Search" outlined>
                            <template v-slot:append>
                                <q-icon name="fas fa-search" />
                            </template>
                            </q-input>
                        </template>
                        <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" >
                            <!-- <q-td>
                                <q-checkbox dense v-model="props.selected" />
                            </q-td> -->
                            <q-td key="action" style="text-align:center;">
                                <q-btn color="green" rounded size="sm" text-color="white" label="Edit" @click="editDialog(props.row)" />  <!-- 01132023 -->
                                <q-btn color="negative" rounded size="sm" text-color="white" label="Delete" @click="deleteTemp(props.row)" />  <!-- 01132023 -->
                            </q-td>
                            <q-td key="id" >
                                {{ props.row.id }}
                            </q-td>
                            <q-td key="status" >
                                {{ props.row.title }}
                            </q-td>
                            <q-td key="content">{{ props.row.msg_content }}</q-td>
                        </q-tr>
                        <template v-slot:no-data>
                            <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                            <span>
                                No Applications Yet
                            </span>
                            <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                            </div>
                        </template>
                        </q-table>
                    </div>
                    </div>
                </q-card-section>
                </q-card>
            </div>
        </div>

        <q-dialog v-model="templateDialog" persistent>

            <q-card style="min-width: 350px">
                <q-card-section>
                    <div class="text-h6">Template Form</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    <q-input dense v-model="tempForm.title" autofocus outlined label="Title"/>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    <q-input dense type="textarea" v-model="tempForm.content" autofocus outlined  label="Message Content"/>
                </q-card-section>

                <q-card-actions align="right" class="text-primary">
                    <q-btn flat label="Cancel" @click="cancelForm" v-close-popup />
                    <q-btn flat label="Submit" @click="submitForm"/>
                </q-card-actions>
            </q-card>

        </q-dialog>

    </div>
</template>

<script>

const columns = [
    {
        name: 'action',
        field: 'action',
        label: 'Action',
        style: 'width: 200px',
        align: 'center',
        headerStyle: 'width: 200px',
        headerClasses: 'bg-indigo-1'
    },
    {
        name: 'id',
        field: 'id',
        label: 'Id',
        align: 'left',
        headerStyle: 'width: 200px',
        headerClasses: 'bg-indigo-1'
    },
    {
        name: 'title',
        field: 'title',
        label: 'Title',
        align: 'left',
        headerStyle: 'width: 200px',
        headerClasses: 'bg-indigo-1'
    },
    {
        name: 'msg_content',
        field: 'msg_content',
        label: 'Message Content',
        align: 'left',
        headerStyle: 'width: 200px',
        headerClasses: 'bg-indigo-1'
    },
]

import axios from 'axios';

export default {
    data(){
        return {
            tempList:[],
            columns,
            filter:'',
            templateDialog:false,
            Pagination: {
                // sortBy: 'date_of_application',
                descending: true,
                // page: 1,
                rowsPerPage: 10
            },
            tempForm:{
                id: '',
                title: '',
                content: ''
            }
        }
    },
    methods: {
        submitForm(){
            var form = new FormData()
            form.append('title',this.tempForm.title)
            form.append('content',this.tempForm.content)
            if(this.tempForm.id != ''){
                form.append('id',this.tempForm.id)
                axios.post(this.apiUrl+'/recruitments/template-update',form,{
                    headers: {'Authorization': 'Bearer '+this.token}
                }).then((res) => {
                    console.log('update template',res);
                    this.$q.notify({
                        icon: res.data.icon,
                        message: res.data.message,
                        color: res.data.color,
                        position:'center',
                        timeout: 1000
                    })
                    this.resetForm()
                })
            }else{
                axios.post(this.apiUrl+'/recruitments/sms-template',form,{
                    headers: {'Authorization': `Bearer ${this.token}`}
                }).then((res) => {
                    console.log('create template:',res)
                    if(res.data.statusCode == '200'){
                        this.$q.notify({
                            icon: res.data.icon,
                            message: res.data.message,
                            color: res.data.color,
                            position:'center',
                            timeout: 1000
                        })
                       this.resetForm()
                    }
                })
            }
        },

        resetForm(){
            this.templateDialog = false
            this.tempForm.id = ''
            this.tempForm.title = ''
            this.tempForm.content = ''
            this.getAllTemplate()
        },

        getAllTemplate(){
            axios.get(this.apiUrl+'/recruitments/template-list',{
                headers: {"Authorization":'Bearer '+this.token}
            }).then((res) => {
                console.log('template list',res)
                this.tempList = res.data
            })
        },

        editDialog(data){
            this.templateDialog = true
            this.tempForm.id = data.id
            this.tempForm.title = data.title
            this.tempForm.content = data.msg_content
        },

        deleteTemp(data){
            this.$q.dialog({
                title:'Are you sure?',
                message:'Delete template permanently?',
                cancel:true,
                persistent: true
            }).onOk(() => {
                console.log('delete',data)
                var form = new FormData();
                form.append('id',data.id)
                axios.post(this.apiUrl+'/recruitments/template-delete',form,{
                    headers: {'Authorization':'Bearer '+this.token}
                }).then((res) => {
                    console.log('test ',res)
                    this.getAllTemplate()
                })
            })
        },

        cancelForm(){
            this.templateDialog = false
            this.tempForm.id = ''
            this.tempForm.title = ''
            this.tempForm.content = ''
        }
    },
    mounted(){
        this.getAllTemplate();
    }
}

</script>
