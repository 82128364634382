<template>
	<div class="q-pa-md q-gutter-sm">

	    <q-dialog v-model="myreadmoremodal">
	      <q-card>
	        <q-card-section class="bg-green-10 text-white ">
	            <div class="caps text-h5" v-html="subject"></div>
	        </q-card-section>
	        <q-separator />
	        <q-card-section style="max-height: 50vh;" class="scroll">
	        	<!-- <div class="caps text-h6" v-html="timeAgo"></div> -->
	            <p v-html="fullMsg"></p>
	        </q-card-section>

	        <q-separator />

	        <q-card-actions align="right">
		        <q-btn flat label="Close" color="primary" v-close-popup />
		        <!-- <q-btn flat label="Accept" color="primary" v-close-popup /> -->
	        </q-card-actions>
	      </q-card>
	    </q-dialog>
	</div>
</template>

<script>
	import axios from 'axios';
	import mixins from '../../mixins';
	export default {
  	data () {
	    return {
	      myreadmoremodal: false,
	      date: null,
	      subject: '',
	      message: '',
	      fullMsg: '',
	      timeAgo: ''
	    }
 	},

    created() {
    	this.eventHub.$on('openReadMoreModal', this.openReadMoreModal);
    },

    methods: {
    	openReadMoreModal:function(props){
    		this.myreadmoremodal = props.stat;
    		this.id = props.obj.id;
    		this.subject = props.obj.subject;
    		this.message = props.obj.message;
    		this.fullMsg = props.obj.full_message;
    		this.attachment = props.obj.attachment;
    		this.dateCreated = props.obj.date_created;
    		this.created_by = props.obj.created_by;
    		this.is_publish = props.obj.is_publish;
    		this.is_deleted = props.obj.is_deleted;
    		this.timeAgo = '<small>' + props.obj.timeAgo + '</small>';
    		console.log(props.obj)
		},
    }
 
}
</script>