<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="col-12 q-mb-md right">
        <h4 class="q-ma-none">List of Users</h4><br />
      </div>
      <div class="col-12 q-mb-md">
        <div class="col-12 q-mb-md">
          <q-table title="Users" dense :data="searchUsers" :columns="columns" row-key="id" :pagination.sync="pagination">
            <template v-slot:body="props">
              <q-tr slot="body" :props="props">
                <q-td key="photo" :props="props">
                  <q-avatar>
                    <img src="../../assets/boy-avatar.png">
                  </q-avatar>
                </q-td>
                <q-td key="firstname" :props="props">{{ props.row.firstname }}</q-td>
                <q-td key="middlename" :props="props">{{ props.row.middlename }}</q-td>
                <q-td key="lastname" :props="props">{{ props.row.lastname }}</q-td>
                <q-td key="email" :props="props">{{ props.row.email }}</q-td>
                <q-td key="account" :props="props">{{ props.row.account }}</q-td>
                <q-td key="action" :props="props">
                  <div>
                    <q-btn color="red" @click="deleteUser(props)">
                      <i class="fas fa-user-times"></i>
                      <q-tooltip anchor="top middle" content-class="bg-red"  self="bottom middle" :offset="[10, 10]" color="red">
                        <strong>Delete</strong>
                      </q-tooltip>
                    </q-btn>
                    &nbsp; &nbsp; 
                    <q-btn color="orange" @click="updateUser(props)">
                      <i class="fas fa-user-edit"></i>
                      <q-tooltip anchor="top middle" content-class="bg-orange" self="bottom middle" :offset="[10, 10]">
                        <strong>Update</strong>
                      </q-tooltip>
                    </q-btn>
                    &nbsp; &nbsp; 
                    <q-btn color="green" @click="roleAccess(props)">
                      <i class="fa fa-lock" aria-hidden="true"></i>
                      <q-tooltip anchor="top middle" content-class="bg-green"  self="bottom middle" :offset="[10, 10]">
                        <strong>User Access</strong>
                      </q-tooltip>
                    </q-btn>
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:no-data="">
              <div class="full-width row flex-center text-red q-gutter-sm">
                <i class="fas fa-exclamation-triangle"></i>
                <span>
                  No Result Found!
                </span>
              </div>
            </template>
            <template v-slot:top-right="props">
              <q-input dense v-model="searchKey" placeholder="Search Employee">
                <i class="fas fa-search" slot="append"></i>
              </q-input>
            </template>
          </q-table>
          <q-dialog v-model="spinner" persistent>
            <q-card class="my-card transparent no-shadow">
              <q-card-section>
                <q-spinner-gears color="cyan" size="5.5em" />
              </q-card-section>
            </q-card>
          </q-dialog>
        </div>
        <q-dialog v-model="deleteDialog" persistent transition-show="scale" transition-hide="scale">
          <q-card class="q-bottom-sheet--list">
            <q-form ref="vForm">
              <q-card-section class="row items-center">
                <div class="text-h6">Are you sure?</div>
                <q-space />
                <q-btn  flat round dense v-close-popup><i class="fas fa-times"></i></q-btn>
              </q-card-section>
              <q-card-actions align="right">
                <div>
                  <q-btn flat label="Agree" type="submit" @click="startDeleteUser" color="primary"/>
                </div>
              </q-card-actions>
            </q-form>
          </q-card>
        </q-dialog>
        
        <AddUserAccountModal></AddUserAccountModal>
        <EditUserAccountModal></EditUserAccountModal>
        <UserAccess></UserAccess>

        <q-page-sticky position="bottom-right" :offset="[18, 18]">
          <q-btn fab icon="fas fa-plus" @click="addUser" color="primary" />
        </q-page-sticky>


      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
import AddUserAccountModal from './AddUserAccountModal';
import EditUserAccountModal from './EditUserAccountModal';
import UserAccess from './Role-Access';
export default {
 // name: 'Users',
 components: {
  "AddUserAccountModal": AddUserAccountModal,
  "EditUserAccountModal": EditUserAccountModal,
  "UserAccess": UserAccess
},
data() {
  return {
    spinner: false,
    searchKey: null,
    pagination: {
      rowsPerPage: 10
    },
    columns: [
    { name: 'photo', align: 'left', label: 'Photo', field: '', sortable: true },
    { name: 'firstname', align: 'center', label: 'First Name', field: 'firstname', sortable: true },
    { name: 'middlename', align: 'center', label: 'Middle Name', field: 'middlename', sortable: true },
    { name: 'lastname', align: 'center', label: 'Last Name', field: 'lastname', sortable: true },
    { name: 'email', align: 'center', label: 'Email', field: 'email', sortable: true },
    { name: 'action', align: 'center', label: 'Action', field: '', sortable: true }
    ],
    activateDialog: false,
    addUserDialog : false,
    deleteDialog: false,
    users : [],
    id : null
  }
},
created() {
  let _this = this;
  _this.getUsers();
  _this.eventHub.$on('refreshEmployeeList', _this.getUsers);
},
watch: {

},
computed: {
  searchUsers() {
   // console.log(this.searchKey);
   if (this.searchKey !== null) {
    return this.users.filter(emp => {
      return emp.firstname.toLowerCase().match(this.searchKey) || emp.lastname.toLowerCase().match(this.searchKey) || emp.employee_id.match(this.searchKey)
    });
  } else {
    return this.users
  }
}
},
methods: {
  getUsers: function() {
    this.spinner = true
    axios.create({
      baseURL: this.apiUrl,
      headers: {
        'Authorization': `Bearer ${this.token}`
      }
    })
    .get('/admin/users')
    .then((res) => {
      this.users = res.data.data;
      this.intUser = res.data.data;

      setTimeout(() => {
        this.spinner = false
      }, 500)
    })
  },
  addUser(emp) {
    this.eventHub.$emit('AddUserAccount', { stat: true, employee: emp });
  },
  updateUser(emp) {
    this.eventHub.$emit('EditUserAccount', { stat: true, employee: emp });
  },
  roleAccess(emp) {
    this.eventHub.$emit('UserAccess', { stat: true, employee: emp });
  },
  deleteUser(emp) {
    this.deleteDialog = true;
    this.id  = emp.row.id;
  },
  startDeleteUser(){
    let formData = new FormData();

    formData.append("id", this.id);

    axios.create({
      baseURL : this.apiUrl,
      headers : {'Authorization' : `Bearer ${this.token}`}
    }).post('/admin/delete',formData)
    .then((res) => {

      if(res.data.status){
        this.$q.notify({
          message: 'User successfully deleted.',
          color: 'positive',
          actions: [
        { label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
        ]
      });
        this.getUsers();
        this.deleteDialog = false;
      }else{
        this.$q.notify({
          message: 'Failed to delete a user.',
          color: 'negative',
          actions: [
        { label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
        ]
      })
      }

    });

  }

}
}
</script>
<style lang="css" scoped>
</style>