<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="row q-mb-md full-width">
        <div class="full-width text-h4" style="height: 40px;">
          Endorse Reports
          <q-chip square class="q-ml-sm text-bold" size="md" color="blue" text-color="white">
            <q-avatar font-size="14px" color="blue-grey-8" text-color="white" style="width:1.7em">{{ applicationCounts }}</q-avatar>
            Applications
          </q-chip>
        </div>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-12 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <!-- :visible-columns="visibleColumns" 01182023 -->
                <q-table :data="applicationsData" :columns="ResultColumns" :pagination.sync="Pagination" :filter="filter" row-key="id" dense
                virtual-scroll :visible-columns="visibleColumns" :virtual-scroll-item-size="48" style="max-height: 600px">
                <!-- END:: :visible-columns="visibleColumns" 01182023 -->
                 <template v-slot:top-left>
                    <div style="display:inline-flex;">
                      <q-input type="date" dense debounce="400" color="primary" v-model="dateFrom" outlined class="q-mr-sm" hint="Date From"/>
                      <q-input type="date" dense debounce="400" color="primary" v-model="dateTo" outlined class="q-mr-sm" hint="Date To"/>
                      <q-btn class="q-mb-lg q-mr-sm" label="Search" color="primary" @click="searchDate"/>

                    </div>
                  </template>
                  <template v-slot:top-right>
                    <q-input borderless dense debounce="300" v-model="filter" placeholder="Search" outlined>
                      <template v-slot:append>
                        <q-icon name="fas fa-search" />
                      </template>
                    </q-input>
                  </template>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" style="text-align:center;">

                    <q-td key="a_id" :props="props" >{{ props.row.id }}</q-td>
                    <q-td key="firstname" :props="props" >{{ props.row.firstname }}</q-td>
                    <q-td key="middlename" :props="props" >{{ props.row.middlename }}</q-td>
                    <q-td key="lastname" :props="props" >{{ props.row.lastname }}</q-td>
                    <q-td key="account" :props="props" >{{ props.row.account }}</q-td>
                    <q-td key="position" :props="props" >{{ props.row.position }}</q-td>
                    <q-td key="pb_date" :props="props" >{{ props.row.pb_date }}</q-td>
                    <q-td key="basic_salary" :props="props" >{{ props.row.basic_salary }}</q-td>
                    <q-td key="employee_status" :props="props" >{{ props.row.employee_status }}</q-td>

                  </q-tr>
                  <template v-slot:no-data>
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
    <q-dialog v-model="loadingModel" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            {{loadingText}}
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="loadingAction" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            Processing Application
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="noData">
      <q-card>
        <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">NO DATA</span>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="row q-pa-md">
            <div class="text-h6">There is no data!</div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="confirmModal">
      <q-card style="width: 500px">
        <q-card-section>
          <div class="text-h6">{{ confirm.title }}</div>
        </q-card-section>
        <q-separator />
        <q-card-section class="q-py-sm" style="text-align: center">
          <q-icon name="fas fa-question-circle" style="font-size: 50px;color: blue;" />
          <div class="text-h5">{{ confirm.message }}</div>
        </q-card-section>
        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn flat label="Yes" @click="updateApplication(confirm.id, confirm.status)"/>
          <q-btn flat label="Cancel" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';
import { exportFile } from 'quasar';
import vueCookies from 'vue-cookies';
function wrapCsvValue (val, formatFn) {
  let formatted = formatFn !== void 0
    ? formatFn(val)
    : val

  formatted = formatted === void 0 || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

export default {
  name: 'NewApplication',
  data() {
    return {
      filter: '',
      loadingModel: false,
      loadingText: '',
      loadingAction: false,
      applicationCounts: 0,
      applications: [],
      applicationsData: [],
      forHiredSelected: [],
      noData: false,
      dateTo: null,
      dateFrom: null,
      searchDateToFrom: {
          dateTo: null,
          dateFrom: null,
          status: 'Endorse'
      },
      Pagination: {
        sortBy: 'date_of_application',
        descending: true,
        // page: 1,
        rowsPerPage: 0
      },
      ResultColumns: [
        {
          name: 'a_id',
          field: 'a_id',
          label: 'ID',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'firstname',
          field: 'firstname',
          label: 'First Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'middlename',
          field: 'middlename',
          label: 'Middle Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'lastname',
          field: 'lastname',
          label: 'Last Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'account',
          field: 'account',
          label: 'Account',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'position',
          field: 'position',
          label: 'Position',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'pb_date',
          field: 'pb_date',
          label: 'PB Date',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'basic_salary',
          field: 'basic_salary',
          label: 'Basic Salary',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'employee_status',
          field: 'employee_status',
          label: 'Status',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
      ],
      confirmModal: false,
      confirm: {
        title: "",
        message: "",
        id: "",
        status: ""
      },
       visibleColumns: ['firstname', 'middlename', 'lastname', 'account', 'position', 'pb_date', 'basic_salary', 'employee_status'],
    }
  },
  methods: {
        searchData: function(){
            vueCookies.set(this.searchKey, {
            'search': this.filter
            });
            //this.filter = this.searchKeyword;
        },

        loadApplications: function() {
            this.loadingText = 'Retrieving Applications';
            this.loadingModel = true;
            this.applications = [];
            this.applicationsData = [];

        axios.create({
            baseURL: this.apiUrl,
            headers: {
                'Authorization': `Bearer ${this.token}`
            }})
            .get('/employee/applicationReports')
            .then((res) => {
                console.log(res)
                this.applications = res.data.data;
                this.applicationCounts = Object.keys(this.applications).length;


                for (let eachApplication of this.applications) {
                    let name = eachApplication.applicant_details.lastname + ', ' + eachApplication.applicant_details.firstname + ' ' + eachApplication.applicant_details.middlename;
                    let tempDate = eachApplication.applicant_details.date_apply;
                    let date_applied = date.formatDate(tempDate, 'MMMM DD, YYYY');
                    let date_exam = date.formatDate(eachApplication.applicant_details.exam_date, 'MMMM DD, YYYY');

                    this.applicationsData.push({
                        id: eachApplication.id,
                        date_completed: eachApplication.date_completed,
                        firstname: eachApplication.applicant_details.firstname,
                        middlename: eachApplication.applicant_details.middlename,
                        lastname: eachApplication.applicant_details.lastname,
                        account: eachApplication.account,
                        position: eachApplication.position,
                        pb_date: eachApplication.pb_date,
                        basic_salary: eachApplication.basic_salary,
                        employee_status: eachApplication.employee_status,
                    });
                    // console.log(this.applicationsData);
                }

            }).finally(() => {
                this.loadingModel = false;
                this.loadingText = '';
            });
        },

        searchDate(){
          this.loading = true;
          let formData = new FormData();
          this.searchDateToFrom.dateFrom = this.dateFrom;
          this.searchDateToFrom.dateTo = this.dateTo;
          //  this.searchDateToFrom.status = this.status; // 01182023
          this.applications = [];
          this.applicationsData = [];
          formData.append('form_data', JSON.stringify(this.searchDateToFrom));
          axios.create({
            baseURL: this.apiUrl,
            headers: {
                'Authorization': `Bearer ${this.token}`
            }
            }).post('/employee/application/search-date', formData).then((res) => {
              this.applications = res.data.data;
              this.applicationCounts = Object.keys(this.applications).length;

              for (let eachApplication of this.applications) {
                  let name = eachApplication.applicant_details.lastname + ', ' + eachApplication.applicant_details.firstname + ' ' + eachApplication.applicant_details.middlename;
                  let tempDate = eachApplication.applicant_details.date_apply;
                  let date_applied = date.formatDate(tempDate, 'MMMM DD, YYYY');
                  let date_exam = date.formatDate(eachApplication.applicant_details.exam_date, 'MMMM DD, YYYY');

                  this.applicationsData.push({
                      id: eachApplication.id,
                      date_completed: eachApplication.date_completed,
                      firstname: eachApplication.applicant_details.firstname,
                      middlename: eachApplication.applicant_details.middlename,
                      lastname: eachApplication.applicant_details.lastname,
                      account: eachApplication.account,
                      position: eachApplication.position,
                      pb_date: eachApplication.pb_date,
                      basic_salary: eachApplication.basic_salary,
                      employee_status: eachApplication.employee_status,
                  });
                }

          });
        },
    },
    mounted() {
        this.loadApplications();
    }
}
</script>
<style scoped>
.sticky {
  height: 80px;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  top: 0;
  z-index: 10;
}

.sticky div {
  width: 100%;
}

.btn-height {
  height: 40px;
}

.text-head {
  font-size: 16px;
}
</style>
