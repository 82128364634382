<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="row q-mb-md full-width">
        <div class="full-width" style="height: 40px;">
          <h4 class="q-ma-none row inline">My Approvals</h4>
        </div>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-10 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none">
            <div class="text-h6">Change of Work Schedule</div>
          </q-card-section>
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <q-table :data="cwsAppResultData" :columns="ResultColumns" :pagination.sync="cwsPagination" row-key="id" dense>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click="viewDetails('cwsApp',props.row.id)">
                    <q-td key="status" :props="props">{{ props.row.status }}</q-td>
                    <q-td key="date_applied" :props="props">{{ props.row.date_applied }}</q-td>
                    <q-td key="inclusive_dates" :props="props">{{ props.row.inclusive_dates }}</q-td>
                    <q-td key="name" :props="props">{{ props.row.name }}</q-td>
                    <q-td key="department" :props="props">{{ props.row.department }}</q-td>
                  </q-tr>
                  <template v-slot:no-data="{ message }">
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-10 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none">
            <div class="text-h6">Half Day</div>
          </q-card-section>
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <q-table :data="hdAppResultData" :columns="ResultColumns" :pagination.sync="hdPagination" row-key="id" dense>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click="viewDetails('hdApp',props.row.id)">
                    <q-td key="status" :props="props">{{ props.row.status }}</q-td>
                    <q-td key="date_applied" :props="props">{{ props.row.date_applied }}</q-td>
                    <q-td key="inclusive_dates" :props="props">{{ props.row.inclusive_dates }}</q-td>
                    <q-td key="name" :props="props">{{ props.row.name }}</q-td>
                    <q-td key="department" :props="props">{{ props.row.department }}</q-td>
                  </q-tr>
                  <template v-slot:no-data="{ message }">
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-10 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none">
            <div class="text-h6">Official Business Trip</div>
          </q-card-section>
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <q-table :data="obtAppResultData" :columns="ResultColumns" :pagination.sync="obtPagination" row-key="id" dense>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click="viewDetails('obtApp',props.row.id)">
                    <q-td key="status" :props="props">{{ props.row.status }}</q-td>
                    <q-td key="date_applied" :props="props">{{ props.row.date_applied }}</q-td>
                    <q-td key="inclusive_dates" :props="props">{{ props.row.inclusive_dates }}</q-td>
                    <q-td key="name" :props="props">{{ props.row.name }}</q-td>
                    <q-td key="department" :props="props">{{ props.row.department }}</q-td>
                  </q-tr>
                  <template v-slot:no-data="{ message }">
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-10 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none">
            <div class="text-h6">Overtime</div>
          </q-card-section>
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <q-table :data="otAppResultData" :columns="ResultColumns" :pagination.sync="otPagination" row-key="id" dense>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click="viewDetails('otApp',props.row.id)">
                    <q-td key="status" :props="props">{{ props.row.status }}</q-td>
                    <q-td key="date_applied" :props="props">{{ props.row.date_applied }}</q-td>
                    <q-td key="inclusive_dates" :props="props">{{ props.row.inclusive_dates }}</q-td>
                    <q-td key="name" :props="props">{{ props.row.name }}</q-td>
                    <q-td key="department" :props="props">{{ props.row.department }}</q-td>
                  </q-tr>
                  <template v-slot:no-data="{ message }">
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-10 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none">
            <div class="text-h6">Undertime</div>
          </q-card-section>
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <q-table :data="utAppResultData" :columns="ResultColumns" :pagination.sync="utPagination" row-key="id" dense>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click="viewDetails('utApp',props.row.id)">
                    <q-td key="status" :props="props">{{ props.row.status }}</q-td>
                    <q-td key="date_applied" :props="props">{{ props.row.date_applied }}</q-td>
                    <q-td key="inclusive_dates" :props="props">{{ props.row.inclusive_dates }}</q-td>
                    <q-td key="name" :props="props">{{ props.row.name }}</q-td>
                    <q-td key="department" :props="props">{{ props.row.department }}</q-td>
                  </q-tr>
                  <template v-slot:no-data="{ message }">
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-10 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none">
            <div class="text-h6">Work From Home</div>
          </q-card-section>
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <q-table :data="wfhAppResultData" :columns="ResultColumns" :pagination.sync="wfhPagination" row-key="id" dense>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click="viewDetails('wfhApp',props.row.id)">
                    <q-td key="status" :props="props">{{ props.row.status }}</q-td>
                    <q-td key="date_applied" :props="props">{{ props.row.date_applied }}</q-td>
                    <q-td key="inclusive_dates" :props="props">{{ props.row.inclusive_dates }}</q-td>
                    <q-td key="name" :props="props">{{ props.row.name }}</q-td>
                    <q-td key="department" :props="props">{{ props.row.department }}</q-td>
                  </q-tr>
                  <template v-slot:no-data="{ message }">
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
    <q-dialog v-model="loadingModel" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            {{loadingText}}
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="loadingAction" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            Processing {{ actionType }} of Application
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="viewCWSDetailsDialog" persistent transition-show="scale" transition-hide="scale">
      <div class="bg-white" style="height: auto; width: 900px; max-width: 900px;">
        <div class="full-width q-pa-sm bg-red-6 text-center text-white">
          <div class="text-h5">Change of Work Schedule</div>
          <div>( {{ viewCWSDetails.status }} )</div>
        </div>
        <div class="col-12 q-ma-sm">
          <q-card class="my-card transparent no-shadow">
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Employee Details</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="Name" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewCWSDetails.employee_name}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Department" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewCWSDetails.employee_department}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Date Applied" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewCWSDetails.date_applied}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Inclusive Date(s)</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="From" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewCWSDetails.date_start}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="To" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewCWSDetails.date_end}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Total Number of Days" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewCWSDetails.total_days}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Schedule</div>
              <div class="row q-col-gutter-md">
                <div class="col-2">
                  <q-field label="From" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewCWSDetails.original_schedule_type}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-2">
                  <q-field label="To" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewCWSDetails.new_schedule_type}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Original Schedule" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewCWSDetails.original_time_start}} - {{viewCWSDetails.original_time_end}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="New Schedule" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewCWSDetails.new_schedule_time_start}} - {{viewCWSDetails.new_schedule_time_end}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Reason</div>
              <div class="col-12 q-pa-none q-ma-none">
                <q-field dense color="red-6">
                  <template v-slot:control>
                    <div>{{viewCWSDetails.reason}}</div>
                  </template>
                </q-field>
              </div>
            </q-card-section>
            <q-card-actions class="row col-12 q-mt-md q-pb-md">
              <div class="row col-9 q-pl-sm q-col-gutter-sm">
                <div class="col-4">
                  <q-btn label="Approve" @click="actionForm('Approve','CWS',viewCWSDetails.id)" class="full-width bg-green-9 text-white btn-height" />
                </div>
                <div class="col-4">
                  <q-btn label="Decline" @click="actionForm('Decline','CWS',viewCWSDetails.id)" class="full-width bg-orange-9 text-white btn-height" />
                </div>
              </div>
              <div class="col-3 q-pl-sm justify-end">
                <q-btn label="CLOSE" class="full-width bg-red text-white btn-height" v-close-popup />
              </div>
            </q-card-actions>
          </q-card>
        </div>
      </div>
    </q-dialog>
    <q-dialog v-model="viewHDDetailsDialog" persistent transition-show="scale" transition-hide="scale">
      <div class="bg-white" style="height: auto; width: 900px; max-width: 900px;">
        <div class="full-width q-pa-sm bg-red-6 text-center text-white">
          <div class="text-h5">Half Day</div>
          <div>( {{ viewHDDetails.status }} )</div>
        </div>
        <div class="col-12 q-ma-sm">
          <q-card class="my-card transparent no-shadow">
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Employee Details</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="Name" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewHDDetails.employee_name}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Department" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewHDDetails.employee_department}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Date Applied" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewHDDetails.date_applied}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Inclusive Date</div>
              <div class="row q-col-gutter-md">
                <div class="col-12">
                  <q-field color="red-6">
                    <template v-slot:control>
                      <div>{{viewHDDetails.date}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Schedule</div>
              <div class="row q-col-gutter-md">
                <div class="col-6">
                  <q-field label="Original Schedule" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewHDDetails.original_time_start}} - {{viewHDDetails.original_time_end}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-6">
                  <q-field label="Half Day Schedule" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewHDDetails.halfday_time_start}} - {{viewHDDetails.halfday_time_end}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Reason</div>
              <div class="col-12 q-pa-none q-ma-none">
                <q-field dense color="red-6">
                  <template v-slot:control>
                    <div>{{viewHDDetails.reason}}</div>
                  </template>
                </q-field>
              </div>
            </q-card-section>
            <q-card-actions class="row col-12 q-mt-md q-pb-md">
              <div class="row col-9 q-pl-sm q-col-gutter-sm">
                <div class="col-4">
                  <q-btn label="Approve" @click="actionForm('Approve','Halfday',viewHDDetails.id)" class="full-width bg-green-9 text-white btn-height" />
                </div>
                <div class="col-4">
                  <q-btn label="Decline" @click="actionForm('Decline','Halfday',viewHDDetails.id)" class="full-width bg-orange-9 text-white btn-height" />
                </div>
              </div>
              <div class="col-3 q-pl-sm justify-end">
                <q-btn label="CLOSE" class="full-width bg-red text-white btn-height" v-close-popup />
              </div>
            </q-card-actions>
          </q-card>
        </div>
      </div>
    </q-dialog>

    <q-dialog v-model="viewOBTDetailsDialog" persistent transition-show="scale" transition-hide="scale">
      <div class="bg-white" style="height: auto; width: 900px; max-width: 900px;">
        <div class="full-width q-pa-sm bg-red-6 text-center text-white">
          <div class="text-h5">Official Business Trip</div>
          <div>( {{ viewOBTDetails.status }} )</div>
        </div>
        <div class="col-12 q-ma-sm">
          <q-card class="my-card transparent no-shadow">
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Employee Details</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="Name" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewOBTDetails.employee_name}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Department" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewOBTDetails.employee_department}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Date Applied" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewOBTDetails.date_applied}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Inclusive Date(s)</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="From" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewOBTDetails.date_start}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="To" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewOBTDetails.date_end}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Total Number of Days" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewOBTDetails.total_days}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Schedule</div>
              <div class="row q-col-gutter-md">
                <div class="col-6">
                  <q-field label="Trip Covered" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewOBTDetails.trip_covered}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-6">
                  <q-field label="Date of Return" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewOBTDetails.date_return}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Reason</div>
              <div class="col-12 q-pa-none q-ma-none">
                <q-field dense color="red-6">
                  <template v-slot:control>
                    <div>{{viewOBTDetails.reason}}</div>
                  </template>
                </q-field>
              </div>
            </q-card-section>
            <q-card-actions class="row col-12 q-mt-md q-pb-md">
              <div class="row col-9 q-pl-sm q-col-gutter-sm">
                <div class="col-4">
                  <q-btn label="Approve" @click="actionForm('Approve','OBT',viewOBTDetails.id)" class="full-width bg-green-9 text-white btn-height" />
                </div>
                <div class="col-4">
                  <q-btn label="Decline" @click="actionForm('Decline','OBT',viewOBTDetails.id)" class="full-width bg-orange-9 text-white btn-height" />
                </div>
              </div>
              <div class="col-3 q-pl-sm justify-end">
                <q-btn label="CLOSE" class="full-width bg-red text-white btn-height" v-close-popup />
              </div>
            </q-card-actions>
          </q-card>
        </div>
      </div>
    </q-dialog>

    <q-dialog v-model="viewOTDetailsDialog" persistent transition-show="scale" transition-hide="scale">
      <div class="bg-white" style="height: auto; width: 900px; max-width: 900px;">
        <div class="full-width q-pa-sm bg-red-6 text-center text-white">
          <div class="text-h5">Overtime</div>
          <div>( {{ viewOTDetails.status }} )</div>
        </div>
        <div class="col-12 q-ma-sm">
          <q-card class="my-card transparent no-shadow">
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Employee Details</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="Name" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewOTDetails.employee_name}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Department" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewOTDetails.employee_department}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Date Applied" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewOTDetails.date_applied}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Inclusive Dates</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="From" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewOTDetails.overtime_start_date}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="To" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewOTDetails.overtime_end_date}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field color="red-6">
                    <template v-slot:control>
                      <div>{{viewOTDetails.schedule_type}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Schedule</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="Original Schedule" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewOTDetails.original_time_start}} - {{viewOTDetails.original_time_end}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Overtime" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewOTDetails.overtime_start}} - {{viewOTDetails.overtime_end}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Total OT Hours" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewOTDetails.total_ot_hours}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Reason</div>
              <div class="col-12 q-pa-none q-ma-none">
                <q-field dense color="red-6">
                  <template v-slot:control>
                    <div>{{viewOTDetails.reason}}</div>
                  </template>
                </q-field>
              </div>
            </q-card-section>
            <q-card-actions class="row col-12 q-mt-md q-pb-md">
              <div class="row col-9 q-pl-sm q-col-gutter-sm">
                <div class="col-4">
                  <q-btn label="Approve" @click="actionForm('Approve','Overtime',viewOTDetails.id)" class="full-width bg-green-9 text-white btn-height" />
                </div>
                <div class="col-4">
                  <q-btn label="Decline" @click="actionForm('Decline','Overtime',viewOTDetails.id)" class="full-width bg-orange-9 text-white btn-height" />
                </div>
              </div>
              <div class="col-3 q-pl-sm justify-end">
                <q-btn label="CLOSE" class="full-width bg-red text-white btn-height" v-close-popup />
              </div>
            </q-card-actions>
          </q-card>
        </div>
      </div>
    </q-dialog>
    <q-dialog v-model="viewUTDetailsDialog" persistent transition-show="scale" transition-hide="scale">
      <div class="bg-white" style="height: auto; width: 900px; max-width: 900px;">
        <div class="full-width q-pa-sm bg-red-6 text-center text-white">
          <div class="text-h5">Undertime</div>
          <div>( {{ viewUTDetails.status }} )</div>
        </div>
        <div class="col-12 q-ma-sm">
          <q-card class="my-card transparent no-shadow">
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Employee Details</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="Name" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewUTDetails.employee_name}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Department" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewUTDetails.employee_department}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Date Applied" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewUTDetails.date_applied}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Inclusive Date</div>
              <div class="row q-col-gutter-md">
                <div class="col-12">
                  <q-field color="red-6">
                    <template v-slot:control>
                      <div>{{viewUTDetails.date}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Schedule</div>
              <div class="row q-col-gutter-md">
                <div class="col-6">
                  <q-field label="Original Schedule" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewUTDetails.original_time_start}} - {{viewUTDetails.original_time_end}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-6">
                  <q-field label="Undertime" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewUTDetails.undertime_timeout}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Reason</div>
              <div class="col-12 q-pa-none q-ma-none">
                <q-field dense color="red-6">
                  <template v-slot:control>
                    <div>{{viewUTDetails.reason}}</div>
                  </template>
                </q-field>
              </div>
            </q-card-section>
            <q-card-actions class="row col-12 q-mt-md q-pb-md">
              <div class="row col-9 q-pl-sm q-col-gutter-sm">
                <div class="col-4">
                  <q-btn label="Approve" @click="actionForm('Approve','Undertime',viewUTDetails.id)" class="full-width bg-green-9 text-white btn-height" />
                </div>
                <div class="col-4">
                  <q-btn label="Decline" @click="actionForm('Decline','Undertime',viewUTDetails.id)" class="full-width bg-orange-9 text-white btn-height" />
                </div>
              </div>
              <div class="col-3 q-pl-sm justify-end">
                <q-btn label="CLOSE" class="full-width bg-red text-white btn-height" v-close-popup />
              </div>
            </q-card-actions>
          </q-card>
        </div>
      </div>
    </q-dialog>

    <q-dialog v-model="viewWFHDetailsDialog" persistent transition-show="scale" transition-hide="scale">
      <div class="bg-white" style="height: auto; width: 900px; max-width: 900px;">
        <div class="full-width q-pa-sm bg-red-6 text-center text-white">
          <div class="text-h5">Work From Home</div>
          <div>( {{ viewWFHDetails.status }} )</div>
        </div>
        <div class="col-12 q-ma-sm">
          <q-card class="my-card transparent no-shadow">
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Employee Details</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="Name" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewWFHDetails.employee_name}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Department" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewWFHDetails.employee_department}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Date Applied" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewWFHDetails.date_applied}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Inclusive Date(s)</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="From" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewWFHDetails.date_start}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="To" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewWFHDetails.date_end}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Total Number of Days" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewWFHDetails.total_days}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Schedule</div>
              <div class="row q-col-gutter-md">
                <div class="col-6">
                  <q-field label="Date of Return" stack-label color="red-6">
                    <template v-slot:control>
                      <div>{{viewWFHDetails.date_return}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-sm q-pa-md q-pt-sm">
              <div class="text-bold">Reason</div>
              <div class="col-12 q-pa-none q-ma-none">
                <q-field dense color="red-6">
                  <template v-slot:control>
                    <div>{{viewWFHDetails.reason}}</div>
                  </template>
                </q-field>
              </div>
            </q-card-section>
            <q-card-actions class="row col-12 q-mt-md q-pb-md">
              <div class="row col-9 q-pl-sm q-col-gutter-sm">
                <div class="col-4">
                  <q-btn label="Approve" @click="actionForm('Approve','WFH',viewWFHDetails.id)" class="full-width bg-green-9 text-white btn-height" />
                </div>
                <div class="col-4">
                  <q-btn label="Decline" @click="actionForm('Decline','WFH',viewWFHDetails.id)" class="full-width bg-orange-9 text-white btn-height" />
                </div>
              </div>
              <div class="col-3 q-pl-sm justify-end">
                <q-btn label="CLOSE" class="full-width bg-red text-white btn-height" v-close-popup />
              </div>
            </q-card-actions>
          </q-card>
        </div>
      </div>
    </q-dialog>

    <q-dialog v-model="actionDialog" persistent>
      <q-card>
        <q-card-section class="row q-pa-sm bg-red-5 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">Confirm {{ actionType }} of Application</span>
        </q-card-section>
        <q-card-section class="q-pa-md q-mt-md">
          Are you sure you want to {{ actionText }} the application?
        </q-card-section>
        <q-card-section class="q-pa-md q-mt-sm">
          <span class="small-text">Remarks:</span>
          <q-input v-model="form.approverRemarks" dense color="red-6">
            <template v-slot:append>
              <q-icon name="fa fa-times" @click="form.approverRemarks = ''" class="cursor-pointer" />
            </template>
          </q-input>
        </q-card-section>
        <q-card-actions class="q-pa-md" align="right">
          <q-btn flat label="Cancel" @click="form.approverRemarks = ''" color="red-6" v-close-popup />
          <q-btn flat label="Confirm" @click="submitConfirmation" class="bg-red-6 text-white" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';

export default {
  name: 'MyApprovals_Form',
  data() {
    return {
      loadingModel: true,
      loadingText: '',
      loadingAction: false,
      viewCWSDetailsDialog: false,
      viewHDDetailsDialog: false,
      viewOBTDetailsDialog: false,
      viewWFHDetailsDialog: false,
      viewOTDetailsDialog: false,
      viewUTDetailsDialog: false,
      actionDialog: false,
      actionType: '',
      actionText: '',
      applications: '',
      cwsAppResultData: [],
      hdAppResultData: [],
      obtAppResultData: [],
      wfhAppResultData: [],
      otAppResultData: [],
      utAppResultData: [],
      cwsPagination: {
        // sortBy: 'date_applied',
        // descending: true,
        page: 1,
        rowsPerPage: 5
      },
      hdPagination: {
        // sortBy: 'date_applied',
        // descending: true,
        page: 1,
        rowsPerPage: 5
      },
      obtPagination: {
        // sortBy: 'date_applied',
        // descending: true,
        page: 1,
        rowsPerPage: 5
      },
      wfhPagination: {
        // sortBy: 'date_applied',
        // descending: true,
        page: 1,
        rowsPerPage: 5
      },
      otPagination: {
        // sortBy: 'date_applied',
        // descending: true,
        page: 1,
        rowsPerPage: 5
      },
      utPagination: {
        // sortBy: 'date_applied',
        // descending: true,
        page: 1,
        rowsPerPage: 5
      },
      ResultColumns: [{
          name: 'status',
          field: 'status',
          label: 'Status',
          sortable: true,
          style: 'width: 140px',
          align: 'center',
          headerStyle: 'width: 140px',
          headerClasses: 'bg-red-1'
        },
        {
          name: 'date_applied',
          field: 'date_applied',
          label: 'Date Applied',
          sortable: true,
          style: 'width: 180px',
          align: 'center',
          headerStyle: 'width: 180px',
          headerClasses: 'bg-red-1'
        },
        {
          name: 'inclusive_dates',
          field: 'inclusive_dates',
          label: 'Inclusive Date(s)',
          style: 'width: 270px',
          align: 'center',
          headerStyle: 'width: 270px',
          headerClasses: 'bg-red-1'
        },
        {
          name: 'name',
          field: 'name',
          label: 'Name',
          align: 'center',
          headerClasses: 'bg-red-1'
        },
        {
          name: 'department',
          field: 'department',
          label: 'Department',
          style: 'width: 250px',
          align: 'center',
          headerStyle: 'width: 250px',
          headerClasses: 'bg-red-1'
        }
      ],
      viewCWSDetails: {},
      viewHDDetails: {},
      viewOBTDetails: {},
      viewWFHDetails: {},
      viewOTDetails: {},
      viewUTDetails: {},
      form: {
        approverAction: '',
        approverRemarks: '',
        appType: '',
        appID: ''
      },
      approveNotifType: ''
    }
  },
  methods: {
    loadApplications: function() {
        console.log(this.userData.id)
      this.loadingText = 'Retrieving Applications';
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/online-forms/applications/retrieve-approver-list',{
          params: {
            id: this.userData.id,
          }
        })
        .then((res) => {
          this.applications = res.data.data;
          Object.keys(this.applications).forEach((key, index) => {
            for (let eachApp of this.applications[key]) {
              let tempDate = date.extractDate(eachApp.date_applied, 'YYYY-MM-DD HH:mm:ss');
              let date_applied_h = date.formatDate(tempDate, 'MMMM DD, YYYY');
              var finalIncDate = '';
              if (key == 'hdApp' || key == 'utApp') {
                finalIncDate = date.formatDate(eachApp.inclusive_dates, 'MMMM DD, YYYY')
              } else {
                let tempIncDate = eachApp.inclusive_dates.split('/');
                if (tempIncDate[0] == tempIncDate[1]) {
                  finalIncDate = date.formatDate(tempIncDate[0], 'MMMM DD, YYYY');
                } else {
                  let tempInc1 = date.formatDate(tempIncDate[0], 'MMMM DD');
                  let tempInc2 = date.formatDate(tempIncDate[1], 'DD, YYYY');
                  finalIncDate = tempInc1 + ' - ' + tempInc2;
                }
              }
              let appKey = key + "ResultData";
              this[appKey].push({
                id: eachApp.id,
                status: eachApp.status,
                date_applied: date_applied_h,
                inclusive_dates: finalIncDate,
                name: eachApp.name,
                department: eachApp.department
              });
            }
          });
        })
        .finally(() => {
          this.loadingModel = false;
          this.loadingText = '';
        })
    },

    viewDetails: function(formApp, rowID) {
      this.loadingModel = true;
      this.loadingText = 'Retrieving Details';
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/online-forms/applications/details', {
          params: {
            id: rowID,
            type: formApp
          }
        })
        .then((res) => {
          this.loadingModel = false;
          let appObj = res.data.data;
          var temp_date_applied, final_date_applied, final_inclusive_date_start, final_inclusive_date_end,
            totalIncDays, temp_original_time_start, final_original_time_start, temp_original_time_end,
            final_original_time_end, temp_new_schedule_time_start, final_new_schedule_time_start,
            temp_new_schedule_time_end, final_new_schedule_time_end, temp_date, final_date,
            temp_halfday_time_start, final_halfday_time_start, temp_halfday_time_end, final_halfday_time_end,
            final_datetime_start, final_datetime_end, temp_date_of_return, final_date_of_return,
            temp_overtime_start_date, final_overtime_start_date, temp_overtime_end_date, final_overtime_end_date,
            temp_overtime_start, final_overtime_start, temp_overtime_end, final_overtime_end, temp_overtime_date,
            final_overtime_date, temp_undertime_timeout, final_undertime_timeout, temp_date_processed, final_date_processed;

          switch (formApp) {
            case 'cwsApp':
              this.viewCWSDetails = appObj;

              temp_date_applied = date.extractDate(this.viewCWSDetails.date_applied, 'YYYY-MM-DD HH:mm:ss');
              final_date_applied = date.formatDate(temp_date_applied, 'MMMM DD, YYYY @ hh:mmA');
              this.viewCWSDetails.date_applied = final_date_applied;

              final_inclusive_date_start = date.formatDate(this.viewCWSDetails.date_start, 'MMMM DD, YYYY');
              this.viewCWSDetails.date_start = final_inclusive_date_start;

              final_inclusive_date_end = date.formatDate(this.viewCWSDetails.date_end, 'MMMM DD, YYYY');
              this.viewCWSDetails.date_end = final_inclusive_date_end;

              totalIncDays = date.getDateDiff(this.viewCWSDetails.date_end, this.viewCWSDetails.date_start, 'days');
              this.viewCWSDetails.total_days = totalIncDays + 1;

              temp_original_time_start = date.extractDate(this.viewCWSDetails.original_time_start, 'HH:mm:ss');
              final_original_time_start = date.formatDate(temp_original_time_start, 'hh:mmA');
              this.viewCWSDetails.original_time_start = final_original_time_start;

              temp_original_time_end = date.extractDate(this.viewCWSDetails.original_time_end, 'HH:mm:ss');
              final_original_time_end = date.formatDate(temp_original_time_end, 'hh:mmA');
              this.viewCWSDetails.original_time_end = final_original_time_end;

              temp_new_schedule_time_start = date.extractDate(this.viewCWSDetails.new_schedule_time_start, 'HH:mm:ss');
              final_new_schedule_time_start = date.formatDate(temp_new_schedule_time_start, 'hh:mmA');
              this.viewCWSDetails.new_schedule_time_start = final_new_schedule_time_start;

              temp_new_schedule_time_end = date.extractDate(this.viewCWSDetails.new_schedule_time_end, 'HH:mm:ss');
              final_new_schedule_time_end = date.formatDate(temp_new_schedule_time_end, 'hh:mmA');
              this.viewCWSDetails.new_schedule_time_end = final_new_schedule_time_end;

              if (this.viewCWSDetails.status != 'Pending') {
                temp_date_processed = date.extractDate(this.viewCWSDetails.date_processed, 'YYYY-MM-DD HH:mm:ss');
                final_date_processed = date.formatDate(temp_date_processed, 'MMMM DD, YYYY @ hh:mmA');
                this.viewCWSDetails.date_processed = final_date_processed;
              }

              this.viewCWSDetailsDialog = true;
              break;
            case 'hdApp':
              this.viewHDDetails = appObj;

              temp_date_applied = date.extractDate(this.viewHDDetails.date_applied, 'YYYY-MM-DD HH:mm:ss');
              final_date_applied = date.formatDate(temp_date_applied, 'MMMM DD, YYYY @ hh:mmA');
              this.viewHDDetails.date_applied = final_date_applied;

              final_date = date.formatDate(this.viewHDDetails.date, 'MMMM DD, YYYY');
              this.viewHDDetails.date = final_date;

              temp_original_time_start = date.extractDate(this.viewHDDetails.original_time_start, 'HH:mm:ss');
              final_original_time_start = date.formatDate(temp_original_time_start, 'hh:mmA');
              this.viewHDDetails.original_time_start = final_original_time_start;

              temp_original_time_end = date.extractDate(this.viewHDDetails.original_time_end, 'HH:mm:ss');
              final_original_time_end = date.formatDate(temp_original_time_end, 'hh:mmA');
              this.viewHDDetails.original_time_end = final_original_time_end;

              temp_halfday_time_start = date.extractDate(this.viewHDDetails.halfday_time_start, 'HH:mm:ss');
              final_halfday_time_start = date.formatDate(temp_halfday_time_start, 'hh:mmA');
              this.viewHDDetails.halfday_time_start = final_halfday_time_start;

              temp_halfday_time_end = date.extractDate(this.viewHDDetails.halfday_time_end, 'HH:mm:ss');
              final_halfday_time_end = date.formatDate(temp_halfday_time_end, 'hh:mmA');
              this.viewHDDetails.halfday_time_end = final_halfday_time_end;

              if (this.viewHDDetails.status != 'Pending') {
                temp_date_processed = date.extractDate(this.viewHDDetails.date_processed, 'YYYY-MM-DD HH:mm:ss');
                final_date_processed = date.formatDate(temp_date_processed, 'MMMM DD, YYYY @ hh:mmA');
                this.viewHDDetails.date_processed = final_date_processed;
              }

              this.viewHDDetailsDialog = true;
              break;
            case 'obtApp':
              this.viewOBTDetails = appObj;

              temp_date_applied = date.extractDate(this.viewOBTDetails.date_applied, 'YYYY-MM-DD HH:mm:ss');
              final_date_applied = date.formatDate(temp_date_applied, 'MMMM DD, YYYY @ hh:mmA');
              this.viewOBTDetails.date_applied = final_date_applied;

              final_datetime_start = date.formatDate(this.viewOBTDetails.date_start, 'MMMM DD, YYYY');
              this.viewOBTDetails.date_start = final_datetime_start;

              final_datetime_end = date.formatDate(this.viewOBTDetails.date_end, 'MMMM DD, YYYY');
              this.viewOBTDetails.date_end = final_datetime_end;

              totalIncDays = date.getDateDiff(this.viewOBTDetails.date_end, this.viewOBTDetails.date_start, 'days');
              this.viewOBTDetails.total_days = totalIncDays + 1;

              temp_date_of_return = date.extractDate(this.viewOBTDetails.date_return, 'YYYY-MM-DD');
              final_date_of_return = date.formatDate(temp_date_of_return, 'MMMM DD, YYYY');
              this.viewOBTDetails.date_return = final_date_of_return;

              if (this.viewOBTDetails.status != 'Pending') {
                temp_date_processed = date.extractDate(this.viewOBTDetails.date_processed, 'YYYY-MM-DD HH:mm:ss');
                final_date_processed = date.formatDate(temp_date_processed, 'MMMM DD, YYYY @ hh:mmA');
                this.viewOBTDetails.date_processed = final_date_processed;
              }

              this.viewOBTDetailsDialog = true;
              break;
            case 'wfhApp':
                this.viewWFHDetails = appObj;
                temp_date_applied = date.extractDate(this.viewWFHDetails.date_applied, 'YYYY-MM-DD HH:mm:ss');
                final_date_applied = date.formatDate(temp_date_applied, 'MMMM DD, YYYY @ hh:mmA');
                this.viewWFHDetails.date_applied = final_date_applied;

                final_datetime_start = date.formatDate(this.viewWFHDetails.date_start, 'MMMM DD, YYYY');
                this.viewWFHDetails.date_start = final_datetime_start;

                final_datetime_end = date.formatDate(this.viewWFHDetails.date_end, 'MMMM DD, YYYY');
                this.viewWFHDetails.date_end = final_datetime_end;

                totalIncDays = date.getDateDiff(this.viewWFHDetails.date_end, this.viewWFHDetails.date_start, 'days');
                this.viewWFHDetails.total_days = totalIncDays + 1;

                temp_date_of_return = date.extractDate(this.viewWFHDetails.date_return, 'YYYY-MM-DD');
                final_date_of_return = date.formatDate(temp_date_of_return, 'MMMM DD, YYYY');
                this.viewWFHDetails.date_return = final_date_of_return;

                if (this.viewWFHDetails.status != 'Pending') {
                  temp_date_processed = date.extractDate(this.viewWFHDetails.date_processed, 'YYYY-MM-DD HH:mm:ss');
                  final_date_processed = date.formatDate(temp_date_processed, 'MMMM DD, YYYY @ hh:mmA');
                  this.viewWFHDetails.date_processed = final_date_processed;
                }

                this.viewWFHDetailsDialog = true;
                break;
            case 'otApp':
              this.viewOTDetails = appObj;

              temp_date_applied = date.extractDate(this.viewOTDetails.date_applied, 'YYYY-MM-DD HH:mm:ss');
              final_date_applied = date.formatDate(temp_date_applied, 'MMMM DD, YYYY @ hh:mmA');
              this.viewOTDetails.date_applied = final_date_applied;

              temp_overtime_start_date = date.extractDate(this.viewOTDetails.overtime_start_date, 'YYYY-MM-DD');
              final_overtime_start_date = date.formatDate(temp_overtime_start_date, 'MMMM DD, YYYY');
              this.viewOTDetails.overtime_start_date = final_overtime_start_date;

              temp_overtime_end_date = date.extractDate(this.viewOTDetails.overtime_end_date, 'YYYY-MM-DD');
              final_overtime_end_date = date.formatDate(temp_overtime_end_date, 'MMMM DD, YYYY');
              this.viewOTDetails.overtime_end_date = final_overtime_end_date;

              temp_original_time_start = date.extractDate(this.viewOTDetails.original_time_start, 'HH:mm:ss');
              final_original_time_start = date.formatDate(temp_original_time_start, 'hh:mmA');
              this.viewOTDetails.original_time_start = (this.viewOTDetails.schedule_type == 'Restday') ? this.viewOTDetails.schedule_type : final_original_time_start;

              temp_original_time_end = date.extractDate(this.viewOTDetails.original_time_end, 'HH:mm:ss');
              final_original_time_end = date.formatDate(temp_original_time_end, 'hh:mmA');
              this.viewOTDetails.original_time_end = (this.viewOTDetails.schedule_type == 'Restday') ? this.viewOTDetails.schedule_type : final_original_time_end;

              temp_overtime_start = date.extractDate(this.viewOTDetails.overtime_start, 'HH:mm:ss');
              final_overtime_start = date.formatDate(temp_overtime_start, 'hh:mmA');
              this.viewOTDetails.overtime_start = final_overtime_start;

              temp_overtime_end = date.extractDate(this.viewOTDetails.overtime_end, 'HH:mm:ss');
              final_overtime_end = date.formatDate(temp_overtime_end, 'hh:mmA');
              this.viewOTDetails.overtime_end = final_overtime_end;

              if (this.viewOTDetails.status != 'Pending') {
                temp_date_processed = date.extractDate(this.viewOTDetails.date_processed, 'YYYY-MM-DD HH:mm:ss');
                final_date_processed = date.formatDate(temp_date_processed, 'MMMM DD, YYYY @ hh:mmA');
                this.viewOTDetails.date_processed = final_date_processed;
              }

              this.viewOTDetailsDialog = true;
              break;
            case 'utApp':
              this.viewUTDetails = appObj;

              temp_date_applied = date.extractDate(this.viewUTDetails.date_applied, 'YYYY-MM-DD HH:mm:ss');
              final_date_applied = date.formatDate(temp_date_applied, 'MMMM DD, YYYY @ hh:mmA');
              this.viewUTDetails.date_applied = final_date_applied;

              final_date = date.formatDate(this.viewUTDetails.date, 'MMMM DD, YYYY');
              this.viewUTDetails.date = final_date;

              temp_original_time_start = date.extractDate(this.viewUTDetails.original_time_start, 'HH:mm:ss');
              final_original_time_start = date.formatDate(temp_original_time_start, 'hh:mmA');
              this.viewUTDetails.original_time_start = final_original_time_start;

              temp_original_time_end = date.extractDate(this.viewUTDetails.original_time_end, 'HH:mm:ss');
              final_original_time_end = date.formatDate(temp_original_time_end, 'hh:mmA');
              this.viewUTDetails.original_time_end = final_original_time_end;

              temp_undertime_timeout = date.extractDate(this.viewUTDetails.undertime_timeout, 'HH:mm:ss');
              final_undertime_timeout = date.formatDate(temp_undertime_timeout, 'hh:mmA');
              this.viewUTDetails.undertime_timeout = final_undertime_timeout;

              if (this.viewUTDetails.status != 'Pending') {
                temp_date_processed = date.extractDate(this.viewUTDetails.date_processed, 'YYYY-MM-DD HH:mm:ss');
                final_date_processed = date.formatDate(temp_date_processed, 'MMMM DD, YYYY @ hh:mmA');
                this.viewUTDetails.date_processed = final_date_processed;
              }

              this.viewUTDetailsDialog = true;
              break;
          }
        })
    },

    actionForm: function(action, appType, appID) {
      this.form.approverAction = action;
      this.form.appType = appType;
      this.form.appID = appID;
      if (action === 'Approve') {
        this.approveNotifType = 'Approved';
        this.actionType = 'Approval';
        this.actionText = action.toLowerCase();

      } else {
        this.approveNotifType = 'Declined';
        this.actionType = 'Denial';
        this.actionText = action.toLowerCase();
      }
      this.actionDialog = true;
    },

    submitConfirmation: function() {
      if (this.form.approverRemarks == '' || this.form.approverRemarks == null) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please fill-in approver remarks.'
        });
      } else {
        this.actionDialog = false;
        this.loadingAction = true;
        let formData = new FormData();
        formData.append('form_data', JSON.stringify(this.form));

        axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).post('/online-forms/applications/approver-process', formData).then((res) => {
          setTimeout(() => {
            this.loadingAction = false;
            this.actionDialog = false;
            this.cwsAppResultData = [];
            this.hdAppResultData = [];
            this.obtAppResultData = [];
            this.wfhAppResultData = [];
            this.otAppResultData = [];
            this.utAppResultData = [];
            this.loadApplications();
            switch (this.form.appType) {
              case 'CWS':
                this.viewCWSDetailsDialog = false;
                break;
              case 'Halfday':
                this.viewHDDetailsDialog = false;
                break;
              case 'OBT':
                this.viewOBTDetailsDialog = false;
                break;
              case 'WFH':
                  this.viewWFHDetailsDialog = false;
                break;
              case 'Overtime':
                this.viewOTDetailsDialog = false;
                break;
              case 'Undertime':
                this.viewUTDetailsDialog = false;
                break;
            }
            if (this.approveNotifType == 'Approved') {
              this.$q.notify({
                color: 'green-7',
                textColor: 'white',
                icon: 'fa fa-signature',
                message: this.form.appType + ' Application ' + this.approveNotifType
              });
            } else {
              this.$q.notify({
                color: 'deep-orange-10',
                textColor: 'white',
                icon: 'fa fa-signature',
                message: this.form.appType + ' Application ' + this.approveNotifType
              });
            }
            this.form.approverAction = '';
            this.form.approverRemarks = '';
            this.form.appType = '';
            this.form.appID = '';
          }, 1000);
        });
      }
    }
  },
  mounted() {
    this.loadApplications();
  }
}
</script>
<style scoped>
.btn-height {
  height: 40px;
}

.small-text {
  font-size: 12px;
  font-weight: 500;
}
</style>
