<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="row q-mb-md full-width">
        <div class="full-width text-h4" style="height: 40px;">
          Hired List
          <q-chip square class="q-ml-sm text-bold" size="md" color="light-blue" text-color="white">
            <q-avatar font-size="14px" color="blue-grey-8" text-color="white" style="width:1.7em">{{ applicationCounts }}</q-avatar>
            Applications
          </q-chip>
        </div>
        <div class="row q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
          <!-- <q-btn outline label="For Exam" @click="multipleForExam" color="primary"/> -->
          <q-btn color="red" @click="multipleDecline" label="Decline" />
          <!-- <q-btn outline color="green" @click="multipleAccept" label="Accept" /> -->
          <q-btn color="orange" @click="multipleDump" label="Dump" />
        </div>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-10 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
              <!-- :visible-columns="visibleColumns" 01182023 -->
                <q-table :data="applicationsData" :columns="ResultColumns" :pagination.sync="Pagination" :filter="filter" row-key="id" dense
                selection="multiple" :selected.sync="forHiredSelected" :visible-columns="visibleColumns"
                virtual-scroll :virtual-scroll-item-size="48" style="max-height: 600px">
              <!-- END:: :visible-columns="visibleColumns" 01182023 -->
                  <template v-slot:top-left>
                    <div style="display:inline-flex;">
                      <q-input type="date" dense debounce="400" color="primary" v-model="dateFrom" outlined class="q-mr-sm" hint="Date From"/>
                      <q-input type="date" dense debounce="400" color="primary" v-model="dateTo" outlined class="q-mr-sm" hint="Date To"/>
                      <q-select dense v-model="site" :options="sitess" outlined class="q-mr-sm"  />
                      <q-btn class="q-mb-lg q-mr-sm" label="Search" color="primary" @click="searchDate"/>
                      <q-btn
                        class="q-mb-lg"
                        color="primary"
                        icon-right="fas fa-download"
                        label="Export to csv"
                        no-caps
                        @click="exportModal = true"
                      />
                    </div>
                  </template>
                  <template v-slot:top-right>
                    <q-input borderless dense debounce="300" v-model="filter" placeholder="Search" outlined @change="searchData">
                      <template v-slot:append>
                        <q-icon name="fas fa-search" />
                      </template>
                    </q-input>
                  </template>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" >
                    <q-td>
                      <q-checkbox dense v-model="props.selected" />
                    </q-td>
                    <q-td key="action" style="text-align:center;">
                      <q-btn color="red" @click="forReprofile(props.row.id, props.row.applicant_id)" rounded size="sm" text-color="white" label="Decline" />
                      <!-- <q-btn color="green" @click="approvedApplication('Y',props.row.id, props.row.applicant_id)" rounded size="sm" text-color="white" label="Accept" /> -->
                      <q-btn color="orange" rounded size="sm" text-color="white" @click="forDumping(props.row.id, props.row.applicant_id)" label="Dump" />
                      <q-btn color="green" rounded size="sm" text-color="white" @click="forEndorsing(props.row.id, props.row.applicant_id)" label="Endorse" /> <!-- update 01092023 -->
                      <q-btn color="negative" rounded size="sm" text-color="white" @click="cancelRehire(props.row)" label="Cancel"  />
                    </q-td>
                    <q-td key="a_id" :props="props">{{ props.row.id }}</q-td>
                    <q-td key="site" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.site }}</q-td>
                    <q-td key="trainee_id" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.trainee_id }}</q-td>
                    <q-td key="applicant_id" @click="viewDetails('Passed Training','',props.row.id)" :props="props">{{ props.row.applicant_id }}</q-td>
                    <q-td key="firstname" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.firstname }}</q-td>
                    <q-td key="middlename" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.middlename }}</q-td>
                    <q-td key="lastname" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.lastname }}</q-td>
                    <q-td key="mobile" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.mobile }}</q-td>
                    <q-td key="email" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.email }}</q-td>
                    <q-td key="presaddress" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.presaddress }}</q-td>
                    <q-td key="prescity" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.prescity }}</q-td>
                    <q-td key="civil_status" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.civil_status }}</q-td>
                    <q-td key="gender" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.gender }}</q-td>
                    <q-td key="date_of_birth" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.date_of_birth }}</q-td>
                    <q-td key="edattain" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.edattain }}</q-td>
                    <q-td key="course" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.course }}</q-td>
                    <q-td key="estrainee" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.estrainee }}</q-td>
                    <q-td key="account" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.account }}</q-td>
                    <q-td key="batch" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.batch }}</q-td>
                    <q-td key="date_hired" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.date_hired }}</q-td>
                    <q-td key="expected_pbdate" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.expected_pbdate }}</q-td>
                    <q-td key="result" :props="props" @click="viewDetails('For Training','',props.row.id)">{{ props.row.result }}</q-td>

                    <q-td key="date_of_application" @click="viewDetails('Passed Training','',props.row.id)" :props="props">{{ props.row.date_of_application }}</q-td>
                    <q-td key="position_applying_for" @click="viewDetails('Passed Training','',props.row.id)" :props="props">{{ props.row.position_applying_for }}</q-td>
                    <q-td key="with_call_center_experience" @click="viewDetails('Passed Training','',props.row.id)" :props="props">{{ props.row.with_call_center_experience }}</q-td>
                    <q-td key="reprofile_count" :props="props " >{{ props.row.reprofile_count }}</q-td> <!-- 01182023 -->
                  </q-tr>
                  <template v-slot:no-data>
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
    <q-dialog v-model="ForApprovedDialog" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <span class="q-ml-sm">Are you sure you want to
            <span class="text-bold text-green" >ACCEPT</span> this applicant
            <span class="text-bold text-indigo-7" >
               <span v-for="track_no in this.form_approved.track_no" :key="track_no" class="text-bold text-indigo-7" >
              {{ track_no }}
            </span> ?</span>?
          </span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="red" v-close-popup />
          <!-- <q-btn flat label="Hire" color="indigo-7" @click="applicationApproved" v-close-popup /> -->
          <q-btn flat label="Hire" color="indigo-7" @click="uploadNewHiredApplication" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="reprofileModal" persistent>
      <q-card>
        <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
          <q-icon size="1.5em" name="fa fa-question-circle" />
          <span class="q-pl-sm">For Reprofile</span>
        </q-card-section>
        <q-card-section class="row items-center">
          <!-- <span class="q-ml-sm">Are you sure you want to <span class="text-bold text-red" >DECLINED/REPROFILE</span>?</span> -->
          <span class="q-ml-sm">Are you sure you want to <span class="text-bold text-red" >DECLINED/REPROFILE</span> this applicant/s
            <span v-for="applicantid in this.reprofile.reprofileApplicantID" :key="applicantid" class="text-bold text-indigo-7" >
              {{ applicantid }}
            </span> ?
          </span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="No" color="negative" v-close-popup />
          <q-btn flat label="Yes" color="primary"  @click="reprofileApplication" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="loadingModel" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            {{loadingText}}
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="loadingAction" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            Processing Application
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="viewDetailsDialog" transition-show="scale" transition-hide="scale">
      <div class="bg-white" style="height: auto; width: 1200px; max-width: 1200px;">
        <div class="row full-width q-pa-sm bg-indigo-6 text-center text-white sticky">
          <div class="text-h5">{{ vAD.name }}</div>
          <div>( {{ vAD.trackingID }} / {{ vAD.dateApplied }} )</div>
        </div>
        <div class="col-12 q-ma-sm">
          <q-card class="my-card transparent no-shadow">
            <q-card-section class="q-mt-sm q-pa-md">
              <div class="text-bold text-head">Personal Information</div>
              <div class="row q-col-gutter-md">
                <div class="col-6">
                  <q-field label="Present Address" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.presentAddress}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-6">
                  <q-field label="Permanent Address" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.permanentAddress}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Email Address" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.email}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Mobile Number" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.mobileNumber}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Telephone Number" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.telNumber}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Gender" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.gender}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Birthday" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.birthday}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Civil Status" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.civilStatus}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Religion" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.religion}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Application Information</div>
              <div class="row q-col-gutter-md">
                <div class="col-6">
                  <q-field label="Know Company Through" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.through}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-6">
                  <q-field label="Position Applying For" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.positionApplyingFor}}</div>
                    </template>
                  </q-field>
                </div>
              </div>

              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col-3">
                  <q-field label="Onsite?" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.onsite}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="WFH?" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.wfh}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Mode of Transportation" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.trasportation}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Availability to join?" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.availabilityToJoin}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col-3">
                  <q-field label="Pending Application" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.pendingApplication}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Account" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.account}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Position" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.position}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Recruiter" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.recruiter}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col-4">
                  <q-field label="Exam Date" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.examDate}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Exam Result" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.examResult}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col-3">
                  <q-field label="ISP" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.isp}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Speed" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.speed}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Previous Salary" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.prevSalary}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Expected Salary" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.expSalary}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col-12">
                  <q-field label="Remarks" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.remarks}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Work Eperiences</div>
              <div class="row q-col-gutter-md">
                <div class="col">
                  <q-field label="With Call Center Experience" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.callCenterExp}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Position" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpPosition}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Year(s) of Experience" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpDuration}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col q-mt-sm q-pt-none" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Company" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpCompany}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Account Handled" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpAccount}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Reason for Leaving" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpReasonLeave}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-md">
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="With Other Work Experience" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.otherWorkExperience}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Position" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpPosition}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Year(s) of Experience" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpDuration}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Company" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpCompany}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Nature of Work" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpBusNature}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Reason for Leaving" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpReasonLeave}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Educational Background</div>
              <div class="row q-col-gutter-md">
                <div class="col-2">
                  <q-field label="Highest Educational Attainment" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.educAttainment}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-1">
                  <q-field label="Year Attended" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.yearAttended}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-5">
                  <q-field label="School / University / College" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.suc}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3" v-if="vAD.educAttainment != 'High School Graduate'">
                  <q-field label="Course" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.course}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-1" v-if="vAD.educAttainment == 'College Level'">
                  <q-field label="Year Level" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.yearLevel}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md" v-if="vAD.natureOfIllness1 != 'N/A'">
              <div class="text-bold text-head">Medical / Health Background</div>
              <div class="row q-col-gutter-md">
                <div class="col-8">
                  <q-field label="Nature of Illness" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.natureOfIllness1}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Duration" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.illnessDuration1}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs" v-if="vAD.natureOfIllness2 != 'N/A'">
                <div class="col-8">
                  <q-field label="Nature of Illness" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.natureOfIllness2}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Duration" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.illnessDuration2}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs" v-if="vAD.natureOfIllness3 != 'N/A'">
                <div class="col-8">
                  <q-field label="Nature of Illness" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.natureOfIllness3}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Duration" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.illnessDuration3}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Emergency Contact</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="Contact Person" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.contactPerson}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Contact Relationship" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.contactRelation}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Contact Number" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.contactNumber}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Assessment</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="Remarks" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.assessment}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Assessed By" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.assessedBy}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Date of Assessment" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.assessmentDate}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-2">
                  <q-field label="Last Process" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.lastProcess}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-actions class="row col-12 q-pb-md q-mt-none q-mt-md">
              <div class="row col-9 q-pl-sm q-col-gutter-sm"></div>
              <div class="col-3 q-pl-sm justify-end">
                <q-btn label="CLOSE" class="full-width bg-indigo-6 text-white btn-height" v-close-popup />
              </div>
            </q-card-actions>
          </q-card>
        </div>
      </div>
    </q-dialog>
    <q-dialog v-model="actionDialog" persistent>
      <div class="bg-white" style="height: auto; width: 500px; max-width: 500px;">
        <q-card>
          <q-card-section class="row q-pa-sm bg-amber-8 text-white">
            <q-icon size="1.5em" name="fa fa-exclamation-circle" />
            <span class="q-pl-sm">Application Assessment : [ {{ form.assessorAction }} ]</span>
          </q-card-section>
          <q-card-section class="q-pa-md q-mt-sm" v-if="form.assessorAction == 'Failed'">
            <div class="q-gutter-sm">
              <q-radio v-model="form.failedAssessment" val="Failed" label="Failed" color="amber-8" />
              <q-radio v-model="form.failedAssessment" val="For Reconsideration" label="For Reconsideration" color="amber-8" />
              <q-radio v-model="form.failedAssessment" val="For Pooling" label="For Pooling" color="amber-8" />
            </div>
          </q-card-section>
          <q-card-section class="q-pa-md q-mt-sm">
            <span class="small-text">Assessment:</span>
            <q-input v-model="form.assessorRemarks" dense color="amber-8">
              <template v-slot:append>
                <q-icon name="fa fa-times" @click="form.assessorRemarks = null" class="cursor-pointer" />
              </template>
            </q-input>
          </q-card-section>
          <q-card-actions class="q-pa-md" align="right">
            <q-btn flat label="Cancel" @click="form.assessorRemarks = null,form.failedAssessment = null" color="amber-8" v-close-popup />
            <q-btn flat label="Confirm" @click="processAssessment" class="bg-amber-8 text-white" />
          </q-card-actions>
        </q-card>
      </div>
    </q-dialog>
    <q-dialog v-model="dumpModal" persistent>
      <q-card class="my-card" style="width: 700px; max-width: 80vw;">
        <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">For Dump</span>
        </q-card-section>
        <q-card-section>
          <div class="row">
            <div class="col-12 q-px-sm">Reason</div>
          </div>
          <div class="row">
            <div class="col-12 q-px-sm">
              <q-input type="textarea" v-model="dump.dumpReason" outlined color="indigo-6"/>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="indigo-6" v-close-popup dense/>
          <q-btn flat label="Dump" class="bg-indigo-6 text-white" @click="dumpApplication" v-close-popup dense/>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <!-- 01092023 -->
    <q-dialog v-model="endorseModal" persistent>
      <q-card class="my-card" style="width: 700px; max-width: 80vw;">
        <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">For Endorsing</span>
        </q-card-section>
        <q-card-section class="row items-center">
          <!-- <span class="q-ml-sm">Are you sure you want to <span class="text-bold text-red" >DECLINED/REPROFILE</span>?</span> -->
          <span class="q-ml-sm">Are you sure you want to <span class="text-bold text-green" >ENDORSE</span> this applicant/s
            <span class="text-bold text-indigo-7" >
              {{ this.endorse.endorseApplicantID }}
            </span> ?
          </span>
        </q-card-section>
        <hr>
        <q-card-actions align="right">
          <q-btn flat label="No" color="negative" v-close-popup />
          <q-btn flat label="Yes" color="primary"  @click="endorseApplication" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="exportModal" persistent>
      <q-card class="my-card" style="width: 700px; max-width: 80vw;">
        <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">For Export</span>
        </q-card-section>
        <q-card-section>
          <div class="row">
            <div class="col-6 q-pr-sm">Batch</div>
            <div class="col-6 q-pl-sm">Account</div>
          </div>
          <div class="row">
            <div class="col-6 q-pr-sm">
              <q-select :options="exporting_batch" v-model="exporting.batch" outlined color="indigo-6" required/>
            </div>
            <div class="col-6 q-pl-sm">
              <q-select :options="exporting_account" v-model="exporting.account" outlined color="indigo-6" required/>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="indigo-6" v-close-popup dense/>
          <q-btn flat label="Export" class="bg-indigo-6 text-white" @click="exportTable" v-close-popup dense/>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="noData">
      <q-card>
        <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">NO DATA</span>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="row q-pa-md">
            <div class="text-h6">There is no data!</div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" @click="resetNoData" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="notSelectedDialog" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="fas fa-exclamation" color="red" text-color="white" />
          <span class="q-ml-sm">There is no selected applicants!
          </span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Okay" color="green" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="ForEndorseDialog" persistent>
      <q-card>

        <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">For Endorsing</span>
        </q-card-section>
        <q-card-section>
          <div class="row">
            <div class="col-6 q-pr-sm">Account</div>
            <div class="col-6 q-pl-sm">Position</div>
          </div>
          <div class="row q-mb-sm">
            <div class="col-6 q-pr-sm">
              <q-select v-model="endorse.account" :options="accountEndorse" outlined color="indigo-6"/>
            </div>
            <div class="col-6 q-pl-sm">
              <q-select v-model="endorse.position" :options="positionEndorse" outlined color="indigo-6"/>
            </div>
          </div>
          <div class="row">
            <div class="col-6 q-pr-sm">PB Date</div>
            <div class="col-6 q-pl-sm">Basic Salary</div>
          </div>
          <div class="row q-mb-sm">
            <div class="col-6 q-pr-sm">
              <q-input type="date" v-model="endorse.pb_date" outlined color="indigo-6"/>
            </div>
            <div class="col-6 q-pl-sm">
              <q-input v-model="endorse.basic_salary" outlined color="indigo-6"/>
            </div>
          </div>
          <!-- ruben:: 13-06-2023 -->
          <div class="row">
            <div class="col-6 q-pr-sm">Location</div>
            <div class="col-6 q-pl-sm">Deminimis</div>
          </div>
          <div class="row q-mb-sm">
            <div class="col-6 q-pr-sm">
              <!-- <q-input dense type="date" v-model="endorse.location" outlined color="indigo-6"/> -->
              <q-select dense  v-model="endorse.location" :options="sites" outlined color="indigo-6"/>
            </div>
            <div class="col-6 q-pl-sm">
              <q-input dense v-model="endorse.deminimis" outlined color="indigo-6"/>
            </div>
          </div>
          <!-- end -->
          <div class="row">
            <div class="col-12 q-pr-sm">Status</div>
          </div>
          <div class="row">
            <div class="col-12 q-px-none">
              <q-select v-model="endorse.status" :options="['Project Base', 'Probationary', 'Seasonal']" outlined color="indigo-6"/>
            </div>
          </div>

          <!-- add by ruben -->
        <!-- 09-03-2023 -->
        <div class="row">
          <div class="col-12 q-px-none">
            <label for="">Person to notify</label>
            <q-input dense v-model="endorse.contact_name" outlined color="indigo-6"/>
          </div>
        </div>

        <div class="row">
          <div class="col-12 q-px-none">
            <label for="">Person to notify contact</label>
            <q-input dense v-model="endorse.contact_number" outlined color="indigo-6" mask="#### - ### - ####" unmasked-value hint="0912-345-6789"/>
          </div>
        </div>

        <div class="row">
          <div class="col-12 q-px-none">
            <label for="">Relationship</label>
            <q-input dense v-model="endorse.contact_relation" outlined color="indigo-6" />
          </div>
        </div>

        <!--========================================================== ruben::13/07/2023 =========================================================-->
          <div class="row">
            <div class="col-12 q-px-none">
              <label for="">SSS</label>
              <q-input dense v-model="endorse.sss" outlined color="indigo-6" />
            </div>
          </div>
          <div class="row">
            <div class="col-12 q-px-none">
              <label for="">TIN</label>
              <q-input dense v-model="endorse.tin" outlined color="indigo-6" />
            </div>
          </div>
          <div class="row">
            <div class="col-12 q-px-none">
              <label for="">HDMF</label>
              <q-input dense v-model="endorse.hdmf" outlined color="indigo-6" />
            </div>
          </div>
          <div class="row">
            <div class="col-12 q-px-none">
              <label for="">PHIC</label>
              <q-input dense v-model="endorse.phic" outlined color="indigo-6" />
            </div>
          </div>

          <!-- ruben::30/08/2024 -->
          <div class="row">
            <div class="col-12 q-px-none">
              <label for="">Period Rate</label>
              <q-select v-model="endorse.period_rate" :options="['Monthly', 'Hourly']" outlined color="indigo-6"/>
            </div>
          </div>
          <!--========================================================== end =========================================================-->

        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="red" v-close-popup />
          <q-btn flat label="Endorse" color="indigo-7" @click="endorseApplication" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';
import { exportFile } from 'quasar';
import vueCookies from 'vue-cookies';
function wrapCsvValue (val, formatFn) {
  let formatted = formatFn !== void 0
    ? formatFn(val)
    : val

  formatted = formatted === void 0 || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

export default {
  name: 'ForInitialExam',
  data() {
    return {
      sitess: ['ALL','ORTIGAS', 'MARILAO'],
      site:'ALL',
      filter: '',
      loadingModel: true,
      loadingText: '',
      loadingAction: false,
      viewDetailsDialog: false,
      actionDialog: false,
      ForNotHiredDialog: false,
      applications: '',
      applicationsData: [],
      Pagination: {
        sortBy: 'date_of_application',
        descending: true,
        // page: 1,
        rowsPerPage: 0
      },
      ResultColumns: [
        {
          name: 'action',
          field: 'action',
          label: 'Action',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'a_id',
          field: 'a_id',
          label: 'ID',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'site',
          field: 'site',
          label: 'Site',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'trainee_id',
          field: 'trainee_id',
          label: 'Trainee ID',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'applicant_id',
          field: 'applicant_id',
          label: 'Applicant ID',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'firstname',
          field: 'firstname',
          label: 'First Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'middlename',
          field: 'middlename',
          label: 'Middle Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'lastname',
          field: 'lastname',
          label: 'Last Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'mobile',
          field: 'mobile',
          label: 'Contact Number',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'email',
          field: 'email',
          label: 'Email Address',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'presaddress',
          field: 'presaddress',
          label: 'Home Address',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'prescity',
          field: 'prescity',
          label: 'City',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'civil_status',
          field: 'civil_status',
          label: 'Civil Status',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'gender',
          field: 'gender',
          label: 'Gender',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'date_of_birth',
          field: 'date_of_birth',
          label: 'Birthdate',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'edattain',
          field: 'edattain',
          label: 'Highest Educational Attainment',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'course',
          field: 'course',
          label: 'Course',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'estrainee',
          field: 'estrainee',
          label: 'E.S.',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'account',
          field: 'account',
          label: 'Account',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'batch',
          field: 'batch',
          label: 'Batch',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'date_hired',
          field: 'date_hired',
          label: 'Date Hired',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'expected_pbdate',
          field: 'expected_pbdate',
          label: 'Expected PB Date',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'result',
          field: 'result',
          label: 'Result',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'date_of_application',
          field: 'date_of_application',
          label: 'Date Applied',
          sortable: true,
          align: 'center',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'position_applying_for',
          field: 'position_applying_for',
          label: 'Position Applying For',
          align: 'center',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'with_call_center_experience',
          field: 'with_call_center_experience',
          label: 'With Call Center Expereience?',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        { // 01182023
          name: 'reprofile_count',
          field: 'reprofile_count',
          label: 'Reprofile Count',
          align: 'center',
          headerClasses: 'bg-indigo-1'
        },
      ],
      applicationCounts: 0,
      vAD: {
        id: null,
        trackingID: null,
        dateApplied: null,
        name: null,
        presentAddress: null,
        permanentAddress: null,
        email: null,
        mobileNumber: null,
        telNumber: null,
        gender: null,
        birthday: null,
        civilStatus: null,
        religion: null,
        knowCompanyThru: null,
        positionApplyingFor: null,
        callCenterExp: null,
        ccExpDuration: null,
        ccExpCompany: null,
        ccExpPosition: null,
        ccExpAccount: null,
        ccExpReasonLeave: null,
        otherWorkExperience: null,
        owExpDuration: null,
        owExpCompany: null,
        owExpPosition: null,
        owExpBusNature: null,
        owExpReasonLeave: null,
        educAttainment: null,
        yearAttended: null,
        suc: null,
        course: null,
        yearLevel: null,
        natureOfIllness1: null,
        illnessDuration1: null,
        natureOfIllness2: null,
        illnessDuration2: null,
        natureOfIllness3: null,
        illnessDuration3: null,
        contactPerson: null,
        contactRelation: null,
        contactNumber: null,
        assessment: null,
        assessedBy: null,
        assessmentDate: null,
        lastProcess: null,
        onsite: null,
        wfh: null,
        trasportation: null,
        availabilityToJoin: null,
        pendingApplication: null,
        isp: null,
        speed: null,
        prevSalary: null,
        expSalary: null,
        account: null,
        position: null,
        recruiter: null,
        examDate: null,
        examResult: null,
        remarks: null,
      },
      form: {
        assessorAction: null,
        failedAssessment: null,
        assessorRemarks: null,
        appID: null
      },
      approveNotifType: '',
      reprofileModal: false,
      reprofile: {
        reprofileApplicantID: [],
        reprofileApplicationID: []
      },
      ForApprovedDialog: false,
      form_approved: {
        id: [],
        track_no: []
      },
      dateTo: null,
      dateFrom: null,
      searchDateToFrom: {
          dateTo: null,
          dateFrom: null,
          status: 'Passed Training',
          site:'ALL'
      },
      dumpModal: false,
      dump: {
        dumpReason: '',
        dumpApplicantID: [],
        dumpApplicationID: []
      },
      endorseModal: false, // 01092023
      endorse: {  // 01092023
        endorseApplicantID: "",
        endorseApplicationID: "",
        account: "",
        position: "",
        pb_date: "",
        basic_salary: "",
        status: "",
        //add by ruben
        //09-03-2023
        contact_name: "",
        contact_relation: "",
        contact_number: "",
        //ruben 13-06-2023
        location: "",
        deminimis:"",

        // ruben::13/07/2023
        sss: "",
        tin: "",
        hdmf: "",
        phic: "",
        //ruben 30/08/2024
        period_rate: ""
      },
      //ruben 13-06-2023
      sites:["ORTIGAS","MARILAO"],

      exportModal: false,
      exporting: {
        account: '',
        batch: ''
      },
      exporting_account: [],
      exporting_batch: [],
      noData: false,
      forHiredSelected: [],
      notSelectedDialog: false,
      // 01182023
      visibleColumns: ['action','site', 'applicant_id', 'trainee_id', 'firstname', 'middlename' , 'lastname', 'mobile',
      'email','presaddress', 'prescity','civil_status','gender','date_of_birth','edattain','course',
      'estrainee','account','batch', 'date_hired','expected_pbdate', 'result','date_of_application',
      'position_applying_for','with_call_center_experience','reprofile_count'],
      ForEndorseDialog: false,
      endorseApp: {

      },
      positionEndorse: [],
      accountEndorse: [],

      // END:: 01182023
    }
  },
  methods: {
    searchData: function(){
      vueCookies.set(this.searchKey, {
          'search': this.filter
      });
      //this.filter = this.searchKeyword;
    },
    loadApplications: function() {
      this.loadingModel = true;
      this.loadingText = 'Retrieving Applications';
      this.applications = [];
      this.applicationsData = [];

      // if(document.cookie != ''){
      //   let decodedCookie = decodeURIComponent(document.cookie);
      //   let ca = decodedCookie.split(';');
      //   let searchFilter = ca[0].substring(8, ca[0].length);
      //   this.filter = searchFilter;
      // }

      this.filter = this.searchKeyword;

      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/recruitments/application/get-applicant-listv2', {
          params: {
            status: 'Passed Training',
            passfail: 'Pass',
            code: 'passed_training'
          }
        })
        .then((res) => {
          console.log(res)
          this.applications = res.data.data;
          this.applicationCounts = Object.keys(this.applications).length;

          for (let eachApplication of this.applications) {
            let name = eachApplication.lastname + ', ' + eachApplication.firstname + ' ' + eachApplication.middlename;
            let tempDate = eachApplication.date_apply;
            let date_applied = date.formatDate(tempDate, 'MMMM DD, YYYY');
            let date_exam = date.formatDate(eachApplication.exam_date, 'MMMM DD, YYYY');

            var status = "";
            if(eachApplication.passfail == 'Pass'){
              status = "For Final Interview";
            }else if(eachApplication.passfail == 'Fail'){
              status = "Delete";
            }else{
              status = "No results";
            }

            var edattn = '';
            if(eachApplication.edattain == "ed0"){
              edattn = "High School Graduate";
            } else if(eachApplication.edattain == "ed1"){
              edattn = "College Level";
            } else if(eachApplication.edattain == "ed2"){
              edattn = "Degree Holder";
            } else if(eachApplication.edattain == "ed3"){
              edattn = "Vocational Graduate";
            }else {
              edattn = eachApplication.edattain;
            }

            var account_telco = false;

            if(eachApplication.account != null){
              var account = eachApplication.account.toLowerCase();
              if(account.includes("telco")){
                account_telco = true;
              }else{
                account_telco = false;
              }
            }else{
              account_telco = false;
            }

            this.applicationsData.push({
              id: eachApplication.id,
              tracking_number: eachApplication.tracking_number,
              trainee_id: eachApplication.trainee_id,
              firstname: eachApplication.firstname,
              middlename: eachApplication.middlename,
              lastname: eachApplication.lastname,
              mobile: eachApplication.mobile,
              email: eachApplication.email,
              presaddress: eachApplication.presaddress,
              prescity: eachApplication.prescity,
              civil_status: eachApplication.civil_status,
              gender: eachApplication.gender,
              edattain: edattn,
              course: eachApplication.course,
              estrainee: eachApplication.estrainee,
              account: account_telco,
              batch: eachApplication.batch,
              date_hired: eachApplication.date_hired,
              expected_pbdate: eachApplication.expected_pbdate,
              result: eachApplication.result,
              date_of_application: date_applied,
              position_applying_for: eachApplication.position_sought,
              with_call_center_experience: eachApplication.ccexperience,
              applicant_id: eachApplication.tracking_number,
              exam_date: date_exam,
              passfail: eachApplication.passfail_int,
              // ave_grade: eachApplication.ave_grade,
              inactive: eachApplication.inactive,
              status: status,
              reprofile_count: eachApplication.reprofile_count, // 01182023
              site: eachApplication.site,
            });
          }
        })
        .finally(() => {
          this.loadingModel = false;
          this.loadingText = '';
        })
    },

    searchDate(){
      this.loading = true;
      let formData = new FormData();
      this.searchDateToFrom.dateFrom = this.dateFrom;
      this.searchDateToFrom.dateTo = this.dateTo;
      this.searchDateToFrom.site = this.site;
      formData.append('form_data', JSON.stringify(this.searchDateToFrom));
      axios.create({
          baseURL: this.apiUrl,
          headers: {
              'Authorization': `Bearer ${this.token}`
          }
          }).post('/recruitments/application/search-date', formData).then((res) => {
          console.log(res)
          this.applicationsData = [];
          this.applications = res.data.data;
          this.applicationCounts = Object.keys(this.applications).length;
          for (let eachApplication of this.applications) {
            console.log(eachApplication)
            let name = eachApplication.lastname + ', ' + eachApplication.firstname + ' ' + eachApplication.middlename;
            let tempDate = eachApplication.date_apply;
            let date_applied = date.formatDate(tempDate, 'MMMM DD, YYYY');
            let date_exam = date.formatDate(eachApplication.exam_date, 'MMMM DD, YYYY');

            var status = "";
            if(eachApplication.passfail == 'Pass'){
              status = "For Final Interview";
            }else if(eachApplication.passfail == 'Fail'){
              status = "Delete";
            }else{
              status = "No results";
            }

            var edattn = '';
            if(eachApplication.edattain == "ed0"){
              edattn = "High School Graduate";
            } else if(eachApplication.edattain == "ed1"){
              edattn = "College Level";
            } else if(eachApplication.edattain == "ed2"){
              edattn = "Degree Holder";
            } else if(eachApplication.edattain == "ed3"){
              edattn = "Vocational Graduate";
            }else {
              edattn = eachApplication.edattain;
            }

            var account_telco = false;

            if(eachApplication.account != null){
              var account = eachApplication.account.toLowerCase();
              if(account.includes("telco")){
                account_telco = true;
              }else{
                account_telco = false;
              }
            }else{
              account_telco = false;
            }

            this.applicationsData.push({
              id: eachApplication.id,
              tracking_number: eachApplication.tracking_number,
              trainee_id: eachApplication.trainee_id,
              firstname: eachApplication.firstname,
              middlename: eachApplication.middlename,
              lastname: eachApplication.lastname,
              mobile: eachApplication.mobile,
              email: eachApplication.email,
              presaddress: eachApplication.presaddress,
              prescity: eachApplication.prescity,
              civil_status: eachApplication.civil_status,
              gender: eachApplication.gender,
              edattain: edattn,
              course: eachApplication.course,
              estrainee: eachApplication.estrainee,
              account: account_telco,
              batch: eachApplication.batch,
              date_hired: eachApplication.date_hired,
              expected_pbdate: eachApplication.expected_pbdate,
              result: eachApplication.result,
              date_of_application: date_applied,
              position_applying_for: eachApplication.position_sought,
              with_call_center_experience: eachApplication.ccexperience,
              applicant_id: eachApplication.tracking_number,
              exam_date: date_exam,
              passfail: eachApplication.passfail_int,
              // ave_grade: eachApplication.ave_grade,
              inactive: eachApplication.inactive,
              status: status,
              reprofile_count: eachApplication.reprofile_count, // 01182023
              site: eachApplication.site,
            });

          }
      });
    },


    viewDetails: function(applicationStatus, status, rowID) {
      this.loadingModel = true;
      this.loadingText = 'Retrieving Details';

      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/recruitments/application/get-examresult-list', {
          params: {
            id: rowID,
            status: applicationStatus,
            dump: status
          }
        })
        .then((res) => {
          this.loadingModel = false;
          var viewApplicantDetails = res.data.data[0];

          console.log(viewApplicantDetails);

          var temp_date_applied, temp_birthdate;

          this.vAD.id = viewApplicantDetails.id;

          this.vAD.trackingID = viewApplicantDetails.tracking_number;
          this.vAD.dateApplied = date.formatDate(viewApplicantDetails.date_apply, 'MMMM DD, YYYY');

          this.vAD.name = this.toTitleCase(viewApplicantDetails.firstname) + ' ' + this.toTitleCase(viewApplicantDetails.middlename) + ' ' +
            this.toTitleCase(viewApplicantDetails.lastname);

          this.vAD.presentAddress = this.toTitleCase(viewApplicantDetails.presaddress) + ', ' + this.toTitleCase(viewApplicantDetails.prescity);

          this.vAD.permanentAddress = this.toTitleCase(viewApplicantDetails.presaddress) + ', ' + this.toTitleCase(viewApplicantDetails.prescity);

          this.vAD.email = viewApplicantDetails.email.toLowerCase();

          this.vAD.mobileNumber = viewApplicantDetails.mobile;

          this.vAD.telNumber = viewApplicantDetails.telephone;

          this.vAD.gender = this.toTitleCase(viewApplicantDetails.gender);

          this.vAD.birthday = date.formatDate(viewApplicantDetails.date_of_birth, 'MMMM DD, YYYY');

          this.vAD.civilStatus = this.toTitleCase(viewApplicantDetails.civil_status);

          this.vAD.religion = this.toTitleCase(viewApplicantDetails.religion);

          //this.vAD.through = this.toTitleCase(viewApplicantDetails.through) + ': ' + this.toTitleCase(viewApplicantDetails.through);

          if (viewApplicantDetails.through == "job posting" || viewApplicantDetails.through == "Referral" || viewApplicantDetails.through == 'OTHERS') {
            this.vAD.through = this.toTitleCase(viewApplicantDetails.through) + ': ' + this.toTitleCase(viewApplicantDetails.thru);
          } else {
            this.vAD.through = this.toTitleCase(viewApplicantDetails.through);
          }

          this.vAD.positionApplyingFor = this.toTitleCase(viewApplicantDetails.position_sought);

          if (viewApplicantDetails.ccexperience == 'with call center experience') {
            this.vAD.callCenterExp = this.toTitleCase(viewApplicantDetails.ccexperience);
            this.vAD.ccExpDuration = this.toTitleCase(viewApplicantDetails.ccyrsexp) + ' & ' + this.toTitleCase(viewApplicantDetails.ccmonthexp);
            this.vAD.ccExpCompany = this.toTitleCase(viewApplicantDetails.cc_compname);
            this.vAD.ccExpPosition = this.toTitleCase(viewApplicantDetails.position);
            //this.vAD.ccExpAccount = this.toTitleCase(viewApplicantDetails.cc_exp_account_handled);
            this.vAD.ccExpReasonLeave = this.toTitleCase(viewApplicantDetails.reason_for_leaving);
          } else {
            this.vAD.callCenterExp = this.toTitleCase(viewApplicantDetails.ccexperience);
          }

          if (viewApplicantDetails.othexp == 'YES') {
            this.vAD.otherWorkExperience = this.toTitleCase(viewApplicantDetails.othexp);
            this.vAD.owExpDuration = this.toTitleCase(viewApplicantDetails.othyrsexp) + ' & ' + this.toTitleCase(viewApplicantDetails.othmonthexp);
          } else {
            this.vAD.otherWorkExperience = this.toTitleCase(viewApplicantDetails.othexp);
          }

          var edattn = '';
          if(viewApplicantDetails.edattain == "ed0"){
            edattn = "High School Graduate";
          } else if(viewApplicantDetails.edattain == "ed1"){
            edattn = "College Level";
          } else if(viewApplicantDetails.edattain == "ed2"){
            edattn = "Degree Holder";
          } else if(viewApplicantDetails.edattain == "ed3"){
            edattn = "Vocational Graduate";
          }

          this.vAD.educAttainment = this.toTitleCase(edattn);

          this.vAD.yearAttended = viewApplicantDetails.school_year;

          this.vAD.natureOfIllness1 = viewApplicantDetails.illness;
          this.viewDetailsDialog = true;
        })
    },

    approvedApplication: function(status, rowID, applicantID){
      this.form_approved.id.push(rowID);
      this.form_approved.track_no.push(applicantID);

      this.ForApprovedDialog = true;
      // this.ForDeleteDialog = false;

      console.log(this.form_approved)

    },

    applicationApproved: function(){
      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.form_approved));
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/recruitments/application/update-for-approved', formData).then((res) => {
        setTimeout(() => {
          console.log(res);
          this.forHiredSelected = [];
          this.loadApplications();
          // this.uploadNewHiredApplication(); // 06162022 comment only
        }, 1000);
      });
    },

    getDropdownProperties:function(){
      axios.create({
        baseURL : this.apiUrl,
        headers : {'Authorization' : `Bearer ${this.token}`}
      }).get('/employee/dropdown/properties')
      .then((res) => {
        this.positionEndorse = res.data.data.positions
        this.accountEndorse = res.data.data.account

      });
    },

    //ruben::cancel rehire 26-06-2023
  cancelRehire(row){
      this.$q.dialog({
          title:'Are you sure?',
          message:'Remove '+row.employee_id+'? Click ok to continue.',
          cancel:true,
          persistent: true
      }).onOk(() => {
          axios.get(this.apiUrl+'/employee/rehired-cancel',{
              headers: { 'Authorization': `Bearer ${this.token}`},
              params:{id:row.id}
          }).then((res) => {
              console.log('ruben',res);
              this.fetchData()
          })
      })
  },

    multipleAccept(){
      if(this.forHiredSelected.length > 0){
        this.form_approved.id = []; //HRD
        this.form_approved.track_no = [];  // NUMBER

        for(var i = 0; i < this.forHiredSelected.length;i++){
          this.form_approved.id.push(this.forHiredSelected[i].id);
          this.form_approved.track_no.push(this.forHiredSelected[i].tracking_number);
        }
        this.ForApprovedDialog = true;
        console.log("hired form: ", this.form_approved)
      }else{
        this.notSelectedDialog = true;
      }
    },


    multipleDecline(){
      if(this.forHiredSelected.length > 0){
        this.reprofile.reprofileApplicantID = []; //HRD
        this.reprofile.reprofileApplicationID = [];  // NUMBER

        for(var i = 0; i < this.forHiredSelected.length;i++){
          this.reprofile.reprofileApplicationID.push(this.forHiredSelected[i].id);
          this.reprofile.reprofileApplicantID.push(this.forHiredSelected[i].tracking_number);
        }
        this.reprofileModal = true;
        console.log("hired form: ", this.reprofile)
      }else{
        this.notSelectedDialog = true;
      }

    },

    multipleDump(){
      if(this.forHiredSelected.length > 0){
        this.dump.dumpApplicantID = []; //HRD
        this.dump.dumpApplicationID = [];  // NUMBER

        for(var i = 0; i < this.forHiredSelected.length;i++){
          this.dump.dumpApplicationID.push(this.forHiredSelected[i].id);
          this.dump.dumpApplicantID.push(this.forHiredSelected[i].tracking_number);
        }
        this.dumpModal = true;
        console.log("hired form: ", this.dump)
      }else{
        this.notSelectedDialog = true;
      }
    },

    forReprofile: function(rowID, applicationID){

      this.reprofile.reprofileApplicationID.push(applicationID);
      this.reprofile.reprofileApplicantID.push(rowID);
      this.reprofileModal = true;

    },

    reprofileApplication: function(){
      console.log("REOPEN APPLICATION");
      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.reprofile));
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/recruitments/application/reprofile-application', formData).then((res) => {
        setTimeout(() => {
          console.log(res);
          this.forHiredSelected = [];
          this.reprofileModal = false;
          this.loadApplications();
        }, 1000);
      });
    },

    forDumping: function(rowID, applicationID){

      this.dump.dumpApplicationID.push(rowID);
      this.dump.dumpApplicantID.push(applicationID);
      this.dumpModal = true;
    },

    dumpApplication: function(){
      console.log("DUMP APPLICATION");
      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.dump));
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/recruitments/application/dump-application', formData).then((res) => {
        setTimeout(() => {
          console.log(res);
          this.forHiredSelected = [];
          this.loadApplications();
        }, 1000);
      });
    },

    // update 01092023
    forEndorsing: function(rowID, applicationID){

      this.endorse.endorseApplicationID = rowID;
      this.endorse.endorseApplicantID = applicationID;
      this.ForEndorseDialog = true;
    },

    // update 01092023
    endorseApplication: function(){
      console.log("ENDORSE APPLICATION");
      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.endorse));
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/recruitments/application/endorse-application', formData).then((res) => {
        setTimeout(() => {
          console.log(res)

          this.endorse.endorseApplicantID = "";
          this.endorse.endorseApplicationID = "";
          this.endorse.account = "";
          this.endorse.position = "";
          this.endorse.pb_date = "";
          this.endorse.basic_salary = "";
          this.endorse.status = "";

          //add by ruben
          //09-03-2023
          this.endorse.contact_name = "";
          this.endorse.contact_number = "";
          this.endorse.contact_relation = "";
          //ruben:: 13-06-2023
         this.endorse.location = "";
         this.endorse.deminimis = "";

         // ruben::13/07/2023
          this.endorse.sss = "";
          this.endorse.tin = "";
          this.endorse.hdmf = "";
          this.endorse.phic = "";
          // ruben::30/08/2024
          this.endorse.period_rate = "";

          this.forHiredSelected = [];
          this.loadApplications();
        }, 1000);
      });
    },

    uploadNewHiredApplication: function(){
      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.form_approved));

      console.log("upload new hired application: ", this.form_approved);
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/received/new-employee', formData).then((res) => {
        setTimeout(() => {
          console.log(res);
          this.forHiredSelected = [];
          this.applicationApproved();
          // this.loadApplications();
        }, 1000);
      });
    },

    actionForm: function(action) {
      this.form.assessorAction = action;
      this.form.appID = this.vAD.id;
      this.actionDialog = true;
    },

    processAssessment: function() {
      if (this.form.assessorAction != 'Failed' && this.form.assessorRemarks == null) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please put assessment / remarks for this application'
        });
      } else if (this.form.assessorAction == 'Failed' && (this.form.failedAssessment == null || this.form.assessorRemarks == null)) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please select option and put assessment / remarks for this application'
        });
      } else {
        this.loadingAction = true;
        let formData = new FormData();
        formData.append('form_data', JSON.stringify(this.form));

        axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).post('/recruitments/application/assessment', formData).then((res) => {
          setTimeout(() => {
            this.loadingAction = false;
            this.actionDialog = false;
            this.viewDetailsDialog = false;
            this.applicationsData = [];
            this.loadApplications();
            this.$q.notify({
              color: 'green-7',
              textColor: 'white',
              icon: 'fa fa-file-signature',
              message: 'Assessment Done'
            });
            this.form.assessorAction = null;
            this.form.assessorRemarks = null;
            this.form.failedAssessment = null;
            this.form.appID = null;
          }, 1000);
        });
      }
    },

    getAccount(){
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }})
      .get('/employee/get-account')
      .then((res) => {
        console.log(res)
        this.exporting_account = res.data.data;
      })
    },

    getBatch(){
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }})
      .get('/employee/get-batch')
      .then((res) => {
        console.log(res)
        this.exporting_batch = res.data.data;
      })
    },

    exportTable () {
      let newApplicationData = [];
      let account = this.exporting.account;
      let batch = this.exporting.batch;

      for(let i = 0; i < this.applicationsData.length; i++){
        if(account == 'ALL' && batch == 'ALL'){
          newApplicationData = this.applicationsData;
          break;
        }else if(account != '' && batch != ''){
          if(this.applicationsData[i].account == account && this.applicationsData[i].batch == batch){
            newApplicationData.push(this.applicationsData[i]);
            continue;
          }
        }
      }

      if(newApplicationData.length != 0){
        this.exportingTable(newApplicationData);
      }else if(newApplicationData.length == 0){
        this.noData = true;
      }
      console.log(newApplicationData);
    },


    exportingTable (data) {
      // naive encoding to csv format
      const content = [ this.ResultColumns.map(col => wrapCsvValue(col.label)) ].concat(
        data.map(row => this.ResultColumns.map(col => wrapCsvValue(
          typeof col.field === 'function'
            ? col.field(row)
            : row[col.field === void 0 ? col.name : col.field],
          col.format
        )).join(','))
      ).join('\r\n')

      const status = exportFile(
        'Hired.csv',
        content,
        'text/csv'
      )

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        })
      }
    },

    resetNoData(){
      this.exporting.account = '';
      this.exporting.batch = '';
    },

    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },

  mounted() {
    this.loadApplications();
    // this.getAccount();
    // this.getBatch();
    this.getDropdownProperties();
    this.timer = setInterval(this.loadApplications, 300000);
  },

  beforeUnmount() {
    this.cancelAutoUpdate();
  },
}
</script>
<style scoped>
.sticky {
  height: 80px;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  top: 0;
  z-index: 10;
}

.sticky div {
  width: 100%;
}

.btn-height {
  height: 40px;
}

.text-head {
  font-size: 16px;
}
</style>
