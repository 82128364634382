<template>
	<div class="q-pa-md">
		<div class="row">
			<div class="q-pa-md">
				<div class="row">
					<div class="row justify q-banner--top-padding q-pa-md">
						<q-select filled v-model="searchKey" use-input input-debounce="0" label="Search Employee"
				        :options="employeeList" @filter="filterFn" style="width: 550px" behavior="dialog" class="caps"
				      	>
				        <template v-slot:no-option>
				          <q-item>
				            <q-item-section class="text-grey">
				              No results
				            </q-item-section>
				          </q-item>
				        </template>
				      </q-select>
					</div>
	                <div class="row justify q-banner--top-padding q-pa-md">
						<q-select v-model="year" :options="yearOptions"  filled label="Select Year" class="caps" style="width:200px"/>
					</div>
	                <div class="row justify q-banner--top-padding q-pa-md">
						 <q-btn color="primary" style="color: red;" label="Show Details" @click="showDetails" />
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
				<div class="q-pa-md">
				    <q-markup-table separator="cell">
				      <thead>
				        <tr>
				          	<th class="text-left"><strong>MONTHS</strong></th>
				          	<th class="text-center">JANUARY</th>
				          	<th class="text-center">FEBRUARY</th>
				          	<th class="text-center">MARCH</th>
				          	<th class="text-center">APRIL</th>
				          	<th class="text-center">MAY</th>
							<th class="text-center">JUNE</th>
							<th class="text-center">JULY</th>
							<th class="text-center">AUGUST</th>
							<th class="text-center">SEPTEMBER</th>
							<th class="text-center">OCTOBER</th>
							<th class="text-center">NOVEMBER</th>
							<th class="text-center">DECEMBER</th>
							<th class="text-center">TOTAL</th>
				        </tr>
				      </thead>
				      <tbody>
				        <tr>
				          	<td class="text-left"><strong>GROSS</strong></td>
				          	<td class="text-center" v-show="monthlyGross.length > 0" v-for="(gross,g) in monthlyGross" :key="g">{{ Number(gross).toLocaleString() }}</td>
							<td class="text-center" v-show="monthlyGross.length == 0" v-for="i in 12" :key="i+15">0</td>
							<td class="text-center"><strong>{{ yearlyTotal }}</strong></td>
				        </tr>
				        <tr>
				          	<td class="text-left"><strong>SSS</strong></td>
							<td class="text-center" v-show="sssData.length > 0" v-for="(sss,s) in sssData" :key="s">{{ Number(sss).toLocaleString() }}</td>
							<td class="text-center" v-show="monthlyGross.length == 0" v-for="i in 12" :key="i+15">0</td>
							<td class="text-center"><strong>{{ yearlySSSTotal }}</strong></td>
				        </tr>
				        <tr>
				          	<td class="text-left"><strong>PHIL</strong></td>
							<td class="text-center" v-show="philData.length > 0" v-for="(phil,p) in philData" :key="p">{{ Number(phil).toLocaleString() }}</td>
							<td class="text-center" v-show="philData.length == 0" v-for="i in 12" :key="i+15">0</td>
							<td class="text-center"><strong>{{ yearlyPhilTotal }}</strong></td>
				        </tr>
						<tr>
				          	<td class="text-left"><strong>HDMF</strong></td>
							<td class="text-center" v-show="pagiData.length > 0" v-for="(pagi,h) in pagiData" :key="h">{{ Number(pagi).toLocaleString() }}</td>
							<td class="text-center" v-show="pagiData.length == 0" v-for="i in 12" :key="i+15">0</td>
							<td class="text-center"><strong>{{ yearlyPagiTotal }}</strong></td>
				        </tr>
						<tr>
				          	<td class="text-left"></td>
							<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
				        </tr>
						<tr>
				          	<td class="text-left"><strong>TAXABLE INCOME</strong></td>
							<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"><strong>337,800.00</strong></td>
				        </tr>
						<tr>
				          	<td class="text-left"><strong>TAX WITHELD</strong></td>
							<td class="text-center">300</td>
						  	<td class="text-center">300</td>
						  	<td class="text-center">300</td>
						  	<td class="text-center">300</td>
						  	<td class="text-center">300</td>
						  	<td class="text-center">300</td>
						  	<td class="text-center">300</td>
						  	<td class="text-center">300</td>
						  	<td class="text-center">300</td>
						  	<td class="text-center">300</td>
						  	<td class="text-center">300</td>
						  	<td class="text-center">300</td>
						  	<td class="text-center"><strong>(10,200.00)</strong></td>
				        </tr>
						<tr>
				          	<td class="text-left"><strong>ANNUAL INCOME TAX</strong></td>
							<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"><strong>35,000</strong></td>
				        </tr>
						<tr>
							<td class="text-left"><strong>ANNUAL NET INCOME</strong></td>
							<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"><strong>302,800.00</strong></td>
				        </tr>
				      </tbody>
				    </q-markup-table>
				  </div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';


export default {

	data () {
		return {
			searchKey:'',
			employeeList:[],

			year:'',
            yearOptions:[],

			monthlyGross:[],
			sssData:[],
			philData:[],
			pagiData:[],

			yearlyTotal:0,
			yearlySSSTotal:0,
			yearlyPhilTotal:0,
			yearlyPagiTotal:0,

		}
	},

	created(){
		this.getListOfEmployees()
		this.yearsList()

	},

	methods: {

		filterFn (val, update) {
	     	if (val === '') {
		        update(() => {
		          this.employeeList = this.employeeOptions
		        })
	        	return
	      	}
	      update(() => {
	        	const needle = val.toLowerCase()
	        	this.employeeList = this.employeeOptions.filter(emp => emp.toLowerCase().indexOf(needle) > -1)
	      })
	  	},

		showDetails:function(){
            this.$q.loading.show({
                spinnerColor: 'yellow',
                spinnerSize: 140,
                backgroundColor: 'purple',
                message: 'Generating in process...',
                messageColor: 'black'
            })
            var employeeID = this.searchKey.substr(0, this.searchKey.indexOf('|'));
            let formData = new FormData();
            formData.append("empID", employeeID);
            formData.append("year", this.year);
            axios.create({
                baseURL: this.apiUrl,
                headers: { 'Authorization': `Bearer ${this.token}` }
              }).post(`/backend/alphalist`, formData)
              .then((res) => {
                    this.monthlyGross = res.data.data.monthlyGross
					this.sssData = res.data.data.sssTotal
					this.philData = res.data.data.philTotal
					this.pagiData = res.data.data.pagiTotal

					this.yearlyTotal = res.data.data.yearlyTotal
					this.yearlySSSTotal = res.data.data.yearSSSTotal
					this.yearlyPhilTotal = res.data.data.yearPhilTotal
					this.yearlyPagiTotal = res.data.data.yearPagiTotal

                    this.timer = setTimeout(() => {
                        this.$q.loading.hide()
                        this.timer = void 0
                    }, 1000)
              });
        },

		getListOfEmployees: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/employee/list`)
			.then((res) => {
				this.employeeOptions = res.data.data;
			})

		},
		yearsList : function(){
            axios.create({
                baseURL : this.apiUrl,
                headers : {'Authorization' : `Bearer ${this.token}`}
            }).post(`/timekeeping/years/list`)
            .then((res) => {
                this.yearOptions = res.data.data
            });
        },
	}
}
</script>
