<template>
    <div id="app">
        <div style="display: flex; justify-content: start; align-items: self-start;">
            <div class="q-gutter-md row justify-start q-carousel__navigation-inner row items-center" style="margin-top: 5px;">
                <p style="color: #564254; font-size: 22px; font-weight: bolder; margin-left: 50px; flex-shrink: 0;">Form 2316</p>
            </div>
            <q-form v-if="isAdmin" ref="vForm" @submit="onSubmitSearch" style="background: transparent; width: 100%;">
                <q-input v-model="searchModel" dense outlined :rules="[formRules.required]" placeholder="Search employee here...">
                    <template v-slot:append>
                    <q-icon name="fa fa-search" />
                    </template>
                </q-input>
            </q-form>
            <!-- <div class="row justify-end q-carousel__navigation-inner">
                <q-select v-model="location" :options="locationOptions" label="LOCATION" @input="getEmployees" style="width: 200px" tabindex="0" />
                <q-select v-model="department" :options="departments" option-value="id" option-label="name"  emit-value map-options label="DEPARTMENT" @input="getEmployees" style="width: 200px" />
            </div> -->
        </div>
    <form @submit.prevent="submitForm" style="margin: auto; margin-left: 15%;">
      <table border="1" width="100%" cellpadding="5" cellspacing="0" style="max-width: 1100px; min-width: 1100px; border-collapse: collapse; table-layout: fixed;">
        <tr>
            <td width="25%" style="text-align: center;">
                BIR Form No.<br />
                <span style="font-weight: bold; font-size: 30px;">2316</span><br />
                {{currentMonth}} {{ currentYear }}(ENCS)
            </td>
            <td width="50%" style="font-weight: bold; font-size: 20px; text-align: center;">
                <p style="width: 60%; margin: auto;">Certificate of Compensation Payment/Tax Withheld</p>
                <p  style="font-weight: normal;  font-size: 15px;">For Compensation Payment With or Without Tax Withheld</p>
            </td>
            <td>Barcode</td>
        </tr>
        
       <!--<tr>-->
       <!--    <td colspan="3" style="display:flex; width: 375%; justify-content: space-between; align-items: center;">-->
       <!--       <div style="display: flex; align-items: center; gap: 50px; border-right: 1px solid black; width: 50%;">-->
       <!--           <p>1. For the Year <br /> (YYYY)</p>-->
       <!--           <input type="text" name="" id="" value="" style="height: 25px" />-->
       <!--       </div>-->
       <!--       <div style="display: flex; align-items: center; gap: 50px;">-->
       <!--           <p>1. For the Year <br /> (YYYY)</p>-->
       <!--           <input type="text" name="" id="" value="" style="height: 25px" />-->
       <!--       </div>-->
       <!--    </td>-->
       <!--</tr>-->
        
    </table>
    
    <main style="max-width: 1100px; min-width: 1100px; border: 0.5px solid black; margin-top: 2px; font-size: 15px; display: flex;">
        <div>
            <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>
                    1. For the Year <br />
                    (YYYY)
                </p>
                <input v-model="form.for_the_year" type="text" maxlength="4" placeholder="YYYY" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px;" />
            </div>
        </div>

        <!-- Part 1: Employee Information -->
        <div style="border: 0.5px solid black; text-align: center; font-weight: bold;">
            <p style="font-size: 15px;">Part I - Employee Information</p>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>3. TIN</p>
                <input v-model="form.tin" type="text" maxlength="14" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; align-items: center; justify-content: center; gap: 10px">
                <div style="display: flex; justify-content: center; flex-direction: column; margin-bottom: 22px;">
                  <p>4. Employee's Name <br />(Last Name, First Name, Middle Name)</p>
                  <input v-model="form.employee_name" type="text" :readonly="!isAdmin" style="height: 25px;" />
                </div>
                <div style="display: flex; justify-content: center; flex-direction: column;">
                    <p>5. RDO Code</p>
                    <input  v-model="form.rdo_code" type="text" maxlength="3" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px;" />
                </div>
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; align-items: center; justify-content: space-between; padding-left: 20px; padding-right: 20px; padding-bottom: 10px">
                <div style="display: flex; justify-content: center; flex-direction: column;">
                <p>6. Registered Address</p>
                <input v-model="form.registered_address" type="text" :readonly="!isAdmin" style="height: 25px; width: 147%;" />
                </div>
                <div style="display: flex; justify-content: center; flex-direction: column;">
                    <p>6A. Zip Code</p>
                    <input v-model="form.registered_zip" type="text" maxlength="4" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px;" />
                </div>
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; align-items: center; justify-content: space-between; padding-left: 20px; padding-right: 20px; padding-bottom: 10px">
                <div style="display: flex; justify-content: center; flex-direction: column;">
                <p>6B. Local Home Address</p>
                <input v-model="form.local_address" type="text" :readonly="!isAdmin" style="height: 25px; width: 147%;" />
                </div>
                <div style="display: flex; justify-content: center; flex-direction: column;">
                    <p>6C. Zip Code</p>
                    <input v-model="form.local_zip" type="text" maxlength="4" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px;" />
                </div>
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; align-items: center; justify-content: space-between; padding-left: 20px; padding-right: 20px; padding-bottom: 10px">
                <div style="display: flex; justify-content: center; flex-direction: column; width: 100%;">
                <p>6D. Foreign Address</p>
                <input v-model="form.foreign_address" type="text" :readonly="!isAdmin" style="height: 25px;" />
                </div>
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; align-items: center; justify-content: center; gap: 10px">
                <div style="display: flex; justify-content: center; flex-direction: column; margin-bottom: 18px;">
                <p style="margin-right: 25px;">7. Date of Birth (MM/DD/YYYY)</p>
                <input v-model="form.date_of_birth" type="date" :readonly="!isAdmin" style="height: 25px;" />
                </div>
                <div style="display: flex; justify-content: center; flex-direction: column; margin-bottom: 18px;">
                    <p>8. Contact Number</p>
                    <input v-model="form.contact_number" type="text" maxlength="11" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px; width: 110%;" />
                </div>
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>9. Statutory Minimum Wage rate per day</p>
                <input  v-model="form.statutory_min_wage_per_day" type="text" :readonly="!isAdmin" @keypress="isNumber($event)" style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>10. Statutory Minimum Wage rate per month</p>
                <input v-model="form.statutory_min_wage_per_month" type="text" :readonly="!isAdmin" @keypress="isNumber($event)" style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        
        <!-- Missing column -->
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <input type="checkbox" name="" id="" value="" style="height: 25px;" />
                <p>11. Statutory Minimum Wage Earner (MWE) whose compensation is exempt from withholding tax and not subjec to income tax</p>
            </div>
        </div>
        <!-- Missing column -->
        
        <!-- Part 2: Present Employer Information -->
        <div style="border: 0.5px solid black; text-align: center; font-weight: bold;">
            <p>Part II - Employer Information (Present)</p>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>12. TIN</p>
                <input v-model="form.present_employer_tin" type="text" maxlength="14" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; align-items: center; justify-content: center; gap: 10px">
                <div style="display: flex; justify-content: center; flex-direction: column; margin-bottom: 18px; width: 90%;">
                <p>13. Employer's Name</p>
                <input v-model="form.present_employer_name" type="text" :readonly="!isAdmin" style="height: 25px;" />
                </div>
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; align-items: center; justify-content: space-between; padding-left: 20px; padding-right: 20px; padding-bottom: 10px">
                <div style="display: flex; justify-content: center; flex-direction: column;">
                <p>14. Registered Address</p>
                <input v-model="form.present_employer_address" type="text" :readonly="!isAdmin" style="height: 25px; width: 147%;" />
                </div>
                <div style="display: flex; justify-content: center; flex-direction: column;">
                    <p>14A. Zip Code</p>
                    <input v-model="form.present_employer_zip" type="number" maxlength="4" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px;" />
                </div>
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 5px; align-items: center; padding-left: 20px;">
                <p>15. Type of Employer</p>
                
                <input type="checkbox" @click="form.employer_type = 'Main Employer'" name="main" id="" value="" style="height: 25px;" />
                <label for="main">Main Employer</label>
                
                <input type="checkbox" @click="form.employer_type = 'Secondary Employer'" name="main" id="" value="" style="height: 25px;" />
                <label for="main">Secondary Employer</label>
            </div>
        </div>
        
        <!-- Part 3: Previous Employer Information -->
        <div style="border: 0.5px solid black; text-align: center; font-weight: bold;">
            <p>Part III - Employer Information (Previous)</p>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>16. TIN</p>
                <input v-model="form.previous_employer_tin" type="text" maxlength="14"  @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; align-items: center; justify-content: center; gap: 10px">
                <div style="display: flex; justify-content: center; flex-direction: column; margin-bottom: 18px; width: 90%;">
                <p>17. Employer's Name</p>
                <input v-model="form.previous_employer_name" type="text" :readonly="!isAdmin" style="height: 25px;" />
                </div>
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; align-items: center; justify-content: space-between; padding-left: 20px; padding-right: 20px; padding-bottom: 10px">
                <div style="display: flex; justify-content: center; flex-direction: column;">
                <p>18. Registered Address</p>
                <input v-model="form.previous_employer_address" type="text" :readonly="!isAdmin" style="height: 25px; width: 147%;" />
                </div>
                <div style="display: flex; justify-content: center; flex-direction: column;">
                    <p>18A. Zip Code</p>
                    <input v-model="form.previous_employer_zip" type="text" maxlength="4" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px;" />
                </div>
            </div>
        </div>
        
        <!-- Part 4A: Compensation and Tax Details -->
        <div style="border: 0.5px solid black; text-align: center; font-weight: bold;">
            <p>Part IV-A - Summary</p>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>19. Gross Compensation Income from Present Employer (Sum of Items 38 and 52) </p>
                <input v-model="form.gross_income_from_present_employer" type="text" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>20.<span>Less: Total Non-Taxable/Exempt Compensation</span> <br />
                    Income from present employer (From Item 38)
                </p>
                <input v-model="form.less_non_taxable_compensation" type="text" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>21. Taxable Compensation Income from Present Employer (Item 19 Less Item 20) (From Item 52) </p>
                <input v-model="form.taxable_income_from_present_employer" type="text" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>22. Add: Taxable Compensation Income from Previous Employer, if applicable </p>
                <input  v-model="form.taxable_income_from_previous_employer" type="text" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        
        <!-- Missing -->
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; justify-content: space-between; align-items: center; padding-left: 20px;">
                <p>23. Gross Taxable Compensation Income <br /> (Sum of Items 21 and 22) </p>
                <input type="text" name="" id="" value="" style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        <!-- Missing -->

        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; justify-content: space-between; align-items: center; padding-left: 20px;">
                <p>24. Tax Due </p>
                <input v-model="form.tax_due" type="text" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <p style="height:3px; padding-left: 20px;">25. Amount of Taxes Withheld</p>
            <div style="display: flex; justify-content: center; align-items: center; padding-left: 20px; flex-direction: column;">
                <div style="height:35px; display: flex; justify-content: space-between; align-items: center; width: 100%;">
                    <p>25A. Present Employer</p>
                    <input type="text" name="" id="" value="" style="height: 25px; margin-right: 20px;" />
                </div>
                <div style="height:35px; display: flex; justify-content: space-between; align-items: center; width: 100%;">
                    <p>25B. Previous Employer, if applicable</p>
                    <input type="text" name="" id="" value="" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>26. Total Amount of Taxes Withheld as adjusted <br /> (Sum of Items 25A and 25B) </p>
                <input v-model="form.total_amount_taxes_withheld" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                <p>27. 5% Tax Credit (PERA Act of 2008) </p>
                <input v-model="form.tax_credit" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>28. Total Taxes Withheld (Sum of Items 26 and 27)  </p>
                <input v-model="form.total_taxes_withheld" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
            </div>
        </div>    
        </div>
        
        <div>
            <div style="border: 0.5px solid black;">
                <div style="display: flex; gap: 10px; align-items: center; padding-left: 20px;">
                    <p>
                        2. For the Period <br />
                         From (MM/DD)
                    </p>
                    <input v-model="form.for_the_period_from" type="text" maxlength="5" placeholder="MM/DD" @keypress="formatFrom(form.for_the_period_from); isNumber($event)" :readonly="!isAdmin" style="height: 25px; width:20%;" />
                    <p>To (MM/DD)</p>
                    <input v-model="form.for_the_period_to" type="text" maxlength="5" placeholder="MM/DD"  @keypress="formatTo(form.for_the_period_to); isNumber($event)" :readonly="!isAdmin" style="height: 25px; width:20%;" />
                </div>
            </div>
        
            <!-- Part 4B B: Taxable Compensation Details -->
            <div style="border: 0.5px solid black; text-align: center; font-weight: bold;">
                <p style="font-size: 15px;">Part IV-B - Details of Compensation Income & Tax Withheld from Present Employer</p>
            </div>
            
            <div style="display:flex; justify-content: space-around; align-items: center;  margin-top: 30px;">
              <p>A. NON-TAXABLE/EXEMPT <br /> COMPENSATION INCOME</p>
              <p>Amount</p>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>29. Total Taxes Withheld (Sum of Items 26 and 27)  </p>
                    <input type="text" name="" id="" value="" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>30. Holiday Pay (MWE)  </p>
                    <input type="text" name="" id="" value="" style="height: 25px; margin-right: 20px;" />
                </div>
            </div> 
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>31. Overtime Pay (MWE)  </p>
                    <input type="text" name="" id="" value="" style="height: 25px; margin-right: 20px;" />
                </div>
            </div> 
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>32. Night Shift Differential (MWE)</p>
                    <input type="text" name="" id="" value="" style="height: 25px; margin-right: 20px;" />
                </div>
            </div> 
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>33. Hazard Pay (MWE)</p>
                    <input type="text" name="" id="" value="" style="height: 25px; margin-right: 20px;" />
                </div>
            </div> 
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>34. 13th Month Pay and Other Benefits <br /> (Maximum of P90,000)</p>
                    <input type="text" name="" id="" value="" style="height: 25px; margin-right: 20px;" />
                </div>
            </div> 
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>35. De Minimis Benefits</p>
                    <input type="text" name="" id="" value="" style="height: 25px; margin-right: 20px;" />
                </div>
            </div> 
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>36. SSS, GSIS, PHIC, & PAG-IBIG Contributions and Union Dues (Employee share only)</p>
                    <input type="text" name="" id="" value="" style="height: 25px; margin-right: 20px;" />
                </div>
            </div> 
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>37. Salaries and Other Forms of Compensation</p>
                    <input type="text" name="" id="" value="" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>38. Total Non-Taxable/Exempt Compensation</p>
                    <input type="text" name="" id="" value="" style="height: 25px; margin-right: 20px;" />
                </div>
            </div> 
            
            <div>
                <div style="padding-left: 20px; margin-top: 50px;">
                    <p>B. TAXABLE COMPENSATION INCOME REGULAR</p>
                </div>
            </div> 
            
            <!-- Missing -->
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>39. Basic Salary</p>
                    <input type="text" name="" id="" value="" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            <!-- Missing -->
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>40. Representation</p>
                    <input  v-model="form.representation" type="text" :readonly="!isAdmin"  style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>41. Transportation</p>
                    <input v-model="form.transportation" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px; margin-right: 20px;">
                    <p>42. Cost of Living Allowance (COLA)</p>
                    <input v-model="form.cost_of_living_allowance" @keypress="isNumber($event)" type="text" :readonly="!isAdmin" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>43. Fixed Housing Allowance</p>
                    <input v-model="form.fixed_housing_allowance" @keypress="isNumber($event)" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <p style="height:3px; padding-left: 20px;">44. Others (Specify)</p>
                <div style="display: flex; justify-content: center; align-items: center; padding-left: 20px; flex-direction: column;">
                    <div style="height:35px; display: flex; justify-content: flex-start; align-items: center; width: 100%;">
                        <p>44A</p>
                        <input v-model="form.others_1" type="text" :readonly="!isAdmin" style="height: 25px; margin-left: 20px;" />
                    </div>
                    <div style="height:35px; display: flex; justify-content: flex-start; align-items: center; width: 100%;">
                        <p>44B</p>
                        <input v-model="form.others_2" type="text" :readonly="!isAdmin" style="height: 25px; margin-left: 20px;" />
                    </div>
                </div>
            </div>
            
            <div>
                <div style="padding-left: 20px; margin-top: 50px;">
                    <p>Supplementary</p>
                </div>
            </div> 
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>45. Commission</p>
                    <input v-model="form.commission" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>46. Profit Sharing</p>
                    <input v-model="form.profit_sharing" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>47. Fees Including Director's Fees</p>
                    <input v-model="form.fees_and_director_fees" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>48. Taxable 13th Month Benefits</p>
                    <input v-model="form.taxable_13th_month" @keypress="isNumber($event)" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>49. Hazard Pay</p>
                    <input v-model="form.taxable_hazard_pay" @keypress="isNumber($event)" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>50. Overtime Pay</p>
                    <input v-model="form.taxable_overtime_pay" @keypress="isNumber($event)" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <p style="height:3px; padding-left: 20px;">51. Others (Specify)</p>
                <div style="display: flex; justify-content: center; align-items: center; padding-left: 20px; flex-direction: column;">
                    <div style="height:35px; display: flex; justify-content: flex-start; align-items: center; width: 100%;">
                        <p>51A</p>
                        <input v-model="form.others_3" type="text" :readonly="!isAdmin" style="height: 25px; margin-left: 20px;" />
                    </div>
                    <div style="height:35px; display: flex; justify-content: flex-start; align-items: center; width: 100%;">
                        <p>51B</p>
                        <input v-model="form.others_4" type="text" :readonly="!isAdmin" style="height: 25px; margin-left: 20px;" />
                    </div>
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>52. Total Taxable Compensation Income <br /> (Sum of Items 39 to 51B)</p>
                    <input v-model="form.total_taxable_income" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
        </div>
    </main>

      <div style="margin: auto; text-align: center; width: 80%; margin-top: 20px;" v-if="isAdmin">
        <q-btn push color="primary" label="Submit" style="width: 100%;" @click="submitForm()"/>
      </div>
    </form>
  </div>
  </template>
  
<script>
import axios from "axios";
import { readonly } from "vue";
import vueCookies from 'vue-cookies';

export default {
    data() {
      return {
        isAdmin: false,
        form: {
            for_the_year: "2024",
            for_the_period_from: "",
            for_the_period_to: "",
            tin: "",
            employee_name: "",
            rdo_code: "",
            registered_address: "",
            registered_zip: "",
            local_address: "",
            local_zip: "",
            foreign_address: "",
            date_of_birth: "",
            contact_number: "",
            statutory_min_wage_per_day: "",
            statutory_min_wage_per_month: "",
            present_employer_tin: "",
            present_employer_name: "",
            present_employer_address: "",
            present_employer_zip: "",
            employer_type: "",
            previous_employer_tin: "",
            previous_employer_name: "",
            previous_employer_address: "",
            previous_employer_zip: "",
            gross_income_from_present_employer: "",
            less_non_taxable_compensation: "",
            income_from_present_employer: "",
            taxable_income_from_present_employer: "",
            taxable_income_from_previous_employer: "",
            tax_due: "",
            present_employer_taxes_withheld: "",
            previous_employer_taxes_withheld: "",
            total_amount_taxes_withheld: "",
            tax_credit: "",
            total_taxes_withheld: "",
            non_taxable_basic_salary: "",
            holiday_pay: "",
            overtime_pay: "",
            night_shift_diff: "",
            hazard_pay: "",
            thirteenth_month_pay: "",
            de_minimis_benefits: "",
            gov_contributions: "",
            salaries_and_others: "",
            total_non_taxable_compensation: "",
            taxable_basic_salary: "",
            representation: "",
            transportation: "",
            cost_of_living_allowance: "",
            fixed_housing_allowance: "",
            others_1: "",
            others_2: "",
            commission: "",
            profit_sharing: "",
            fees_and_director_fees: "",
            taxable_13th_month: "",
            taxable_hazard_pay: "",
            taxable_overtime_pay: "",
            others_3: "",
            others_4: "",
            total_taxable_income: "",
        },
        currentMonth: null,
        currentYear: null,
        searchModel: null,
      };
    },

    mounted: function() {
      this.getUserData()
      const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
      const d = new Date();
      this.currentMonth = month[d.getMonth()]
      this.currentYear = d.getFullYear()

    },

    methods: {
      submitForm() {
        let formData = new FormData();
        formData.append("for_the_year", this.form.for_the_year);
        formData.append("for_the_period_from", this.form.for_the_period_from);
        formData.append("for_the_period_to", this.form.for_the_period_to);
        formData.append("tin", this.form.tin);
        formData.append("employee_name", this.form.employee_name);
        formData.append("rdo_code", this.form.rdo_code);
        formData.append("registered_address", this.form.registered_address);
        formData.append("registered_zip", this.form.registered_zip);
        formData.append("local_address", this.form.local_address);
        formData.append("local_zip", this.form.local_zip);
        formData.append("foreign_address", this.form.foreign_address);
        formData.append("date_of_birth", this.form.date_of_birth);
        formData.append("contact_number", this.form.contact_number);
        formData.append("statutory_min_wage_per_day", this.form.statutory_min_wage_per_day);
        formData.append("statutory_min_wage_per_month", this.form.statutory_min_wage_per_month);
        formData.append("present_employer_tin", this.form.present_employer_tin);
        formData.append("present_employer_name", this.form.present_employer_name);
        formData.append("present_employer_address", this.form.present_employer_address);
        formData.append("present_employer_zip", this.form.present_employer_zip);
        formData.append("employer_type", this.form.employer_type);
        formData.append("previous_employer_tin", this.form.previous_employer_tin);
        formData.append("previous_employer_name", this.form.previous_employer_name);
        formData.append("previous_employer_address", this.form.previous_employer_address);
        formData.append("previous_employer_zip", this.form.previous_employer_zip);
        formData.append("gross_income_from_present_employer", this.form.gross_income_from_present_employer);
        formData.append("less_non_taxable_compensation", this.form.less_non_taxable_compensation);
        formData.append("income_from_present_employer", this.form.income_from_present_employer);
        formData.append("taxable_income_from_present_employer", this.form.taxable_income_from_present_employer);
        formData.append("taxable_income_from_previous_employer", this.form.taxable_income_from_previous_employer);
        formData.append("tax_due", this.form.tax_due);
        formData.append("present_employer_taxes_withheld", this.form.present_employer_taxes_withheld);
        formData.append("previous_employer_taxes_withheld", this.form.previous_employer_taxes_withheld);
        formData.append("total_amount_taxes_withheld", this.form.total_amount_taxes_withheld);
        formData.append("tax_credit", this.form.tax_credit);
        formData.append("total_taxes_withheld", this.form.total_taxes_withheld);
        formData.append("non_taxable_basic_salary", this.form.non_taxable_basic_salary);
        formData.append("holiday_pay", this.form.holiday_pay);
        formData.append("overtime_pay", this.form.overtime_pay);
        formData.append("night_shift_diff", this.form.night_shift_diff);
        formData.append("hazard_pay", this.form.hazard_pay);
        formData.append("thirteenth_month_pay", this.form.thirteenth_month_pay);
        formData.append("de_minimis_benefits", this.form.de_minimis_benefits);
        formData.append("gov_contributions", this.form.gov_contributions);
        formData.append("salaries_and_others", this.form.salaries_and_others);
        formData.append("total_non_taxable_compensation", this.form.total_non_taxable_compensation);
        formData.append("taxable_basic_salary", this.form.taxable_basic_salary);
        formData.append("representation", this.form.representation);
        formData.append("transportation", this.form.transportation);
        formData.append("cost_of_living_allowance", this.form.cost_of_living_allowance);
        formData.append("fixed_housing_allowance", this.form.fixed_housing_allowance);
        formData.append("others_1", this.form.others_1);
        formData.append("others_2", this.form.others_2);
        formData.append("commission", this.form.commission);
        formData.append("profit_sharing", this.form.profit_sharing);
        formData.append("fees_and_director_fees", this.form.fees_and_director_fees);
        formData.append("taxable_13th_month", this.form.taxable_13th_month);
        formData.append("taxable_hazard_pay", this.form.taxable_hazard_pay);
        formData.append("taxable_overtime_pay", this.form.taxable_overtime_pay);
        formData.append("others_3", this.form.others_3);
        formData.append("others_4", this.form.others_4);
        formData.append("total_taxable_income", this.form.total_taxable_income);
        
        axios.create({
            baseURL: this.apiUrl,
            headers: { 'Authorization': `Bearer ${this.token}` }
        }).post('/create/form/2316', formData)
        .then((res) => {
            this.$q.notify({
              progress: true,
              message: `Form submitted successfully.`,
              color: 'green'
            }, 2000)
        }).catch((error) => {
          this.$q.notify({
              progress: true,
              message: `Something went wrong.`,
              color: 'red'
          }, 2000)
            console.log(error)
        });
      },

      isNumber: function(evt) {
          evt = (evt) ? evt : window.event;
          var charCode = (evt.which) ? evt.which : evt.keyCode;
          if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
              evt.preventDefault();
          } else {
              return true;
          }
      } ,

      formatFrom(input) {
          let value = input.replace(/[^\d]/g, "");
          if (value.length > 2) {
              value = value.slice(0, 2) + "/" + value.slice(2, 4);
          }
          this.form.for_the_period_from = value
          console.log(value)
      },

      formatTo(input) {
          let value = input.replace(/[^\d]/g, "");
          if (value.length > 2) {
              value = value.slice(0, 2) + "/" + value.slice(2, 4);
          }
          this.form.for_the_period_to = value
          console.log(value)
      },

      getUserData(){
        if(vueCookies.get('sgcchris').user.role_id === 1){
          this.isAdmin = true;
        }else{
          this.getEmployeeForm()
        }
      },

      getEmployeeForm(id){
        let formData = new FormData();
        if(id){
            formData.append("employee_id", id);
        }else{
            formData.append("employee_id", vueCookies.get('sgcchris').user.id);
        }

        axios.create({
            baseURL: this.apiUrl,
            headers: { 'Authorization': `Bearer ${this.token}` }
        }).post('/search/form-2316', formData)
        .then((res) => {
          console.log('getEmployeeForm success.')
          if (res.data) {
            Object.assign(this.form, res.data.data);
            console.log(this.form)
          }
        }).catch((error) => {
          this.$q.notify({
              progress: true,
              message: `Something went wrong.`,
              color: 'red'
          }, 2000)
            console.log(error)
        });
      },

      onSubmitSearch: function() {
        this.getEmployeeForm(this.searchModel)
      },
    },
  };
</script>
  
<style scoped>
  form {
    width: auto;
    padding: 20px;
    background: white;
    border-radius: 25px;
    display: inline-block;
    margin-left: 25%;
    margin-right: 25%;
    
  }
  table {
    margin: 20px 0;
    border-collapse: collapse;
    width: 100%;
  }
  th {
    background-color: #f2f2f2;
  }
  td {
    padding: 8px;
  }
</style>
  