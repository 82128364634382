<template>
   <div class="q-pa-md q-gutter-sm">
      <q-dialog v-model="thirteenthDialog">

      <q-card style="width: 700px; max-width: 80vw;">
         <q-card-section>
         <div class="text-h6">Date: Jan {{ thirteenthMonthInfo.year }}</div>
         <div class="text-subtitle2">Jan {{ thirteenthMonthInfo.year }} - Dec {{ thirteenthMonthInfo.year }}</div>
         </q-card-section>

         <q-card-section class="q-pt-none">
            <q-markup-table dense flat bordered separator="cell" >
               <tbody>
                 <tr>
                   <td class="text-right">Employee ID</td>
                   <td class="text-left">{{ thirteenthMonthInfo.employee_id }}</td>
                   <td class="text-right">Annual Salaries</td>
                   <td class="text-left">{{ Number(thirteenthMonthInfo.annual_salaries).toLocaleString() }}</td>
                 </tr>
                 <tr>
                   <td class="text-right">Employee Name</td>
                   <td class="text-left">{{ thirteenthMonthInfo.employee_name }}</td>
                   <td class="text-right">Net Gross</td>
                   <td class="text-left">{{ Number(thirteenthMonthInfo.net_gross).toLocaleString() }}</td>
                 </tr>
                 <tr>
                   <td class="text-right">Department</td>
                   <td class="text-left">{{ thirteenthMonthInfo.dept_id }}</td>
                   <td class="text-right">Thirteen Month</td>
                   <td class="text-left">{{ Number(thirteenthMonthInfo.thirteen_month).toLocaleString() }}</td>
                 </tr>
                 <tr>
                   <td class="text-right"></td>
                   <td class="text-left"></td>
                   <td class="text-right">Tardy</td>
                   <td class="text-left">{{ Number(thirteenthMonthInfo.tardy).toLocaleString() }}</td>
                 </tr>
                 <tr>
                   <td class="text-right"></td>
                   <td class="text-left"></td>
                   <td class="text-right">Deduction</td>
                   <td class="text-left">{{ Number(thirteenthMonthInfo.deduction).toLocaleString() }}</td>
                 </tr>
                 <tr>
                   <td class="text-right"></td>
                   <td class="text-left"></td>
                   <td class="text-right">Net Amount</td>
                   <td class="text-left">{{ Number(thirteenthMonthInfo.net_amount).toLocaleString() }}</td>
                 </tr>
               </tbody>
             </q-markup-table>
         </q-card-section>
      </q-card>
      <q-dialog v-model="spinner" persistent>
        <q-card class="my-card transparent no-shadow">
          <q-card-section>
            <q-spinner-gears color="cyan" size="5.5em" />
          </q-card-section>
        </q-card>
      </q-dialog>
      </q-dialog>
   </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
export default {
  data () {
    return {
      isCorrect:false,
      spinner:false,
      thirteenthDialog:false,
      thirteenthMonthInfo:{},
    }
  },

  created(){
   this.eventHub.$on('showthirteenthMonth', this.showthirteenthMonth);
  },

  methods:{

   showthirteenthMonth : function(payload){
      this.thirteenthDialog = payload.stat
      this.thirteenthMonthInfo = payload.thirteenthMonth;
   },
   }
}
</script>
