<template>
	<div class="q-pa-md">
		<div class="q-pa-md">
			<daykeep-calendar 
			event-ref="clickEvent"
			:event-array="mycalendar"
			:allow-editing="false"

			/>
		</div>

		<q-dialog v-model="spinner" persistent >
			<q-card class="my-card transparent no-shadow">
				<q-card-section >
					<q-spinner-gears color="cyan"  size="5.5em"/>
				</q-card-section>
			</q-card>
		</q-dialog>
	</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';
import  { DaykeepCalendar, } from '@daykeep/calendar-quasar'

export default {
	components: {
		'DaykeepCalendar': DaykeepCalendar,
	},
	data () {
		return {
			spinner:false,
			file:'',
			mycalendar:[]
		}
	},

	created(){
		this.displayCalendar()
		//this.eventHub.$on('MYCALENDAR', this.clickEvent);
	},

	methods: {

		clickEvent:function(payload){
			alert('test')
			console.log(payload)
		},



		displayCalendar: function() {
			let employee_id =  this.userData.employee_id
			//this.spinner = true
			let formData  = new FormData();
			formData.append("from", this.fromDay);
			formData.append("to", this.toDay);
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/schedule/mycalendar/${employee_id}`)
			.then((res) => {
				this.mycalendar = res.data.data;
				setTimeout(() => {
					this.spinner = false
				},500)
			})

		},

		filterByDate:function(){
			this.displaySchedule()
		}
	}
}
</script>
