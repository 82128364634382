<template>
	<div class="q-pa-md">
		<div class="q-pa-md">
			<div class="row">
				<div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
					<q-btn color="primary" label="Refresh" @click="getTKSummaryRequest"/>
					<q-btn color="negative" label="Approve All" @click="approveAll"/>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
			<q-table :dense="$q.screen.lt.md" :grid="$q.screen.xs" title="TK Summary" :data="generatedData" :columns="columns"
			:pagination.sync="pagination" row-key="id" separator="cell" selection="multiple"  :selected.sync="selectedRequest"
			no-data-label="I didn't find anything for you!" :loading="loading" :filter="filter" binary-state-sort>
			<template v-slot:body="props">
				<q-tr :props="props" style="cursor: pointer;">
					<q-td class="text-center">
					  <q-checkbox dense v-model="props.selected" />
					</q-td>
					<q-td key="action" :props="props">
						 <div>
							<q-btn outline style="color: goldenrod;" label="Pending" @click="selectPending(props.row)"/>
						</div>
					</q-td>
					<q-td key="empID" :props="props">{{ props.row.tk_summary_new.empID }}</q-td>
					<q-td key="name" :props="props">{{ props.row.tk_summary_new.name }}</q-td>
					<q-td key="REG_HOUR" :props="props">{{ props.row.tk_summary_new.REG_HOUR }}</q-td>
					<q-td key="UNDER_TIME" :props="props">{{ props.row.tk_summary_new.UNDER_TIME }}</q-td>
					<q-td key="ABSENT" :props="props">{{ props.row.tk_summary_new.ABSENT }}</q-td>
					<q-td key="OT" :props="props">{{ props.row.tk_summary_new.OT }}</q-td>
					<q-td key="RD" :props="props">{{ props.row.tk_summary_new.RD }}</q-td>
					<q-td key="OT_RD" :props="props">{{ props.row.tk_summary_new.OT_RD }}</q-td>
					<q-td key="SH" :props="props">{{ props.row.tk_summary_new.SH }}</q-td>
					<q-td key="OT_SH" :props="props">{{ props.row.tk_summary_new.OT_SH }}</q-td>
					<q-td key="SH_RD" :props="props">{{ props.row.tk_summary_new.SH_RD }}</q-td>
					<q-td key="OT_RD_SH" :props="props">{{ props.row.tk_summary_new.OT_RD_SH }}</q-td>
					<q-td key="LH" :props="props">{{ props.row.tk_summary_new.LH }}</q-td>
					<q-td key="OT_LH" :props="props">{{ props.row.tk_summary_new.OT_LH }}</q-td>
					<q-td key="LH_RD" :props="props">{{ props.row.tk_summary_new.LH_RD }}</q-td>
					<q-td key="OT_RD_LH" :props="props">{{ props.row.tk_summary_new.OT_RD_LH }}</q-td>
					<q-td key="ND" :props="props">{{ props.row.tk_summary_new.ND }}</q-td>
					<q-td key="OT_ND" :props="props">{{ props.row.tk_summary_new.OT_ND }}</q-td>
					<q-td key="ND_RD" :props="props">{{ props.row.tk_summary_new.ND_RD }}</q-td>
					<q-td key="OT_ND_RD" :props="props">{{ props.row.tk_summary_new.OT_ND_RD }}</q-td>
					<q-td key="ND_SH" :props="props">{{ props.row.tk_summary_new.ND_SH }}</q-td>
					<q-td key="OT_ND_SH" :props="props">{{ props.row.tk_summary_new.OT_ND_SH }}</q-td>
					<q-td key="ND_SH_RD" :props="props">{{ props.row.tk_summary_new.ND_SH_RD }}</q-td>
					<q-td key="ND_LH" :props="props">{{ props.row.tk_summary_new.ND_LH }}</q-td>
					<q-td key="ND_LH_RD" :props="props">{{ props.row.tk_summary_new.ND_LH_RD }}</q-td>
					<q-td key="OT_ND_RD_LH" :props="props">{{ props.row.tk_summary_new.OT_ND_RD_LH }}</q-td>
				</q-tr>
			</template>
			<template v-slot:no-data="">
	          <div class="full-width row flex-center text-red q-gutter-sm">
	            <i class="fas fa-exclamation-triangle"></i>
	            <span>The filter didn't uncover any results!</span>
	          </div>
	        </template>
			<template v-slot:loading>
	            <q-inner-loading showing>
	                <q-spinner-hourglass  size="50px" color="cyan" />
	            </q-inner-loading>
	        </template>
			<template v-slot:top>
			  <q-input dense v-model="filter" placeholder="Search Employee">
				<i class="fas fa-search" slot="append"></i>
			  </q-input>
			  <q-space />
			  <q-btn color="primary" label="Download Excel" style="margin-right: 10px;" no-caps @click="exportTable('xlsx')"/>
          	  <q-btn color="primary" label="Download CSV" no-caps @click="exportTable('csv')"/>
			</template>
		</q-table>
	</div>


	<PendingDialog></PendingDialog>
</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';
import { exportFile } from 'quasar'
import PendingDialog from './TKPendingRequestDialog';
function wrapCsvValue (val, formatFn) {
	let formatted = formatFn !== void 0 ? formatFn(val) : val

	formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

	formatted = formatted.split('"').join('""')
	return `"${formatted}"`
}
export default {
	components: {
      "PendingDialog": PendingDialog,
    },
	data () {
		return {
			filter: '',
	        loading: false,
			id:0,
			declineremarks:'',
	      pagination: {
	         page: 1,
	         rowsPerPage: 15,
	         rowsNumber: 10
	      },
			searchKey:'',
			yearfilter:new Date().getFullYear(),
	      yearOptions: [],
			separator:'cell',

			month:new Date().getMonth() + 1,
			cutoff: new Date().getDate() > 10 && new Date().getDate() < 26 ? 2 : 1,
			selectedRequest: [],
			generatedData:[],

			data: [],
			columns: [
				{ name:'action', align: 'center', label: 'Status', sortable: true, desc: 'Status' },
				{ name: 'empID', align: 'center', label: 'Employee ID', field: 'empID', sortable: true, desc: 'Employee ID' },
		        { name: 'name', align: 'center', label: 'Name', field: 'name', sortable: true, desc: 'Name' },

		        { name: 'REG_HOUR', align: 'center', label: 'Regular Hour/Days', field: 'REG_HOUR', sortable: true, desc: 'Regular Days' },
		        { name: 'UNDER_TIME', align: 'center', label: 'UNDERTIME', field: 'UNDER_TIME', sortable: true, desc: 'Undertime' },
		       // { name: 'TARDY', align: 'center', label: 'LATE', field: 'TARDY', sortable: true, desc: 'Tardy' },
		        { name: 'ABSENT', align: 'center', label: 'Absent', field: 'ABSENT', sortable: true, desc: 'Absent' },
		        { name: 'OT', align: 'center', label: 'Overtime', field: 'OT', sortable: true, desc: 'Overtime' },
		        { name: 'RD', align: 'center', label: 'Restday', field: 'RD', sortable: true, desc: 'Restday' },
		        { name: 'OT_RD', align: 'center', label: 'Restday-OT', field: 'OT_RD', sortable: true, desc: 'Restday,Overtime' },

		        { name: 'SH', align: 'center', label: 'Special Holiday', field: 'SH', sortable: true, desc: 'Special Holiday' },
		        { name: 'OT_SH', align: 'center', label: 'Special Holiday-OT', field: 'OT_SH', sortable: true, desc: 'Special Holiday, Overtime' },
		        { name: 'SH_RD', align: 'center', label: 'Special Holiday-RD', field: 'SH_RD', sortable: true, desc: 'Special Holiday, Restday' },
		        { name: 'OT_RD_SH', align: 'center', label: 'Special Holiday-OT-RD', field: 'OT_RD_SH', sortable: true, desc: 'Special Holiday,Overtime, Restday'},

		        { name: 'LH', align: 'center', label: 'Legal Holiday', field: 'LH', sortable: true, desc: 'Legal Holiday'},
		        { name: 'OT_LH', align: 'center', label: 'Legal Holiday-OT', field: 'OT_LH', sortable: true, desc: 'Legal Holiday,Overtime'},
		        { name: 'LH_RD', align: 'center', label: 'Legal Holiday-RD', field: 'LH_RD', sortable: true, desc: 'Legal Holiday, Restday'},
		        { name: 'OT_RD_LH', align: 'center', label: 'Legal Holiday-OT-RD', field: 'OT_RD_LH', sortable: true, desc: 'Legal Holiday,Overtime,Restday'},

		        { name: 'ND', align: 'center', label: 'Night Diff', field: 'ND', sortable: true, desc: 'Night Diff'},
		        { name: 'OT_ND', align: 'center', label: 'Night Diff-OT', field: 'OT_ND', sortable: true, desc: 'Night Diff, Overtime'},
		        { name: 'ND_RD', align: 'center', label: 'Night Diff-RD', field: 'ND_RD', sortable: true, desc: 'Night Diff, Restday'},
		        { name: 'OT_ND_RD', align: 'center', label: 'Night Diff-RD-OT', field: 'OT_ND_RD', sortable: true, desc: 'Night Diff, Restday,Overtime'},

		        { name: 'ND_SH', align: 'center', label: 'Night Diff-Special Holiday', field: 'ND_SH', sortable: true, desc: 'Night Diff, Special Holiday'},
		        { name: 'OT_ND_SH', align: 'center', label: 'Night Diff-Special Holiday-OT', field: 'OT_ND_SH', sortable: true, desc: 'Night Shift, Special Holiday,Overtime'},
		        { name: 'ND_SH_RD', align: 'center', label: 'Night Diff-Special Holiday-RD', field: 'ND_SH_RD', sortable: true, desc: 'Night Shift,Special Holiday,Restday' },

		        { name: 'ND_LH', align: 'center', label: 'Night Shift-Legal Holiday', field: 'ND_LH', sortable: true, desc: 'Night Shift, Legal Holiday' },
		        { name: 'ND_LH_RD', align: 'center', label: 'Night Shift-Legal Holiday-RD', field: 'ND_LH_RD', sortable: true, desc: 'Night Shift, Legal Holiday, Restday' },
		        { name: 'OT_ND_RD_LH', align: 'center', label: 'Night Shift-Legal Holiday-Restday-Overtime', field: 'OT_ND_RD_LH', sortable: true, desc: ' Night Shift, Legal Holiday,Restday, Overtime' },
			],
		}
	},

	created(){
		this.eventHub.$on('refreshPending', this.getTKSummaryRequest);
		this.getTKSummaryRequest()
	},


	methods: {

		approveAll: function() {

        if (this.selectedRequest.length == 0) {
          this.$q.notify({
            color: 'red-6',
            textColor: 'white',
            icon: 'fa fa-exclamation-circle',
            message: 'No Row Selected'
          });
        } else {
          this.loading = true;
          let allSelectedId = [];

          for (let eachID of this.selectedRequest) {
            allSelectedId.push(eachID.id);
          }

          let formData = new FormData();
          formData.append('selected_ids', JSON.stringify(allSelectedId));
          axios.create({
            baseURL: this.apiUrl,
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          }).post('/timekeeping/request/approved/all', formData).then((res) => {
            setTimeout(() => {
              this.loading = false;
              this.getTKSummaryRequest();
              this.$q.notify({
                color: 'green-7',
                textColor: 'white',
                icon: 'fa fa-signature',
                message: 'Processing Request Done'
              });
              this.selectedRequest = [];
            }, 1000);
          });

        }
      },

		getTKSummaryRequest: function(){
	      this.spinner = true;
	      let formData = new FormData();
	      axios.create({
	          baseURL: this.apiUrl,
	          headers: { 'Authorization': `Bearer ${this.token}` }
	        }).post(`/timekeeping/summary/request`, formData)
	        .then((res) => {
	          this.generatedData = res.data.data
	        });
	    },


		selectPending(obj) {
	      this.eventHub.$emit('selectPending', { stat: true, id: obj.id });
	    },


		yearsList : function(){
	        axios.create({
	            baseURL : this.apiUrl,
	            headers : {'Authorization' : `Bearer ${this.token}`}
	        }).post(`/timekeeping/years/list`)
	        .then((res) => {
	            this.yearOptions = res.data.data
	        });
	    },

		exportTable:function(fileExtension){

			const content = [ this.columns.map(col => wrapCsvValue(col.label)) ].concat(
			this.generatedData.map(row => this.columns.map(col => wrapCsvValue(
			typeof col.field === 'function'
			? col.field(row)
			: row[col.field === void 0 ? col.name : col.field],
			col.format
			)).join(','))
			).join('\r\n')

			const status = exportFile(
				`TKPending.${fileExtension}`,
				content,
				`text/${fileExtension}`
			);

			if (status !== true) {
				this.$q.notify({
					message: 'Browser denied file download...',
					color: 'negative',
					icon: 'warning'
				})
			}
		},
	}
}
</script>
<style lang="css" scoped>
.center{
	text-align:center
}
</style>
<style lang="sass" scoped>
	// .my-sticky-column-table
	// 	thead tr:nth-child(2) th:nth-child(2)
	// 		background-color: #fff
	// 	td:nth-child(2)
	// 		background-color: #c1f4cd
	// 	th:nth-child(2)
	// 	td:nth-child(2)
	// 		position: sticky
	// 		left: 0
	// 		z-index: 1
</style>
