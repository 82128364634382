<template>
  <div class="q-pa-md">
    <div class="q-pa-md">
      <div class="row">
        <div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
            <q-select v-model="yearfilter" :options="yearOptions" label="Year"  style="width: 200px"/>
          <q-select v-model="month" :options="monthOptions" label="Month" emit-value map-options option-value="id" option-label="name" style="width: 200px" @input="checkITimekeepingIsLock('TK')" />
          <q-select v-model="cutoff" :options="cutoffOptions" label="Cut-off" emit-value map-options option-value="id" option-label="name" style="width: 200px" @input="checkITimekeepingIsLock('TK')" />
          <q-select v-model="department" :options="departments" option-value="id" option-label="name" label="DEPARTMENT"  style="width: 200px"/>
          <!--<q-select
          filled
          v-model="modelMultiple"
          multiple
          :options="departments"
          use-chips
          stack-label
          option-value="id" option-label="name"
          label="Multiple selection"
        />-->
          <q-btn color="primary" label="Generate Data" @click="checkIfAlreadyGenerated" />
          <!-- <q-btn color="primary" label="Generate Data" @click="generateDTR" v-show="!isLock" />
          <q-btn color="green" label="Display Generated Data" @click="getDetailedTK" v-show="isLock" /> -->
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
      <q-table class="my-sticky-column-table" :dense="$q.screen.lt.md" :grid="$q.screen.xs" title="Detailed TK"
      :data="data"
      :columns="columns"
      row-key="id" separator="cell"
      no-data-label="I didn't find anything for you!"
      :pagination.sync="pagination"
      :loading="loading"
      :filter="filter"
      @request="onRequest"
      binary-state-sort>
        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th colspan="4" class="text-center">Information</q-th>
            <q-th colspan="2" class="text-center">Time In/Out</q-th>
            <q-th colspan="2" class="text-center">Tardiness</q-th>
            <q-th colspan="2" class="text-center">Absent</q-th>
            <q-th colspan="7" class="text-center">Holiday</q-th>
            <q-th colspan="8" class="text-center">Night Differential</q-th>
            <q-th colspan="17" class="text-center">Overtime</q-th>
            <q-th colspan="8" class="text-center">Leaves</q-th>
          </q-tr>
          <q-tr :props="props">
            <q-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
              <q-tooltip anchor="top middle" content-class="bg-red" :offset="[10, 10]">
                {{ col.desc }}
              </q-tooltip>
            </q-th>
          </q-tr>
        </template>
        <template v-slot:no-data="">
          <div class="full-width row flex-center text-red q-gutter-sm">
            <i class="fas fa-exclamation-triangle"></i>
            <span>The filter didn't uncover any results!</span>
          </div>
        </template>
        <template v-slot:loading>
            <q-inner-loading showing>
                <q-spinner-hourglass  size="50px" color="cyan" />
            </q-inner-loading>
        </template>
        <template v-slot:top>
          <q-input dense v-model="filter" placeholder="Search Employee">
            <i class="fas fa-search" slot="append"></i>
          </q-input>
          <q-space />
          <q-btn color="primary" label="Download Excel" style="margin-right: 10px;" no-caps @click="exportTable('xlsx')"/>
          <q-btn color="primary" label="Download CSV" no-caps @click="exportTable('csv')"/>
        </template>
      </q-table>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
import { exportFile } from 'quasar'

function wrapCsvValue(val, formatFn) {
  let formatted = formatFn !== void 0 ? formatFn(val) : val

  formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

  formatted = formatted.split('"').join('""')
  return `"${formatted}"`
}
export default {
  data() {
    return {
      modelMultiple:[{'id':0,'name':'ALL'}],
        filter: '',
        loading: false,
        pagination: {
            sortBy: 'empID',
            descending: false,
            page: 1,
            rowsPerPage: 15,
            rowsNumber: 10
        },
        department:{'id':0,'name':'ALL'},
        departments:[],
        yearfilter:new Date().getFullYear(),
        yearOptions: [],
        isLock: false,
        fromDay: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
        toDay: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
        month: new Date().getMonth() + 1,
        cutoff: 1,
        cutoffOptions: [{ id: 1, name: 'First Cut-off' }, { id: 2, name: 'Second Cut-off' }],

        monthOptions: [
            { id: 1, name: 'January' },
            { id: 2, name: 'February' },
            { id: 3, name: 'March' },
            { id: 4, name: 'April' },
            { id: 5, name: 'May' },
            { id: 6, name: 'June' },
            { id: 7, name: 'July' },
            { id: 8, name: 'August' },
            { id: 9, name: 'September' },
            { id: 10, name: 'October' },
            { id: 11, name: 'November' },
            { id: 12, name: 'December' },
        ],
      data: [],
      columns: [
        { name: 'empID', align: 'center', label: 'Employee ID', field: 'empID', sortable: true, desc: 'Employee ID' },
        { name: 'name', align: 'center', label: 'Name', field: 'name', sortable: true, desc: 'Name' },
        { name: 'date', align: 'center', label: 'Date', field: 'date', sortable: true, desc: 'Date' },
        { name: 'schedule', align: 'center', label: 'Schedule', field: row => row.schedule, sortable: true, desc: 'Schedule' },

        { name: 'ftimeLogs', align: 'center', label: 'Log-in', field: row => row.ftimeLogs, sortable: true, desc: 'Log-in' },
        { name: 'ltimeLogs', align: 'center', label: 'Log-out', field: row => row.ltimeLogs, sortable: true, desc: 'Log-out' },

        { name: 'TARDY', align: 'center', label: 'TARDY (hrs)', field: 'TARDY', sortable: true, desc: 'TARDY' },

        { name: 'UNDER_TIME', align: 'center', label: 'Under Time (hrs)', field: 'UNDER_TIME', sortable: true, desc: 'Undertime' },
        { name: 'REG_HOUR', align: 'center', label: 'Regular Hours (hrs)', field: 'REG_HOUR', sortable: true, desc: 'Regular Hours' },
        { name: 'ABSENT', align: 'center', label: 'Absent (hrs)', field: 'ABSENT', sortable: true, desc: 'Absent' },

        { name: 'RD', align: 'center', label: 'RD (hrs)', field: 'RD', sortable: true, desc: 'Working on Restday' },
        { name: 'SH', align: 'center', label: 'SH (hrs)', field: 'SH', sortable: true, desc: 'Special Holiday' },
        { name: 'SH_RD', align: 'center', label: 'SH-RD (hrs)', field: 'SH_RD', sortable: true, desc: 'Special Holiday, Restday' },
        { name: 'LH', align: 'center', label: 'LH (hrs)', field: 'LH', sortable: true, desc: 'Legal Holiday' },
        { name: 'LH_RD', align: 'center', label: 'LH-RD (hrs)', field: 'LH_RD', sortable: true, desc: 'Legal Holiday, Restday' },
        { name: 'DBL_LH', align: 'center', label: 'DBL-LH (hrs)', field: 'DBL_LH', sortable: true, desc: 'Double Legal Holiday' },
        { name: 'DBL_RD', align: 'center', label: 'DBL-RD (hrs)', field: 'DBL_RD', sortable: true, desc: 'Double Legal Holiday, Restday' },

        { name: 'ND', align: 'center', label: 'ND (hrs)', field: 'ND', sortable: true, desc: 'Night Shift' },
        { name: 'ND_RD', align: 'center', label: 'ND-RD (hrs)', field: 'ND_RD', sortable: true, desc: 'Night Shift, Restday' },
        { name: 'ND_SH', align: 'center', label: 'ND-SH (hrs)', field: 'ND_SH', sortable: true, desc: 'Night Shift, Special Holiday' },
        { name: 'ND_SH_RD', align: 'center', label: 'ND-SH-RD (hrs)', field: 'ND_SH_RD', sortable: true, desc: 'Night Shift,Special Holiday,Restday' },
        { name: 'ND_LH', align: 'center', label: 'ND-LH (hrs)', field: 'ND_LH', sortable: true, desc: 'Night Shift, Legal Holiday' },
        { name: 'ND_LH_RD', align: 'center', label: 'ND-LH-RD (hrs)', field: 'ND_LH_RD', sortable: true, desc: 'Night Shift, Legal Holiday, Restday' },
        { name: 'ND_DBL_LH', align: 'center', label: 'ND-DBL-LH (hrs)', field: 'ND_DBL_LH', sortable: true, desc: 'Night Shift, Double Legal Holiday' },
        { name: 'ND_RD_DBL_RH', align: 'center', label: 'ND-RD-DBL-RH (hrs)', field: 'ND_RD_DBL_RH', sortable: true, desc: 'Night Shift, Restday, Double Legal Holiday' },

        { name: 'OT', align: 'center', label: 'OT (hrs)', field: 'OT', sortable: true, desc: 'Overtime' },
        { name: 'OT_RD', align: 'center', label: 'OT-RD (hrs)', field: 'OT_RD', sortable: true, desc: 'Overtime, Restday' },
        { name: 'OT_SH', align: 'center', label: 'OT-SH (hrs)', field: 'OT_SH', sortable: true, desc: 'Overtime, Special Holiday' },
        { name: 'OT_RD_SH', align: 'center', label: 'OT-RD-SH (hrs)', field: 'OT_RD_SH', sortable: true, desc: 'Overtime, Restday, Special Holiday' },
        { name: 'OT_LH', align: 'center', label: 'OT-LH (hrs)', field: 'OT_LH', sortable: true, desc: 'Overtime,Legal Holiday' },
        { name: 'OT_RD_LH', align: 'center', label: 'OT-RD-LH (hrs)', field: 'OT_RD_LH', sortable: true, desc: 'Overtime,Restday, Legal Holiday' },
        { name: 'OT_DBL_LH', align: 'center', label: 'OT-DBL-LH (hrs)', field: 'OT_DBL_LH', sortable: true, desc: 'Overtime,Double Legal Holiday' },
        { name: 'OT_RD_DBL_LH', align: 'center', label: 'OT-RD-DBL-LH (hrs)', field: 'OT_RD_DBL_LH', sortable: true, desc: 'Overtime,Restday, Double Legal Holiday' },
        { name: 'OT_ND', align: 'center', label: 'OT-ND (hrs)', field: 'OT_ND', sortable: true, desc: 'Overtime, Night Shift' },
        { name: 'OT_ND_RD', align: 'center', label: 'OT-ND-RD (hrs)', field: 'OT_ND_RD', sortable: true, desc: 'Overtime, Night Shift, Restday' },
        { name: 'OT_ND_SH', align: 'center', label: 'OT-ND-SH (hrs)', field: 'OT_ND_SH', sortable: true, desc: 'Overtime, Night Shift, Special Holiday' },
        { name: 'OT_ND_RD_SH', align: 'center', label: 'OT-ND-RD-SH (hrs)', field: 'OT_ND_RD_SH', sortable: true, desc: 'Overtime, Night Shift, Restday, Special Holiday' },
        { name: 'OT_ND_LH', align: 'center', label: 'OT-ND-LH (hrs)', field: 'OT_ND_LH', sortable: true, desc: 'Overtime,Night Shift, Legal Holiday' },
        { name: 'OT_ND_RD_LH', align: 'center', label: 'OT-ND-RD-LH (hrs)', field: 'OT_ND_RD_LH', sortable: true, desc: 'Overtime, Night Shift, Restday, Legal Holiday' },
        { name: 'OT_ND_DBL_LH', align: 'center', label: 'OT-ND-DBL-LH (hrs)', field: 'OT_ND_DBL_LH', sortable: true, desc: 'Overtime, Night Shift, Double Legal Holiday' },
        { name: 'OT_ND_RD_DBL_LH', align: 'center', label: 'OT-ND-RD-DBL-LH (hrs)', field: 'OT_ND_RD_DBL_LH', sortable: true, desc: 'Overtime, Night Shift, Restday, Double Legal Holiday' },

        //leaves
        { name: 'VL', align: 'center', label: 'VL (days)', field: 'VL', sortable: true, desc: 'Vacation Leave' },
        { name: 'SL', align: 'center', label: 'SL (days)', field: 'SL', sortable: true, desc: 'Sick Leave' },
        { name: 'EL', align: 'center', label: 'EL (days)', field: 'EL', sortable: true, desc: 'Emergency Leave' },
        { name: 'OBT', align: 'center', label: 'OBT (days)', field: 'OBT', sortable: true, desc: 'On Business Trip' },
      ],
    }
  },

  created() {
    this.checkITimekeepingIsLock('TK')
    this.getDropdownProperties()
    this.yearsList()
  },

    mounted(){
        // this.onRequest({
        //     pagination: this.pagination,
        //     filter: ''
        // })
    },

  methods: {
    //start if server side
    onRequest (props) {

      const { page, rowsPerPage, sortBy, descending} = props.pagination


      const filter = props.filter
      this.loading = true
      setTimeout(() => {

        this.getRowsNumberCount(filter).then(total => {
            this.pagination.rowsNumber = total
        })
        const fetchCount = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage
        const startRow = (page - 1) * rowsPerPage
        this.fetchFromServer(startRow, fetchCount, filter, sortBy, descending)
        this.pagination.page = page
        this.pagination.rowsPerPage = rowsPerPage
        this.pagination.sortBy = sortBy
        this.pagination.descending = descending
        this.loading = false
      }, 1500)
    },

    fetchFromServer (startRow, count, filter, sortBy, descending) {
        axios.get(this.apiUrl + '/timekeeping/generate/detailed', {
            headers: { 'Authorization': `Bearer ${this.token}` },
            params: {
              department:this.department.id,
              year: this.yearfilter,
              month: this.month,
              cutoff: this.cutoff,
              start: startRow,
              countPerPage: count,
              filter: filter,
              sortBy: sortBy,
              descending: descending
            }
        }).then((response) => {
            this.data = response.data.data
            this.loading = false
        }).catch((error) => {
            console.log(error);
        })
   },

    async getRowsNumberCount (filter) {
       let response = await axios.get(this.apiUrl + '/timekeeping/generate/detailed/total', {
           headers: { 'Authorization': `Bearer ${this.token}` },
           params: {
             department:this.department.id,
             year: this.yearfilter,
             month: this.month,
             cutoff: this.cutoff,
             filter: filter,
           }
       })
       return response.data
    },

    ///end of server side
    checkIfAlreadyGenerated: function() {
      //console.log(this.department.id)
      let formData = new FormData();
      formData.append("year", this.yearfilter);
      formData.append("month", this.month);
      formData.append("cutoff", this.cutoff);
      formData.append("department", this.department.id);
      axios.create({
          baseURL: this.apiUrl,
          headers: { 'Authorization': `Bearer ${this.token}` }
        }).post(`/timekeeping/generate/checked`, formData)
        .then((res) => {
            console.log(res)
            if(parseInt(res.data.data) <= 0){
               this.generateDTR()
            }else{
               this.onRequest({
                   pagination: this.pagination,
                   filter: ''
               })
            }

        });
    },

    generateDTR: function() {
      this.loading = true
      let formData = new FormData();
      formData.append("year", this.yearfilter);
      formData.append("month", this.month);
      formData.append("cutoff", this.cutoff);
      formData.append("department", this.department.id);
      axios.create({
          baseURL: this.apiUrl,
          headers: { 'Authorization': `Bearer ${this.token}` }
        }).post(`/timekeeping/generate`, formData)
        .then((res) => {
           this.onRequest({
               pagination: this.pagination,
               filter: ''
           })
          this.loading = false
        });
    },

    yearsList : function(){
        axios.create({
            baseURL : this.apiUrl,
            headers : {'Authorization' : `Bearer ${this.token}`}
        }).post(`/timekeeping/years/list`)
        .then((res) => {
            this.yearOptions = res.data.data
        });
    },


    getDropdownProperties: function() {
      axios.create({
          baseURL: this.apiUrl,
          headers: { 'Authorization': `Bearer ${this.token}` }
      }).get('/employee/dropdown/properties')
      .then((res) => {
          this.departments = res.data.data.department

      });
    },

    exportTable: function(fileExtension) {

      const content = [this.columns.map(col => wrapCsvValue(col.label))].concat(
        this.data.map(row => this.columns.map(col => wrapCsvValue(
          typeof col.field === 'function' ?
          col.field(row) :
          row[col.field === void 0 ? col.name : col.field],
          col.format
        )).join(','))
      ).join('\r\n')

      const status =  exportFile(
				`DetailedTK.${fileExtension}`,
				content,
				`text/${fileExtension}`
			);

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        })
      }
    },


    checkITimekeepingIsLock: function(type) {
      axios.create({
          baseURL: this.apiUrl,
          headers: { 'Authorization': `Bearer ${this.token}` }
        }).get(`/schedule/islock/${this.month}/${this.cutoff}/${type}`)
        .then((res) => {
          if (res.data.status) {
            this.isLock = true
          } else {
            this.isLock = false
          }

        });
    },
  }
}
</script>
<style>
tr:hover {
  cursor: pointer;
  background-color: #f8f5f8;
}
</style>

<style lang="sass">
	// .my-sticky-column-table
	// 	thead tr:nth-child(3) th:nth-child(3)
	// 		background-color: #fff
	// 	td:nth-child(3)
	// 		background-color: #c1f4cd
	// 	th:nth-child(3)
	// 	td:nth-child(3)
	// 		position: sticky
	// 		left: 0
	// 		z-index: 1
</style>
