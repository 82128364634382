<template>
	<div class="q-pa-md">
		<q-form ref="vForm" @submit="onSubmit">
			<div class="row">
				<div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
					<q-input v-model="holidayname" label="Holiday" :rules="[formRules.required]" class="caps"/>
					<q-input v-model="holidaydate" mask="date" :rules="['date']"  label="Date">
						<template v-slot:append>
							<i class="far fa-calendar-alt cursor-pointer" >
								<q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
									<q-date v-model="holidaydate" @input="() => $refs.qDateProxy.hide()" />
									</q-popup-proxy>
								</i>
							</template>
						</q-input>
						<div class="q-gutter-sm">
							<q-radio  keep-color v-model="holidaytype" val="regular" label="Regular Holiday" :rules="[formRules.required]" @input="showLocation"/>
							<q-radio  keep-color v-model="holidaytype" val="special" label="Special Holiday" :rules="[formRules.required]"  @input="showLocation"/>
						</div>
						<q-select v-model="location" use-chips multiple :options="locOptions" :required="true" label="LOCATION"  v-show="isSpecial"/>

						<q-separator />
						<div class="q-pa-md q-gutter-sm">
							<q-btn  class="full-width" color="teal" type="submit" >Save</q-btn>
						</div>
					</div>
					<div class="col-9 col-lg-9 col-xs-12 col-md-6 q-mb-md q-pr-xl">
						<q-select v-model="selectedYear" :options="years" label="Year" @input="changeYear" />
						<div class="q-pa-md">
							<q-table
							title="Holidays List"
							:data="holidays"
							:columns="columns"
							row-key="id"
							:pagination.sync="pagination">
							<template v-slot:body="props">
								<q-tr slot="body"  :props="props" @click="selectedRow(props)" style="cursor: pointer;">
									<q-td key="holiday" :props="props">{{ props.row.holiday }}</q-td>
									<q-td key="holiday_date" :props="props">{{ props.row.holiday_date }}</q-td>
									<q-td key="holiday_type" :props="props">
										<q-chip outline :color="props.row.holiday_type == 'regular' ? 'green': 'blue'" text-color="white">
											{{ props.row.holiday_type.toUpperCase() }}
										</q-chip>
									</q-td>
									<q-td key="locations" :props="props">{{ props.row.locations }}</q-td>
									<q-td key="action" :props="props">
										<div >
											<q-btn round outline  color="red" @click="removeRow(props)">
												<i class="fas fa-trash"></i>
												<q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" color="red">
													<strong>Remove</strong>
												</q-tooltip>
											</q-btn>
										</div>
									</q-td>
								</q-tr>
							</template>
							<template v-slot:no-data="">
								<div class="full-width row flex-center text-red q-gutter-sm">
									<i class="fas fa-exclamation-triangle"></i>
									<span>
										No Result Found!
									</span>
								</div>
							</template>
						</q-table>
					</div>
				</div>

			</div>
		</q-form>

		<q-dialog v-model="spinner" persistent >
			<q-card class="my-card transparent no-shadow">
				<q-card-section >
					<q-spinner-gears color="cyan"  size="5.5em"/>
				</q-card-section>
			</q-card>
		</q-dialog>

		<q-dialog v-model="deleted" persistent>
			<q-card>
				<q-card-section class="row items-center">
					<span class="q-ml-sm">This file will permanently delete.</span>
				</q-card-section>
				<q-card-actions align="right">
					<q-btn flat label="Cancel" color="primary" v-close-popup />
					<q-btn flat label="Continue" color="primary" v-close-popup @click="deletionConfirmation"/>
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';


export default {

	data () {
		return {
			tempID:0,
			spinner:false,
			deleted:false,
			holidayname:'',
			holidaydate:'',
			holidaytype: 'regular',
			selectedYear: new Date().getFullYear(),
			holidays:[],
			columns: [
			{ name: 'holiday', align: 'center', label: 'Event', field: 'holiday', sortable: true },
			{ name: 'holiday_date', align: 'center', label: 'Date', field: 'holiday_date', sortable: true },
			{ name: 'holiday_type', align: 'center', label: 'Types', field: 'holiday_type' , sortable: true},
			{ name: 'locations', align: 'center', label: 'Locations', field: 'locations' , sortable: true},
			{ name: 'action', align: 'center', label: 'Action', field: '', sortable: true },
			],
			years: ['2019','2020', '2021', '2022', '2023', '2024','2025', '2026', '2027', '2028', '2029', '2030'],
			pagination: {
				rowsPerPage: 20
			},
			locOptions:[],
			location:[],
			isSpecial:false,
			isReq:false
		}
	},

	created(){
		this.displayHoliday()
		this.displayLocation()
	},

	methods: {

		onSubmit : function(){
			this.spinner = true;
			let formData = new FormData();

			formData.append("tempID", this.tempID);
			formData.append("holiday", this.holidayname);
			formData.append("holiday_date", this.holidaydate);
			formData.append("holiday_type", this.holidaytype);
			formData.append("location", this.location);
			//console.log(formData)
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/timekeeping/holiday',formData)
			.then((res) => {
				this.holidayname = ''
				this.holidaydate = ''
				this.holidaytype = ''
				this.location = []
				this.tempID = 0
				this.displayHoliday()
				this.$q.notify({
					message: 'Successfully added!',
					color: 'green',
					position:'center',
					actions: [
						{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
					]
				})
			});
		},

		displayHoliday: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/timekeeping/holiday/${this.selectedYear}`)
			.then((res) => {
				this.holidays = res.data.data;
				//console.log(this.holidays)
				setTimeout(() => {
					this.spinner = false
				},500)
			})

		},

		displayLocation: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/employee/dropdown/properties`)
			.then((res) => {
				this.locOptions = res.data.data.location;
				setTimeout(() => {
					this.spinner = false
				},500)
			})

		},

		showLocation:function(event){
			if(event == 'special'){
				this.isSpecial = true
				this.isReq = true
			}else{
				this.isSpecial = false
				this.isReq = false
				this.location = []
			}
			//console.log(this.holidaytype)
		},

		selectedRow:function(obj){
			this.holidayname = obj.row.holiday,
			this.holidaytype = obj.row.holiday_type,
			this.holidaydate = obj.row.holiday_date,
			this.tempID = obj.row.id
		},

		changeYear:function(year){
			this.selectedYear = year;
			this.displayHoliday()
		},

		removeRow:function(obj){
			this.deleted = true
			this.tempID = obj.row.id
		},

		deletionConfirmation:function(){
			this.spinner = true;
			let formData = new FormData();
			formData.append("tempID", this.tempID);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/timekeeping/holiday/delete',formData)
			.then((res) => {
				this.tempID = 0
				this.displayHoliday()
			});
		}

	}
}
</script>
