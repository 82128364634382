<template>
    <div class="q-gutter-sm q-pa-lg">
    	<div class="row">
    		<div class="row q-mb-md full-width">
		        <div class="full-width" style="height: 40px;">
		          	<h4 class="q-ma-none row inline h4cust">Announcement</h4>
		          	<q-btn color="green-10" class="float-right buttonCustom" @click="showAddModal">
	                  	<i class="fas fa-plus"></i>
	                  	<q-tooltip anchor="bottom middle" content-class="bg-green-10"  self="top middle" :offset="[10, 10]" color="green">
	                    	<strong>Add Announcement</strong>
	                  	</q-tooltip>
                	</q-btn>
		        </div>
		    </div>
	    </div>
	    <div class="q-pa-md row justify-center items-start q-gutter-xl">
	<!------ PUBLISHED ------>
	    	<q-card class="card colCustom col-4 col-md-6">
	    		<h5 class="margCustom caps text-grey-8" ><strong>Published</strong></h5>
		    	<div class="margCustom" v-for="item in pub" :key="item.id">
				    <q-card class="my-card">
				      <q-card-section horizontal>
				        <q-card-section class="q-pt-xs" style="width: 100%!important;">
				          <!-- <div class="text-overline">
				          	{{item.date_created}} by {{item.created_by}}
				          </div> -->
				          <div class="row">
					          <div class="text-h5 q-mt-sm q-mb-xs col-11" style="text-overflow: clip;">
					          	<strong>{{item.subject}}</strong>
					          	<span>
					          		<small> by {{item.created_by}}</small>
					          	</span>
					          </div>
					          <div class="col-1" v-if="item.status == 'new'">
					          	<q-badge class="absolute-top-right badgeNew caps" color="orange-10" text-color="white" v-html="item.status">
					          	</q-badge>
					          </div>
					      </div>
				          <div class="text-grey-8" v-if="item.full_message != ''" v-html="item.message" @click="readMoreModal(item)"></div>
			    		  <div class="text-grey-8" v-else v-html="item.message"></div>
			    		  <div class="text-grey-8" v-html="item.timeAgo"></div>


				        </q-card-section>
				      </q-card-section>

				      <q-separator />

					  <q-card-actions class="bg-orange-10 row text-white">
					  	<q-btn flat @click="showEditModal(item)">
					  		<q-tooltip anchor="bottom middle" content-class="bg-orange-10" self="top middle" :offset="[5, 5]" >
					            <strong>Update</strong>
					        </q-tooltip>
					  		<i class="fa fa-edit"></i>
					  	</q-btn>
					  	<q-btn flat @click="deleteAnnouncement(item)">
					  		<q-tooltip anchor="bottom middle" content-class="bg-orange-10" self="top middle" :offset="[5, 5]" >
					            <strong>Delete</strong>
					        </q-tooltip>
					  		<i class="fa fa-trash"></i>
					  	</q-btn>
					  	<q-btn flat @click="publishAnnouncement(item)">
				        	<q-tooltip anchor="bottom middle" content-class="bg-orange-10" self="top middle" :offset="[5, 5]">
					            <strong>Unpublish</strong>
					        </q-tooltip>
				        	<i class="fa fa-angle-double-up"></i>
				        </q-btn>
					  	<q-space />
				        <div class="text-grey-8" style="margin-top: 3px;" v-if="item.attachment != null">
		    		  	  <a class="aTag" href="#" @click="dlAttacment(item.attachmentLink,item.attachment)">
		    		  	  	<i class="fa fa-paperclip"></i>
		    		  	    Download Attachment
		    		  	  </a>
			    		</div>
				      </q-card-actions>
				    </q-card>
				</div>
			</q-card>
	<!------ NOT PUBLISHED ------>
			<q-card class="card col-4 col-md-6 colCustom">
				<h5 class="margCustom caps text-grey-8"><strong>Not Published</strong></h5>
		    	<div class="margCustom" v-for="item in nPub" :key="item.id">
				    <q-card class="my-card">
				      <q-card-section horizontal>
				        <q-card-section class="q-pt-xs">
				          <!-- <div class="text-overline">
				          	{{item.date_created}} by {{item.created_by}}
				          </div> -->
				          <div class="text-h5 q-mt-sm q-mb-xs">
				          	<strong>{{item.subject}}</strong>
				          	<span>
				          		<small> by {{item.created_by}}</small>
				          	</span>
				          </div>
				          <div class="text-grey-8" v-if="item.full_message != ''" v-html="item.message" @click="readMoreModal(item)"></div>
			    		  <div class="text-grey-8" v-else v-html="item.message"></div>
			    		  <!-- <div class="text-grey-8" v-html="item.timeAgo"></div> -->

				        </q-card-section>
				      </q-card-section>

				      <q-separator />

					  <q-card-actions class="bg-orange-10 row text-white">
					  	<q-btn flat @click="showEditModal(item)">
					  		<q-tooltip anchor="bottom middle" content-class="bg-orange-10" self="top middle" :offset="[5, 5]" >
					            <strong>Update</strong>
					        </q-tooltip>
					  		<i class="fa fa-edit"></i>
					  	</q-btn>
					  	<q-btn flat @click="deleteAnnouncement(item)">
					  		<q-tooltip anchor="bottom middle" content-class="bg-orange-10" self="top middle" :offset="[5, 5]" >
					            <strong>Delete</strong>
					        </q-tooltip>
					  		<i class="fa fa-trash"></i>
					  	</q-btn>
					  	<q-btn flat @click="publishAnnouncement(item)">
				        	<q-tooltip anchor="bottom middle" content-class="bg-orange-10" self="top middle" :offset="[5, 5]">
					            <strong>Publish</strong>
					        </q-tooltip>
				        	<i class="fa fa-angle-double-down"></i>
				        </q-btn>
					  	<q-space />
					  	<q-space />
				        <div class="text-grey-8"  v-if="item.attachment != null" style="margin-top: 3px;">
		    		  	  <a class="aTag" href="#" @click="dlAttacment(item.attachmentLink,item.attachment)">
		    		  	  	<i class="fa fa-paperclip"></i>
		    		  	    Download attachment
		    		  	  </a>
			    		</div>
				      </q-card-actions>

				    </q-card>
				</div>
			</q-card>
		</div>

		<q-dialog v-model="deleteDialog" persistent transition-show="scale" transition-hide="scale">
	      <q-card class="q-bottom-sheet--list">
	        <q-form ref="vForm">
	          <q-card-section class="row items-center">
	          	<!-- <q-btn  flat round dense v-close-popup><i class="fas fa-times"></i></q-btn> -->
	            <div class="text-h6">Are you sure you want to delete this announcement?</div>
	            <q-space />

	          </q-card-section>
	          <q-card-actions align="right">
	            <div>
	              <q-btn flat label="Yes"  @click="startDeleteAnnouncement" color="primary"/>
	              <q-btn flat label="No" color="danger" v-close-popup/>
	            </div>
	          </q-card-actions>
	        </q-form>
	      </q-card>
	    </q-dialog>




		<EditModal></EditModal>
		<AddModal></AddModal>
		<ReadMoreModal></ReadMoreModal>
		<!-- <ChatModal></ChatModal> -->
	</div>
</template>



<script>
	import axios from 'axios';
	import mixins from '../../mixins';
	import EditModal from './AdminEditAnnouncementModal';
	import AddModal from './AdminAddAnnouncementModal';
	import ReadMoreModal from './AdminReadMore';
	// import ChatModal from './AdminChatModal';

	export default {
		components:{
			"EditModal" : EditModal,
			"AddModal": AddModal,
			"ReadMoreModal": ReadMoreModal,
			// "ChatModal": ChatModal,
		},

		data() {
			return {
				myeditmodal:true,
				myaddmodal:true,
				mychatmodal:true,
				data:[],
				message: "hello world",
				showMessage: true,
				deleteDialog: false,
				publishModel: [],
				id: '',
				isPub:'',
				fullMsg: '',
				pub: [],
				nPub: [],
				chattext: '',
				chat: [],
			}
		},

		created(){
			this.eventHub.$on('updatedAnnouncement', this.displayData);
			this.eventHub.$on('addAnnouncement', this.displayData);
			this.eventHub.$on('readMoreAnnouncement', this.displayData);
			// this.eventHub.$on('showChatModal', this.displayData);
			this.displayData();

		},

		methods: {
			displayData: function() {
				axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
				.get(`getAnnouncementList`)
				.then((res) => {
					this.pub = res.data.data.pub;
					this.nPub = res.data.data.nPub;
					//this.xPub = res.data.isPub.xPub;
					// console.log(res.data.data)
					//this.data = res.data.data;

				})
			},
			readMoreModal: function(obj){
				this.eventHub.$emit('openReadMoreModal', { stat: true,obj: obj });
			},
			showEditModal: function(obj){
				this.eventHub.$emit('openEditModal', { stat: true,obj: obj });
			},
			showAddModal: function(){
				this.eventHub.$emit('openAddModal', { stat: true });
			},
			// showChat: function(obj){
			// 	this.eventHub.$emit('openChat', { stat: true});
			// },
			deleteAnnouncement: function(obj){
				this.deleteDialog = true;
    			this.id  = obj.id;
			},

			startDeleteAnnouncement(){
			    let formData = new FormData();
			    formData.append("id", this.id);
			    axios.create({
			      baseURL : this.apiUrl,
			      headers : {'Authorization' : `Bearer ${this.token}`}
			    }).post('/announcement/delete',formData)
			    .then((res) => {
                    this.displayData()
                    this.deleteDialog = false
			    	//this.data = res.data.data;
			    	//console.log(res.data.data)
			    });

			},
			publishAnnouncement: function(obj){
				console.log("obj: ", obj)

				this.id  = obj.id;
				this.isPub = obj.is_publish;
				let formData = new FormData();

			    formData.append("id", this.id);
			     formData.append("is_publish", this.isPub);

			    axios.create({
			      baseURL : this.apiUrl,
			      headers : {'Authorization' : `Bearer ${this.token}`}
			    }).post('/announcement/publish',formData)
			    .then((res) => {
			    	this.displayData();
			    	console.log(res.data)
			    });
			},

		  	dlAttacment: function(url,filename) {
			  	//alert(filename)
			    axios({
			      url: url,
			      method: 'GET',
			      responseType: 'arraybuffer',
			    }).then((response) => {
			      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
			      var fileLink = document.createElement('a');
			      fileLink.href = fileURL;
			      fileLink.setAttribute('download', filename);
			      document.body.appendChild(fileLink);

			      fileLink.click();
			    });
			},

		}
	};

</script>
<style>
	h4,h5 {
		margin:0;
	}
	.pcustom{
		font-style: italic;
	}
	.btnCustom {
		margin: 0 3px;
	}
	.q-hr {
		margin: 15px 0 0 0;
	}
	.btnCustom1 {
		position: relative;
		top: 20%;
	}
	.colCustom {
		width: 45%!important;
		padding-bottom: 25px;
	}
	@media (pointer:coarse) {
	  .colCustom {
	    width: 100%!important;
		padding-bottom: 25px;
	  }
	  .h4cust {
	  	font-size: 1.7rem;
		}
	}
	.margCustom {
		margin: 15px 25px 5px 25px;
	}
	.badgeNew {
		padding: 5px;
	    margin: 13px 13px 0 0;
	    font-size: 14px;
	}
	.q-menu {
		/*top: 40%!important;
		left: 77.7%!important;
		min-height: 500px!important;*/
    	min-height: 150px!important;
	}
	.aTag {
	  color: white;
	}

</style>
