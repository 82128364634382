<template>
	<q-dialog v-model="myeditmodal">
		 <q-card class="q-bottom-sheet--list">
		   <q-card-section>
			 <div class="text-h6">Edit Announcement</div>
		   </q-card-section>

		   <q-card-section>
			   <div class="col-12 q-mb-md">
				   <!-- START:: 11-03-2020 NICELE 11:36AM -->
				   <q-input label="Subject" v-model="subject"  ref="subj" :rules="[val => !!val || 'Subject is required']"/>
				   <q-input label="Body" v-if="fullMesg != ''" ref="message" v-model="fullMesg" type="textarea" :rules="[val => !!val || 'Body is required']"/>
				   <q-input label="Body" v-else v-model="message" ref="message" type="textarea" :rules="[val => !!val || 'Body is required']"/>

				   <q-file v-model="file" id="file" label="Pick one file" filled ref="file" accept="image/*,.pdf" :rules="[val => !!val || 'Attachment is required']" />

				   <!-- data return : file : null -->
				   <!-- END:: 11-03-2020 NICELE 11:36AM -->
			   </div>
		   </q-card-section>

		   <q-card-actions align="right" class="text-primary">
			   <q-btn flat label="Update" type="submit" @click="updateAnnouncement"/>
			   <q-btn flat label="Close" v-close-popup />
		   </q-card-actions>
		 </q-card>
	   </q-dialog>
</template>

<script>
   import axios from 'axios';
   import mixins from '../../mixins';
export default {
   data () {
	   return {
		 myeditmodal: false,
		 id: '',
		 subject : '',
		 message: '',
		 attachment: '',
		 dateCreated: '',
		 created_by: '',
		 fullMesg: '',
		 file: null,
		 text: '',
		 dense: false,
		 selectedFile: null
	   }
   },

   created() {
	   this.eventHub.$on('openEditModal', this.openEditModal);
   },

   methods: {
	   openEditModal:function(props){
		   this.myeditmodal = props.stat;
		   this.id = props.obj.id;
		   this.subject = props.obj.subject;
		   this.message = props.obj.message;
		   this.attachment = props.obj.attachment;
		   this.dateCreated = props.obj.date_created;
		   this.created_by = props.obj.created_by;
		   this.fullMesg = props.obj.full_message;
		   //this.file = props.obj.attachment;
		   console.log(props)
	   },
	   pickFile() {
		   this.file = this.$refs.file.files[0];
	   },

	   onUpload(){
		   let formData = new FormData();
		   formData.append("file", this.file, this.file.name);
		   axios.post('/announcement/file',formData)
		   .then(res => {
			   console.log("res", res);
		   });
	   },

	   updateAnnouncement:function(){
		   this.spinner=true;
		   let formData = new FormData();
		   // START:: 11-03-2020 NICELE 11:36AM
		   this.$refs.subj.validate()
		   this.$refs.message.validate()
		   this.$refs.file.validate()

		   if (this.$refs.subj.hasError || this.$refs.message.hasError || this.$refs.file.hasError) {
			   this.formHasError = true
		   }else {
			   formData.append("id", this.id);
			   formData.append("subject", this.subject);

			   if(this.fullMesg == ''){
				   formData.append("fullMesg", this.message);
			   }else{
				   formData.append("fullMesg", this.fullMesg);
			   }
			   formData.append("file", this.file);
			   console.log("this.file: ",this.file);
			   axios.create({
				   baseURL : this.apiUrl,
				   headers : {'Authorization' : `Bearer ${this.token}`}
			   }).post('/announcement/update',formData)
			   .then((res) => {
				   console.log(res.data);
				   this.myeditmodal = false;
				   this.eventHub.$emit('updatedAnnouncement');

				   this.$q.notify({
					 position: 'center',
					 color: 'positive',
					 message: 'Updated'
				   })
			   });
		   }
		   // END:: 11-02-2020 NICELE 6:33PM
	   },

   }

}
</script>
