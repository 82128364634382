<template>
	<div class="q-pa-md">
		<div class="q-pa-md">
			<div class="row">
				<div class="row justify q-banner--top-padding">
					<q-btn outline color="green" label="Download Template"  @click="getTemplate()"/>
					<q-btn outline color="red" label="Upload Binary" @click="importEmployees"/>
				</div>
				<div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
					<q-select v-model="month" :options="monthOptions" label="Month" emit-value map-options option-value="id" option-label="name" style="width: 200px"/>
					<q-select v-model="cutoff" :options="cutoffOptions" label="Cut-off" emit-value map-options option-value="id" option-label="name" style="width: 200px"/>
                    <q-select v-model="department" :options="departments" option-value="id" option-label="name"  label="DEPARTMENT" style="width: 200px" />
                    <q-btn color="primary" label="Filter" @click="filterByDate"/>
				</div>
			</div>
		</div>
		<div class="q-pa-md">
			<!-- <q-table
			title="Binary Schedule"
			dense
			:data="schdules"
			:columns="rangeHeader"
			row-key="id"
			:pagination.sync="pagination"
			:separator="separator"
			/> -->
            <q-table
				:dense="$q.screen.lt.md"
				title="SET SCHEDULE"
				:columns="rangeHeader"
				:data="schdules"
				row-key="id"
				:separator="separator"
				:pagination.sync="pagination">
				<template v-slot:header="props">
		        <q-tr :props="props">
			        <q-th v-for="col in props.cols" :key="col.name" :props="props" > <!-- :colspan="col.span" -->
			            {{ col.label }}
			         </q-th>
		        </q-tr>
		      </template>
				<template v-slot:body="props">
					<q-tr slot="body"  :props="props"  style="cursor: pointer;">
						<q-td class="text-center">{{ props.row.employee_id }}</q-td>
						<q-td class="text-center">{{ props.row.name }}</q-td>
						<q-td v-for="sched in props.row.schedule" :key="sched.id"  class="text-center caps">
                            {{ sched.type }}
						</q-td>
					</q-tr>
				</template>
				<template v-slot:no-data="">
					<div class="full-width row flex-center text-red q-gutter-sm">
						<i class="fas fa-exclamation-triangle"></i>
						<span>
							No Result Found!
						</span>
					</div>
				</template>
				<template v-slot:top="props">
					<q-input dense v-model="searchKey" placeholder="Search Employee" class="caps">
						<i class="fas fa-search" slot="append"></i>
					</q-input>
					<q-space />
					<q-btn outline color="primary" label="Download" no-caps />
				</template>
			</q-table>
		</div>

		<q-dialog v-model="importfile" persistent transition-show="scale" transition-hide="scale">
			<q-card class="q-bottom-sheet--list">
				<q-form ref="form" autocomplete="off" @submit="onSubmit">
					<q-card-section>
						<div class="text-h6">Select File</div>
					</q-card-section>
					<q-card-section>
						<small>(.csv Only)</small><br/>
						<input type="file" id="file" ref="file" accept=".csv" :rules="[ val => val && val.length > 0 || 'This field is required!']"  v-on:change="handleFileUpload()"/>

					</q-card-section>
					<q-card-actions align="right">
						<div>
							<q-btn flat label="Submit" type="submit" color="primary"/>
							<q-btn flat label="Close" color="primary" @click="closeModal"/>
						</div>
					</q-card-actions>
				</q-form>
			</q-card>
		</q-dialog>

		<q-dialog v-model="spinner" persistent >
			<q-card class="my-card transparent no-shadow">
				<q-card-section >
					<q-spinner-gears color="cyan"  size="5.5em"/>
				</q-card-section>
			</q-card>
		</q-dialog>
	</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';
export default {
	data () {
		return {
            searchKey:'',
            rangeHeader:[],
            schdules:[],
            departments: [],
            department:{'id':0,'name':'ALL'},
			separator:'cell',
			fromDay: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
			toDay: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
			importfile:false,
			spinner:false,
			file:'',
			pagination: {
				rowsPerPage: 30
			},
			month:new Date().getMonth() + 1,
			cutoff: new Date().getDate() > 10 && new Date().getDate() < 26 ? 2 : 1,
			cutoffOptions:[{id:1,name:'First Cut-off'},{id:2,name:'Second Cut-off'}],
			monthOptions:[
			{id:1,name:'January'},
			{id:2,name:'February'},
			{id:3,name:'March'},
			{id:4,name:'April'},
			{id:5,name:'May'},
			{id:6,name:'June'},
			{id:7,name:'July'},
			{id:8,name:'August'},
			{id:9,name:'September'},
			{id:10,name:'October'},
			{id:11,name:'November'},
			{id:12,name:'December'},],
		}
	},
	created(){
		this.displaySchedule()
        this.getDropdownProperties()
	},
	methods: {
		importEmployees:function(){
			this.importfile = true
		},
		closeModal:function(){
			this.importfile = false
		},
		onSubmit:function(){
			this.spinner = true
			let formData  = new FormData();
			formData.append("files", this.file);
			axios({
				onUploadProgress: uploadEvent => {
					console.log("Progress: " + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
				},
				headers : {'Authorization' : `Bearer ${this.token}`},
				url:this.apiUrl+'/binary/schedule/upload',
				data: formData,
				method: 'POST'
			})
			.then(response => {
				setTimeout(() => {
					this.spinner = false
					this.importfile = false
				},500)
			}).catch((error) => {
				console.log(error.response)
			})
		},
		handleFileUpload(){
			this.file = this.$refs.file.files[0];
		},
        displaySchedule: function() {
			//this.spinner  = true
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/binary/schedule/display/${this.month}/${this.cutoff}/${this.department.id}`)
			.then((res) => {
				this.rangeHeader = res.data.ranges
				this.schdules = res.data.members
				//this.initData = res.data.members
				//this.spinner  = false
			})
		},
		filterByDate:function(){
			this.displaySchedule()
		},
        getDropdownProperties: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: { 'Authorization': `Bearer ${this.token}` }
			}).get('/employee/dropdown/properties')
			.then((res) => {
				this.departments = res.data.data.department
			});
		},
		getTemplate: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get('/employee/template/download')
			.then((res) => {
				this.downloadTemplate(res.data.data.binary_template)
			})
		},
		downloadTemplate:function(url){
			axios({
				url: this.apiUrl+'/'+url,
				method: 'GET',
				responseType: 'arraybuffer',
			}).then((response) => {
				var fileURL = window.URL.createObjectURL(new Blob([response.data]));
				var fileLink = document.createElement('a');
				fileLink.href = fileURL;
				fileLink.setAttribute('download', 'Binary_Schedule_Template.csv');
				document.body.appendChild(fileLink);
				fileLink.click();
			});
		},
	}
}
</script>
