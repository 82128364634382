<template>
	<div class="q-pa-md">
		<div class="q-pa-md">
			<div class="row">
				<div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
					<q-select v-model="yearfilter" :options="yearOptions" label="Year"  style="width: 200px"/>
					<q-btn color="primary" label="Filter" @click="filterData"/>
					<q-btn color="primary" label="Create" @click="createCutOff"/>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
			<q-table
			:dense="$q.screen.lt.md"
			:grid="$q.screen.xs"
			title="Tax Table"
			:data="generatedData"
			:columns="columns"
			:pagination="initialPagination"
			row-key="id">
			<template v-slot:body="props">
				<q-tr slot="body"  :props="props" @click="selectedRow(props)" style="cursor: pointer;">
                    <q-td key="year" :props="props">{{ props.row.year }}</q-td>
					<q-td key="type" :props="props">{{ props.row.type }}</q-td>
					<q-td key="range_from" :props="props">{{ props.row.range_from }}</q-td>
					<q-td key="range_to" :props="props">{{ props.row.range_to }}</q-td>
					<q-td key="percent_rate" :props="props">{{ props.row.percent_rate }}</q-td>
					<q-td key="fixed_tax" :props="props">{{ props.row.fixed_tax }}</q-td>
					<q-td key="comp_level" :props="props">{{ props.row.comp_level }}</q-td>
				</q-tr>
			</template>
			<template v-slot:no-data="">
				<div class="full-width row flex-center text-red q-gutter-sm">
					<i class="fas fa-exclamation-triangle"></i>
					<span>
						No Result Found!
					</span>
				</div>
			</template>
			<template v-slot:top-right>
				<q-btn color="primary" label="Download Excel" style="margin-right: 10px;" no-caps @click="exportTable('xlsx')"/>
            	<q-btn color="primary" label="Download CSV" no-caps @click="exportTable('csv')"/>
			</template>
		</q-table>
	</div>

	<q-dialog v-model="spinner" persistent >
		<q-card class="my-card transparent no-shadow">
			<q-card-section >
				<q-spinner-gears color="cyan"  size="5.5em"/>
			</q-card-section>
		</q-card>
	</q-dialog>

	<q-dialog v-model="addDialog" persistent transition-show="scale">
      	<q-card style="width: 400px">
        	<q-card-section>
          		<div class="text-h6">Create Tax Table</div>
				<p style="color:red">Note: Kindly use "Below" and "Above" in minimum and maximum value</p>
        	</q-card-section>
        	<q-separator />
        	<q-card-section>
				<div class="q-pa-md" >
					<q-form @submit="onSubmit" class="q-gutter-md">
                    <q-input filled v-model="year" label="Year"/>
					<q-select filled v-model="type" :options="typeOptions" label="Type"/>
					<q-input filled v-model="range_from" label="Range From" lazy-rules :rules="[ val => val && val.length > 0 || 'Required']"/>
					<q-input filled v-model="range_to" label="Range To" lazy-rules :rules="[ val => val && val.length > 0 || 'Required']"/>
                    <q-input filled v-model="percent_rate" label="Rate" lazy-rules :rules="[ val => val && val.length > 0 || 'Required']"/>
					<q-input filled v-model="fixed_tax" label="Fixed Tax" lazy-rules :rules="[ val => val && val.length > 0 || 'Required']"/>
					<q-input filled v-model="comp_level" label="Compensation Level" lazy-rules :rules="[ val => val && val.length > 0 || 'Required']"/>
					<div>
						<q-btn label="Submit" type="submit" flat color="primary"/>
						<q-btn label="Close" type="reset" color="primary" flat class="q-ml-sm" v-close-popup/>
				  	</div>
					</q-form>
				</div>
			</q-card-section>
        	<q-separator />
      	</q-card>
    </q-dialog>
</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';
import { exportFile } from 'quasar'
function wrapCsvValue (val, formatFn) {
	let formatted = formatFn !== void 0 ? formatFn(val) : val

	formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

	formatted = formatted.split('"').join('""')
	return `"${formatted}"`
}
export default {
	data () {
		return {
			tempID:0,
			initialPagination: {rowsPerPage: 40},
			addDialog: false,
			yearfilter:new Date().getFullYear(),

			year:new Date().getFullYear(),
			type:'',
            range_from:'',
			range_to:'',
			percent_rate:'',
			fixed_tax:'',
			comp_level:'',

			typeOptions:['DAILY','WEEKLY','SEMI-MONTHLY','MONTHLY'],
			yearOptions: [],
			separator:'cell',
			spinner:false,
			generatedData:[],

			columns: [
            { name: 'year', align: 'center', label: 'Years', field: 'year', sortable: true},
			{ name: 'type', align: 'center', label: 'Type', field: 'type', sortable: true},
			{ name: 'range_from', align: 'center', label: 'Range From', field: 'range_from', sortable: true},
			{ name: 'range_to', align: 'center', label: 'Range To', field: 'range_to', sortable: true},
			{ name: 'percent_rate', align: 'center', label: 'Rate', field: 'percent_rate' , sortable: true},
			{ name: 'fixed_tax', align: 'center', label: 'Fixed Tax', field: 'fixed_tax' , sortable: true},
			{ name: 'comp_level', align: 'center', label: 'Compensation Level', field: 'comp_level' , sortable: true},
			],
		}
	},

	created(){
		this.filterData()
		this.yearsList()
	},

	methods: {
		onSubmit () {
			let formData = new FormData();
			formData.append("tempID", this.tempID);
            formData.append("year", this.year);
			formData.append("type", this.type);
			formData.append("range_from", this.range_from);
			formData.append("range_to", this.range_to);
			formData.append("percent_rate", this.percent_rate);
			formData.append("fixed_tax", this.fixed_tax);
			formData.append("comp_level", this.comp_level);

			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/backend/tax/set',formData)
			.then((res) => {
				this.tempID = 0
				this.onReset()
				this.filterData()
				this.$q.notify({
		          color: 'green-9',
		          textColor: 'white',
		          message: 'Submitted'
		        })
				this.addDialog = false
			});

    	},

		onReset () {
			this.type = ''
            this.range_from = ''
			this.range_to = ''
            this.percent_rate = ''
			this.fixed_tax = ''
			this.comp_level = ''
			this.tempID = 0
	    },

		createCutOff: function(){
            this.onReset()
			this.addDialog = true
		},

		selectedRow:function(obj){
            this.tempID = obj.row.id
            this.year = obj.row.year
			this.type = obj.row.type
			this.range_from = obj.row.range_from
			this.range_to = obj.row.range_to
            this.percent_rate = obj.row.percent_rate
			this.fixed_tax = obj.row.fixed_tax
			this.comp_level = obj.row.comp_level
			this.addDialog = true
		},

		filterData : function(){
			this.spinner = true;
			let formData = new FormData();
			formData.append("year", this.yearfilter);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post(`/backend/tax/table`,formData)
			.then((res) => {
				//console.log(res.data)
				this.generatedData = res.data.data
				this.spinner = false
			});
		},

		yearsList : function(){
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post(`/timekeeping/years/list`)
			.then((res) => {
				this.yearOptions = res.data.data
			});
		},

		exportTable:function(fileExtension){

			const content = [ this.columns.map(col => wrapCsvValue(col.label)) ].concat(
			this.generatedData.map(row => this.columns.map(col => wrapCsvValue(
			typeof col.field === 'function'
			? col.field(row)
			: row[col.field === void 0 ? col.name : col.field],
			col.format
			)).join(','))
			).join('\r\n')

			const status =  exportFile(
				`SSSTable.${fileExtension}`,
				content,
				`text/${fileExtension}`
			);

			if (status !== true) {
				this.$q.notify({
					message: 'Browser denied file download...',
					color: 'negative',
					icon: 'warning'
				})
			}
		},
	}
}
</script>
<style lang="css" scoped>
.center{
	text-align:center
}
</style>
<style lang="sass" scoped>
	.my-sticky-column-table
		thead tr:nth-child(2) th:nth-child(2)
			background-color: #fff
		td:nth-child(2)
			background-color: #c1f4cd
		th:nth-child(2)
		td:nth-child(2)
			position: sticky
			left: 0
			z-index: 1
</style>
