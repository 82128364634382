<template>
    <div class="q-gutter-sm q-pa-lg">
    	<div class="row">
    		<div class="row full-width">
		        <div class="full-width">
		          	<h4 class="q-ma-none row inline h4cust">List of Annoucements</h4>
		        </div>
		    </div>
	    </div>
	    <div class="q-pa-md row justify-center items-start">
	    	<q-card class="card col-12 bg-grey-2">
	    		<h5 class="margCustom caps text-grey-8" ><strong>Announcements</strong></h5>
	    		<div class="row">
			    	<div class="margCustom colCustom1 xl-3 lg-3 md-6 sm-12" v-for="item in pub" :key="item.id">
			    	<!-- NEW ANNOUNCEMENT -->
					    <q-card class="my-card">
					        <q-card-section horizontal>
						        <q-card-section class="q-pt-xs">
						        	<div class="row">
						        		<div class="text-h5 q-mt-sm q-mb-xs col-10 " style="line-height: 1.5rem;">
							          	    <strong>{{item.subject}}</strong><br>
							          	    <span><small> by {{item.created_by}}</small></span>
							            </div>
							            <div class="col-2" v-show="item.status == 'new'">
							            	<q-badge class="absolute-top-right badgeNew caps" color="orange-10" text-color="white">new</q-badge>
							            </div>
						        	</div>
						            <div class="text-grey-8" v-if="item.full_message != ''" v-html="item.message" @click="readMoreModal(item)"></div>
					    		    <div class="text-grey-8" v-else v-html="item.message"></div>
					    		    <div class="text-grey-8" v-html="item.timeAgo"></div>
					        	</q-card-section>
					      	</q-card-section>
					      	<q-separator />
                            <q-card-actions align="around" class="row text-white" :class="item.status == 'new' ? 'bg-orange-10' : 'bg-grey'">
                                <q-btn flat @click="dlAttacment(item.attachmentLink,item.attachment)">
                                    <small>
                                    <i class="fa fa-paperclip"></i>
                                    Download Attachment
                                    </small>
                                </q-btn>
                                <q-btn flat></q-btn>
                            </q-card-actions>
					    </q-card>
					</div>


				</div>
			</q-card>

		</div>

		<ReadMoreModal></ReadMoreModal>
	</div>
</template>



<script>
	import axios from 'axios';
	import mixins from '../../mixins';
	import ReadMoreModal from './AdminReadMore';

	export default {
		components:{
			"ReadMoreModal": ReadMoreModal,
		},

		data() {
			return {
				data:[],
				message: "hello world",
				showMessage: true,
				deleteDialog: false,
				publishModel: [],
				id: '',
				isPub:'',
				fullMsg: '',
				pub: [],
				nPub: []

			}
		},

		created(){
			this.eventHub.$on('readMoreAnnouncement', this.displayData);
			this.displayData();
		},

		methods: {
			displayData: function(){
				axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
				.get(`getAnnouncementList`)
				.then((res) => {
					this.pub = res.data.data.pub;
					this.nPub = res.data.data.nPub;
				})
			},

			readMoreModal: function(obj){
				this.eventHub.$emit('openReadMoreModal', { stat: true,obj: obj });
			},

			dlAttacment: function(url,filename){
			  	//alert(filename)
			    axios({
			      url: url,
			      method: 'GET',
			      responseType: 'arraybuffer',
			    }).then((response) => {
			      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
			      var fileLink = document.createElement('a');
			      fileLink.href = fileURL;
			      fileLink.setAttribute('download', filename);
			      document.body.appendChild(fileLink);

			      fileLink.click();
			    });
			},
		}
	};

</script>
<style>
	h4,h5 {
		margin:0;
	}
	.pcustom{
		font-style: italic;
	}
	.btnCustom {
		margin: 0 3px;
	}
	.q-hr {
		margin: 15px 0 0 0;
	}
	.btnCustom1 {
		position: relative;
		top: 20%;
	}
	.colCustom1{
		width: 22.2%;
		padding-bottom: 25px;
	}
	@media (pointer:coarse) {
	  .colCustom1 {
	    width: 100%;
		padding-bottom: 25px;
	  }
	  .h4cust {
	  	font-size: 1.7rem;
		}
	}

	.margCustom {
		margin: 15px 25px 5px 25px;
	}
	.aTag {
	  color: white;
	}

</style>
