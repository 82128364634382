<template>
	<div>
		<q-dialog v-model="dialog" persistent transition-show="scale" transition-hide="scale">
			<q-card class="q-bottom-sheet--list">
				<q-form ref="vForm" @submit="holdEmployeeStatus">
					<q-card-section>
						<div class="text-h6">Hold Employee</div>
					</q-card-section>
					<q-card-section>
						<div class="col-12 q-mb-md">
							<q-input v-model="companyInfo.holdEffectivity" mask="date"  label="Hold Effectivity" :rules="[ val => val && val.length > 0 || 'This field is required!']">
								<template v-slot:append>
									<i class="far fa-calendar-alt cursor-pointer" >
										<q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
											<q-date v-model="companyInfo.holdEffectivity" @input="calendarChange"/>
										</q-popup-proxy>
									</i>
								</template>
							</q-input>
							<q-input v-model="companyInfo.hold_period" label="Hold Period"  :rules="[ val => val && val.length > 0 || 'This field is required!']"  readonly/>
								<q-select v-model="companyInfo.holdReason"  :options="reasons" label="Reasons" :rules="[ val => val && val.length > 0 || 'This field is required!']" />
									<q-input v-model="companyInfo.holdDuration" label="Hold Duration"  :rules="[ val => val && val.length > 0 || 'This field is required!']"/>
									</div>
								</q-card-section>

								<q-card-actions align="right">
									<div>
										<q-btn flat label="Submit" type="submit" color="primary"/>
										<q-btn flat label="Close" color="primary" @click="closeModal"/>
									</div>
								</q-card-actions>
							</q-form>
						</q-card>
					</q-dialog>
					<!-- for deactivate or activate -->
					<q-dialog v-model="activateDialog" persistent transition-show="scale" transition-hide="scale">
						<q-card class="q-bottom-sheet--list">
							<q-form ref="vForm" @submit="activateOrDeactivate">
								<q-card-section class="row items-center">
									<div class="text-h6">{{ confirmMessage  }}</div>
									<q-space />
									<q-btn  flat round dense v-close-popup><i class="fas fa-times"></i></q-btn>
								</q-card-section>
								<q-card-actions align="right">
									<div>
										<q-btn flat label="Agree" type="submit" color="primary"/>
									</div>
								</q-card-actions>
							</q-form>
						</q-card>
					</q-dialog>
				</div>
			</template>
			<script>
			import axios from 'axios';
			import mixins from '../../mixins';

			export default {

				data() {
					return {
						confirmMessage:null,
						activateDialog:false,
						dialog:false,
						file:'',
						spinner:false,
						holdPeriodList: [],
						status: [],
						reasons: [],
						companyInfo:{
							hold_period:null,
							holdReason:null,
							holdDate:null,
							holdEffectivity:null,
							holdDuration:null,
						},
						employee:{},
						statusTo:null
					}
				},
				created(){
					this.getDropdownProperties()
					this.eventHub.$on('changeEmpStatus', this.changeStatus);

				},

				methods: {

					changeStatus:function(payload){
						this.employee = payload.emp
						this.statusTo = payload.status

						if(payload.status.toLowerCase() === 'hold'){
							this.dialog = true
						}else{
							this.confirmMessage = payload.status + ' this employee?'
							this.activateDialog = true
						}
					},

					holdEmployeeStatus:function(){
						let formData = new FormData();
						formData.append("id", this.employee.key);
						formData.append("is_active", this.statusTo);
						formData.append("hold_period", this.companyInfo.hold_period);
						formData.append("hold_effectivity", this.companyInfo.holdEffectivity);
						formData.append("hold_reason", this.companyInfo.holdReason);
						formData.append("hold_date", this.companyInfo.holdDate);
						formData.append("hold_duration", this.companyInfo.holdDuration);
						axios.create({
							baseURL : this.apiUrl,
							headers : {'Authorization' : `Bearer ${this.token}`}
						}).post('/employee/update/status/hold',formData)
						.then((res) => {
							setTimeout(() => {
								this.dialog = false
							},500)
							this.companyInfo.hold_period = null
							this.companyInfo.holdEffectivity = null
							this.companyInfo.holdReason = null
							this.companyInfo.holdDate = null
							this.companyInfo.holdDuration = null
							this.eventHub.$emit('refreshEmployeeList');
						});
					},


					activateOrDeactivate:function(){

						let formData = new FormData();
						formData.append("id", this.employee.key);
						formData.append("is_active", this.statusTo);
						axios.create({
							baseURL : this.apiUrl,
							headers : {'Authorization' : `Bearer ${this.token}`}
						}).post('/employee/update/status/deactivate',formData)
						.then((res) => {
							setTimeout(() => {
								this.activateDialog = false
							},500)
							this.eventHub.$emit('refreshEmployeeList');
						});
					},

					closeModal:function(){
						this.dialog = false
					},

					holdperiodDateOptions(date){

						const today = new Date();
						const year = today.getFullYear();
						const month1 = (today.getMonth() + 1).toString().padStart(2, "0");
						const month2 = (today.getMonth() + 2).toString().padStart(2, "0");
//const day = today.getDate().toString().padStart(2, "0");

if(this.companyInfo.hold_period === '11 to 25'){
	return date >= year +'/'+ month1 +'/'+'11' && date <= year +'/'+ month1 +'/'+'25'
}else if(this.companyInfo.hold_period === '26 to 10'){
	return date >= year +'/'+ month1 +'/'+'26' && date <= year +'/'+ month2 +'/'+'10'
}
},

calendarChange:function(){
	this.$refs.qDateProxy.hide() //close calendar
	var date = this.companyInfo.holdEffectivity.substring(8, 10)
	if(date > 10 && date < 26){
		this.companyInfo.hold_period = '11 to 25'
	}else{
		this.companyInfo.hold_period = '26 to 10'
	}
},

getDropdownProperties:function(){
	axios.create({
		baseURL : this.apiUrl,
		headers : {'Authorization' : `Bearer ${this.token}`}
	}).get('/employee/dropdown/properties')
	.then((res) => {
		this.holdPeriodList = res.data.data.holdPeriodList
		this.status = res.data.data.status
		this.reasons = res.data.data.reasons
	});
}
}
}
</script>
<style lang="css" scoped>
</style>