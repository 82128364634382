<template>
	<div class="q-pa-md">
		<div class="row">
			<div class="col-12 q-mb-md">
				<h4 class="q-ma-none">Deactivation Reason Settings</h4>
			</div>
		</div>
		<q-form ref="vForm" @submit="onSubmit">
			<div class="row">
				<div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
					<q-input v-model="reason" label="Reason" :rules="[formRules.required]" class="caps"/>
					<div class="q-gutter-sm">
						<q-radio  keep-color v-model="is_active" val="Y" label="Active" :rules="[formRules.required]"/>
						<q-radio  keep-color v-model="is_active" val="N" label="In-active" :rules="[formRules.required]"  />
					</div>
					<q-separator />
					<div class="q-pa-md q-gutter-sm">
						<q-btn  class="full-width" color="teal" type="submit" >Save</q-btn>
					</div>
				</div>
				<div class="col-9 col-lg-9 col-xs-12 col-md-6 q-mb-md q-pr-xl">
					<div class="q-pa-md">
						<q-table
						title="Deactivation Reason List"
						:data="data"
						:columns="columns"
						row-key="id"
						:pagination.sync="pagination">
						<template v-slot:body="props">
							<q-tr slot="body"  :props="props" @click="selectedRow(props)" style="cursor: pointer;">
								<q-td key="id" :props="props">{{ props.row.counter }}</q-td>
								<q-td key="name" :props="props">{{ props.row.name }}</q-td>
								<q-td key="is_active" :props="props">
									<q-chip outline :color="props.row.is_active == 'Y' ? 'green': 'red'" text-color="white">
										{{ props.row.is_active == 'Y' ? 'Active' : 'Inactive' }}
									</q-chip>
								</q-td>
								<q-td key="action" :props="props">
									<div >
										<q-btn round outline  color="red" @click="removeRow(props)"> 
											<i class="fas fa-trash"></i>
											<q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" color="red">
												<strong>Remove</strong>
											</q-tooltip>
										</q-btn>
									</div>
								</q-td>
							</q-tr>
						</template>
						<template v-slot:no-data="">
							<div class="full-width row flex-center text-red q-gutter-sm">
								<i class="fas fa-exclamation-triangle"></i>
								<span>
									No Result Found!
								</span>
							</div>
						</template>
					</q-table>
				</div>
			</div>

		</div>
	</q-form>

	<q-dialog v-model="spinner" persistent >
		<q-card class="my-card transparent no-shadow">
			<q-card-section >
				<q-spinner-gears color="cyan"  size="5.5em"/>
			</q-card-section>
		</q-card>
	</q-dialog>

	<q-dialog v-model="deleted" persistent>
		<q-card>
			<q-card-section class="row items-center">
				<span class="q-ml-sm">This file will permanently delete.</span>
			</q-card-section>
			<q-card-actions align="right">
				<q-btn flat label="Cancel" color="primary" v-close-popup />
				<q-btn flat label="Continue" color="primary" v-close-popup @click="deletionConfirmation"/>
			</q-card-actions>
		</q-card>
	</q-dialog>
</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';


export default {

	data () {
		return {
			tempID:0,
			spinner:false,
			deleted:false,
			reason:null,
			is_active:'Y',
			data:[],
			type:'reason',
			columns: [
			{ name: 'id', align: 'center', label: 'ID', field: 'id', sortable: true },
			{ name: 'name', align: 'center', label: 'Name', field: 'name', sortable: true },
			{ name: 'is_active', align: 'center', label: 'Status', field: 'is_active' , sortable: true},
			{ name: 'action', align: 'center', label: 'Action', field: '', sortable: true },
			],
			pagination: {
				rowsPerPage: 20
			},
		}
	},

	created(){
		this.displayData()
	},

	methods: {

		onSubmit : function(){
			this.spinner = true;
			let formData = new FormData();

			formData.append("name", this.reason);
			formData.append("type", this.type);
			formData.append("status", this.is_active);
			formData.append("tempID", this.tempID);

			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/admin/setting/relationship',formData)
			.then((res) => {
				this.displayData()
				this.reason = ''
				this.is_active = ''
				this.tempID = 0
				this.spinner = false;
				this.$refs.vForm.reset()
			});
		},

		displayData: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/admin/setting/${this.type}`)
			.then((res) => {
				this.data = res.data.data;
				setTimeout(() => {
					this.spinner = false
				},500)
			})

		},

		selectedRow:function(obj){
			this.reason = obj.row.name
			this.is_active = obj.row.is_active
			this.tempID = obj.row.id
		},

		removeRow:function(obj){

			this.deleted = true
			this.tempID = obj.row.id
		},

		deletionConfirmation:function(){
			this.spinner = true;
			let formData = new FormData();
			formData.append("tempID", this.tempID);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/admin/setting/delete',formData)
			.then((res) => {
				this.tempID = 0
				this.displayData()
			});
		}

	}
}
</script>
<style>
.caps {
	text-transform: uppercase
}
</style>