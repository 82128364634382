<template>
    <div class="q-gutter-sm q-pa-lg">
    	<div class="row">
    		<div class="row full-width">
		        <div class="full-width">
		          	<h4 class="q-ma-none row inline h4cust">Database Management</h4>
		        </div>
		    </div>
	    </div>
	    <div class="q-pa-md row justify-center items-start">
	    	<q-card class="card col-12 bg-grey-2">
	    		<h5 class="margCustom caps text-grey-8" ><strong>ON GOING</strong></h5>
	    		<div class="row">



				</div>
			</q-card>

		</div>
	</div>
</template>



<script>
	import axios from 'axios';
	import mixins from '../../mixins';


	export default {


		data() {
			return {
				data:[],
				message: "hello world",
				showMessage: true,
				deleteDialog: false,
				publishModel: [],
				id: '',
				isPub:'',
				fullMsg: '',
				pub: [],
				nPub: []

			}
		},

		created(){
			this.eventHub.$on('readMoreAnnouncement', this.displayData);
			//this.displayData();
		},

		methods: {

		}
	};

</script>
